export namespace Exception {
    //TODO：需要重新梳理，目前每个地方都到处写

    /**
     * @description 错误码
     */
    export enum ErrorCode {
        // 通用错误
        /**
         * 操作成功
         */
        SUCCESSFUL = 200,
        /**
         * 入参校验失败
         */
        PARM_Verification_Failed = 777,
        /**
         * 系统服务错误
         */
        Server_System_Error = 888,

        // 10*** 用户错误
        // 11*** 组织错误
        // 12*** 系统配置错误
        // 20*** 应用配置错误
        // 21*** 表单配置错误
        // 22*** 表单运行错误
        // 23*** 流程配置错误
        // 24*** 流程运行错误
        // 25*** 列表错误
    }

    export enum LoginErrorCode {
        // 成功
        Success = 0,
        // 登录方式不可用
        TypeInvalid = 200001,
        // 参数为空
        ArgumentIsNull = 200101,
        // 未授权
        NotAuth = 200201,
        // 已授权
        DingTalkAuthed = 200301,
        // 企业被禁用
        EngineDisabled = 200401,
        // 企业正在迁移
        EngineMigrating = 200402,
        // 企业被归档
        EngineArchived = 200403,
        // 正在安装
        EngineAllocating = 200404,
        // 用户不存在
        UserNotExist = 200501,
        // 用户被禁用
        UserDisabled = 200502,
        // 用户未授权
        UserDisableAuthorized = 200503,
        // 手机号不存在
        MobileNotExist = 200504,
        // 企业不存在
        EngineNotExist = 200601,
        // 企业不存在
        EngineNotExistDingTalkCorpId = 200602,
        // 企业不存在 TODO
        EngineNotExistAnonymous = 200603,
        // 扫码，钉钉错误
        DingTalkScanError = 200701,
        // 钉钉oa免登错误
        DingTalkOAError = 200801,
        // 钉钉信息配置错误
        DingTalkSettingError = 200802,
        // h3sceret错误
        H3SecretError = 200802,
        // 钉钉客户端免登错误
        DingTalkAppError = 200901,
        // 选择企业页面停留时间过长，token超期
        SecurityTokenExpiredError = 201001,
        // 验证码错误
        MobileVerifyCodeInvalid = 201101,
        // 发送验证码次数过多
        MobileVerifyCodeLimited = 201102,
        // 发送验证码失败
        SendMobileMessageFaild = 201103,
        // 密码错误
        PasswordInvalid = 201201,
        // 密码错误次数太多
        PsswordErrorTooMuch = 201202,
        // 人机验证，通过，实际使用中不会返回这个错误码
        Nvc_Success = 202100,
        // 人机验证，通过，实际使用中不会返回这个错误码
        Nvc_Success2 = 202200,
        // 人机验证，不能确定，需要唤醒滑块验证
        Nvc_Maybe_NoCaptcha = 202400,
        // 人机验证，第一次，有风险
        Nvc_Risky = 202800,
        // 人机验证，第二次，有风险
        Nvc_Risky2 = 202900,
        // 获取人机验证，配置参数失败
        Aliyun_VerifyConfigFailed = 203000,
        // 验证码验证失败的次数过多
        CaptchaVerifyLimited = 203100,
        // 更新密码失败
        UpdatePasswordFailed = 203001,
        // 系统错误
        SystemError = 200002,
        // token超期
        TokenExpired = 200102,
        // 没有配置企业微信 应用信息或配置错误
        WeChatHaveNotAgentInfo = 203101,
        WeChatAppInfoError = 203102,
        WeChatNotImplementedAssociateCorp = 203103,
        WeChatNotImplementedExternal = 203103,
        DingTalkComponentIsNull = 204001,
        DingTalkComponentSignatureError = 204002,
        // 当前企业不存在该关联组织
        AssocitedOrganizationNotExists = 205001,
        // 当前用户不是该关联组织的联系人
        NotAssocitedOrganizationContactUser = 205002,
        // 关联组织已关闭
        AssocitedOrganizationClosed = 205003,
        // 关联组织非付费组织
        AssocitedOrganizationNotCharged = 205004,
        // 关联组织邀请码异常
        AssocitedOrganizationCodeInvalid = 205005,
        // 无效的授权码
        WeChatOfficialInvalidCode = 206001,
        // 二维码未绑定手机号
        WeChatOfficialUnbindMobile = 206002,
        // 绑定失败
        WeChatOfficialBindFail = 206003,
        FeiShuEntryCorpInfoNotExist = 207001,
        // 没有配置飞书 应用信息或配置错误
        FeiShuHaveNotAgentInfo = 207002,
        FeiShuAppInfoError = 207003,
        // 企业微信三方应用信息不存在
        WeChatIsvHaveNotSuiteInfo = 207004,
        // 缺少企业微信isv应用信息或应用信息配置错误
        WeChatIsvHaveNotAgentInfo = 207005,
        // 获取企业微信三方应用扫码用户信息失败
        WeChatIsvScanUserInfoFail = 207006,
        // 获取企微服务商授权token失败
        LoadWeChatProviderAccessTokenFail = 207007,
        // 在企业微信后台，管理员在应用管理登录，获取用户失败
        WeChatOaGetUserInfoFail = 207008,
        // 不支持企微自建应用发布鉴权
        WeChatCorpPublishAutheNotSupport = 207009,
        // 获取企微应用授权token失败
        LoadWeChatSuiteAccessTokenFail = 207010,
        // 单点登录
        SSOSettingIsNull = 208001,
        SSOSettingAuthUrlIsNull = 208002,
        SSOSettingAuthError = 208003,
        SSOSettingReturnSecretError = 208004,
        SSOSettingReturnMobileError = 208005,
        SSOSettingAuthResultError = 208006,
    }

    /** 删除提醒(表单、应用、控件字段) */
    export namespace RemoveTip {
        /**
         * 依赖当前项的目标类型
         */
        export enum TargetType {
            /** 控件 */
            Control = 1,
            /** 提交规则 */
            SubmitRule = 2,
            /** 业务规则 */
            BusinessRule = 3,
            /** 自动化 */
            Automation = 4,
            /** 自定义按钮 */
            CustomAction = 5,
        }

        /**
         * 目标场景
         */
        export enum Scene {
            /** 关联范围限定 */
            AssociationFilter = 1,
            /** 数据联动 */
            DataLinkage = 2,
            /** 隐藏规则 */
            DisplayRule = 3,
            /** 计算公式 */
            ComputedRule = 4,
            /** 公式控件 */
            Formula = 5,
            /** 汇总计算 */
            Rollup = 6,
            /** 关联属性 */
            MappingProperties = 7,
            /** 关联填充 */
            MappingControls = 8,
            /** 关联表单 */
            FormQuery = 9,
            /** 自定义按钮-新增数据 */
            CustomActionCreate = 200,
        }

        /**
         * 表单设计器字段属性异常枚举
         */
        export enum FormDesignerFieldPropertyError {
            /** 隐藏规则 */
            HideRule = 1,
            /** 计算规则 */
            ComputationRule = 2,
            /** 关联表单 */
            FormQuery = 3,
            /** 关联表单配置的表单异常 */
            FormQuerySchema = 301,
            /** 关联表单筛选条件异常 */
            FormQueryFilter = 302,
            /** 关联表单配置的填充异常 */
            FormQueryMapping = 303,
            /** 关联填充子表筛选 */
            FormQueryGridFilter = 304,
            /** 关联属性 */
            MappingProperty = 4,
            /** 数据联动 */
            DataLink = 5,
            /** rollup */
            Rollup = 6,
            /** rollup字段异常 */
            RollupField = 601,
            /** rollup表单异常 */
            RollupSchema = 602,
        }

        export const TargetTypeText = {
            [TargetType.Control]: '控件',
            [TargetType.SubmitRule]: '提交规则',
            [TargetType.BusinessRule]: '业务规则',
            [TargetType.Automation]: '自动化',
            [TargetType.CustomAction]: '自定义按钮',
        };

        export const SceneText = {
            [Scene.AssociationFilter]: '数据限定范围',
            [Scene.DataLinkage]: '数据联动',
            [Scene.DisplayRule]: '隐藏条件',
            [Scene.ComputedRule]: '计算公式',
            [Scene.Formula]: '公式控件',
            [Scene.Rollup]: '汇总计算',
            [Scene.MappingProperties]: '关联属性',
            [Scene.MappingControls]: '关联填充',
            [Scene.FormQuery]: '关联表单',
            [Scene.CustomActionCreate]: '新增数据',
        };

        export namespace Response {
            export type IReferenceFieldModel = {
                appCode: string;
                appName: string;
                schemaCode: string;
                schemaName: string;
                items: IFormatReferenceFieldItem[];
            };

            export type IFormatReferenceFieldModel = {
                appCode: string;
                appName: string;
                schemaCode: string;
                schemaName: string;
                items: Record<number, IFormatReferenceFieldItem[]>;
            };

            export type ICheckFormDesignerIntegrityModel = {
                /** 有问题的字段编码 */
                propertyName: string;
                type: TargetType;
                options: FormDesignerFieldPropertyError[];
            };

            export type IFormatReferenceFieldItem = {
                /** 字段编码(没有dataField则返回key 例如: 描述控件) */
                targetCode: string;
                /** 目标场景 */
                targetFunction: Exception.RemoveTip.Scene;
                /** 字段名称 */
                targetName: string;
                /** 依赖当前项的目标类型 */
                targetType: Exception.RemoveTip.TargetType;
            };

            export type ICheckTreeStructureItem = {
                /** 字段名称 */
                propertyName: string;
                /** 层数是否超过限制 */
                isOverReferenceLevel: boolean;
                /** 是否循环引用 */
                isCircularReference: boolean;
            };

            export type ICheckTreeStructureModel = {
                /** 数据总量是否超过限制 */
                isOverLimitCount: boolean;
                items: ICheckTreeStructureItem[];
            };
        }
    }
}
