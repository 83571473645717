export namespace PCT {
    /**
     * 地址控件格式类型
     */
    export enum AddressFormatType {
        PCT = 'P-C-T',
        PC = 'P-C',
        P = 'P',
    }
    /**
     * 地址等级与对应标记
     */
    export enum AddressLevelEnum {
        province = 'P',
        city = 'C',
        district = 'T',
        street = 'T',
        area = 'T',
        town = 'T',
        detail = 'D',
    }

    /** 获取地址库数据 */
    export interface IAddressInfoModel {
        /** 文件地址 */
        fileUrl: string;
        /** 版本 */
        version?: number;
    }

    /** 地址数据结构 */
    export interface IAreaInfo {
        id: string;
        name: string;
        center: string;
        level: number;
        children: IAreaInfo[];
    }

    /** 位置选择数据结构 */
    export type MapLocationValue = {
        address: string;
        lng: number;
        lat: number;
        adcode: string;
        detail: {
            adcode: string;
            city?: string;
            citycode?: string;
            district?: string;
            province?: string;
            street?: string;
            streetNumber?: string;
            towncode?: string;
            township?: string;
        };
    };

    export namespace Response {
        /** 根据编码获取地址信息响应模型 */
        export interface IGetAddressByCodeModal {
            code: string;
            level: number;
            name: string;
            parent: IGetAddressByCodeModal;
        }
    }
}
