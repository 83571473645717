import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取工作台 */
export function getWorkbench(device: H3.WorkBench.WorkBenchType) {
    return request<H3.Http.Response.H3Response<H3.WorkBench.IWorkbench>>('/v1/workbench/config', {
        method: 'get',
        params: {
            device,
        },
    });
}

/** 保存工作台 */
export function saveWorkbench(workbenchSetting: H3.WorkBench.IWorkbench) {
    return request<H3.Http.Response.H3Response<H3.WorkBench.IWorkbench>>('/v1/workbench/saveconfig', {
        method: 'post',
        data: workbenchSetting,
    });
}

/** 获取最近使用的表单 */
export function getRecentlySchemas() {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/workbench/recentactivity', {
        method: 'get',
    });
}
