import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';
import { Util } from '@h3/h3common';

// 请求头
const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/** 加载部门下人员 */
export const getOrgUserList = (params: H3.Org.Request.IGetUserListModel) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgUserModel[]>>(Api.OrgUserList, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 加载部门或公司下子部门 */
export const getOrgDepartmentList = (params: H3.Org.Request.IGetDepartmentListModel) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgDepartmentModel[]>>(Api.OrgDepartmentList, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 加载角色列表 */
export const getOrgRoleList = () =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgRoleInfo[]>>(Api.OrgRoleList, {
        method: H3.Http.MethodType.GET,
    });

/** 外部组织列表 */
export const getOrgAssociationList = (params: { onlyIncludeCurrentUser?: boolean }) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgDepartmentModel[]>>(Api.OrgAssociationList, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 取人员详情 */
export const getOrgUserInfo = (params: H3.Org.Request.IGetOrgInfoModel) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgUserModel>>(Api.OrgUserInfo, {
        method: H3.Http.MethodType.GET,
        params,
    });

/** 取部门详情 */
export const getOrgDepartmentInfo = (params: H3.Org.Request.IGetOrgInfoModel) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgDepartmentModel>>(Api.OrgDepartmentInfo, {
        method: H3.Http.MethodType.GET,
        params,
    });

/** 搜索人员/部门 */
export const searchOrgList = (params: H3.Org.Request.ISearchOrgListParams) =>
    request<H3.Http.Response.H3Response<H3.Org.ISearchOrgListModel>>(Api.OrgSearch, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 批量取组织机构单元，包括人员/部门 */
export const getOrgList = (params: { ids: string[]; range?: string[] }) =>
    request<H3.Http.Response.H3Response<H3.Org.ISearchOrgListModel>>(Api.OrgList, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 根据id获取当前组织或下级（支持部门和角色） */
export const getOrgChild = (params: { ids: string[] }) =>
    request<H3.Http.Response.H3Response<H3.Org.ISearchOrgListModel>>(Api.OrgChild, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 根据外部组织id取分组列表 */
export const getExternalOrgDepartmentList = (params: { id: string }) =>
    request<H3.Http.Response.H3Response<H3.Org.ISearchOrgListModel>>(Api.ExternalOrgDepartmentList, {
        method: H3.Http.MethodType.GET,
        data: params,
    });

/** 人员/部门信息(视图点击人员部门-展示特殊信息) */
export const getOrgInfo = (params: { unitId: string }) =>
    request<H3.Http.Response.H3Response<H3.Org.Response.IGetOrgInfoModel>>(Api.OrgInfo, {
        method: H3.Http.MethodType.GET,
        params,
    });

/** 校验人员部门是否在设置范围之内 */
export const checkUnitRange = (params: { unitRange: string[]; unitIds: string[] }) =>
    request<H3.Http.Response.H3Response<H3.Org.IOrgUserModel[] | H3.Org.IOrgDepartmentModel[]>>(Api.CheckRange, {
        method: H3.Http.MethodType.POST,
        data: params,
        disableBizError: true,
    });

/**
 * 级联获取关联表单人员填充数据
 * @description 即处理关联表单填充关联表单填充人员部门字段场景
 */
export const batchGetFormQueryOrg = (params: H3.Org.Request.IBatchGetFormQueryOrgModel) =>
    request<H3.Http.Response.H3Response<{ users: H3.Org.IOrgUserModel[]; departments: H3.Org.IOrgDepartmentModel[] }>>(
        '/v1/org/unit/batch',
        {
            method: H3.Http.MethodType.POST,
            data: params,
        },
    );

/**
 * 根据组织机构名称获取组织机构信息
 * @description 子表导入场景，根据导入的名称查找匹配的组织信息
 */
export const getOrgListByNames = (params: { names: string[] }) =>
    request<H3.Http.Response.H3Response<Record<string, string>>>(Api.GetOrgByNames, {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/**
 * 获取外部组织
 */
export const getExtraOrganation = (options = { from: 0, pagesize: 50 }) => {
    return request<H3.Http.Response.H3ResponseV2<H3.Org.Response.IGetAssociatedOrganizationList>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetAssociatedOrganizationList',
                from: options.from,
                pagesize: options.pagesize,
                desc: true,
            }),
        },
        headers,
        requestType: 'form',
    });
};

// 外部组织切换，登录
export const organazitionLogin = (code: string) => {
    return request<H3.Http.Response.H3ResponseChangeOrg<H3.Org.Response.IorganazitionLogin>>(
        `/loginapi/v1/tokens/jwt/h3/associatedorganization?code=${code}`,
        {
            method: H3.Http.MethodType.GET,
        },
    );
};

// 获取外部组织的联系人 EngineCode不同，获取不同的企业联系人
export const getAssociatedOrgUser = (EngineCode: string, unitselectionrange?: any) => {
    return request<H3.Http.Response.H3ResponseV2<any>>('/Form/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'SheetUserAction',
                Command: 'LoadAssociatedOrganizationUsers',
                EngineCode,
                unitselectionrange,
            }),
        },
        headers,
        requestType: 'form',
    });
};
