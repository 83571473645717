import { request } from '@h3/request';
import { H3 } from '@h3/types';

/** 手动执行同步规则 */
export const executeSyncRule = (pluginCode: string, ruleId: string) => {
    const url = '/v1/plugin/syncrule/manual';
    return request<H3.Http.Response.H3Response>(url, {
        method: 'post',
        data: {
            pluginCode,
            ruleId,
        },
        disableBizError: true,
    });
};
