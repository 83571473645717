import { isEqual } from 'lodash-es';

export class OnceRequestManager<T, P> {
    /** 缓存的函数 */
    protected _promiseFunction: (params: P) => Promise<T>;

    /** 参数队列 */
    protected _processedParams: P[] = [];

    /** 返回的值队列 */
    protected _returnValueList: Array<T> = [];

    /** 请求队列 */
    protected _promiseList: Array<Promise<T> | null> = [];

    constructor(initPromise: (params: P) => Promise<T>) {
        this._promiseFunction = initPromise;
    }

    /** 初始化 */
    init(params: P) {
        const index = this.getParamIndex(params);

        if (index <= -1) {
            const paramsIndex = this._processedParams.length;
            this._processedParams.push(params);
            // promise
            const _promise = this._promiseFunction({ ...params });
            // 加入队列
            this._promiseList[paramsIndex] = _promise;
            // 执行
            _promise
                .then((res) => {
                    this._returnValueList[paramsIndex] = res;
                    this._promiseList[paramsIndex] = null;
                })
                .catch(() => {
                    this._promiseList[paramsIndex] = null;
                    this.clear({ ...params });
                });
            return _promise;
        }
        // 已经执行完毕
        if (this._returnValueList.length > index) {
            // 在 returnValue 中能够找到返回值
            return new Promise<T>((resolve) => {
                resolve(this._returnValueList[index]);
            });
        }
        // 正在执行中
        return this._promiseList[index] as Promise<T>;
    }

    /** 刷新(不使用缓存中的值) */
    refresh(params: P) {
        const index = this.getParamIndex(params);

        if (index <= -1) {
            return this.init({ ...params });
        }

        let _promise = this._promiseList.at(index);
        // 返回正在执行的返回值
        if (_promise) {
            return _promise;
        }
        // promise
        _promise = this._promiseFunction({ ...params });
        // 加入队列
        this._promiseList[index] = _promise;
        // 执行
        _promise
            .then((res) => {
                this._returnValueList[index] = res;
                this._promiseList[index] = null;
            })
            .catch(() => {
                this._promiseList[index] = null;
            });
        return _promise;
    }

    /** 清空某个参数的缓存 */
    clear(params: P | []) {
        if ((params as [])?.length === 0) {
            this._promiseList.length = 0;
            this._returnValueList.length = 0;
            this._processedParams.length = 0;
        } else {
            const index = this._processedParams.findIndex((item) => isEqual(item, params));
            // 如果不存在，直接返回
            if (index <= -1) {
                return;
            }
            this._promiseList.splice(index, 1);
            this._returnValueList.splice(index, 1);
            this._processedParams.splice(index, 1);
        }
    }

    private getParamIndex(params: P) {
        return this._processedParams.findIndex((item) => isEqual(item, params));
    }
}

export function OnceRequest<T, P>(fn: (params: P) => Promise<T>) {
    if (fn instanceof Function) {
        return new OnceRequestManager<T, P>(fn);
    }
}

export default OnceRequest;
