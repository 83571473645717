import { createApp } from 'vue';
import { createWebHistory } from 'vue-router';
import { setupDayjs } from '@h3/h3dayjs';
import App from './App.vue';
import { setupStore } from '@/store/index';
import { setupAntd } from './plugins/antd';
import { setupRouter } from './router';
import { setupAssets } from './plugins/assets';
import initRequest from './plugins/request';
import { initEntry } from './plugins/context';
import { setupMonitor } from './plugins/monitor';

const APP_NAME = 'H3YUN_NEXT_ENTRY';

// 移动端适配
import '@/assets/js/amfe-flexible';
initRequest(); // 初始化请求
initEntry(); // 初始化数据上下文请求框架

setupAssets();

const app = createApp(App);

setupDayjs();
setupStore(app);
setupAntd(app); // 注册全局antd组件
setupMonitor(app, APP_NAME);

setupRouter(app, { history: createWebHistory('entry') });

app.mount('#app');
