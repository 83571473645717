import { H3 } from '@h3/types';
import { request } from '@h3/request';

/**
 * 首页查询功能
 * 返回：SearchModels(查询点)  SearchNodes(查询应用表单)  Total (总数)
 * */
export const GetGlobalSearchResult = (word: string, pageIndex: number, pageSize: number) => {
    const url = `/GlobalSearch/SearchByKey?keyword=${encodeURIComponent(
        word,
    )}&pageIndex=${pageIndex}&pageCount=${pageSize}`;
    return request<H3.GlobalSearch.Response.ISearchResult>(url, {
        method: 'get',
    });
};

/** 获取查询应用表单 */
export function getHomeSearchModels(word: string, pageIndex: number, pageSize: number) {
    const url = `/v1/toolkit/fulltext/search?keyword=${encodeURIComponent(
        word,
    )}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return request<H3.Http.Response.H3Response<H3.GlobalSearch.Response.IModalReturnData>>(url, {
        method: 'get',
    });
}

/** 获取查询点 */
export function getHomeSearchNodes(word: string) {
    const url = `/v1/fulltextsearch/nodes?keyword=${encodeURIComponent(word)}`;
    return request<H3.Http.Response.H3Response<H3.GlobalSearch.INodeItem[]>>(url, {
        method: 'get',
    });
}

/**
 * 首页查询功能
 * 返回：SearchModels(查询点)  SearchNodes(查询应用表单)  Total (总数)
 * */
export async function getHomeSearchResult(word: string, pageIndex: number, pageSize: number) {
    const [modelsResponse, nodesResponse] = await Promise.all([
        getHomeSearchModels(word, pageIndex, pageSize),
        getHomeSearchNodes(word),
    ]);
    const result = {
        searchModels: modelsResponse.returnData.items,
        searchNodes: nodesResponse.returnData,
        total: modelsResponse.returnData.totalCount,
    };
    return result;
}
