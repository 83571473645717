export namespace Operate {
    //TODO：文件名 operate ? 这里看起来的是应用的安装状态
    export enum InstallStatusType {
        /** 未安装 */
        INSTALL,
        /** 已安装 */
        INSTALLED,
        /** 安装中 */
        INSTALLING,
    }

    export enum InstallNameType {
        /** 未安装 */
        INSTALL = '未安装',
        /** 已安装 */
        INSTALLED = '已安装',
        /** 安装中 */
        INSTALLING = '安装中',
    }

    /** 请求运营数据时的路由 */
    export enum OperateOpenType {
        /** 工作台 */
        Home = 'Home',

        /** 列表页 */
        AppList = 'AppList',
    }

    export interface ISetCorpInfo {
        /** 公司名称 */
        corpName: string;

        /** 所属行业 */
        industry: string;
    }

    /**
     * 运营推送数据结构
     */
    export interface IOperatePush {
        /** 唯一标识 */
        id: string;

        /** 推送类型（这个枚举中的类型后端不一定都有可能返回，但这不重要，现在前端只显示内容推送） */
        type: OperatePushType; // TODO 后续需要新接口请求时只返回内容推送，那这个字段理论上可以删除

        /** 推送标题 */
        title: string;

        /** 推送内容 */
        content: string;

        /** 推送图片地址 */
        imageUrl: string;

        /** 另一个推送图片的地址 */
        imageUrl2: string;

        /** 推送的显示位置 */
        placeHolderType: OperatePushPositionType;

        /** 推送跳转类型 */
        jumpType: OperatePushJumpType;

        /** 推送跳转地址 */
        jumpUrl: string;

        /** 推送优先级 */
        prority: number;

        /** 推送关闭类型 */
        closeMode: OperatePushCloseType;

        /** 推送选择的模板 */
        classicTemplates: Template.AppTemplateInfo[];

        /** 推送可执行的操作 */
        actions: string;

        // ==============================================================
        // 上述字段不是每个推送都会用到，不同类型的推送可能只用到其中部分的字段
        // 下面的字段前端应该是用不到的，但是接口会有返回
        // ==============================================================

        from: string;
        minimumShouldMatch: number;
        mustTags: string;
        payTypes: string;
        shouldTags: string;
        userTags: string;
    }

    /** 运营推送类型 */
    export enum OperatePushType {
        /** 更新日志推送 */
        UpdateLog = 14,

        /** 内容推送 */
        ContentPush = 15,

        /** 触发条件的内容推送 */
        ConditionPush = 17,

        /** 安装模板 */
        InstallingTemplate = 18,

        /** 打开模板 */
        OpenTemplate = 19,

        /** 新版列表引导推送 */
        BetaListGuid = 25,
    }

    /** 运营推送位置类型 */
    export enum OperatePushPositionType {
        /** PC顶部导航栏 */
        TopNavigation = 10,

        /** 移动端表单详情页顶部 */
        MobileFormHeader = 13,

        /** 移动端首页Banner */
        MobileBanner = 16,

        /** 中间弹窗 */
        Middle = 20,

        /** PC右下弹窗 */
        BottomRight = 30,

        /** PC右下侧边（侧边工具栏智能云客服处推送） */
        BottomRightSide = 31,

        /** 中间图片弹窗 */
        MiddlePic = 40,

        /** 运营首页推送 */
        OperateHomePush = 50,
    }

    /** 运营推送跳转类型 */
    export enum OperatePushJumpType {
        /** 未指定 */
        Unspecified = -1,

        /** 不跳转 */
        NoLink = 1,

        /** 跳转模板中心 */
        AppStore = 2,

        /** 跳转社区 */
        Club = 3,

        /** 跳转客服 */
        CustomerService = 4,

        /** 大数据报告（在目前的运营系统中这个叫“PC登录重定向”） */
        BigDataReport = 5,

        /** 其他链接（自定义链接） */
        Other = 9,
    }

    /** 推送关闭类型 */
    export enum OperatePushCloseType {
        /** 未指定 */
        Unspecified = -1,

        /** 允许手动关闭且关闭后不再显示 */
        AllowClose = 0,

        /** 不允许手动关闭且始终显示 */
        NoClose = 1,

        /** 不允许关闭但查看后自动关闭 */
        AutoCloseAfterVisit = 2,
    }

    /** 处理推送时的操作类型 */
    export enum OperatePushHandleType {
        /** 安装模板 */
        InstallTemplate = 'InstallTemplate',

        /** 打开模板 */
        OpenTemplate = 'OpenTemplate',

        /** 查看详情 */
        ReadMore = 'ReadMore',

        /** 关闭推送 */
        Close = 'Close',
    }

    /** 解决方案类型枚举 */
    export enum SolutionType {
        APaaS = 1,

        SaaS = 2,

        Template = 3,

        Package = 4,
    }

    /** 功能开关名称枚举 */
    export enum FunctionSwitchNameEnum {
        /** AI能力是否开放 */
        OpenAIEnabled = 'openai',

        /** 是否新前端 */
        IsNextFront = 'NextFront',

        /** 统计分析AI能力是否开放 */
        AnalysisAIEnabled = 'AnalysisAI',

        /** 公式型控件是否可用于计算 */
        EnableFormulaControl = 'EnableFormulaControl',

        /** 是否开启高级数据源 */
        AdvancedDataSourceEnabled = 'AdvancedDataSourceEnabled',

        /** 是否1.0表单升级用户 */
        SwitchFormToV2 = 'SwitchFormToV2',

        /** 是否新表单3.0 */
        IsFormV3 = 'FormV3',

        /** 是否新表单设计器3.0 */
        IsNextFormDesigner = 'FormDesignerV3',

        /** 是否支持rollup控件 */
        FormRollupEnable = 'FormControl_Rollup_Enabled',
        /** 是否支持自动化 */
        // EnableAutomation = 'EnableAutomation',

        /** 是否支持自定义控件 */
        FormDynamicEnable = 'FormControl_Dynamic_Enabled',

        /** 新版按钮逻辑 */
        // FormButtonDefinitionEnabled = 'FormButtonDefinitionEnabled',

        /** 是否开启外部组织 */
        AssociatedOrganizationEnabled = 'AssociatedOrganizationEnabled',

        /** 是否开启报表升级仪表盘 */
        EnableUpgradeReport = 'EnableUpgradeReport',

        /** 是否支持第三方单点登录集成 */
        SSOEnabled = 'SSO_Enabled',
        /** 新版打印按钮逻辑 */
        PrintTemplate_Role_Enabled = 'PrintTemplate_Role_Enabled',

        /** 是否开启了新版子表 */
        NewSubTableEnabled = 'IsNewSubTable',
        /** 是否开启了业务规则的企业级开关 */
        BusinessRuleEnabled = 'EnableBusinessRule',
        /** 是否开启了层级视图开关 */
        HierarchyEnabled = 'HierarchyEnabled',
    }

    export interface LicenseInfo {
        /** 规格名称 */
        itemName: string;

        /** 过期时间 */
        expiredDate: string;

        /** 是否免费版 */
        isFreeVersionItem: boolean;

        /** 解决方案编码 */
        solutionCode: string;

        /** 解决方案类型 */
        solutionType: SolutionType;

        /** 解决方案名称 */
        solutionName: string;

        /** 引擎编码 */
        engineCode: string;

        /** 业务数据量限制 */
        bizObjectLimit: number;

        /** 业务数据量 */
        bizObjectCount: number;

        /** 附件量限制（单位M） */
        fileSizeLimit: number;

        /** 附件量（单位M） */
        fileSizeCount: number;

        /** 功能许可 */
        functionLicense: {
            /** 定向发布数限制 */
            directionalReleaseLimit: number;

            /** 定向发布数 */
            directionalReleaseCount: number;
        };

        /** 应用数量限制 */
        appLimit: number;

        /** 应用数量 */
        appCount: number;

        /** 表单数量限制 */
        schemaLimit: number;

        /** 表单数量 */
        schemaCount: number;
    }

    export namespace Template {
        // export enum InstallStatusEnum {
        //     /** 未安装 */
        //     INSTALL,
        //     /** 已安装 */
        //     INSTALLED,
        //     /** 安装中 */
        //     INSTALLING,
        // }

        // export enum InstallNameEnum {
        //     /** 未安装 */
        //     INSTALL = '未安装',
        //     /** 已安装 */
        //     INSTALLED = '已安装',
        //     /** 安装中 */
        //     INSTALLING = '安装中',
        // }

        export interface AppTemplateInfo {
            /** 编码 */
            code: string;
            /** 下载数 */
            installCount?: string;
            /** 是否空闲 */
            isFree?: string;
            /** 是否安装(老接口) */
            isInstalled?: boolean;
            /** 是否安装 */
            install: InstallStatusType;
            /** 安装百分比 */
            percent: number;
            /** logo地址 */
            logo?: string;
            icon: string;
            /** 名称 */
            name: string;
            /** 描述 */
            summary: string;
            /** 安装状态 */
            status?: string;
            /** 加载状态 */
            loading: boolean;
            /** 定时器(前端) */
            timer: ReturnType<typeof setInterval> | null;
        }

        export interface AppTemplateModel {
            code: string;
            name: string;
            summary: string;
            icon: string;
            install: InstallStatusType;
            percent: number;
            loading: boolean;
        }
    }

    export namespace Request {
        /** 获取应用模板 */
        export interface IAppTemplateModel {
            /** index */
            pageIndex: number;
            /** size */
            pageSize: number;
        }

        /** 安装模板 */
        export interface IInstallTemplateModel {
            templateCode: string;
            engineCode: string;
            userId: string;
        }

        /** 发布应用到模板中心接口参数 */
        export interface IPublishTemplateModel {
            /** 应用编码 */
            appCode: string;

            /** 模板描述 */
            description: string;

            /** 升级描述 */
            upgradeInfo: string;

            /** 是否包含示例数据 */
            sampleData: boolean;
        }

        /** 定向发布接口参数 */
        export interface IShareAppModel {
            /** 发布的应用编码 */
            appCode: string;

            /** 发布到的企业编码列表 */
            engineCodes: string[];

            /** 发布的信息 */
            message: string;

            // 是否开放
            isClosed: boolean;
        }

        /** 处理运营推送参数 */
        export type HandleOperatePushParams = {
            TargetId: string;
            Type: OperatePushType;
            TemplateCode: string;
            Close: boolean;
            OperateCode: OperatePushHandleType;
        };
    }

    export namespace Response {
        /** 获取已发布的模板信息响应类型 */
        export interface IGetPublishedTemplateModel {
            /** 应用编码 */
            appCode: string;

            /** 模板名称 */
            displayName: string;

            /** 模板描述 */
            description: string;
        }

        /** 安装模板进度 */
        export interface IInstallTemplateProgressModel {
            /** 进度 */
            progress: number;
            /** 是否完成 */
            complete: boolean;
            /** 安装后的应用编码 */
            appCode: string;
        }
        /** 请求推送数据时的响应 */
        export type GetOperatePushModal = {
            /** 新注册用户调查 */
            newRegistSurvey: boolean;

            /** 新付费用户调查 */
            newPaidSurvey: boolean;

            /** banner列表，该字段内容在PC端不显示 */
            bannerList: IOperatePush[] | null;

            /** 运营推送数据 */
            pushNotification: IOperatePush[] | null;

            /** 新注册用户创建企业弹窗 */
            newRegistFromH3: boolean;
        };
    }

    /** 获取获取运营相关系统链接地址 */
    export type OperateLinkModel = {
        /** 模板中心 */
        appStore: string;
    };

    /** 获取智能云客服信息 */
    export type GetCustomerServiceModel = {
        /** 钉钉客服链接 */
        dingtalkGroupLink?: string;
        /** 浏览器客服地址 */
        browserUrl?: string;
    };

    /** 获取氚云社区链接 */
    export type GoBBSModel = {
        url: string;
        mapId: string;
        nonceStr: string;
        sign: string;
        token: string;
    };

    export interface OperateLink {
        appStore: string;
    }
}
