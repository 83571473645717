export namespace Engine {
    /** 企业接入类型 */
    export enum EntryType {
        /** 氚云自有流量接入 */
        H3 = 'H3',
        /** 钉钉ISV接入 */
        DingTalk = 'DingTalk',
        /** 钉钉企业接入 */
        DingTalkCorp = 'DingTalkCorp',
        /** 微信接入 */
        WeChat = 'WeChat',
        WeChatDK = 'WeChatDK',
        /** 企微ISV接入 */
        WeChatISV = 'WeChatISV',
        /** 飞书接入 */
        FeiShuCorp = 'FeiShuCorp',
        FeiShuISV = 'FeiShuISV',
    }

    /** 企业接入类型文本 */
    export const EntryTypeText = {
        [EntryType.H3]: '自有企业',
        [EntryType.DingTalk]: '钉钉',
        [EntryType.DingTalkCorp]: '钉钉',
        [EntryType.WeChat]: '企业微信',
        [EntryType.WeChatDK]: '企业微信',
        [EntryType.WeChatISV]: '企业微信',
        [EntryType.FeiShuCorp]: '飞书',
        [EntryType.FeiShuISV]: '飞书',
    };

    /** 企业类型，后续取消 */
    export enum EngineType {
        SaasDevelopment = 'SaaSDevelopment',
        Product = 'Product',
        PrivateDevelopment = 'PrivateDevelopment',
    }

    /** 企业信息详情 */
    export class EngineInfo {
        /** 引擎编码 */
        engineCode: string = null!;
        /** 引擎名称 */
        engineName: string = null!;
        /** 开发者编码 */
        devCode: string = null!;
        /** 公司ID(用于获取组织树) */
        companyId: string = null!;
        /** 企业编码 */
        entryCorpId: string = null!;
        /** 是否开发者企业 */
        get isDeveloper() {
            if (!this.devCode || !/[^\s]/.test(this.devCode)) {
                return false;
            } else {
                // 不为空
                return true;
            }
        }
        /** 企业接入类型 */
        entryType: EntryType = null!;
        /** 企业引擎类型 */
        engineType: EngineType = null!;
        /** 企业logo*/
        logoUrl: string = null!;
        /** 当前企业的许可信息 */
        license: unknown | undefined = undefined;
    }

    /**
     * 引擎列表
     */
    export interface IEngine {
        // 引擎编码
        code: string;
        // 引擎名称
        name: string;
    }

    export interface IConnectEngine {
        /** 组织编码 */
        code: string;
        /** 组织名称 */
        name: string;
        /** 是否主企业 */
        primary: boolean;
    }

    /** 关联组织数据结构 */
    export interface IRelationCorp {
        /** 企业编码 */
        engineCode: string;
        /** 公司名称 */
        companyName: string;
        /** 创建时间 */
        createdTime: string;
        /** 到期时间 */
        expiryTime: string;
        /** 企业许可版本 */
        version: string; // TODO 枚举？
        /** 企业许可人数 */
        userLimit: number;
    }

    export namespace Response {
        export interface IConnectEngineModel {
            engines: IConnectEngine[];
            total: number;
        }
    }
}
