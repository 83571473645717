import { Control } from '../control';
import { Workflow } from '../workflow';

export namespace Schema {
    /** 属性列结构 */
    export interface IProperty<T = Record<string, any>, P = Record<string, any>> {
        /** 字段编码 */
        name: string;

        /** 业务数据类型 */
        dataType: Control.BizDataType;

        /** 控件类型 */
        controlType: Control.FormControlKeys;

        /** 字段显示名称 */
        displayName: string;

        /** 字段配置（实际类型应为IControlOptions，该类型定义在控件体系中）（前后端都需要的、重要的配置）*/
        extension: T;

        /** 字段选项（实际类型应为IControlSettings, 该类型定义在控件体系中）（仅前端需要的配置） */
        controlSettingOptions?: P;
    }

    /** schema 结构 */
    export interface ISchema {
        /** 表单编码 */
        schemaCode: string;

        /** 主表编码 */
        parentSchemaCode: string;

        /** 表单名称 */
        displayName: string;

        /** 数据标题生成规则 */
        nameSchema: string;

        /** 表单字段属性 */
        properties: IProperty[];

        /** 子表数据 */
        childSchemas: ISchema[];

        /** 时间戳 */
        timestamp: number;

        /** 树形展示字段编码 */
        treeStructureControlCode: string;
    }

    /** 字段权限 */
    export interface IFieldPermission {
        /** 是否必填 */
        required: boolean;

        /** 是否可编辑 */
        editable: boolean;

        /** 是否可见 */
        visible: boolean;

        /** 是否可打印 */
        printable: boolean;
    }

    /** 系统字段编码枚举 */
    export enum SystemDataField {
        /** 流水号 */
        SeqNo = 'SeqNo',

        /** 创建人 */
        Creator = 'CreatedBy',

        /** 创建时间 */
        CreatedTime = 'CreatedTime',

        /** 拥有者 */
        Owner = 'OwnerId',

        /** 所属部门 */
        OwnerDept = 'OwnerDeptId',

        /** 修改时间 */
        ModifiedTime = 'ModifiedTime',
    }

    export namespace Request {
        export interface IGetSchemaModel {
            schemaCode: string;
            timestamp?: number;
        }
    }

    export namespace Response {}
}
