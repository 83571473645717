import { H3 } from '@h3/types';
import Dexie, { type Table } from 'dexie';
import { Util } from '@h3/h3common';
import { TableSchema } from './table/schema';
import { TableViewSetting } from './table/view-setting';
import { TableFormGridSetting } from './table/form-grid-setting';
import { TableViewListSetting } from './table/view-list-setting';
import { TableViewPersonalization } from './table/view-personalization';

const AppDatabaseVersion = '3';

class Database extends Dexie {
    [H3.DB.DBTable.SCHEMA]!: Table<TableSchema>;
    [H3.DB.DBTable.VIEW_SETTING]!: Table<TableViewSetting>;
    [H3.DB.DBTable.FORM_GRID_SETTING]!: Table<TableFormGridSetting>;
    [H3.DB.DBTable.VIEW_LIST_SETTING]!: Table<TableViewListSetting>;
    [H3.DB.DBTable.VIEW_PERSONALIZATION]!: Table<TableViewPersonalization>;

    /** 企业编码 */
    private engineCode: string;

    constructor(name: string) {
        super(name);

        this.engineCode = Util.Storage.CookieStorage.get(H3.Http.Cache.H3_ENGINE_CODE);

        this.version(4).stores({
            [H3.DB.DBTable.SCHEMA]: `&${H3.DB.DBField.ID}`,
            [H3.DB.DBTable.VIEW_SETTING]: `&${H3.DB.DBField.ID}`,
            [H3.DB.DBTable.FORM_GRID_SETTING]: `&${H3.DB.DBField.ID}`,
            [H3.DB.DBTable.VIEW_LIST_SETTING]: `&${H3.DB.DBField.ID}`,
            [H3.DB.DBTable.VIEW_PERSONALIZATION]: `&${H3.DB.DBField.ID}`,
        });

        this[H3.DB.DBTable.SCHEMA].mapToClass(TableSchema);
        this[H3.DB.DBTable.VIEW_SETTING].mapToClass(TableViewSetting);
        this[H3.DB.DBTable.FORM_GRID_SETTING].mapToClass(TableFormGridSetting);
        this[H3.DB.DBTable.VIEW_LIST_SETTING].mapToClass(TableViewListSetting);
        this[H3.DB.DBTable.VIEW_PERSONALIZATION].mapToClass(TableViewPersonalization);
    }

    /** 设置schema */
    async setSchema(value: H3.Schema.ISchema) {
        const id = `${this.engineCode}_${value.schemaCode}`;
        const schema = new TableSchema({ id, value });

        return await this.getTable(H3.DB.DBTable.SCHEMA)?.put(schema);
    }

    /** 获取schema */
    async getSchema(key: string) {
        const id = `${this.engineCode}_${key}`;
        return (await this.getTable(H3.DB.DBTable.SCHEMA)?.get(id))?.value;
    }

    /** 删除schema */
    async deleteSchema(key: string) {
        const id = `${this.engineCode}_${key}`;
        return await this.getTable(H3.DB.DBTable.SCHEMA)?.delete(id);
    }

    /** 设置视图配置 */
    async setViewSetting(key: string, value: H3.View.IViewSettingModel) {
        const id = `${this.engineCode}_${key}`;
        const setting = new TableViewSetting({ id, value });
        return await this.getTable(H3.DB.DBTable.VIEW_SETTING)?.put(setting);
    }

    /** 获取视图配置 */
    async getViewSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return (await this.getTable(H3.DB.DBTable.VIEW_SETTING)?.get(id))?.value;
    }

    /** 删除视图配置 */
    async deleteViewSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return await this.getTable(H3.DB.DBTable.VIEW_SETTING)?.delete(id);
    }

    /** 设置表单子表配置 */
    async setFormGridSetting(key: string, value: Record<string, H3.DB.DBFormGridSetting>) {
        const id = `${this.engineCode}_${key}`;
        const setting = new TableFormGridSetting({ id, value });
        return await this.getTable(H3.DB.DBTable.FORM_GRID_SETTING)?.put(setting);
    }

    /** 获取表单子表配置 */
    async getFormGridSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return (await this.getTable(H3.DB.DBTable.FORM_GRID_SETTING)?.get(id))?.value;
    }

    /** 删除表单子表配置 */
    async deleteFormGridSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return await this.getTable(H3.DB.DBTable.FORM_GRID_SETTING)?.delete(id);
    }

    /** 设置视图列表配置 */
    async setViewListSetting(key: string, value: H3.DB.DBViewListSetting) {
        const id = `${this.engineCode}_${key}`;
        const setting = new TableViewListSetting({ id, value });
        return await this.getTable(H3.DB.DBTable.VIEW_LIST_SETTING)?.put(setting);
    }

    /** 获取视图列表配置 */
    async getViewListSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return (await this.getTable(H3.DB.DBTable.VIEW_LIST_SETTING)?.get(id))?.value;
    }

    /** 删除视图列表配置 */
    async deleteViewListSetting(key: string) {
        const id = `${this.engineCode}_${key}`;
        return await this.getTable(H3.DB.DBTable.VIEW_LIST_SETTING)?.delete(id);
    }

    /** 获取视图个性化配置 */
    async getViewPersonalization(key: string) {
        const id = `${this.engineCode}_${key}`;
        return (await this.getTable(H3.DB.DBTable.VIEW_PERSONALIZATION)?.get(id))?.value;
    }

    /** 设置视图个性化配置 */
    async setViewPersonalization(key: string, value: H3.DB.DBViewPersonalization) {
        const id = `${this.engineCode}_${key}`;
        const setting = new TableViewPersonalization({ id, value });
        return await this.getTable(H3.DB.DBTable.VIEW_PERSONALIZATION)?.put(setting);
    }

    /** 删除视图个性化配置 */
    async deleteViewPersonalization(key: string) {
        const id = `${this.engineCode}_${key}`;
        return await this.getTable(H3.DB.DBTable.VIEW_PERSONALIZATION)?.delete(id);
    }

    private getTable(name: string) {
        try {
            return this.table(name);
        } catch (error) {
            return null;
        }
    }
}

const DB = new Database(`H3YunDBV${AppDatabaseVersion}`);

export default DB;
