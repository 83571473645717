import { Control } from '../control';
import { Filter } from '../filter';

export namespace Automation {
    // 消息链接值类型
    export const enum EValueType {
        //固定值
        Fixed = 1,
        //动态值
        Property = 2,
        // 查看数据
        View = 3,
        // 新增数据
        Create = 4,
    }

    // 消息链接类型
    export const enum ELinkType {
        Custom = 1,
        Form = 2,
    }

    /** 机器人类型 */
    export enum ERobotType {
        dingtalk = 1,
        wechat = 2,
    }

    // 自动化执行数据集类型
    export const enum DataSetType {
        // 当前数据
        LatestData = 1,
        // 旧数据
        OldData = 2,
        // 新增的数据
        AddedData = 3,
        // 删除的数据
        DeletedData = 4,
        // 更新的数据新的
        LastestUpdatedData = 5,
        // 更新的数据旧的
        OldUpdatedData = 6,
        Empty = '',
    }

    // 更新或新增值的类型
    export const enum ValueSetType {
        Set = 1, // 设为
        Append = 2, // 追加
        Clear = 3, // 清空
    }

    /**
     * 触发器类型
     */
    export const enum ExecuteType {
        ChangingData = 10,
        NewData = 20,
        AfterSubmit = 30,
        DeleteData = 40,
        BeforeSubmit = 50,
        SpecifiedDate = 60,
        TimeTrigger = 100,
        /// <summary>
        /// 数据从非生效变更为生效时
        /// </summary>
        BizObjectEffected = 111,

        /// <summary>
        /// 生效的数据更新时
        /// </summary>
        EffectedBizObjectUpdated = 112,

        /// <summary>
        /// 数据从生效时变更为非生效时，包括删除
        /// </summary>
        BizObjectNotEffected = 113,
        BizObjectEffectedOrEffectiveBizObjectUpdated = 114, // 数据生效或更新
        Webhook = 200,
        DingConnection = 300,
        Button = 400,
        Custom = 401, // 自定义触发
    }

    export interface ExecuteOption {
        value: ExecuteType;
        label: string;
        icon: string;
        prefix: string;
        color: string;
        background: string[];
        desc: string;
        img?: string;
    }

    // 自动化触发类型
    export const ExecuteTypeOptions: ExecuteOption[] = [
        {
            value: ExecuteType.BizObjectEffected,
            label: '数据生效时',
            icon: 'auto-take-effect-o',
            prefix: '数据生效时的',
            color: '#00d495',
            background: ['#36db8c', '#10c970'],
            desc: '表单数据提交，流程表单审批通过',
            img: 'https://static.h3yun.com/common-resource/pc/form-designer/new.png',
        },
        {
            value: ExecuteType.EffectedBizObjectUpdated,
            label: '数据更新时',
            prefix: '数据更新时的',
            icon: 'auto-update-o',
            color: '#107fff',
            background: ['#5a98ff', '#1f73ff'],
            desc: '数据生效后被修改',
            img: 'https://static.h3yun.com/common-resource/pc/form-designer/new.png',
        },
        {
            value: ExecuteType.BizObjectEffectedOrEffectiveBizObjectUpdated,
            label: '数据生效或更新时',
            prefix: '数据生效或更新时的',
            icon: 'ua-stoke',
            color: '#107fff',
            background: ['#5a98ff', '#1f73ff'],
            desc: '数据生效后重新编辑提交时',
            img: 'https://static.h3yun.com/common-resource/pc/form-designer/new.png',
        },
        {
            value: ExecuteType.BizObjectNotEffected,
            label: '数据作废时',
            prefix: '数据作废时的',
            icon: 'auto-lapse-o',
            color: '#FF7527',
            background: ['#ff5f5f', '#ff7777'],
            desc: '表单数据删除，流程表单重新激活/被删除',
            img: 'https://static.h3yun.com/common-resource/pc/form-designer/new.png',
        },
        {
            value: ExecuteType.ChangingData,
            label: '数据发生变化时',
            prefix: '数据发生变化时的',
            icon: 'auto-variation-o',
            color: '#107fff',
            background: ['#b357ff', '#c17bfa'],
            desc: '产生新数据/编辑数据/流程节点变更',
            img: '',
        },
        {
            value: ExecuteType.NewData,
            label: '产生新数据时',
            prefix: '产生新数据时的',
            icon: 'auto-new-data-o',
            color: '#00d495',
            background: ['#1bbded', '#4ed7ff'],
            desc: '新增数据/发起流程/暂存草稿',
            img: '',
        },
        {
            value: ExecuteType.DeleteData,
            label: '数据删除时',
            prefix: '数据删除时的',
            icon: 'auto-delete',
            color: '#FF7527',
            background: ['#ff7448', '#ff8f6c'],
            desc: '任意数据被删除',
            img: '',
        },
        {
            value: ExecuteType.AfterSubmit,
            label: '新增提交数据',
            prefix: '新增提交数据的',
            icon: 'auto-submit-data-o',
            color: '#6238FF',
            background: ['#0fc6b5', '#1edcca'],
            desc: '提交新增数据或提交流程时',
            img: '',
        },
        {
            value: ExecuteType.SpecifiedDate,
            label: '指定日期触发',
            prefix: '指定日期触发的',
            icon: 'auto-date-o',
            color: '#00D495',
            background: ['#36db8c', '#10c970'],
            desc: '按表单内日期字段的时间触发',
            img: '',
        },
        {
            value: ExecuteType.TimeTrigger,
            label: '定时触发',
            prefix: '定时触发的',
            icon: 'auto-time-o',
            color: '#107FFF',
            background: ['#3d86ff', '#005ffc'],
            desc: '自定义时间触发',
            img: '',
        },
        {
            value: ExecuteType.Custom,
            label: '自定义触发',
            prefix: '自定义触发的',
            icon: 'auto-Custom-triggers-o',
            color: '#107fff',
            background: ['#fecd14', '#ffd73f'],
            desc: '可被自定义代码/按钮调用，实现便捷触发',
            img: '',
        },
    ];

    /**
     * 动作所属类型
     */
    export const enum ActionBelongType {
        Form = 1,
        List = 2,
    }

    /**使用按钮设置面板的不同场景 */
    export const enum ButtonConfigScene {
        ListBtn = 'List',
        FormBtn = 'Form',
    }

    export namespace Condition {
        //重复周期
        export const enum RepeatCycle {
            Once = 0, // 只执行一次
            Daily = 1, // 每天
            Weekly = 2, // 每周
            Biweekly = 3, // 每两周
            Monthly = 4, // 每月
            Quarterly = 5, // 每季度
            Semiannual = 6, // 每半年
            Yearly = 7, // 每年
            Weekdays = 8, // 每工作日
            Weekends = 9, // 每周末
            Hourly = 10, // 每小时
            Minutely = 11, // 每分钟
            Secondly = 12, // 每10秒
        }
        // 触发器开始执行时间与参考时间的关系
        export const enum TimeRelation {
            Before = 0,

            After = 1,

            Current = 2,
        }

        /* 日期触发 end */
        export enum Frequency {
            NoRepeat = 0,
            Daily = 1,
            Weekly = 2,
            Monthly = 3,
            Yearly = 4,
            Custom = 5,
        }

        export enum FrequencyUnit {
            Undefined = 0,
            Day = 1,
            Week = 2,
            Month = 3,
            Year = 4,
        }

        // 定时触发结束类型
        export enum TimingTriggerEndType {
            Times = 0, // 执行次数
            Fixed = 1, // 指定时间
        }

        /**
         * 自动化自定义参数扩展属性
         */
        export interface ICustomPropertySchemaExtension {
            AssociationSchemaCode?: string; // 关联表单编码
            BOSchemaInfo?: string; // 关联表单信息
            DefaultItems?: string[]; // 选项数据源
            DisplayFormat?: string; // 显示格式
            DefaultValue?: any;
            DecimalPlaces?: number;
            ShowMode?: number;
            Mode?: string;
            UnitSelectionRange?: string;
        }

        /**
         * 自动化自定义参数
         */
        export interface ICustomPropertySchema {
            Name: string; // 字段编码
            DisplayName: string; // 字段名称
            DataType: number; // 字段数据类型
            FormControlType: Control.FormControlKeys; // 字段控件类型
            Extension?: ICustomPropertySchemaExtension;
        }

        /**
         * 自动化自定义参数归属schema
         */
        export interface ICustomBizObjectSchema {
            SchemaCode: string;
            CustomPropertySchemas: ICustomPropertySchema[];
        }

        interface IBaseCondition {
            ExcuteTypeList: ExecuteType[];
            Expression?: Filter.IFilterItem[][];

            RelatedSchemaCodeAliases: Array<{
                Alias: string;
                SchemaCode: string;
            }>;
        }
        //数据发生变化时
        export interface IChangeDataCondition extends IBaseCondition {
            // 条件表达式
            Expression: Filter.IFilterItem[][];
            // 变更的字段
            UpdatedFiled: {
                Value: Array<string>; //['F0000004', 'F0000022'],
                // todo 暂时不知道有啥用
                Text: '';
            };
        }

        export interface INewDataCondition extends IBaseCondition {
            // 条件表达式
            Expression: Filter.IFilterItem[][];
        }

        export interface IRemoveDataCondition extends IBaseCondition {
            // 条件表达式
            Expression: Filter.IFilterItem[][];
        }

        export interface ISubmitDataCondition extends IBaseCondition {
            // 条件表达式
            Expression: Filter.IFilterItem[][];
        }

        // 定时触发
        export interface ITimeTriggerCondition extends IBaseCondition {
            Schedule: {
                Value: {
                    Frequency: Frequency; //重复
                    Repeat: Array<string>; //Frequency为不重复、每天：[]，为每周、每月可多选：['1','2'],为每年单选：['01-05']
                    Time: string;
                    TriggerEndTime: string;
                    EndType: TimingTriggerEndType;
                    CustomFrequencyUnit: FrequencyUnit;
                    CustomFrequency: number;
                };
                Text: '';
            };
        }
        // TODO 指定日期触发(不熟悉以下业务逻辑)
        export interface ISpecifiedDateCondition extends IBaseCondition {
            DateFieldTrigger: {
                PropertyName: string; //指定日期：选择字段
                TriggerStartTime: {
                    // 年月日/年月日时分用下面1个字段
                    // 正负号区分前/后
                    OffsetMilliseconds: number; // 偏移量了多少天，换算成ms
                    // 年月格式用下面1个字段，-1表示不生效
                    Day: number; // 一个月中的第几天
                    // 时分字段，各种时间格式共用
                    Hour: number;
                    Minute: number;
                };
                RepeatCycle: RepeatCycle; //重复周期
                TriggerEndTime: {
                    ValueType: EValueType;
                    Value: string;
                };
                LastDisplayFormat: string; // 指定日期字段旧的显示格式，用于提示日期格式是否发生了变化
            };
        }
        // Webhook
        export interface IWebhookCondition extends IBaseCondition {
            ExcuteConfig: {
                RequestMapping: {
                    CallbackUrl: string; //Webhook地址
                };
                ResponseMapping: {
                    ResponseFields: Array<WebhookFieldItem>; //接收数据
                };
            };
        }
        // 按钮触发
        export interface IButtonCondition extends IBaseCondition {
            ButtonTrigger: {
                TriggerButton: string;
                TriggerButtonName: string;
                ButtonFrom: ButtonConfigScene;
            };
        }

        // 自定义触发
        export interface ICustomCondition extends IBaseCondition {
            CustomBizObjectSchema: {
                SchemaCode: string;
                CustomPropertySchemas: Array<{
                    Name: string;
                    DisplayName: string;
                    DataType: typeof Control.BizDataType;
                    FormControlType: Control.FormControlKeys;
                }>;
            };
        }
        export type WebhookFieldItem = {
            //数据字段
            DisplayName: string;
            //字段值
            Name: string;
        };

        export interface OldCondition {
            Expression: {
                Value: string;
            };
        }

        export type TCondition =
            | IChangeDataCondition
            | INewDataCondition
            | IRemoveDataCondition
            | ISubmitDataCondition
            | ITimeTriggerCondition
            | ISpecifiedDateCondition
            | IWebhookCondition
            | IButtonCondition
            | IBaseCondition
            | ICustomCondition;
    }

    export namespace Action {
        // 数据源类型
        export enum DataSourceType {
            All = 1, // 全部数据
            Condition = 2, // 满足条件的
        }

        interface IJoinOn {
            SourcePropertyName: string; // 源表单字段编码，或者另一个目标表字段编码。如果是源表单编码，格式是mainSchemaCodeAlias.mainPropertyName 或者 mainSchemaCodeAlias.childSchemaCodeAlias.childPropertyName；如果是目标表单编码，格式 mainSchemaCode.mainPropertyName 或者 mainSchemaCode.childSchemaCode.childPropertyName。
            TargetPropertyName: string; // 目标表字段编码，格式 mainSchemaCode.mainPropertyName 或者 mainSchemaCode.childSchemaCode.childPropertyName
        }

        export interface ITargetSchemaConfig {
            TargetAppCode: string; // 目标表单所在应用编码
            TargetSchemaCode: string; // 目标表单编码，固定为主表单编码
            TargetChildSchemaCode: string; // 目标子表单编码，如果是主表则为主表单编码
            JoinOns: IJoinOn[]; // 0个或1个
        }

        // 在线支付无以下?字段
        interface IBaseAction {
            DisplayName: string; // 执行动作显示名称
            // 一级执行动作
            PluginCode: AutomationActionType;
            // 分级执行动作
            PluginAction: AutomationActionType[];
            ObjectId: string;
            // 触发条件类型，若选择查找节点为''
            TriggerType?: ExecuteType;
            // 执行方式，不选择节点时为''，选择后可以为为主表schemaCode、子表schemaCode、查找结点ObjectId
            SourceId?: string;
            // sourceID别名
            SourceIdAlias?: string;
            // 选择查找节点时为'querydata'，否则为''
            SourceCode?: AutomationActionType.QueryData | string;
            //当前表单的schemaCode
            SchemaCode?: string;
            //当前表单的schemaCode别名
            SchemaCodeAlias?: string;
            //触发节点为数据发生变化时 && 执行方式选择子表时可选，默认为1，不选择节点或者选查找结点时为''
            DataSetType: DataSetType;
            //执行方式的满足条件
            Condition: Filter.IFilterItem[][];
            SourceCondition?: Filter.IFilterItem[][]; // 查询节点新增的满足条件
            ResponseMapping: Record<string, any>;
            RelatedSchemaCodeAliases: Array<RelationSchemaCodeAlias>;
        }
        export enum AutomationActionType {
            RemoveData = 'removedata',
            QueryData = 'querydata',
            QueryDataNext = 'querydatanext',
            UpdateData = 'updatedata',
            InsertData = 'insertdata',
            Notification = 'notification',
            Email = 'email',
            SMS = 'sms',
            DingtalkAttendance = 'dingtalkattendance',
            Payment = 'payment',
            DingtalkConnect = 'dingtalkconnect',
            DingtalkCorpConversation = 'dingtalkcorpconversation',
            DingtalkRobot = 'dingtalkrobot',
            RobotNotification = 'robotnotification', // 发送消息至群聊
            Pay = 'pay',
            Wxworkschoolnotification = 'wxworkschoolnotification',
        }
        export const EPluginCodeMap = {
            [AutomationActionType.RemoveData]: '删除数据',
            [AutomationActionType.QueryData]: '查找数据',
            [AutomationActionType.UpdateData]: '更新数据',
            [AutomationActionType.InsertData]: '新增数据',
            [AutomationActionType.Notification]: '发送消息提醒',
            [AutomationActionType.Email]: '发送邮件',
            [AutomationActionType.SMS]: '发送短信',
            // [AutomationActionType.DingtalkRobot]: '发送消息至群机器人（钉钉）',
            [AutomationActionType.RobotNotification]: '发送群消息',
            [AutomationActionType.DingtalkAttendance]: '钉钉',
            [AutomationActionType.Payment]: '在线支付',
            [AutomationActionType.DingtalkConnect]: '钉钉连接器', // 执行动作中也有一个钉钉连接器，金蝶KIS云专用
        };
        // 钉钉的两个子执行动作
        export enum EDingtalkAttendanceType {
            DingtalkCorpConversation = 'dingtalkcorpconversation',
            DingtalkRobot = 'dingtalkrobot',
        }

        // 钉钉连接器的一个子执行动作
        export const enum EDingtalkConnectType {
            Kingdeekis = 'kingdeekis',
        }

        export type TargetSchemaValue = [string, string, string]; //['appCode','主表schemaCode','主表schemaCode'|'子表schemaCode']
        export type RelationSchemaCodeItem = [string, string, string]; // ['主表schemaCode'，'主表|子表schemaCode'，'主表表名']
        export type RelationSchemaCodeAlias = {
            Alias: string;
            SchemaCode: string;
        };
        // 删除数据
        export interface IAction_RemoveData extends IBaseAction {
            RequestMapping: {
                //目标表单
                TargetSchemaValue: TargetSchemaValue;
                //所有相关的表单数据（执行方式+目标表单），可能有0-4项（链式查询到最后可能会有4个相关表单）
                RelationSchemaCode: Array<RelationSchemaCodeItem>;
                //满足条件的主表数据
                ParentCondition: Filter.IFilterItem[][];
                //满足条件的子表数据
                ChildCondition: Filter.IFilterItem[][];
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
            ChildrenTriggerAction: IAction_InsertData[];
            OnlyRemoveChildren: boolean; // 是否只新增子表
        }
        // 查找数据
        export interface IAction_QueryData extends IBaseAction {
            Condition: Filter.IFilterItem[][];
            RequestMapping: {
                TargetSchemaValue: TargetSchemaValue; // 过时属性，需要升级到TargetSchemaConfigs
                RelationSchemaCode: Array<RelationSchemaCodeItem>;
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
                RelationFiled: Array<QueryRelationFiledItem>; // 关联规则, 过时属性，需要升级到TargetSchemaConfigs
                Condition: Filter.IFilterItem[][]; // 满足条件
                TargetSchemaConfigs: ITargetSchemaConfig[];
            };
        }

        export interface IAction_QueryDataNext extends IBaseAction {
            Condition: Filter.IFilterItem[][];
            RequestMapping: {
                // TargetSchemaValue: TargetSchemaValue, // 过时属性，需要升级到TargetSchemaConfigs
                RelationSchemaCode: Array<RelationSchemaCodeItem>;
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
                // RelationFiled: Array<QueryRelationFiledItem>, // 关联规则, 过时属性，需要升级到TargetSchemaConfigs
                Condition: Filter.IFilterItem[][]; // 满足条件
                TargetSchemaConfigs: ITargetSchemaConfig[];
            };
        }
        export type QueryRelationFiledItem = {
            // 执行方式:'D0001833c3a111d7c624032885af91f9a937d96.ObjectId'
            ActionFiled: string;
            // 目标表单
            TargetFiled: string;
        };
        export enum ESourceTypeForUpdateData {
            //选择字段
            Property = 1,
            //使用公式编辑器
            Formula = 2,
            Fixed = 3, // 自定义
            CustomParameter = 4, // 自定义参数
            Sum = 102, // 求和
            Max = 100, // 最大值, 日期为最晚时间
            Min = 101, // 最小值， 日期为最早时间
        }
        export type FiledItem = {
            SourceField: string; //'OwnerId','{D000183705eb678282b4429b116b238d17c3169.ModifiedBy}+{D000183705eb678282b4429b116b238d17c3169.Name}'
            SourceType: ESourceTypeForUpdateData;
            TargetField: string; //'OwnerId'
            ValueSetType: ValueSetType; // 值设置方式，默认为设为
            Extension?: { AssociationPropertyName: string };
        };
        enum EDataState {
            Draft = 0, //草稿
            Valid = 1, //生效
            Workflow = 2, //发起流程
        }
        // 子节点归属方式
        export enum EChildActionType {
            Target = 0, // 目标表单
            OnlyChildren = 1, // 仅子表
        }
        // 更新数据
        export interface IAction_UpdateData extends IBaseAction {
            RequestMapping: {
                TargetSchemaValue: TargetSchemaValue; //目标表单
                IsInsert: boolean; //无可更新数据时，新增一条数据
                State: EDataState;
                ParentCondition: Filter.IFilterItem[][];
                ChildCondition: Filter.IFilterItem[][];
                UpdateFieldMappings: Array<FiledItem>; //更新字段
                InsertFieldMappings: Array<FiledItem>; //新增字段
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
            GroupByPropertyMappings: boolean; // 是否分组新增
            ChildrenTriggerAction: IAction_InsertData[];
            OnlyUpdateChildren: boolean; // 是否只新增子表
        }
        // 新增数据
        export interface IAction_InsertData extends IBaseAction {
            RequestMapping: {
                GroupByMainPropertyMappings: boolean; // 目标表单是子表时，是否同一条主表新增多条子表
                TargetSchemaValue: TargetSchemaValue; //目标表单
                State: EDataState;
                InsertFieldMappings: Array<FiledItem>; //新增数据字段
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
            ChildrenTriggerAction: IAction_InsertData[];
            OnlyCreateChildren: boolean; // 是否只新增子表
            GroupByPropertyMappings: boolean; // 是否分组新增
            ParentConditionV2: Filter.IFilterItem[][]; // 仅新增子表时，满足条件的主表设置

            ChildActionType?: EChildActionType; // 子节点归属方式
        }
        // 发送邮件
        export interface IAction_Email extends IBaseAction {
            RequestMapping: {
                Received: Array<EmailReceiveItem>; //接收人
                Title: string; //标题
                Context: string; //内容
                Attachments?: Array<EmailAttachmentItem>; // 附件
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }
        type EmailReceiveItem = {
            Type: EValueType;
            Value: string; //'D0001833c3a111d7c624032885af91f9a937d96.F0000001'|'xxx@xx.com'
        };

        // 邮件附件
        type EmailAttachmentItem = {
            Type: EValueType;
            Value: string;
        };

        // 发送短信
        export interface IAction_SMS extends IBaseAction {
            RequestMapping: {
                TempId: string; //模板Id
                TempContent: string; //模板内容：'【氚云】尊敬的{变量1}, 您的{变量2}服务单有新回复，回复：{变量3}，请查阅。'
                TempParam: Array<SMSTempParamItem>; //模板参数
                Phone: Array<SMSPhoneItem>; //接收人
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }
        type SMSTempParamItem = {
            Type: EValueTypeForSMS;
            Value: string; //'F0000022'|'用户自定义内容'
            Name: string; // 填充至'Name'
        };
        interface SMSPhoneItem {
            Type: EValueType;
            Value: string; // 'D000183F492208a240a24a38b440cc4eebf70f3e.F0000008'|13000000000
        }
        export const enum EValueTypeForSMS {
            //动态值
            Property = '1',
            //固定值
            Fixed = '2',
        }

        // 发送消息通知Action数据结构
        // TODO 和后端保持定义一致
        export interface IAction_Notification extends IBaseAction {
            RequestMapping: {
                Receivers: any[]; //接收人
                MessageType: DingtalkMsgType;
                Title: string; //消息标题
                Content: string; //消息内容
                //MsgType为文本消息时为空，为链接消息时为固定值
                //跳转链接
                Link: {
                    Type: ELinkType;
                    ValueType: EValueType;
                    Value: string;
                };
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }

        // 钉钉/发送工作通知
        export interface IAction_DingtalkCorpConversation extends IBaseAction {
            RequestMapping: {
                ReceivedUser: Array<string>; //固定值接收人
                Received: Array<DingtalkReceiveItem>; //动态值接收人
                MsgType: DingtalkMsgType;
                Title: string; //消息标题
                Content: string; //消息内容
                //MsgType为文本消息时为空，为链接消息时为固定值
                PicUrl: Array<{
                    Url: string;
                    Name: string;
                }>;
                //跳转链接
                Link: {
                    Type: EValueType;
                    Value: string;
                    Name: 'MessageUrl';
                };
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }
        type DingtalkReceiveItem = {
            Type: EDingtalkReceiveType;
            Value: string;
        };
        export enum EDingtalkReceiveType {
            people = 1,
            Department = 2,
        }
        // 钉钉/发送消息至群机器人
        export interface IAction_DingtalkRobot extends IBaseAction {
            RequestMapping: {
                Webhook: string; //Webhook
                EncryKey: string; //加签密钥
                MsgType: DingtalkMsgType; //消息类型
                Content: string; //消息内容
                Title: string; //消息标题
                //跳转链接
                Link: {
                    Type: EValueType;
                    Value: string;
                    Name: 'MessageUrl';
                };
                SetType: EDingtalkSetType; //@群成员类型
                Mobiles: Array<MobileItem>; //@群成员
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }

        export interface IAction_RobotNotification extends IBaseAction {
            RequestMapping: {
                RobotType: ERobotType;
                Webhook: string; //Webhook
                EncryKey: string; //加签密钥
                MsgType: DingtalkMsgType; //消息类型
                Content: string; //消息内容
                Title: string; //消息标题
                //跳转链接
                Link: {
                    Type: EValueType;
                    Value: string;
                    Name: 'MessageUrl';
                };
                SetType: EDingtalkSetType; //@群成员类型
                Mobiles: Array<MobileItem>; //@群成员
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }
        export type MobileItem = {
            Type: EValueType;
            Value: string;
        };
        export const enum DingtalkMsgType {
            // 链接消息
            Link = 'link',
            // 文本消息
            Text = 'text',
        }
        enum EDingtalkSetType {
            Someone = 1, //指定群成员
            All = 2, //所有群成员
        }

        // 在线支付
        export interface IAction_Payment {
            ObjectId: string;
            PluginCode: AutomationActionType;
            PluginAction: AutomationActionType[];
            // 表单字段
            RequestMapping: {
                subject: string; // 支付内容：'F0000022'
                totalAmount: string; //支付金额（元）
            };
            // 返回订单信息
            ResponseMapping: {
                outTradeNo: string; //商户订单号
                tradeNo: string; //交易号
                tradeStatus: string; //支付状态
                receiptAmount: string; //实收金额（元）
                buyerLogonId: string; //买家支付宝账号
                orderCreatedTime: string; //订单创建时间
            };
        }
        // 钉钉连接器，金蝶：dingtalkconnect_kingdeekis_create

        // 家校通通知类型，根据下面的NotificationType和NotificationReceiver转换成该类型提给后端
        export const enum ScenarioType {
            /// 发给指定的家长
            SendToParents = 1,
            /// 发给学生
            SendToStudents = 2,
            /// 发给学生的所有家长
            SendToParentsOfStudents = 3,
            /// 发给学生和学生的所有家长
            SendToStudentsAndParents = 4,
            /// 发给班级的学生
            SendToStudentsInClass = 5,
            /// 发给班级学生的家长
            SendToParentsInClass = 6,
            /// 发给班级的学生及班级学生的家长
            SendToStudentsAndParentsInClass = 7,
            /// 发送给学校的所有学生
            SendToStudentsInSchool = 8,
            /// 发送给学校的所有家长
            SendToParentsInSchool = 9,
            /// 发送给学校的所有学生及家长
            SendToStudentsAndParentsInSchool = 10,

            Empty = '',
        }
        // 通知类型，前端页面渲染用
        export const enum NotificationType {
            Parent = 'parent', // 家长通知
            Student = 'student', // 学生通知
            Class = 'class', // 班级通知
            School = 'school', // 学校通知

            Empty = '',
        }

        // 通知类型下的发送对象分类
        export const enum NotificationReceiver {
            Parents = 'parents', // 家长
            Students = 'students', // 学生
            ParentsOfStudents = 'parentsOfStudents', // 学生的家长
            ParentsAndStudents = 'parentsAndStudents', // 学生和学生的家长
            StudentsInSchool = 'studentsInSchool', // 全校学生
            ParentsInSchool = 'parentsInSchool', // 全校学生的家长
            StudentsAndParentsInSchool = 'studentsAndParentsInSchool', // 全校学生和其家长
        }
        // 家校通
        export interface IAction_SchoolNotification extends IBaseAction {
            RequestMapping: {
                ScenarioType: ScenarioType;
                Receiver: string;
                MsgType: DingtalkMsgType;
                Title: string;
                Content: string;
                Link: string;
                RelationSchemaCodeAlias: Array<RelationSchemaCodeItem>;
            };
        }

        export type TAction =
            | IAction_RemoveData
            | IAction_QueryData
            | IAction_QueryDataNext
            | IAction_UpdateData
            | IAction_InsertData
            | IAction_Email
            | IAction_SMS
            | IAction_DingtalkCorpConversation
            | IAction_DingtalkRobot
            | IAction_RobotNotification
            | IAction_SchoolNotification
            | IAction_Notification;
    }

    // 单个自动化数据
    export interface IAutomationData {
        ObjectId: string;
        SchemaCode: string;
        ExpressionEditable: boolean | null;
        IsDevMode?: boolean | null;
        DisplayName: string;
        Code?: string;
        ExcuteType: ExecuteType;
        State: boolean;
        SortKey: number;
        Action: Array<Action.TAction>;
        Condition: Condition.TCondition;
        CustomBizObjectSchema?: Condition.ICustomBizObjectSchema;
    }

    // 分组自动化数据
    export interface IGroupAutomationData {
        SchemaCode: string;
        TriggerModels: IAutomationData[];
    }

    // 分组自动化请求结果类型
    export interface IGroupAutomationDataResponse {
        Triggers: IGroupAutomationData[];
    }
}
