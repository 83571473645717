/** 异步任务命名空间 */
export namespace AsyncTask {
    // 其中被注释掉的部分是异步任务框架中存在的但暂时不集成到前端异步任务提示中的功能
    /** 异步任务场景枚举 */
    export enum TaskScene {
        /** 导入 */
        ScheduleCode_ImportData = 'ScheduleCode_ImportData',

        /** 导出 */
        ScheduleCode_ExportData = 'ScheduleCode_ExportData',

        /** 新导入 */
        ScheduleCode_ImportData_V2 = 'ScheduleCode_ImportData_V2',

        /** 新导出 */
        ScheduleCode_ExportData_V2 = 'ScheduleCode_ExportData_V2',

        /** 导入V3 */
        ScheduleCode_ImportData_V3 = 'ScheduleCode_ImportData_V3',

        /** 导出V3 */
        ScheduleCode_ExportData_V3 = 'ScheduleCode_ExportData_V3',

        /** 安装模板 */
        InstallTemplate = 'InstallTemplate',

        /** 批量更新 */
        ScheduleCode_BatchUpdate = 'ScheduleCode_BatchUpdate',

        /** 按钮触发自动化单条执行 */
        ScheduleCode_Action_Automation = 'ScheduleCode_Action_Automation',

        /** 更改数据标题 */
        ChangeSchemaDataTitle = 'ChangeSchemaDataTitle',

        /** 修改流水号 */
        ScheduleCode_SequenceNo = 'ScheduleCode_SequenceNo',

        /** 公式型控件变更 */
        FormulaControlChanged = 'FormulaControlChanged',

        /** 汇总控件变更 */
        ScheduleCode_RollupConfigChanged = 'ScheduleCode_RollupConfigChanged',

        /** 批量升级旧报表 */
        ScheduleCode_MigrateReportToDashboard = 'ScheduleCode_MigrateReportToDashboard',

        /** 文件批量下载 */
        BatchDownloadFile = 'BatchDownloadFile',

        /** 打印预览 */
        // PrintPreview = 'PrintPreview',

        /** 打印 */
        // PrintExecute = 'PrintExecute',

        /** 开发者任务 */
        // DeveloperTask = 'DeveloperTask',

        /** 安装解决方案 */
        // AsyncInstallSolution = 'AsyncInstallSolution',

        /** 组织机构关联 */
        // AsyncOrganizationAssociation = 'AsyncOrganizationAssociation',

        /** 移除打印模板 */
        // RemovePrintTemplate = 'RemovePrintTemplate',

        /** 定时器 */
        // ScheduleCode_CodeRunner_Timer = 'ScheduleCode_CodeRunner_Timer',

        /** 修改人员控件“允许所选人员查看数据” */
        // ChangeSchemaRelatedData = 'ChangeSchemaRelatedData',
    }

    export const TaskScenes = Reflect.ownKeys(TaskScene).map((key) => TaskScene[key]) as TaskScene[];

    /** 异步任务状态枚举 */
    export enum TaskState {
        /** 已完成 */
        Finished = 1,

        /** 失败 */
        Failed = 2,

        /** 运行中 */
        Running = 3,

        /** 等待中 */
        Waiting = 4,

        /** 已取消 */
        Canceled = 5,

        /** 超时 */
        Timeout = 6,

        /** 排队中 */
        Queuing = 7,
    }

    /** 异步任务项 */
    export type TaskItem = Response.TGetTaskList & {
        /** 是否被标记为将要被自动删除 */
        willBeAutoDeleted: boolean;
    };

    /** 异步任务执行结果 */
    export type TaskItemResult = {
        /** 应用编码 */
        appCode: string;

        /** 应用名称 */
        appName: string;

        /** 表单编码 */
        schemaCode: string;

        /** ossUrl */
        ossUrl: string;

        /** 错误信息 */
        errorMessage: string;

        /** 错误详情 */
        errorDetail: string;

        /** 执行报告 */
        executeReport: string;

        /** 取消原因 */
        cancelReason: string;

        /** 批量下载生成的压缩文件地址 */
        fileUrl: string;
    };

    export namespace Response {
        /** 加载异步任务列表响应模型 */
        export type TGetTaskList = {
            /** 任务id */
            objectId: string;

            /** 创建时间 */
            createdTime: string;

            /** 任务状态 */
            state: TaskState;

            /** 任务标题 */
            title: string;

            /** 任务场景 */
            sceneCode: TaskScene;

            /** 任务需要执行的记录总数 */
            total: number;

            /** 任务已执行的记录数 */
            finished: number;

            /** 任务执行结果（一些用于交互的数据，数据结构取决于任务场景） */
            result: TaskItemResult;
        };

        /** 查询任务进度接口响应模型 */
        export type TGetTaskProgress = {
            /** 任务需要执行的记录总数 */
            total: number;

            /** 任务已执行的记录数 */
            finished: number;

            /** 任务状态 */
            state: TaskState;
        };

        /** 获取公式型控件异步任务执行详情响应模型 */
        export type TGetFormulaChangedExeRecord = {
            /** 记录总数 */
            total: number;

            /** 记录列表 */
            logs: Array<{
                /** 引擎编码 */
                engineCode: string;

                /** 记录时间 */
                logTime: string;

                /** 异步任务id */
                taskId: string;

                /** 业务数据id */
                bizObjectId: string;

                /** 业务数据标题 */
                bizObjectName: string;

                /** 执行成功标志 */
                successFlag: boolean;

                /** 执行失败类型 */
                failType: 'fail' | 'abort';

                /** 错误描述 */
                failDesc: string;

                /** 错误详情 */
                failDetail: string;
            }>;
        };
    }
}
