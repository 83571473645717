import { H3 } from '@h3/types';
import { Util } from '@h3/h3common';
import dayjs from '@h3/h3dayjs';
import * as Api from '../api';
import * as Service from '../service';

/**
 * 全局缓存服务
 * @classdesc 缓存内容包括人员部门和业务数据，主要作用是根据id缓存对应的数据，减少请求次数
 */
export default class CacheService {
    /** 业务数据缓存(schemaCode到该schema下数据缓存的映射) */
    private readonly _bizDataCache: Map<string, Map<string, Record<string, unknown>>>;

    /** 组织机构缓存 */
    private readonly _orgCache: Map<string, H3.Org.IOrgInfo>;

    /** oss临时凭证缓存 */
    private readonly _ossCache: Map<string, H3.Oss.IOssInfo>;

    constructor() {
        this._bizDataCache = new Map();
        this._orgCache = new Map();
        this._ossCache = new Map();
    }

    /**
     * 根据schemaCode和bizObjectIds获取BO列表
     * @param schemaCode 表单编码
     * @param bizObjectIds 业务数据id列表
     * @returns 业务数据列表（返回Promise说明部分数据要从接口获取）
     */
    getBizObjectsByIds(
        schemaCode: string,
        bizObjectIds: string[],
    ): Promise<Record<string, unknown>[]> | Record<string, unknown>[] {
        // 缓存中没有的ids
        let idsToFetch = bizObjectIds;
        if (this._bizDataCache.has(schemaCode)) {
            idsToFetch = idsToFetch.filter((id) => !this._bizDataCache.get(schemaCode)!.has(id));
        }

        // 如果都在缓存中，直接返回
        if (idsToFetch.length === 0) {
            const cache = this._bizDataCache.get(schemaCode);
            if (!cache) {
                return [];
            }
            return bizObjectIds.map((id) => cache.get(id)!).filter(Boolean);
        }

        // 缓存中没有的ids，从服务端获取并存到缓存中，然后返回
        return Service.BizDataService.getBizObjectsByIds(schemaCode, idsToFetch)
            .then((res) => {
                this.cacheBizData(schemaCode, res);
                const cache = this._bizDataCache.get(schemaCode);
                if (!cache) {
                    return [];
                }
                return bizObjectIds.map((id) => cache.get(id)!).filter(Boolean);
            })
            .catch(() => {
                return [];
            });
    }

    /**
     * 根据schemaCode和bizObject获取单个BO
     * @param schemaCode 表单编码
     * @param bizObjectId 业务数据id
     * @returns 业务数据（返回Promise说明数据要从接口获取）
     */
    getBizObjectById(
        schemaCode: string,
        bizObjectId: string,
    ): Promise<Record<string, unknown>> | Record<string, unknown> {
        const res = this.getBizObjectsByIds(schemaCode, [bizObjectId]);
        if (Util.IS.isPromise(res)) {
            return res.then((data) => data[0]);
        }
        return res[0];
    }

    /**
     * 根据ids获取组织机构信息列表
     * @param ids 组织机构id列表
     * @returns 组织机构信息列表（返回Promise说明部分数据要从接口获取）
     */
    getOrgInfoByIds(ids: string[]): Promise<H3.Org.IOrgInfo[]> | H3.Org.IOrgInfo[] {
        // 缓存中没有的ids
        const idsToFetch = ids.filter((id) => !this._orgCache.has(id));

        // 全在缓存中，直接返回
        if (idsToFetch.length === 0) {
            return ids.map((id) => this._orgCache.get(id)!).filter(Boolean);
        }

        // 从服务端获取并存到缓存中，然后返回
        return Api.Org.getOrgList({ ids: idsToFetch })
            .then((res) => {
                if (!res.successful) {
                    return [];
                }

                const { users, departments, roles } = res.returnData;
                users.forEach((user) =>
                    this._orgCache.set(user.id, {
                        ...user,
                        unitType: H3.Org.UnitType.User,
                        nodeType: H3.Org.OrgNodeType.Unit,
                        type: H3.Org.WeChartOrgType.User,
                    }),
                );
                departments.forEach((department) =>
                    this._orgCache.set(department.id, {
                        ...department,
                        unitType: H3.Org.UnitType.OrganizationUnit,
                        nodeType: H3.Org.OrgNodeType.Unit,
                        type: H3.Org.WeChartOrgType.Department,
                    }),
                );
                roles.forEach((role) =>
                    this._orgCache.set(role.id, {
                        ...role,
                        unitType: H3.Org.UnitType.Role,
                        nodeType: H3.Org.OrgNodeType.Unit,
                    }),
                );

                return ids.map((id) => this._orgCache.get(id)!).filter(Boolean);
            })
            .catch(() => {
                return [];
            });
    }

    /**
     * 根据id获取组织机构信息
     * @param id 组织机构id
     * @returns 组织机构信息（返回Promise说明数据要从接口获取）
     */
    getOrgInfoById(id: string): Promise<H3.Org.IOrgInfo> | H3.Org.IOrgInfo {
        const res = this.getOrgInfoByIds([id]);
        if (Util.IS.isPromise(res)) {
            return res.then((data) => data[0]);
        }
        return res[0];
    }

    /** 缓存给定的人员部门数据 */
    cacheOrgInfo(orgInfos: H3.Org.IOrgInfo[]) {
        orgInfos.forEach((info) => this._orgCache.set(info.id, info));
    }

    /**
     * 缓存业务数据，对子表数据做一下特殊处理
     * @param schemaCode 表单编码
     * @param data 数据
     */
    cacheBizData(schemaCode: string, data: Record<string, unknown>[]) {
        // 没数据，不处理
        if (!data.length) {
            return;
        }

        if (!this._bizDataCache.has(schemaCode)) {
            this._bizDataCache.set(schemaCode, new Map());
        }
        const cache = this._bizDataCache.get(schemaCode)!;

        // 子表数据的key结构为`${schemaCode}.${fieldCode}`，且field中一定有ObjectId
        const childSchemaObjectIdKey = `${schemaCode}.ObjectId`;
        const isChildSchema = !!data[0][childSchemaObjectIdKey];

        // 如果是子表
        if (isChildSchema) {
            data.forEach((item) => {
                cache.set(item[childSchemaObjectIdKey] as string, item);
            });
            return;
        }

        // 不是子表，缓存key就是ObjectId
        data.forEach((item) => {
            cache.set(item.ObjectId as string, item);
        });
    }

    /**
     * 删除指定业务数据缓存
     * @param schemaCode 表单编码，不传则删除所有缓存
     * @param bizObjectIds 业务数据id，不传则删除整个schemaCode下的缓存
     */
    deleteBizData(schemaCode?: string, bizObjectIds?: string[]) {
        if (!schemaCode) {
            this._bizDataCache.clear();
            return;
        }

        if (!this._bizDataCache.has(schemaCode)) {
            return;
        }

        if (!bizObjectIds) {
            this._bizDataCache.delete(schemaCode);
            return;
        }

        const cache = this._bizDataCache.get(schemaCode)!;
        bizObjectIds.forEach((id) => cache.delete(id));
    }

    /**
     * 获取oss文件直传的临时凭证
     */
    async getOssToken() {
        if (this._isCredentialsExpired()) {
            const res = await Api.Oss.getOssToken();
            if (res.successful) {
                this._ossCache.set('oss', res.returnData);
            }
        }
        return this._ossCache.get('oss');
    }

    /**
     * 判断临时凭证是否到期。
     * @returns 到期返回true
     */
    private _isCredentialsExpired() {
        const oss = this._ossCache.get('oss');
        if (!oss || !oss.expiration) {
            return true;
        }
        // 如果有效期不足一分钟，视为过期。
        const expirationTime = 60000;
        return dayjs(oss.expiration * H3.Util.M_TO_MS_SCALE).diff(dayjs()) <= expirationTime;
    }
}
