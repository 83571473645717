export namespace UI {
    /** 空枚举，防止报错 */
    export enum _UnUse {}

    /** 氚云UI工具 */
    export interface IUiTools {
        /**
         * 显示警告提示
         * @param message 提示内容
         * @param duration 提示持续时间，单位秒，传入0时不自动关闭
         * @returns 关闭此次提示的方法
         */
        warning: (message: string, duration?: number) => () => void;

        /**
         * 显示成功提示
         * @param message 提示内容
         * @param duration 提示持续时间，单位秒，传入0时不自动关闭
         * @returns 关闭此次提示的方法
         */
        success: (message: string, duration?: number) => () => void;

        /**
         * 显示错误提示
         * @param message 提示内容
         * @param duration 提示持续时间，单位秒，传入0时不自动关闭
         * @returns 关闭此次提示的方法
         */
        error: (message: string, duration?: number) => () => void;

        /**
         * 显示信息提示
         * @param message 提示内容
         * @param duration 提示持续时间，单位秒，传入0时不自动关闭
         * @returns 关闭此次提示的方法
         */
        info: (message: string, duration?: number) => () => void;

        /**
         * 显示加载提示
         * @param message 提示内容
         * @param duration 持续时间，单位秒，传入0时不自动关闭
         * @returns 关闭此次提示的方法
         */
        loading: (message: string, duration?: number) => () => void;

        /** 关闭所有waning, success, error, info, loading */
        destroy: () => void;

        /**
         * 打开二次确认弹窗
         * @param option 二次确认弹窗配置
         * @param option.title 标题
         * @param option.content 内容
         * @param option.okText 确认按钮文本
         * @param option.cancelText 取消按钮文本
         * @param option.onOk 确认回调
         * @param option.onCancel 取消回调
         */
        confirm: (option: {
            title?: string;
            content?: string;
            okText?: string;
            cancelText?: string;
            onOk?: () => void;
            onCancel?: () => void;
        }) => void;

        /**
         * 弹窗提示错误信息
         * @param message 提示信息
         * @param detail 错误详情
         * @param title 弹窗标题，默认为'错误提示'
         */
        showErrorDetail: (message: string, detail: string, title?: string) => void;
    }
}
