import { createRouter, createWebHistory, RouteRecordRaw, Router } from 'vue-router';
import type { App } from 'vue';
import { PageEnum, PageNameEnum } from '@/enums/pageEnum';
import { useGlobalConfig } from '@/store/entry';

const { globalConfig } = useGlobalConfig();

/** 路由配置 */
export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'LayoutEntry',
        props: true,
        redirect: { path: PageEnum.LOGIN_HOME },
        component: () => import('@/layouts/index.vue'),
        children: [
            {
                path: '/',
                name: 'LayoutLogin',
                redirect: { path: PageEnum.LOGIN_HOME },
                component: () => import('@/views/login/index.vue'),
                children: [
                    {
                        path: PageEnum.ENTRY_LOGIN,
                        name: PageNameEnum.ENTRY_LOGIN,
                        redirect: { path: PageEnum.LOGIN_HOME },
                    },
                    {
                        path: PageEnum.LOGIN_MOBILE,
                        name: PageNameEnum.LOGIN_MOBILE,
                        redirect: { path: PageEnum.LOGIN_HOME },
                    },
                    {
                        path: PageEnum.LOGIN_HOME,
                        name: PageNameEnum.LOGIN_HOME,
                        component: () => import('@/views/login/login-form.vue'),
                        meta: {
                            title: '登录',
                        },
                    },
                    {
                        path: PageEnum.LOGIN_CORP,
                        name: PageNameEnum.LOGIN_CORP,
                        component: () => import('@/views/login/login-corp.vue'),
                        meta: {
                            title: '登录',
                        },
                    },
                    {
                        path: PageEnum.LOGIN_DINGTALK,
                        name: PageNameEnum.LOGIN_DINGTALK,
                        component: () => import('@/views/login/login-dd.vue'),
                        meta: {
                            title: '钉钉登录',
                        },
                    },
                    {
                        path: PageEnum.LOGIN_WECHAT,
                        name: PageNameEnum.LOGIN_WECHAT,
                        component: () => import('@/views/login/login-wechat.vue'),
                        meta: {
                            title: '微信登录',
                        },
                    },
                    {
                        path: PageEnum.LOGIN_WXWORK,
                        name: PageNameEnum.LOGIN_WXWORK,
                        component: () => import('@/views/login/login-wxwork.vue'),
                        meta: {
                            title: '企微登录',
                        },
                    },
                    // {
                    //     path: PageEnum.LOGIN_FEISHU,
                    //     name: PageNameEnum.LOGIN_FEISHU,
                    //     component: () => import('@/views/login/index.vue'),
                    //     meta: {
                    //         title: '飞书登录',
                    //     },
                    // },
                    {
                        path: PageEnum.REGISTER_HOME,
                        name: PageNameEnum.REGISTER_HOME,
                        component: () => import('@/views/register/register-form.vue'),
                        meta: {
                            title: '注册',
                        },
                    },
                    {
                        path: PageEnum.REGISTER_HOME_AUTH_CODE,
                        name: PageNameEnum.REGISTER_HOME_AUTH_CODE,
                        component: () => import('@/views/register/register-form-auth-code.vue'),
                        meta: {
                            title: '授权码注册',
                        },
                    },
                    {
                        path: PageEnum.REGISTER_CORP,
                        name: PageNameEnum.REGISTER_CORP,
                        component: () => import('@/views/register/register-corp.vue'),
                        meta: {
                            title: '注册企业',
                        },
                    },
                    {
                        path: PageEnum.REGISTER_DINGTALK, // 钉钉注册页
                        name: PageNameEnum.REGISTER_DINGTALK,
                        component: () => import('@/views/register/register-dd.vue'),
                        meta: {
                            title: '钉钉注册',
                        },
                    },
                    {
                        path: PageEnum.REGISTER_WECHAT,
                        name: PageNameEnum.REGISTER_WECHAT,
                        component: () => import('@/views/register/register-wechat.vue'),
                        meta: {
                            title: '个人微信绑定',
                        },
                    },
                    {
                        path: PageEnum.RESET_PASSWORD, // 密码重置页
                        name: PageNameEnum.RESET_PASSWORD,
                        component: () => import('@/views/login/reset-password.vue'),
                        meta: {
                            title: '重置密码',
                        },
                    },
                    {
                        path: PageEnum.EXTERNAL_LOGIN_CODE, // 身份验证页
                        name: PageNameEnum.EXTERNAL_LOGIN_CODE,
                        component: () => import('@/views/login/login-code-old.vue'),
                        meta: {
                            title: '身份验证',
                        },
                    },
                    {
                        path: PageEnum.LOGIN_CODE, // 验证码登录页
                        name: PageNameEnum.LOGIN_CODE,
                        component: () => import('@/views/login/login-code.vue'),
                        meta: {
                            title: '验证码登录',
                        },
                    },
                    {
                        path: PageEnum.REGISTER_SUCCESS, // 注册成功提示页
                        name: PageNameEnum.REGISTER_SUCCESS,
                        component: () => import('@/views/register/register-success.vue'),
                        meta: {
                            title: '注册成功',
                        },
                    },
                ],
            },
            {
                path: PageEnum.BASE_SUCCESS,
                name: PageNameEnum.BASE_SUCCESS,
                component: () => import('@/views/system/success/index.vue'),
            },
            {
                path: PageEnum.ASS_ORG_HOME,
                name: PageNameEnum.ASS_ORG_HOME,
                component: () => import('@/views/ass-org/index.vue'),
                meta: {
                    title: '组织互通',
                },
            },
            {
                path: PageEnum.ASS_ORG_MAKE,
                name: PageNameEnum.ASS_ORG_MAKE,
                component: () => import('@/views/ass-org/make-org.vue'),
                meta: {
                    title: '组织互通',
                },
            },
        ],
    },
    {
        path: PageEnum.BASE_ERROR, // 错误提示页
        name: PageNameEnum.BASE_ERROR,
        component: () => import('@/views/system/error/index.vue'),
    },
    {
        path: PageEnum.AUTH_WXWORK, // 企微免登授权页面
        name: PageNameEnum.AUTH_WXWORK,
        component: () => import('@/views/wxwork/auth.vue'),
        meta: {
            title: '企微授权',
        },
    },
    {
        path: PageEnum.LOGIN_AUTH, // 免登授权页面
        name: PageNameEnum.LOGIN_AUTH,
        component: () => import('@/views/auth/index.vue'),
        meta: {
            title: '授权',
        },
    },
    {
        path: PageEnum.LOGIN_MOBILE_CODE, // 验证码登录
        name: PageNameEnum.LOGIN_MOBILE_CODE,
        component: () => import('@/views/mobile/index.vue'),
        meta: {
            title: '验证码登录',
        },
    },
    {
        path: `${PageEnum.AUTH_EXTERNAL}/:code`, // 外链登录授权页
        name: PageNameEnum.AUTH_EXTERNAL,
        component: () => import('@/views/auth/auth-external.vue'),
        meta: {
            title: '外链授权',
        },
    },
    {
        path: PageEnum.LOGIN_LOGOUT,
        name: PageNameEnum.LOGIN_LOGOUT,
        component: () => import('@/views/logout/index.vue'),
        meta: {
            title: '退出登录',
        },
    },
];

/** 初始化注册路由 */
export function setupRouter(app: App, { history = createWebHistory() }): Router {
    const router = createRouter({
        history,
        routes,
    });

    router.beforeEach((to, from) => {
        if (globalConfig.value) {
            globalConfig.value.lastRoutePath = from.fullPath;
        }
        return true;
    });

    app.use(router);
    return router;
}
