/** 氚云统一结构化过滤器 */
export namespace Filter {
    /** 比较操作符 */
    export enum Operator {
        /** 等于 */
        Equal = 'Equal',
        /** 不等于 */
        NotEqual = 'NotEqual',
        /** 包含 */
        Contains = 'Contains',
        /** 不包含 */
        NotContains = 'NotContains',
        /** 为空 */
        IsBlank = 'IsBlank',
        /** 不为空 */
        NotBlank = 'NotBlank',
        /** 等于任意一个 */
        In = 'In',
        /** 不等于任意一个 */
        NotIn = 'NotIn',
        /** 在范围内 */
        Between = 'Between',
        /** 小于等于 */
        NotAbove = 'NotAbove',
        /** 大于等于 */
        NotBelow = 'NotBelow',
        /** 大于 */
        Above = 'Above',
        /** 小于 */
        Below = 'Below',
        /** 动态范围 */
        Dynamic = 'Dynamic',
    }

    /** 比较操作符文本映射，暂时先实现部分 */
    export const OperatorTextMap = {
        [Filter.Operator.Equal]: '等于',
        [Filter.Operator.NotEqual]: '不等于',
        [Filter.Operator.Contains]: '包含',
        [Filter.Operator.NotContains]: '不包含',
        [Filter.Operator.IsBlank]: '为空',
        [Filter.Operator.NotBlank]: '不为空',
        [Filter.Operator.In]: '等于任意一个',
        [Filter.Operator.NotIn]: '不等于任意一个',
        [Filter.Operator.Between]: '在范围内',
        [Filter.Operator.NotAbove]: '小于等于',
        [Filter.Operator.NotBelow]: '大于等于',
        [Filter.Operator.Above]: '大于',
        [Filter.Operator.Below]: '小于',
        [Filter.Operator.Dynamic]: '动态范围',
    };

    /** 值类型 */
    export enum ValueType {
        /** 自定义 */
        Fixed = 'Fixed',
        /** 字段 */
        PropertyName = 'PropertyName',
        /** 公式计算 */
        Expression = 'Expression',
        /** 自定义 */
        Custom = 'Custom',
        /** 动态 */
        Dynamic = 'Dynamic',
        /** 自动化 */
        CustomParameter = 'CustomParameter',
    }

    /** 筛选使用场景类型 */
    export enum SceneType {
        /** 自动化的通用场景 */
        Automation = 'automation',
        /** 仅支持固定值的场景 */
        AutomationFixed = 'automationFixed',
        /** 关联汇总的通用场景 */
        Rollup = 'rollup',
        /** 仅支持固定值的通用场景 */
        OnlyFixed = 'fixed',
    }

    /** 筛选项 */
    export interface IFilterItem<T = any> {
        PropertyName: string;
        Operator: Operator;
        ValueType: ValueType;
        Value: T;
        Id?: string;
    }

    /** 输入范围限定公式 */
    export interface ILowerCaseFilterItem<T = any> {
        propertyName: string;
        operator: Operator;
        valueType: ValueType;
        value: T;
        id?: string;
    }
}
