import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

// 请求头
const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/**
 * 甘特图升降级
 * @param params
 * @returns
 */
export const updateGanttTaskLevel = (params: H3.BizData.Request.IGanttTaskLevelModel) => {
    return request<H3.Http.Response.H3Response<{ sortIndex: number }>>(Api.UpdateGanttTaskLevelUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/**
 * 更新任务类型
 * @param params
 * @returns
 */
export const updateGanttTaskType = (params: H3.BizData.Request.IGanttTaskTypeModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateGanttTaskTypeUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/**
 * 任务排序
 * @param params
 * @returns
 */
export const updateGanttTaskSort = (params: H3.BizData.Request.IGanttTaskSortModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IGanttTaskSortResponse>>(
        Api.UpdateGanttTaskSortUrl,
        {
            method: 'post',
            data: params,
            disableBizError: true,
        },
    );
};

/**
 * 任务新增(后续Q3)
 * @param params
 * @returns
 */
export const createGanttTask = (params: H3.BizData.Request.IQueryBizDataModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataQueryResponse>>(Api.QueryViewBizDataUrl, {
        method: 'post',
        data: params,
    });
};

/**
 * 任务更新(后续Q3)
 * @param params
 * @returns
 */
export const updateGanttTask = (params: H3.BizData.Request.IGanttTaskModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataQueryResponse>>(Api.UpdateGanttTaskUrl, {
        method: 'post',
        data: params,
    });
};

/**
 * 更新所属依赖
 * @param params
 * @returns
 */
export const updateGanttTaskDependency = (params: H3.BizData.Request.IGanttTaskDependencyModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateGanttTaskDependencyUrl, {
        method: 'post',
        data: params,
    });
};

/**
 * 更新task表单数据(旧接口)
 * @param params
 * @returns
 */
export const updateGanttTaskForm = (params: unknown) => {
    return request<H3.Http.Response.H3ResponseV2>('/GanttChart/OnAction', {
        method: 'post',
        data: {
            PostData: JSON.stringify(params),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
};

/**
 * 删除task(旧接口)
 * @param params
 * @returns
 */
export const deleteGanttTaskForm = (params: unknown) => {
    return request<H3.Http.Response.H3ResponseV2>('/App/OnAction', {
        method: 'post',
        data: {
            PostData: JSON.stringify(params),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
};

/**
 * 更新所属依赖(旧接口)
 * @param params
 * @returns
 */
export const updateGanttTaskFormDependency = (params: unknown) => {
    return request<H3.Http.Response.H3ResponseV2>('/GanttChart/OnAction', {
        method: 'post',
        data: {
            PostData: JSON.stringify(params),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
};
