export namespace DashBoard {
    /** 仪表盘图表类型 */
    export enum WidgetType {
        /** 折线图 */
        Line = 0,

        /** 柱状图 */
        Histogram = 1,

        /** 饼图 */
        Pie = 2,

        /** 面积图 */
        Area = 3,

        /** 雷达图 */
        Radar = 4,

        /** 仪表盘 */
        Gauge = 5,

        /** 漏斗图 */
        Funnel = 6,

        /** 明细表 */
        Detail = 7,

        /** 汇总表 */
        Combined = 8,

        /** 简易看板 */
        SimpleBoard = 9,

        /** 堆叠柱状图 */
        StackedHistogram = 10,

        /** 条形图 */
        Bar = 11,

        /** 透视图 */
        Perspective = 12,

        /** 指标图 */
        Indicator = 13,

        /** 散点图 */
        Scatter = 14,

        /** 双轴图 */
        Biaxial = 15,
    }

    /** 仪表盘组件类型 */
    export interface IReportWidget {
        /** 编码 */
        code: string;

        /** 名称 */
        displayName: string;

        /** 报表编码 */
        parentCode: string;

        /** 应用编码 */
        appCode: string;

        /** 图表类型 */
        nodeType: WidgetType;
    }

    /** 升级记录项 */
    export type UpgradeRecordItem = {
        /** 旧报表编码 */
        ReportNode: string;
        /** 仪表盘编码 */
        DashBoardNode: string;
        /** 报表名称 */
        DisplayName: string;
        /** 转换状态 */
        Successful: boolean;
        /** 迁移结果与错误信息 */
        ErrorMessage: string;
    };
}
