import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 发布应用到模板中心 */
export function publishTemplate(params: H3.Operate.Request.IPublishTemplateModel) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/template/publish', {
        method: 'post',
        data: params,
    });
}

/** 获取已发布的模板信息 */
export function getPublishedTemplateInfo(code: string) {
    const params = {
        appCode: code,
    };
    return request<H3.Http.Response.H3Response<H3.Operate.Response.IGetPublishedTemplateModel>>('/v1/template/info', {
        method: 'get',
        params,
    });
}

/** 请求模板 */
export const getAppTemplate = (params: H3.Operate.Request.IAppTemplateModel) =>
    request<H3.Http.Response.H3Response<H3.Operate.Template.AppTemplateModel[]>>('/v1/template/list', {
        method: 'get',
        params,
    });

/** 安装模板 */
export const installTemplate = (params: H3.Operate.Request.IInstallTemplateModel) =>
    request<H3.Http.Response.H3Response<string>>('/v1/template/install', {
        method: 'post',
        data: params,
    });

/** 安装模板进度 */
export const installTemplateProgress = (params: { actionId: string }) =>
    request<H3.Http.Response.H3Response<H3.Operate.Response.IInstallTemplateProgressModel>>(
        '/v1/template/install/progress',
        {
            method: 'get',
            params,
        },
    );

/** 校验模板是否有示例数据 */
export function checkSampleData(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<boolean>>('/v1/template/checksampledata', {
        method: 'post',
        data: params,
    });
}

/** 清空模板示例数据 */
export function clearSampleData(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<boolean>>('/v1/template/clearsampledata', {
        method: 'post',
        data: params,
    });
}

/** 发布应用给指定企业 */
export function shareApp(
    params: H3.Operate.Request.IShareAppModel,
    requestOption: H3.Http.Request.RequestOptions = {},
) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/share/app/publish', {
        method: 'post',
        data: params,
        ...requestOption,
    });
}

/** 获取关联企业列表 */
export function getRelateCorps() {
    return request<H3.Http.Response.H3Response<H3.Engine.IRelationCorp[]>>('/v1/share/app/engine/list', {
        method: 'get',
    });
}

/** 根据开关名称获取功能开关 */
export const getSwitchListByName = (names: string[]) =>
    request<
        H3.Http.Response.H3Response<{
            switches: Array<{
                name: H3.Operate.FunctionSwitchNameEnum;
                value: string;
            }>;
        }>
    >('/v1/switch/list/bynames', {
        method: 'post',
        data: names,
    });

/**
 * 获取运营链接地址
 * @returns
 */
export const getOperateLink = () =>
    request<H3.Http.Response.H3Response<H3.Operate.OperateLink>>('/v1/operation/linkurls', {
        method: 'get',
    });

/**
 * goBBS
 * @returns
 */
export const goBBS = () =>
    request<H3.Http.Response.H3Response<H3.Operate.GoBBSModel>>('/Navigation/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: JSON.stringify({
                ActionName: 'GoBBS',
            }),
        },
    });

const PromptAction = '/Prompt/OnAction';
/**
 * 获取智能云客服信息
 * @returns
 */
export const getCustomerService = () =>
    request<H3.Http.Response.H3Response<H3.Operate.GetCustomerServiceModel>>(PromptAction, {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: JSON.stringify({
                ActionName: 'GetCustomerService',
            }),
        },
        disableBizError: true,
    });

/** 请求运营推送数据 */
export const getOperatePush = (openType: H3.Operate.OperateOpenType) =>
    request<H3.Http.Response.H3Response<H3.Operate.Response.GetOperatePushModal>>(PromptAction, {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: JSON.stringify({
                ActionName: 'LoadPushNotification',
                OpenType: openType,
            }),
        },
        camelCase: true,
    });

/**
 * 处理运营推送
 * @param param
 * @returns
 */
export const handleOperatePush = (param: H3.Operate.Request.HandleOperatePushParams) =>
    request<H3.Http.Response.H3Response<null>>(PromptAction, {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: JSON.stringify({
                ActionName: 'FinishPushNotification',
                ...param,
            }),
        },
        camelCase: true,
    });

/** 请求运营推送数据 */
export const SetSelectCorp = (params: H3.Operate.ISetCorpInfo) =>
    request<H3.Http.Response.H3Response<null>>('v1/operation/register/corpinfo', {
        method: 'post',
        requestType: 'form',
        data: params,
    });

/** 获取指定应用统计信息 */
export const getAppTrack = (appCode: string) =>
    request<H3.Http.Response.H3Response<{ totalBizObjectCount: number; schemaCount: number; modifiedTime: string }>>(
        '/v1/operation/apptrack/getbycode',
        { method: H3.Http.MethodType.GET, params: { appCode } },
    );
