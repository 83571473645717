import { request } from '@h3/request';
import { H3 } from '@h3/types';
import * as Api from './constant';

/** 查询考勤 */
export const queryDingTalkAttendanceDetail = (params: { ding_userid: string; from_date: string; to_date: string }) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<
            Array<{
                value: number;
                label: string;
                alias: string;
                id: number;
                isChild: boolean;
                unit: string;
            }>
        >
    >(Api.DingTalkAttendanceDetail, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 查询打卡 */
export const queryDingTalkAttendanceClock = (params: { ding_userid: string; from_date: string; to_date: string }) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<
            Array<{
                approveId: number;
                baseCheckTime: string;
                checkType: string;
                groupId: number;
                id: number;
                locationResult: string;
                planId: number;
                procInstId: null | string;
                recordId: number;
                sourceType: string;
                timeResult: string;
                userCheckTime: string;
                userId: string;
                workDate: string;
            }>
        >
    >(Api.DingTalkAttendanceClock, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 查询请假 */
export const queryDingTalkAttendanceVacate = (params: { ding_userid: string; from_date: string; to_date: string }) => {
    return request<H3.Http.Response.H3ResponseV2Beta<Array<{ name: string; date: string; value: string }>>>(
        Api.DingTalkAttendanceVacate,
        {
            method: 'post',
            data: params,
            disableBizError: true,
        },
    );
};

/** 发送消息至会话 */
export const sendDingTalkAttendanceSessionsChat = (params: {
    Msgtype: string;
    Text?: {
        Content: string;
    };
    Link?: {
        MessageUrl: string;
        Text: string;
        Title: string;
    };
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.DingTalkAttendanceSessionsChat, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 发送消息至钉钉机器人 */
export const sendDingTalkAttendanceRobotChat = (params: {
    Msgtype: string;
    Webhook: string;
    EncryKey: string;
    isAtAll: string;
    Mobiles?: string;
    Text?: { Content: string };
    Link?: {
        MessageUrl: string;
        Text: string;
        Title: string;
    };
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.DingTalkAttendanceRobotChat, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};
