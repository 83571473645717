import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

// 请求头
const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/** 获取自定义代码 */
export const loadSourceCode = (params: H3.CustomCode.Request.ICsCodeLoad) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.Response.ICsCodeLoad>>(Api.LoadCode, {
        method: 'post',
        data: params,
    });
};

export const updateSourceCode = (params: H3.CustomCode.Request.ICsCodeUpdate) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.Response.ICsCodeUpdateRes>>(Api.UpdateCode, {
        method: 'put',
        data: params,
    });
};
export const updateSourceLineCode = (params: H3.CustomCode.Request.ICsCodeLineUpdate) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.Response.ICsCodeLineUpdateRes>>(Api.UpdateLineCode, {
        method: 'put',
        data: params,
        disableBizError: true,
    });
};

export const getCsCodeSuggestion = (params: H3.CustomCode.Request.ICsCodeSuggestion) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.Response.ICsCodeSuggestionRes>>(Api.GetSense, {
        method: 'post',
        data: params,
    });
};

// 添加按钮任务
export const addTask = (params: H3.CustomCode.Request.IAddTask) => {
    return request<H3.Http.Response.H3Response<{ taskId: string; logTime: string }>>(Api.AddTask, {
        method: 'post',
        data: params,
    });
};

// 更新按钮状态
export const updateTaskStatus = (params: H3.CustomCode.Request.IUpdateTaskStatus) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateTaskStatus, {
        method: 'post',
        data: params,
    });
};

// 更新按钮中多条数据状态
export const updateObjectsStatus = (params: H3.CustomCode.Request.IUpdateObjectsStatus) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateObjectsStatus, {
        method: 'post',
        data: params,
    });
};
// 查询按钮操作执行日志记录
export const queryTaskLog = (params: H3.CustomCode.Request.IQueryTaskLog) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.ITaskItem[]>>(Api.QueryTaskLog, {
        method: 'get',
        params,
    });
};

//查询按钮操作对应的业务数据执行日志记录
export const queryObjectsLog = (params: H3.CustomCode.Request.IQueryObjectsLog) => {
    return request<H3.Http.Response.H3Response<H3.CustomCode.IObjectItem[]>>(Api.QueryObjectsLog, {
        method: 'post',
        data: params,
    });
};

/** 通过任务id获取视图按钮自动化日志详情 */
export const getAutomationLogByTaskId = (taskId: string) =>
    request<H3.Http.Response.H3Response<H3.CustomCode.Response.TGetActionLogDetailModel>>(
        '/v1/bizlog/action/automation/bytaskId',
        {
            method: H3.Http.MethodType.GET,
            params: {
                taskId,
            },
        },
    );

/** 通过任务id获取视图批量编辑日志详情 */
export const getBatchUpdateLogByTaskId = (taskId: string) =>
    request<H3.Http.Response.H3Response<H3.CustomCode.Response.TGetActionLogDetailModel>>(
        '/v1/bizlog/action/batchupdate/bytaskId',
        {
            method: H3.Http.MethodType.GET,
            params: {
                taskId,
            },
        },
    );

/** 更新自定义代码行 */
export const updateSourceCodeLines = (params: H3.CustomCode.Request.IUpdateSourceCodeLinesParams) =>
    request<H3.Http.Response.H3ResponseV2<H3.CustomCode.Response.IUpdateSourceCodeLines>>('/codeeditor/OnAction', {
        method: 'post',
        data: {
            ...params,
            ActionName: 'UpdateSourceCodeLines',
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
