import { H3 } from '@h3/types';

export class TableFormGridSetting {
    [H3.DB.DBField.ID]!: string;
    [H3.DB.DBField.TIMESTAMP]?: number;
    [H3.DB.DBField.VALUE]: Record<string, H3.DB.DBFormGridSetting> | null = null;

    constructor({
        id,
        value,
    }: {
        [H3.DB.DBField.ID]: string;
        [H3.DB.DBField.VALUE]: Record<string, H3.DB.DBFormGridSetting>;
    }) {
        this.id = id;
        this.timestamp = new Date().getTime();
        this.value = value;
    }
}
