import { EventDataNode } from 'ant-design-vue/lib/tree';
export namespace Tree {
    export enum TreeNodeType {
        /** 加载更多 */
        MoreBtn = 'moreBtn',
    }

    export type KeyType = string | number;

    export type CheckKeys = KeyType[] | { checked: KeyType[]; halfChecked: KeyType[] };

    export interface ITreeNode<T = unknown> {
        source?: T;
        title: string;
        key: string;
        value?: string;
        isLeaf?: boolean;
        selectable?: boolean;
        class?: string;
        children?: ITreeNode<T>[];
    }

    export interface ILoadDataFunction {
        (node: EventDataNode): Promise<void>;
    }

    export interface ITreeListSource {
        filterKey: string;
        filterValue: string;
        pageIndex: number;
        parentKey: string;
        type?: string;
        level?: number;
    }
}
