import { Task } from '../task';
export namespace CustomCode {
    export enum CsKindType {
        NameSpace = 'NameSpace',
        Class = 'Class',
        Constructor = 'Constructor',
        Object = 'Object',
        Variable = 'Variable',
        PrivateMethod = 'PrivateMethod',
        ProtectedMethod = 'ProtectedMethod',
        PublicMethod = 'PublicMethod',
        InternalMethod = 'InternalMethod',
        PrivateProperty = 'PrivateProperty',
        ProtectedProperty = 'ProtectedProperty',
        PublicProperty = 'PublicProperty',
        InternalProperty = 'InternalProperty',
        PrivateField = 'PrivateField',
        ProtectedField = 'ProtectedField',
        PublicField = 'PublicField',
        InternalField = 'InternalField',
    }

    export enum CompletionItemKind {
        Method = 0,
        Function = 1,
        Constructor = 2,
        Field = 3,
        Variable = 4,
        Class = 5,
        Struct = 6,
        Interface = 7,
        Module = 8,
        Property = 9,
        Event = 10,
        Operator = 11,
        Unit = 12,
        Value = 13,
        Constant = 14,
        Enum = 15,
        EnumMember = 16,
        Keyword = 17,
        Text = 18,
        Color = 19,
        File = 20,
        Reference = 21,
        Customcolor = 22,
        Folder = 23,
        TypeParameter = 24,
        User = 25,
        Issue = 26,
        Snippet = 27,
    }

    export type IParameter = {
        name: string;
        summary: string;
        typeName: string;
    };

    export interface ICsCodeSense {
        name: string;
        parameters: IParameter[];
        resultType: CsKindType;
        returnSummary: string;
        typeFullName: string;
        summary: string;
    }

    export interface ICsCodeErr {
        beginColumn: number;
        beginRow: number;
        endColumn: number;
        endRow: number;
        errorMessage: string;
        fileName: string;
        isError: boolean;
    }

    export interface IDebugTrack {
        appCode: string;
        debugState: number;
        series: string;
        lineTrack: ILineTrack;
    }

    export type IDebugCallback = (viewContext: unknown, debugScene?: string) => void;

    export type ITrack = {
        name: string;
        value: string;
        childTracks: ITrack[] | null;
    };

    export interface ILineTrack {
        startRow: number;
        fileName: string;
        objectTrackTable: {
            [key: string]: ITrack;
        };
    }

    export interface DebugArgs {
        editorEl: HTMLElement;
        onChangeTrackDetail: (trackDetail: ITrack[]) => void;
        onFinish: (ctx: unknown, debugScene?: string) => void;
    }

    export type IOldFormDebugData = {
        track: unknown;
        cb: (data: unknown) => void;
    };

    export const enum DebugState {
        // 调试中
        Debugging = 0,
        // 异常信息
        Exceptional = 1,
        // 结束
        Finished = 2,
    }

    export const enum MonitorType {
        CTRLF5 = 0,
        F5 = 116,
        F6 = 117,
        F9 = 120,
        F10 = 121,
        F11 = 122,
        F12 = 123,
    }

    export const enum DebugScene {
        LIST = 'LIST',
        FORM = 'FORM',
    }

    export const enum DebugSubScene {
        /** 调试列表设置请求 */
        LIST_SETTING = 'LIST_SETTING',

        /** 调试列表数据请求 */
        LIST_DATA = 'LIAT_DATA',
    }

    /**
     * 任务同步状态
     */
    export enum TASK_SYNC_STATE {
        unSync = 0, //未同步

        synced = 1, //已同步
    }

    /**
     * 按钮任务状态
     */
    export enum ACTION_TASK_STATE {
        Waiting, // 等待执行
        Running, // 执行中
        Completed, // 已完成
        Aborted, // 终止
    }

    export const ActionTaskStateMap = {
        [ACTION_TASK_STATE.Waiting]: '待处理',
        [ACTION_TASK_STATE.Running]: '执行中',
        [ACTION_TASK_STATE.Completed]: '已完成',
        [ACTION_TASK_STATE.Aborted]: '已终止',
    };

    /**
     * 数据执行状态
     */
    export enum ACTION_OBJECT_STATE {
        Unexecuted = 0, // 未执行 默认状态

        Success = 1, // 执行成功

        Failed = 2, //执行失败
    }

    export const ActionObjectStateMap = {
        [ACTION_OBJECT_STATE.Unexecuted]: '未执行',
        [ACTION_OBJECT_STATE.Success]: '成功',
        [ACTION_OBJECT_STATE.Failed]: '失败',
    };

    // 任务数据结构
    export interface ITaskItem {
        taskScene: Task.TaskScene;
        engineCode?: string;
        schemaCode: string;
        actionCode: string;
        actionName: string;
        taskId: string;
        logTime: string;
        state: ACTION_TASK_STATE;
        total: number;
        result: taskResultItem[];
    }

    export interface taskResultItem {
        state: ACTION_OBJECT_STATE;
        count: number;
    }

    // 单条数据的数据结构
    export interface IObjectItem {
        engineCode: string;
        schemaCode: string;
        actionCode: string;
        actionName: string;
        taskId: string;
        logTime: string;
        state: ACTION_OBJECT_STATE;
        bizObjectId: string;
        remark: string;
    }

    /** 前端自定义代码Api定义 */
    export namespace CustomApi {
        /** 通用api */
        export interface ICommonApi {
            /**
             * 生成一个Guid
             * @returns guid
             */
            IGuid(): string;

            /**
             * 获取url中的指定参数
             * @param name 参数名
             * @returns 参数值，参数不存在时返回空字符串
             */
            IQuery(name: string): string;

            /**
             * 深克隆给定对象
             * @param obj 被克隆的对象
             * @returns 克隆后的对象
             */
            IClone<T extends Record<string, unknown>>(obj: T): T;

            /**
             * 显示成功提示
             * @param title 标题
             * @param msg 内容
             */
            IShowSuccess(title: string, msg: string): void;
            /**
             * 显示成功提示
             * @param msg 内容
             */
            IShowSuccess(msg: string): void;
            /**
             * 显示成功提示
             * @param param 参数
             * @param param.title 标题
             * @param param.msg 内容
             * @param param.detail 详情
             */
            IShowSuccess(param: { title: string; msg: string; detail: string }): void;
            IShowSuccess(...args: unknown[]): void;

            /**
             * 显示失败提示
             * @param title 标题
             * @param msg 内容
             */
            IShowError(title: string, msg: string): void;
            /**
             * 显示失败提示
             * @param msg 内容
             */
            IShowError(msg: string): void;
            /**
             * 显示失败提示
             * @param param 参数
             * @param param.title 标题
             * @param param.msg 内容
             * @param param.detail 详情
             */
            IShowError(param: { title: string; msg: string; detail: string }): void;
            IShowError(...args: unknown[]): void;

            /**
             * 显示警告提示
             * @param title 标题
             * @param msg 内容
             */
            IShowWarn(title: string, msg: string): void;
            /**
             * 显示警告提示
             * @param msg 内容
             */
            IShowWarn(msg: string): void;
            /**
             * 显示警告提示
             * @param param 参数
             * @param param.title 标题
             * @param param.msg 内容
             * @param param.detail 详情
             */
            IShowWarn(param: { title: string; msg: string; detail: string }): void;
            IShowWarn(...args: unknown[]): void;

            /**
             * 显示普通提示
             * @param title 标题
             * @param msg 内容
             */
            IShowTip(title: string, msg: string): void;
            /**
             * 显示普通提示
             * @param msg 内容
             */
            IShowTip(msg: string): void;
            /**
             * 显示普通提示
             * @param param 参数
             * @param param.title 标题
             * @param param.msg 内容
             * @param param.detail 详情
             */
            IShowTip(param: { title: string; msg: string; detail: string }): void;
            IShowTip(...args: unknown[]): void;

            /**
             * 弹出确认弹窗
             * @param title 弹窗标题
             * @param text 弹窗内容（可传入渲染函数）
             * @param callback 弹窗关闭回调，参数为是否确认
             */
            IConfirm(
                title: string,
                text?: string | ((h: unknown) => unknown),
                callback?: (confirm: boolean) => void,
            ): void;

            /**
             * 打开loading提示
             * @param text 提示文字
             * @param duration loading持续时间，单位s，默认0，表示一直显示
             */
            IShowPreLoader(text: string, duration?: number): void;

            /** 关闭loading提示 */
            IHidePreLoader(): void;

            /**
             * 控制另一种样式的loading开启或关闭
             * @param show 开启 or 关闭
             */
            IShowCustomLoading(show: boolean): void;

            /**
             * 打开表单
             * @param args 表单参数
             */
            IShowForm(...args: unknown[]): void;

            /**
             * 触发下载给定文件
             * @param attachmentIds 要下载的附件id
             */
            IDownloadAttachments(attachmentIds: string[]): void;

            /**
             * 获取自定义打印（空白画布打印）url
             * @param schemaCode 表单编码
             * @param printTemplateCode 打印模板编码
             * @param bizObjectIds 要打印的数据id
             * @param callback 获取到url后的回调，回调参数为url
             */
            IGetCustomPrintUrl(
                schemaCode: string,
                printTemplateCode: string,
                bizObjectIds: string[],
                callback: (url: string) => void,
            ): Promise<void>;

            /**
             * 在新窗口打开给定链接
             * @param url 要打开的链接
             */
            IOpenLink(url: string): void;

            /**
             * 获取当前位置信息
             * ! 注意，目前调用此方法返回的是固定位置信息
             * @returns 位置信息
             */
            ILocation(): {
                Address: string;
                Point: { lat: string | number; lng: string | number };
            };

            /** 打开自定义表单 */
            IShowCustomForm(title: string, formConfig: unknown): void;

            // 下面几个api在pc端调用无任何效果
            IScanBarCode(): void;

            IScanQrCode(): void;

            IScanCard(): void;

            IShowUserInfo(): void;

            IShowChatPage(): void;

            IShowFreeCall(): void;

            IPostImageDing(): void;

            IPostLinkDing(): void;
        }

        /** 列表api */
        export interface IListApi extends ICommonApi {
            /**
             * 获取自定义代码参数
             * @description 这里的自定义代码参数从2.0代码逻辑中来看是通过ISideModal传递的，不确定具体业务逻辑
             * ! 要注意的是和自定义代码打开表单传递的参数没有关系
             * @param name 参数名
             */
            IGetParams(name: string): unknown;

            /**
             * 清除自定义代码打开表单时传递的参数
             * ! 在目前的技术方案下，这个方法没有作用，也不应该再被调用
             */
            IClearParams(): void;

            ISideModal(): void;

            /** 刷新视图 */
            IRefreshView(): void;
        }

        /** 表单api */
        export interface IFormApi extends ICommonApi {
            /**
             * 获取自定义代码打开表单时传递的参数
             * @param name 参数名
             * @returns 参数值
             */
            IGetParams(name: string): unknown;

            /**
             * 清除自定义代码打开表单时传递的参数
             * ! 在目前的技术方案下，这个方法没有作用，也不应该再被调用
             */
            IClearParams(): void;

            /** 刷新视图 */
            IRefreshView(): void;
        }
    }

    /** 暴露到全局的Api定义 */
    export namespace GlobalApi {
        /** 全局Api */
        export interface IGlobalApi {
            /**
             * 打开表单
             * @param schemaCode 表单编码
             * @param objectId 业务数据id，不传则为新增
             */
            openForm(this: Window, schemaCode: string, objectId: string): void;
        }
    }

    export interface IGetObjectsNameItem {
        ObjectId: string;
        Name: string;
    }
    export namespace Response {
        export interface ICsCodeLoad {
            /** 前端代码 */
            jsCode: string;
            /** 后端代码 */
            behindCode: string;
        }

        export type ICsCodeUpdateRes = string[] | null;

        export interface ICsCodeLineUpdateRes {
            classNames: string[] | null;
            errors: ICsCodeErr[];
            sourceCode: string | null;
        }

        export type ICsCodeSuggestionRes = ICsCodeSense[];

        export type TGetActionLogDetailModel = {
            logTime: string;
            schemaCode: string;
            actionCode: string;
            actionName: string;
            taskId: string;
            state: ACTION_TASK_STATE;
            total: number;
            result: taskResultItem[];
            detail: Array<{ bizObjectId: string; state: ACTION_OBJECT_STATE; remark: string }>;
        };

        export interface IUpdateSourceCodeLines {
            ErrorCode: number;
            Errors: unknown;
            FileSourceCode: string;
            RefClassNames: string;
            ResultType: string;
        }
    }

    export namespace Request {
        export enum ICSCodeType {
            /** 表单 */
            Form = 1,
            /** 列表 */
            List = 2,
        }

        export interface ICsCodeLoad {
            schemaCode: string;
            /** 1: 表单 2: 列表 */
            type: ICSCodeType;
        }

        export interface ICsCodeUpdate {
            sourceCode: string;
            appCode: string;
            fileName: string;
        }

        export interface ICodeContentChange {
            type: number;
            line: number;
            text: string | null;
        }

        export interface ICsCodeLineUpdate {
            changes: Array<ICodeContentChange>;
            appCode: string;
            fileName: string;
            returnSourceCode: true | false;
        }

        export interface ICsCodeSuggestion {
            row: number;
            column: number;
            lastWord: string;
            appCode: string;
            fileName: string;
            isMoverOver: true | false;
        }

        export interface IAddTask {
            schemaCode: string;
            actionCode: string;
            actionName: string;
            bizObjectIds: string[];
        }

        export interface IUpdateTaskStatus {
            schemaCode: string;
            taskId: string;
            actionCode: string;
            state: ACTION_TASK_STATE;
        }

        export interface IUpdateObjectsStatusItem {
            bizObjectId: string;
            taskId: string;
            remark: string;
            state: ACTION_OBJECT_STATE;
        }

        export interface IUpdateObjectsStatus {
            schemaCode: string;
            updateItems: IUpdateObjectsStatusItem[];
        }

        export interface IQueryTaskLog {
            schemaCode: string;
        }

        export interface IQueryObjectsLog {
            schemaCode: string;
            taskId: string;
        }

        export interface IGetObjectsName {
            schemaCode: string;
            objectIds: string[];
        }

        export interface IUpdateSourceCodeLinesParams {
            Changes: unknown;
            AppCode: string;
            FileName: string;
            ReturnSourceCode: boolean;
            ActionName?: string;
        }
    }
}
