import { Auth } from '../auth';
import { CustomCode } from '../custom-code';
import { Engine } from '../engine';
import { Workflow } from '../workflow';

import { View } from '../view';

export namespace BizData {
    export namespace Filter {
        /**
         * 筛选操作比较符
         */
        export const enum ComparisonOperatorType {
            //     大于
            Above = 0,
            //     大于等于
            NotBelow = 1,
            //     等于
            Equal = 2,
            //     小于等于
            NotAbove = 3,
            //     小于
            Below = 4,
            //     不等于
            NotEqual = 5,
            //     在某个范围内
            In = 6,
            //     不在某个范围内
            NotIn = 7,
            //     比较符左边的对象包含右边的对象，比如：{1, 2, 3} Contain {2} == true，如果是字符串的话，则调用string.Contains这样的逻辑
            Contains = 8,
            //     以某个字符串为开始
            StartWith = 13,
            //     以某个字符串为结束
            EndWith = 14,
            //     是否是Null
            IsNull = 18,
            //     是否不是Null
            NotNull = 19,
            //     逻辑上是空，表示为Null、""、object[0]
            IsNone = 20,
            //     逻辑上非空，表示不是Null、""、object[0]
            NotNone = 21,
            //     不以某个字符串开始
            NotStartWith = 22,
            //     不以*开始
            NotEndWith = 23,
            //     不包含
            NotContains = 24,
        }

        /**
         * 修饰限定返回字段
         */
        export enum SQLFunction {
            //     无任何函数
            None = 0,
            //     去除相同的
            Distinct = 1,
            //     统计数量
            Count = 2,
            //     求和
            Sum = 3,
            //     最大值
            Max = 4,
            //     最小值
            Min = 5,
            //     平均值
            Avg = 6,
        }

        /**
         * 匹配类型
         */
        export enum MatchType {
            AND = 'And',
            OR = 'Or',
            ITEM = 'Item',
        }

        /**
         * 筛选规则项，当前字段与目标字段的比较
         */
        export interface IFilterRuleItem {
            curFormFieldCode: string;
            targetFormFieldCode: string;
            curFormFieldControlKey: string;
            operator?: null;
        }

        /**
         * 筛选匹配器对象
         */
        export interface IMatcherItem {
            type: MatchType;
            operator: ComparisonOperatorType;
            matchers: IMatcherItem[];
            name?: string;
            value?: unknown;
        }

        /**
         * 非视图加载数据查询筛选对象
         */
        export interface IMatcherFilter {
            expressionFilter?: {
                schemaCode: string;
                bizObject: Record<string, unknown>;
                formula: string;
            };
            matcher: IMatcherItem;
        }
    }

    export namespace Gantt {
        /**
         * 升降级类型
         */
        export enum TaskLevel {
            // 升级
            Promote = 'Promote',
            // 降级
            Demote = 'Demote',
        }

        /**
         * 任务类型
         */
        export enum TaskType {
            NORMAL = 0,
            MILESTONE = 1,
        }

        /**
         * 所属依赖
         */
        export enum TaskDependency {
            AddDependency = 'AddDependency',
            RemoveDependency = 'RemoveDependency',
        }

        /**
         * 按钮类型
         */
        export enum ButtonType {
            Add = 'add',
            AddChild = 'addChild',
            CancelMilestone = 'cancelMilestone',
            MarkMilestone = 'markMilestone',
            Upgrade = 'upgrade',
            Relegate = 'relegate',
            Remove = 'remove',
        }
    }

    export type IProperty = {
        propertyName: string;
        function?: Filter.SQLFunction;
    };

    export namespace Request {
        /**
         * 数据查询接口请求参数(经过code runner)
         */
        export interface IQueryBizDataModel {
            schemaCode?: string;
            scope?: Auth.DataScopeType;
            pageIndex?: number;
            pageSize?: number;
            filter?: Filter.IMatcherFilter;
            childSchemaCode?: string;
            sortBy?: string;
            sortDirection?: number;
            listViewDisplayMode?: View.ViewMode;
        }

        /**
         * 非视图数据查询接口请求参数模型
         */
        export interface ICommonQueryBizDataModel {
            schemaCode?: string;
            pageIndex?: number;
            pageSize?: number;
            filter?: Filter.IMatcherFilter;
            properties?: IProperty[];
            requireCount?: boolean;
            sortBy?: string;
            sortDirection?: number;
            scope?: Auth.DataScopeType;
            includeDefaultFilter?: boolean;
        }

        /**
         * 查询业务数据某列去重值请求参数
         */
        export interface IQueryBizDataDistinctUrlParams {
            schemaCode: string;
            propertyName: string;
            searchKey: string;
            scope: Auth.DataScopeType;
            pageIndex: number;
            pageSize: number;
        }

        /**
         * 删除数据请求参数
         */
        export interface IDeleteFormatBizObjectModel {
            schemaCode: string;
            objectIds: string[];
        }

        /**
         * 数据列统计请求参数
         */
        export interface IStatColumnModel {
            schemaCode: string;
            statConfig: Record<string, unknown>;
            scope: number;
            filter?: Record<string, unknown>;
        }

        /**
         * 甘特图升降级请求参数
         */
        export interface IGanttTaskLevelModel {
            operation: Gantt.TaskLevel;
            schemaCode: string;
            bizObjectId: string;
            data: {
                [key in string]: string;
            };
            parentObjectId?: string;
            isMobile?: boolean;
        }

        /**
         * 更新任务类型请求参数
         */
        export interface IGanttTaskTypeModel {
            schemaCode: string;
            bizObjectId: string;
            taskType: Gantt.TaskType;
        }

        /**
         * 任务排序请求参数
         */
        export interface IGanttTaskSortModel {
            schemaCode: string;
            bizObjectId: string;
            parentObjectId: string;
            preObjectId: string;
            afterObjectId: string;
        }

        /**
         * 更新任务内容信息请求参数
         */
        export interface IGanttTaskModel {
            schemaCode: string;
            bizObjectId: string;
            formValue: {
                data: Record<string, unknown>;
            };
            isMobile?: boolean;
        }

        /**
         * 更新所属依赖请求参数
         */
        export interface IGanttTaskDependencyModel {
            schemaCode: string;
            bizObjectId: string;
            dependentBizObjectId: string;
            operation: Gantt.TaskDependency;
            dependencyType: number;
            isMobile?: boolean;
        }

        /** 获取关联表单级联数据请求参数模型 */
        export interface IBatchGetFormQueryBoModel {
            /** 关联表单编码 */
            bOSchemaCode: string;

            /** 源表单编码 */
            sourceSchemaCode: string;

            /** 关联表单控件在源表单中的字段编码 */
            sourceField: string;

            /** 关联表单业务数据ids */
            bizObjectIds: string[];

            /** 子层级 */
            children: Array<Omit<IBatchGetFormQueryBoModel, 'bizObjectIds'>>;
        }
    }

    export namespace Response {
        /**
         * 统计列返回结果
         */
        export interface IColumnStatResponse {
            [key: string]: number | string;
        }

        /**
         * 数据查询返回额外结果
         */
        export interface IDataQueryExtraResponse {
            /** 是否超限(开启树形结构后) */
            isOverMaxBoCount: boolean;
            /** 是否超阈值(开启层级视图后) */
            isOverThresholdHierarchy: boolean;
            /** 是否超限(开启层级视图后) */
            isOverCountHierarchy: boolean;
        }

        /**
         * 数据查询返回结果模型
         */
        export interface IDataQueryResponse {
            dataCount: number;
            returnData: Record<string, unknown>[];
            debugTrack: CustomCode.IDebugTrack | null;
            workflowState: Record<
                string,
                Array<{
                    activityName: string;
                    avatar: string;
                    dingId: string;
                    entryId: string;
                    entryType: Engine.EntryType.H3;
                    itemType: Workflow.WorkItemType;
                    userId: string;
                    userName: string;
                }>
            > | null;
            listModeProperty: unknown;
            isOverMaxBoCount_TreeStructure: boolean;
            /** 是否超阈值(开启层级视图后) */
            isOverMaxBoCount_Hierarchy1: boolean;
            /** 是否超限(开启层级视图后) */
            isOverMaxBoCount_Hierarchy2: boolean;
        }

        /**
         * 数据某列去重值返回结果模型
         */
        export interface IDataDistinctQueryResponse {
            hasMore?: boolean;
            data?: string[];
        }

        /**
         * 请求数据短链返回结果模型
         */
        export interface IDataShortLink {
            name: string;
            url: string;
        }

        /**
         * 任务排序返回结果模型
         */
        export interface IGanttTaskSortResponse {
            newGanttObjects: {
                bizObjectId: string;
                sortIndex: number;
            }[];
        }
    }

    /** 表单字段在前端中的值数据结构 */
    export namespace FrontControlData {
        /** 地址数据值结构 */
        export type TAddressValue = {
            adcode: string;
            adname: string;
            Detail: string;
        };

        /** 位置数据值结构 */
        export type TMapValue = {
            Address: string;
            Point: { lat: number; lng: number };
        };

        /**
         * 文件数据值结构
         * @description 目前这个数据结构和antd的Upload组件深度耦合，想要改动的话需要注意
         */
        export type TAttachmentValue = {
            /** 文件uid */
            uid: string;

            /** 文件名称 */
            name: string;

            /** 复制出来的文件对应的源文件的uid */
            sourceId?: string;

            /** 文件类型 */
            type?: string;

            /** 文件大小 */
            size?: number;

            /** 文件状态，没有抽成枚举，因为在和antd之间转换时有问题 */
            status?: 'uploading' | 'done' | 'success' | 'error' | 'removed';

            /** 文件上传进度 */
            percent?: number;

            /** 文件缩略图地址 */
            thumbUrl?: string;

            /** 描述 */
            description?: string;

            /** 原文件地址 */
            url?: string;

            /** 文件排序key */
            sortKey?: number;

            /** oss上的下载地址 */
            downloadUrl?: string;

            /** 上传到阿里云的临时key，用于查找新上传的文件，在线预览时使用 */
            ossKey?: string;
        };

        /** 业务数据值结构 */
        export type TFormQueryValue = {
            ObjectId: string;

            Name: string;
        };

        /** 人脸识别控件数据值 */
        export type TFaceRecognitionValue = {
            faceId: string;
            personId: string;
            personName: string;
            data?: Array<{
                key: string;
                value: string;
            }>;
        };
    }
}
