import { Auth } from '../auth';
import { Engine } from '../engine';

export namespace Org {
    export const RootId = '18f923a7-5a5e-426d-94ae-a55ad1a4b240'; // 企业组织机构id
    /** 类型 */
    export enum UnitType {
        /** 默认值 */
        Default = 0,
        /** 未指定 */
        Unspecified = 15,
        /** 公司 */
        Company = 1,
        /** 组织单元 */
        OrganizationUnit = 2,
        /** 用户 */
        User = 4,
        /** 角色 */
        Role = 8,
        /** 编制 */
        Post = 16,
        /** 角色组 */
        RoleGroup = 20,
    }

    export enum OrgNodeType {
        /** user,org,role */
        Unit = 0,
        /** 系统变量，参与者控件 */
        Variables = 1,
        /** 参与者函数 */
        Function = 2,
    }

    export enum UserGenderType {
        /** 未知 */
        'None' = 'None',
        /** 男 */
        'Male' = 'Male',
        /** 女 */
        'Female' = 'Female',
    }

    export enum UserGender {
        /** 未知 */
        'None' = '未知',
        /** 男 */
        'Male' = '男',
        /** 女 */
        'Female' = '女',
    }

    export enum WeChartOrgType {
        Department = 'departmentName',
        User = 'userName',
    }

    /** 人员部门类型枚举 */
    export enum OrgScopeType {
        /** 未指定 */
        Unspecified = -1,
        /** 内部组织 */
        INNER = 1,
        /** 外部组织 */
        EXTERNAL = 2,
        /** 全部 */
        ALL = 3,
    }

    /** 用户信息 */
    export interface IUserInfo {
        /** 登录唯一标识 */
        token: string;
        /** 系统唯一标识 */
        userId: string;
        /** 用户名 */
        name: string;
        /** 头像 */
        avatar: string;
        /** 部门Id */
        departmentId: string;
        /**
         * 个人的管理权限控制，用枚举
         * 主管理员、子管理员、个人（是否管理个人应用还是普通使用者）、匿名、外部用户
         * */
        auth: Auth.UserAuthType;

        /** 引擎编码 */
        currentEngine: Engine.EngineInfo;
        /** 有权限登录的引擎编码 */
        engines: Array<{ code: string; name: string }>;
    }

    /** 组织机构-人员信息 */
    export interface IOrgUserInfo {
        /** 父级部门名称 */
        parentName: string;
        /** 生日 */
        birthday: string;
        /** 性别 */
        gender: UserGender;
        /** 邮箱 */
        email: string;
        /** 手机号 */
        mobile: string;
        /** 头像 */
        avatar: null | string;
        /** 工号 */
        employeeNumber: null | number | string;
        /** 职位 */
        position: null | string;
        /** 入职日期 */
        entryDate: null | string;
        /** 父级部门ID */
        parentId: string;
        /** 三方部门id(钉钉/企微/飞书) */
        entryDepartmentId: string;
        /** id */
        id: string;
        /** 三方id(钉钉/企微/飞书) */
        entryId: string;
        /** 姓名 */
        name: string;
        /** 状态 */
        state: number;
        /** 认证状态 */
        authorizedState: number;
        /** 是否是外部用户 */
        isExternal: boolean;
        /** 是否有下级(前端处理后的数据) */
        hasChild?: boolean;
        /** 组织机构类型 */
        unitType: UnitType;
        /** 节点类型 */
        nodeType: OrgNodeType;
        /** 人员部门类型 */
        type?: WeChartOrgType;
    }

    /** 组织机构-部门信息 */
    export interface IOrgDepartmentInfo {
        /** 父级部门ID */
        parentId: string;
        /** id */
        id: string;
        /** 名称 */
        name: string;
        /** 是否有下级 */
        hasChild: boolean;
        /** 状态 */
        state: number;
        /** 是否是外部用户 */
        isExternal: boolean;
        /** 三方部门id(钉钉/企微/飞书) */
        entryId: string;
        /** 组织机构类型 */
        unitType: UnitType;
        /** 节点类型 */
        nodeType: OrgNodeType;
        /** 人员部门类型 */
        type?: WeChartOrgType;
    }

    /** 组织机构-角色信息 */
    export interface IOrgRoleInfo {
        /** 唯一标识 */
        id: string;
        /** 名称 */
        name: string;
        /** 是否是外部用户 */
        isExternal?: boolean;
        entryId?: string;
        /** 组织机构类型 */
        unitType: UnitType;
        /** 节点类型 */
        nodeType: OrgNodeType;
        /** 人员部门类型 */
        type?: WeChartOrgType;
    }

    export interface ICurUser {
        DingTalkAccount: string;
        DingTalkCorpId: string;
        Name: string;
        ProfilePhotoUrl: string;
        UserId: string;
        ParentId: string;
    }
    export interface ICommonUserInfo {
        CurUser: ICurUser;
        EntrySolutionCode: string;
        SuiteKey: string | null;
        OptionalEngines: {
            [key: string]: string;
        };
        EngineCode: string;
        LogoUrl: string;
        IsExternalUser: boolean;
        AssociatedEngineCode: string | null;
        IsAdministrator: boolean;
        IsSubAdministrator: boolean;
        AdminAppCodes: string | null;
        IsAppCreator: boolean;
        Mobile: string;
        AppStoreAddress: string;
        Plugin_Switch: string;
        AppStore_Switch: string;
        Helper_Switch: string;
        IsEnableRelationCorp: boolean;
        TempLicenseFree: boolean;
        AssociatedOrganizationEnabled: boolean;
        ShowSystemIntegration: boolean;
        IsDeveloper: boolean;
        EngineType: string;
        DevCode: string;
        AdvancedDataSourceEnabled: string;
        CustomSql: string;
        ExternalContactEnabled: boolean;
        ThemeCode: number;
        IsShowFreeTip: string;
        EntryType: string;
        EntryCorpId: string;
        EntryId: string;
        CanCustomAppPage: boolean;
        IsShowWeChatAssociation: boolean;
        TotalPublishCount: number;
        AvailablePublishCount: number;
    }

    /** 组织机构-人员信息(接口返回模型) */
    export type IOrgUserModel = Omit<IOrgUserInfo, 'unitType' | 'nodeType' | 'type'>;

    /** 组织机构-部门信息(接口返回模型) */
    export type IOrgDepartmentModel = Omit<IOrgDepartmentInfo, 'unitType' | 'nodeType' | 'type'>;

    /** 组织机构-角色信息(接口返回模型) */
    export type IOrgRoleModel = Omit<IOrgRoleInfo, 'unitType' | 'nodeType' | 'type'>;

    /** 搜索结果 */
    export interface ISearchOrgListModel {
        users: IOrgUserModel[];
        departments: IOrgDepartmentModel[];
        roles: IOrgRoleModel[];
        hasMore: boolean;
    }

    /** org统一模型 */
    export type IOrgModel = IOrgUserModel | IOrgDepartmentModel | IOrgRoleModel;

    export type IOrgInfo = IOrgDepartmentInfo | IOrgUserInfo | IOrgRoleInfo;

    /** 旧版的Org模型 */
    export type IOldOrgModel = {
        // 下方三个字段均表示id，使用时应兼容处理
        /** id */
        UnitID: string;

        /** id */
        UnitId: string;

        /** id */
        ObjectId: string;

        // 下方两个字段均表示name，使用时应兼容处理
        /** 名称 */
        Name: string;

        /** 名称 */
        DisplayName: string;

        /** 上级id */
        ParentId: string;

        /** 类型 */
        Type: UnitType;

        /** 第三方接入Id */
        EntryId?: string;
    };

    export interface IOrgUserInfoByUnitId {
        /** 姓名 */
        name: string;
        /** 性别 */
        gender: UserGenderType;
        /** 头像 */
        avatar: string;
        /** 工号 */
        employeeNumber: string;
        /** 描述 */
        description: string;
        /** 手机号 */
        mobile: string;
        /** 邮箱 */
        email: string;
    }

    export interface IOrgDepartmentInfoByUnitId {
        info: IOrgDepartmentSingleInfo | null;
        manager: IOrgDepartmentSingleInfo | null;
        children: IOrgDepartmentSingleInfo[];
        userCount: number;
    }

    export interface IOrgDepartmentSingleInfo {
        /** 姓名 */
        name: string;
        /** id */
        entryId: string;
    }

    export interface IGetAssociatedOrganizationItem {
        Code: string;
        Name: string;
        Primary: boolean;
        EntryType?: string;
        EntryId?: string;
    }
    export namespace Request {
        /** 加载部门下人员入参 */
        export interface IGetUserListModel {
            /** 部门ID */
            parentId?: string | number;
            /** 是否显示离职人员 */
            showUnActive?: boolean;
            /** 是否显示外部组织 */
            showExternal?: boolean;
            /** 范围 */
            range?: string[];
            /** 关联用户ID */
            associationUserIds?: string[];
            /** 是否递归 */
            recursive?: boolean;
        }

        /** 加载部门或公司下子部门入参 */
        export interface IGetDepartmentListModel {
            /** 部门ID */
            departmentId?: string | number;
            /** 是否显示离职人员 */
            showUnActive?: boolean;
            /** 是否显示外部组织 */
            showExternal?: boolean;
            /** 范围 */
            range?: string[];
            /** 关联用户ID */
            associationUserIds?: string[];
            /** 是否递归 */
            recursive?: boolean;
        }

        /** 取人员详情 */
        /** 取部门详情 */
        export interface IGetOrgInfoModel {
            id: string;
        }

        /** 搜索入参 */
        export interface ISearchOrgListParams {
            /** 关键词 */
            keyword: string;
            /** 页码 */
            pageIndex: number;
            /** 页数 */
            pageSize: number;
            /** orgType */
            orgTypes: UnitType[];
            /** 是否显示离职人员 */
            showUnActive: boolean;
            /** 范围 */
            orgScope: OrgScopeType;
            /** 限定范围 */
            range?: string[];
            /** 外部组织范围 */
            externalRange?: string[];
        }

        /** 级联获取关联表单人员填充数据请求参数 */
        export interface IBatchGetFormQueryOrgModel {
            /** 人员、部门id（这个是顺带查的） */
            rootUnitIds: string[];

            /** 级联关联填充数据 */
            schemas: Array<{
                /** 关联表单编码 */
                boSchemaCode: string;

                /** 关联表单bizObjectIds */
                values: string[];

                /** 源表单编码 */
                sourceSchemaCode: string;

                /** 关联表单中的字段 */
                sourceField: string;

                /** 填充到的源表单中的字段 */
                targetField: string;

                /** 人员部门填充配置 */
                unitMaps: Array<{
                    /** 填充到的目标字段 */
                    targetField: string;

                    /** 用于填充的源字段 */
                    sourceField: string;
                }>;

                /** 下一级填充数据 */
                children: IBatchGetFormQueryOrgModel['schemas'];
            }>;
        }
    }

    export namespace Response {
        /** 人员部门详情 */
        export interface IGetOrgInfoModel {
            unitType: UnitType;
            user: IOrgUserInfoByUnitId | null;
            department: IOrgDepartmentInfoByUnitId | null;
        }
        /** 关联组织列表 */
        export interface IGetAssociatedOrganizationList {
            Items: IGetAssociatedOrganizationItem[];
            IsExternal: boolean;
        }
        /** 流程待办切换组织结果 */
        export interface IorganazitionLogin {
            corps?: object;
            enginecode: string;
            enginetokenid: string;
            havecorps: boolean;
            shardKey: string;
            token: string;
        }
    }
}
