export namespace AMap {
    export enum EventTypeEnum {
        Click = 'click',
        Resize = 'resize',
        Complete = 'complete',
        Close = 'close',
    }

    export type EventType = EventTypeEnum.Click | EventTypeEnum.Complete | EventTypeEnum.Complete | EventTypeEnum.Close;

    export interface IMap<T = EventType> {
        setCenter(
            center: [number, number] | { lng: number; lat: number },
            immediately?: boolean,
            duration?: number,
        ): void;

        setZoom(zoom: number, immediately?: boolean, duration?: number): void;

        destroy(): void;

        add(features: unknown): void;

        addControl(control: Control): void;

        removeControl(control: Control): void;

        plugin: Plugin;

        on(type: T | T[], fn: (e: unknown) => void, context?: unknown, once?: boolean): this;

        off(type: T, fn: (e: unknown) => void, context?: unknown): this;

        hasEvents(type: T, fn: (e: unknown) => void, context?: unknown): boolean;

        clearEvents(type: T): this;

        emit(type: T, data?: unknown): this;
    }

    export interface LabelOptions {
        content?: string;
        direction?: string;
        [key: string]: unknown;
    }

    export interface ControlConfig {
        position:
            | {
                  top?: string;
                  left?: string;
                  right?: string;
                  bottom?: string;
              }
            | 'LT'
            | 'RT'
            | 'LB'
            | 'RB';
        offset?: [number, number];
        visible?: boolean;
    }

    export interface MapOptions {
        zoom?: number;
        center?: [number, number];
        rotation?: number;
        pitch?: number;
        viewMode?: string;
    }

    export interface MarkerOptions {
        map?: IMap;
        position?: [number, number];
        content?: string | HTMLElement;
        offset?: [number, number];
        icon?: string | object;
        title?: string;
        label?: LabelOptions;
        extData?: unknown;
    }

    export interface InfoOptions {
        map?: IMap;
        position?: [number, number];
        content?: string | HTMLElement;
        visible?: boolean;
        offset?: [number, number];
        autoMove: boolean;
        extData?: unknown;
    }

    export type Plugin = (plugins: string | string[], cb: unknown) => void;

    export interface Control {
        map: IMap;
    }

    export interface Marker<T = EventType> {
        setMap(map: IMap | null | undefined): void;

        setTitle(title: string): void;

        setPosition(position: { lng: number; lat: number } | [number, number] | string): void;

        setIcon(icon: string): void;

        setLabel(opts: LabelOptions): void;

        setExtData(extData: unknown): void;

        on(type: T | T[], fn: (e: unknown) => void, context?: unknown, once?: boolean): this;

        off(type: T, fn: (e: unknown) => void, context?: unknown): this;

        destroy(): void;
    }

    export interface InfoWindow<T = EventType> {
        open(map: IMap, position: [number, number], height?: number): void;

        close(): void;

        getIsOpen(): boolean;

        getPosition(): { lng: number; lat: number } | null;

        setPosition(position: { lng: number; lat: number } | [number, number] | string): void;

        on(type: T | T[], fn: (e: unknown) => void, context?: unknown, once?: boolean): this;

        off(type: T, fn: (e: unknown) => void, context?: unknown): this;

        destroy(): void;
    }

    export interface ToolBar {
        map: IMap;

        remove(): void;

        show(): void;

        hide(): void;
    }
}
