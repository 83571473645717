import { CustomCode } from '../custom-code';
import { H3 } from '@h3/types';
export namespace Task {
    /** 提醒事项状态枚举 */
    export enum TaskRemindingStatus {
        /** 未读 */
        unread = 1,

        /** 已读 */
        read = 2,

        /** 全部 */
        all = 3,
    }

    /** 待办数量类型 */
    export enum TodoCountType {
        /** 我发起的 */
        MyInstance = 'myInstanceCount',

        /** 待办 */
        UnFinished = 'unFinishedCount',

        /** 待阅 */
        UnRead = 'unReadCount',

        /** 全部 */
        All = 'all',
    }

    export enum WorkflowLoadType {
        /** 已办 */
        Done = 4,

        /** 我发起的 */
        MyInstance = 3,

        /** 待阅 */
        UnRead = 2,

        /** 待办 */
        UnFinished = 1,

        /** 全部 */
        All = 0,
    }

    export enum WorkflowTaskType {
        /** 待办 */
        Todo = 'todo',

        /** 待阅 */
        ToRead = 'toread',

        /** 我发起的 */
        Apply = 'apply',

        /** 已办 */
        Done = 'done',

        /** 全部 */
        All = 'all',
    }

    export enum WorkflowActionType {
        /** 详情 */
        Detail = 'detail',

        /** 进入处理 */
        GoHandle = 'goHandle',

        /** 已阅 */
        MarkAsRead = 'markAsRead',

        /** 进入审批 */
        GoApproval = 'goApproval',

        /** 按钮 */
        Button = 'button',
    }

    export enum WorkflowActions {
        /** 提交和审批同意 */
        SUBMIT = 'SUBMIT',

        /** 审批不同意 */
        REJECT = 'REJECT',

        /** 删除 */
        REMOVE = 'REMOVE',

        /** 已阅 */
        READ = 'READ',

        /** 转交 */
        FORWARD = 'FORWARD',

        /** 作废流程 */
        CANCELINSTANCE = 'CANCELINSTANCE',
    }

    export enum WorkflowStatusLabel {
        Empty = '',

        /** 我发起的 */
        Mine = 'mine',

        /** 待办 */
        Todo = 'todo',

        /** 已办 */
        Done = 'done',

        /** 待阅 */
        Toread = 'toread',

        /** 已阅 */
        Readed = 'readed',
    }

    export enum SortDirection {
        /** 升序 */
        ASC = 0,

        /** 降序 */
        DESC = 1,
    }

    export enum BatchModalType {
        Agree = 'agree',
        Disagree = 'disagree',
        Close = 'close',
    }

    export enum ApprovalStatus {
        Success = 'success',
        Fail = 'fail',
    }

    export interface IApprovalResults {
        id: string;
        title: string;
        status: ApprovalStatus;
        message?: string;
    }

    export interface IExtraData {
        /** 排序字段名称 */
        ItemCode: string;
        /** 排序类型 */
        SortDirection: SortDirection;
    }

    export interface IFilterData {
        belongApp: H3.FunctionNode.IFunctionNode[];
        belongAppStr: string;
        receiveTime: string[];
        finishTime: string[];
        creator: H3.Org.IOrgInfo[];
        creatorStr: string;
        workflowStatus: string;
        keyWord: string;
    }

    export interface IWorkflowFormData {
        AppCode: string;
        Code: string;
        DirtyProperties: string[];
        DisplayName: string;
        EnableDataAcl: boolean;
        IconColorIndex: number;
        IconCss: string;
        IsAppSetting: boolean;
        IsWorkflowModule: boolean;
        IsWorkflowNode: boolean;
        NodeType: number;
        NodeVisible: number;
        ObjectId: string;
        ParentCode: string | null;
        ParentIndex: number;
        ParentObjectId: string | null;
        ParentPropertyName: string | null;
        Removable: boolean;
        Serialized: boolean;
        SortKey: number;
        State: number;
        Url: string;
        Visible: boolean;
        children: IWorkflowCategorys[];
    }

    export interface IWorkflowCategorys {
        AppCode: string;
        ChildMyworkflow: string[];
        DisplayName: string;
        IconCss: string;
        SortKey: number;
        WorkflowCode: string;
        WorkflowVeision: string | null;
    }

    /** 提醒事项摘要数据结构 */
    export interface IRemindingSummary {
        /** 提醒时间 */
        alertTime: string;

        /** 数据所属应用编码 */
        appCode: string;

        /** 业务数据编码 */
        bizObjectId: string;

        /** 提醒事项名称 */
        name: string;

        /** 数据所属表单编码 */
        schemaCode: string;

        /** 数据所属表单名称 */
        schemaName: string;

        /** 提醒摘要（提醒内容） */
        summary: string;

        /** 任务编码（提醒事项编码） */
        taskId: string;

        /** 提醒事项状态 */
        taskState: TaskRemindingStatus;
    }

    /** 待办数量 */
    export interface TodoCount {
        /** 我发起的数量 */
        [TodoCountType.MyInstance]: number;

        /** 待办数量 */
        [TodoCountType.UnFinished]: number;

        /** 待阅数量 */
        [TodoCountType.UnRead]: number;
    }

    /**
     * 视图按钮使用taskManager的任务场景
     */
    export enum TaskScene {
        Automation = 'Automation',
        BatchEdit = 'ScheduleCode_BatchUpdate',
    }
    /**
     * 视图按钮使用taskManager的任务类型
     */
    export enum TaskType {
        Sync = 'Sync', //同步
        Async = 'Async', //异步
    }

    export interface IControlsOptions {
        /** 控件对应字段编码 */
        DataField: string;
        /** 控件类型 */
        ControlKey: H3.Control.FormControlKeys;
        /** 控件显示名称 */
        DisplayName: string;
        /** 控件描述 */
        Description: string;
        /** 控件转换 */
        TransferItems: H3.Control.FormControlKeys;
        /** 隐藏规则 */
        DisplayRule: H3.Control.ControlDesigner.Rule;
        SelectShowMode: H3.Control.SelectShowMode;
        /** 默认选项 */
        DefaultItems: string[];
        /** 默认值 */
        DefaultValue: string;
        /** 颜色开关 */
        ColorSwitch: boolean;
        /** 选项颜色 */
        ItemColors: H3.Control.ColorSelectItem[];
    }

    export interface IColorControls {
        Key: string;
        ParentKey: string;
        Options: IControlsOptions;
        Type: number;
        TabGroupKey: string;
        ChildControls: IColorControls[];
    }

    export interface ISummaryControls {
        controlKey: string;
        label: string;
        value: string;
        colorSwitch: boolean;
        itemColors: H3.Control.ColorSelectItem[];
        controlType?: number;
        entryIds?: string;
    }

    export namespace Request {
        export interface IAddTask {
            schemaCode: string;
            actionCode: string;
            actionName: string;
            bizObjectIds: string[];
            bizData: unknown;
            appendOpFields: string[];
        }
        export interface IProcess {
            taskIds: string[];
        }
        export interface IGetTaskQueue {
            schemaCode: string;
        }
        export interface IGetEditableControl {
            schemaCode: string;
        }
    }
    export namespace Response {
        export interface IAddTask {
            successful: boolean;
            errorMessage: null | string;
            returnData: {
                taskId: string;
                logTime: string;
            };
        }
        export interface IProcess {
            successful: boolean;
            errorMessage: null | string;
            returnData: Record<string, progressItem>;
        }
        type progressItem = {
            total: number;
            success: number;
            failed: number;
            state: CustomCode.ACTION_TASK_STATE;
        };
        export interface IGetTaskQueue {
            successful: boolean;
            errorMessage: null | string;
            returnData: CustomCode.ITaskItem[];
        }
        type editableControlItem = {
            Visible: boolean;
            Editable: boolean;
            Required: boolean;
            Printable: boolean;
        };
        export interface IGetEditableControl {
            successful: boolean;
            errorMessage: null | string;
            returnData: Record<string, editableControlItem>;
        }
    }
}
