import { Menu, Input, Form, Checkbox, Select, Button, Cascader, Divider, Spin, Radio } from 'ant-design-vue';
import type { App } from 'vue';

export function setupAntd(app: App<Element>) {
    // 注册全局使用的antd组件
    app.use(Form)
        .use(Input)
        .use(Menu)
        .use(Checkbox)
        .use(Select)
        .use(Button)
        .use(Cascader)
        .use(Spin)
        .use(Divider)
        .use(Radio);
}
