import { DashBoard } from '../dashboard';
export namespace WorkBench {
    /** 工作台类型枚举 */
    export enum WorkBenchType {
        PC = 1,
        Mobile = 2,
    }

    /** 组件类型枚举 */
    export enum WorkBenchComponentType {
        /** 待办组件 */
        MyWork = 100,

        /** 消息提醒组件 */
        MyMessage = 101,

        /** 快速新增组件 */
        QuickAdd = 102,

        /** 快捷入口组件 */
        QuickEntry = 103,

        /** 常用应用组件 */
        FrequentlyApp = 104,

        /** 仪表盘/报表类组件，这个是指组件在没有选择绑定的图表时的编码 */
        Chart = 105,
    }

    /** 所有工作台组件类型列表 */
    export const BUILT_IN_COMPONENTS = [
        WorkBenchComponentType.Chart,
        WorkBenchComponentType.FrequentlyApp,
        WorkBenchComponentType.MyMessage,
        WorkBenchComponentType.MyWork,
        WorkBenchComponentType.QuickAdd,
        WorkBenchComponentType.QuickEntry,
    ];

    /** 工作台组件基本数据结构 */
    export interface IWorkbenchComponent {
        /** 组件唯一标识 */
        id: string;

        /** 组件类型 */
        type: WorkBenchComponentType;

        /** 组件名称 */
        name: string;

        /** 组件布局 */
        layout: {
            /** 组件横坐标 */
            x: number;

            /** 组件纵坐标 */
            y: number;

            /** 组件宽度 */
            w: number;

            /** 组件高度 */
            h: number;
        };

        /** 组件额外配置 */
        extSetting: unknown;
    }

    /** 工作台快捷入口组件额外配置数据类型 */
    export interface IQuickEntrySetting {
        /** 快捷入口表单编码 */
        functionNodeCodes: string[];
    }

    /** 工作台常用应用组件额外配置数据类型 */
    export interface IFrequentlyAppSetting {
        /** 常用应用应用编码 */
        functionNodeCodes: string[];
    }

    /** 工作台报表图表组件额外配置数据类型 */
    export interface IReportChartSetting {
        /** 图表编码 */
        chartCode: string;

        /** 报表编码 */
        schemaCode: string;

        /** 应用编码 */
        appCode: string;

        /** 图表类型 */
        chartType: DashBoard.WidgetType;

        /** 是否旧报表 */
        isReport: boolean;
    }

    /** 工作台数据结构 */
    export interface IWorkbench {
        /** 工作台类型 */
        device: WorkBenchType;

        /** 工作台组件信息数组 */
        components: IWorkbenchComponent[];

        /** 是否初始状态（即用户没有手动配置过工作台） */
        initialFlag: boolean;
    }
}
