import { H3 } from '@h3/types';

/** 引擎缓存数据 */
export default class EngineMetaData {
    /** 用户信息 */
    userInfo!: H3.Org.IUserInfo;

    /** 所有应用编码 */
    appCodes: string[] | null = null;

    /** 所有管理的应用编码 */
    adminAppCodes: string[] | null = null;

    /** 所有信任通道引擎 */
    connectEngines: H3.Engine.IConnectEngine[] | null = null;

    /** 所有关联组织 */
    relationCorps: H3.Engine.IRelationCorp[] | null = null;

    /** 清除引擎缓存数据 */
    clear() {
        this.userInfo = null!;
        this.appCodes = null;
        this.adminAppCodes = null;
        this.relationCorps = null;
    }

    /**
     * 缓存用户信息
     * @param userInfo 用户信息
     */
    setUserInfo(userInfo: H3.Org.IUserInfo) {
        this.userInfo = userInfo;
    }

    /**
     * 缓存引擎信息
     * @param engineInfo 引擎信息
     */
    setEngineInfo(engineInfo: H3.Engine.EngineInfo) {
        this.userInfo.currentEngine = engineInfo;
    }

    /**
     * 初始化应用列表
     * @param appCodes 应用列表
     */
    initApps(appCodes: string[]) {
        this.appCodes = appCodes;
    }

    /**
     * 添加应用到缓存
     * @param appCode 应用编码或应用编码列表
     */
    addApp(appCode: string | string[]) {
        if (Array.isArray(appCode)) {
            this.appCodes!.push(...appCode);
            return;
        }

        this.appCodes!.push(appCode);
    }

    /**
     * 从缓存中删除应用
     * @param appCode 要删除的应用编码
     */
    removeApp(appCode: string) {
        const index = this.appCodes!.findIndex((code) => code === appCode);
        if (index > -1) {
            this.appCodes!.splice(index, 1);
        }
    }

    /**
     * 缓存中的应用排序
     * @param code 排序的应用编码
     * @param preCode 排序后的前一个应用编码（不传则排序到第一个）
     */
    sortApps(code: string, preCode?: string) {
        const sortIndex = this.appCodes!.findIndex((appCode) => appCode === code);
        if (sortIndex < 0) {
            return;
        }
        const sortItem = this.appCodes!.splice(sortIndex, 1);

        if (!preCode) {
            this.appCodes!.unshift(...sortItem);
            return;
        }

        const preIndex = this.appCodes!.findIndex((appCode) => appCode === preCode);
        this.appCodes!.splice(preIndex + 1, 0, ...sortItem);
    }

    /**
     * 缓存管理的应用列表
     * @param appCodes 管理的应用列表
     */
    setAdminAppCodes(appCodes: string[]) {
        this.adminAppCodes = appCodes;
    }

    /**
     * 缓存信任通道引擎
     * @param engines 信任通道引擎列表
     */
    setConnectEngines(engines: H3.Engine.IConnectEngine[]) {
        this.connectEngines = engines;
    }

    /**
     * 缓存关联组织
     * @param relationCorps 关联组织列表
     */
    setRelationCorps(relationCorps: H3.Engine.IRelationCorp[]) {
        this.relationCorps = relationCorps;
    }
}
