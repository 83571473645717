import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取企业微信签名 */
export const getWeChatSignature = (params: unknown) =>
    request<H3.Http.Response.H3Response<{ weChatSignature: H3.Entry.ThirdParty.ISignature }>>('/Signature/WeChat', {
        method: 'get',
        params: params as Record<string, unknown>,
        camelCase: true,
    });

/** 从微盘文件下载上传至服务器 */
export const wedriveUpload = (params: H3.ThirdParty.Request.IUploadParams) =>
    request<H3.Http.Response.H3Response<H3.ThirdParty.Response.IWedriveUploadRes>>('/v1/file/wedrive/upload', {
        method: 'post',
        data: params,
    });

/** 下载文件到微盘 */
export const downloadToWedrive = (params: H3.ThirdParty.Request.IDownloadParams) =>
    request<
        H3.Http.Response.H3Response<{
            weChatSignature: H3.Entry.ThirdParty.ISignature;
            files: H3.Entry.ThirdParty.IWedriveDownloadFile[];
        }>
    >('/v1/file/wedrive/download', {
        method: 'post',
        data: params,
    });
