import { H3 } from '@h3/types';

/** 获取插件列表 */
export const PluginList = '/v1/plugin/list';
/** OCR插件调用 */
export const PluginInvoke = '/v1/plugin/ocr/recognize';
/** 添加插件 */
export const PluginAdd = '/v1/plugin/add';
/** 删除插件 */
export const PluginRemove = '/v1/plugin/remove';

/** 获取第三方连接 */
export const ConnectConfigList = '/v1/plugin/connection/connectionconfiglist';
/** 新增第三方连接 */
export const ConnectConfigAdd = '/v1/plugin/connection/addConnectionconfig';
/** 编辑更新第三方连接 */
export const ConnectConfigUpdate = '/v1/plugin/connection/updateconnectionconfig';
/** 新增第三方连接(编码模式) */
export const ConnectCodingConfigAdd = '/v1/plugin/connection/addconnectioncodingconfig';
/** 编辑第三方连接(编码模式) */
export const ConnectCodingConfigUpdate = '/v1/plugin/connection/updateconnectioncodingconfig';
/** 删除第三方连接 */
export const ConnectConfigDelete = '/v1/plugin/connection/deleteconnectionconfig';
/** 获取第三方连接详情 */
export const ConnectConfigDetail = '/v1/plugin/connection/connectionconfigdetail';
/** 禁用启用第三方连接 */
export const ConnectConfigStatus = '/v1/plugin/connection/setconnectionconfigstatus';
/** 执行或者调试第三方连接 */
export const ConnectionConfigInvoke = '/v1/plugin/connection/invokeconnectionconfig';
/** 查看当前连接的表单使用情况 */
export const ConnectionUsageList = '/v1/plugin/connection/getConnectionUsageList';

export const EsignApi = {
    [H3.Plugin.EsignType.CommonEsign]: {
        /** 获取电子签章绑定关系 */
        EsignBizObjectTask: '/v1/plugin/esign/getesignbizobjecttask',
        /** 获取流程签署详细信息 */
        EsignFlowSignDetail: '/v1/plugin/commonesign/detail',
        /** 获取签署人签署地址 */
        EsignNoticeUrl: '/v1/plugin/commonesign/url',
        /** 通过taskid获取签署完成的文件 */
        EsignBizObjectDocs: '/v1/plugin/commonesign/syncfile',
        /** 获取文件上传地址 */
        EsignUploadUrl: '/v1/plugin/esign/getuploadurlbyattachmentIds',
        /** 获取发起签署任务地址 */
        EsignProcessStartUrl: '/v1/plugin/esign/getprocessstarturl',
        /** 获取个人/组织认证地址 */
        EsignAuthUrl: '/v1/plugin/commonesign/authurl',
        /** 获取电子签章模板 */
        EsignTemplate: '/v1/plugin/commonesign/template',
    },
    [H3.Plugin.EsignType.DingtalkEsign]: {
        /** 获取电子签章绑定关系 */
        EsignBizObjectTask: '/v1/plugin/esign/getesignbizobjecttask',
        /** 获取流程签署详细信息 */
        EsignFlowSignDetail: '/v1/plugin/esign/getflowsigndetail',
        /** 获取签署人签署地址 */
        EsignNoticeUrl: '/v1/plugin/esign/getsignnoticeurl',
        /** 通过taskid获取签署完成的文件 */
        EsignBizObjectDocs: '/v1/plugin/esign/getbizobjectdocs',
        /** 获取文件上传地址 */
        EsignUploadUrl: '/v1/plugin/esign/getuploadurlbyattachmentIds',
        /** 获取发起签署任务地址 */
        EsignProcessStartUrl: '/v1/plugin/esign/getprocessstarturl',
        /** 获取个人/组织认证地址 */
        EsignAuthUrl: '/v1/plugin/commonesign/authurl',
        /** 获取电子签章模板 */
        EsignTemplate: '/v1/plugin/commonesign/template',
    },
};

/** 获取邮件配置 */
export const GetEmailConfig = '/v1/plugin/email/getemailconfig';
/** 设置邮件配置 */
export const SetEmailConfig = '/v1/plugin/email/settingemailconfig';
/** 删除邮件配置 */
export const RemoveEmailConfig = '/v1/plugin/email/removeemailconfig';
/** 发送邮件 */
export const SendEmails = '/v1/plugin/email/SendEmails';

/** 获取短信模板列表 */
export const SmsTemplateList = '/v1/plugin/sms/gettemplist';
/** 删除短信模板 */
export const SmsTemplateRemove = '/Automatic/DeleteSmsTemp';
/** 发送短信 */
export const SendSms = '/v1/plugin/sms/sendsms';

/** 查询考勤 */
export const DingTalkAttendanceDetail = '/v1/plugin/dingtalkattendance/getattendancedetail';
/** 查询打卡 */
export const DingTalkAttendanceClock = '/v1/plugin/dingtalkattendance/getattendancelist';
/** 查询请假 */
export const DingTalkAttendanceVacate = '/v1/plugin/dingtalkattendance/getleavetimebynames';
/** 发送消息至会话 */
export const DingTalkAttendanceSessionsChat = '/v1/plugin/dingtalkattendance/sendcropchat';
/** 发送消息至钉钉机器人 */
export const DingTalkAttendanceRobotChat = '/v1/plugin/dingtalkattendance/sendrobotchat';

/** 查询在线支付插件授权信息 */
export const PaymentAliPayAuthInfo = '/v1/plugin/payment/AliPayAuthInfo';
/** 发起支付 */
export const PaymentAliPayCreate = '/v1/plugin/payment/AliPayPreCreate';
/** 查询支付状态 */
export const PaymentAliPayTradeQuery = '/v1/plugin/payment/AliPayTradeQuery';
/** 交易关闭 */
export const PaymentAliPayTradeClose = '/v1/plugin/payment/AliPayTradeClose';
/** 交易退款 */
export const PaymentAliPayTradeRefund = '/v1/plugin/payment/AliPayTradeRefund';

/** 获取人脸识别插件配置信息 */
export const GetFaceRecognitionConfig = '/v1/plugin/facerecognition/getFaceConfig';
/** 保存人脸识别授权账号配置 */
export const SaveFaceRecognitionAuthorize = '/v1/plugin/facerecognition/saveFaceAuthorizeInfo';
/** 获取人脸识别人员库列表 */
export const GetFaceRecognitionPersonGroupInfo = '/v1/plugin/facerecognition/getPersonGroupInfos';
/** 保存人脸识别人员库 */
export const CreateFaceRecognitionPersonGroup = '/v1/plugin/facerecognition/createPersonGroup';
/** 取消人脸识别授权 */
export const CancelFaceRecognitionConfigAuthorize = '/v1/plugin/facerecognition/cancelApiSecretAuthorize';

/** 发送机器人消息 */
export const SendRobotNotification = '/v1/plugin/sendRobotNotification';
/** 群机器人测试 */
export const VerifyRobotNotification = '/v1/toolkit/notification/chatbot/verify';
