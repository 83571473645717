import { Util } from '@h3/h3common';
import { H3 } from '@h3/types';
import * as Api from '../api';

/**
 * 业务数据服务
 * @description 涉及业务数据查询的接口(/v1/bizdata/query)调用封装以及错误处理
 */
export default class BizDataService {
    /**
     * 根据id查询业务数据
     * @param schemaCode 表单编码
     * @param objectIds 数据id
     * @param properties 需要查询的数据字段配置
     * @param defaultFilter 是否使用默认过滤条件，默认为 false
     * @returns 查询结果
     */
    static async getBizObjectsByIds(
        schemaCode: string,
        objectIds: string[],
        properties?: H3.BizData.Request.ICommonQueryBizDataModel['properties'],
        defaultFilter = false,
    ): Promise<Record<string, unknown>[]> {
        const params: H3.BizData.Request.ICommonQueryBizDataModel = {
            schemaCode,
            pageIndex: 0,
            scope: H3.Auth.DataScopeType.All,
            pageSize: objectIds.length,
            filter: {
                matcher: {
                    type: H3.BizData.Filter.MatchType.AND,
                    operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                    matchers: [
                        {
                            type: H3.BizData.Filter.MatchType.ITEM,
                            name: 'ObjectId',
                            operator: H3.BizData.Filter.ComparisonOperatorType.In,
                            value: objectIds,
                            matchers: [],
                        },
                    ],
                },
            },
            includeDefaultFilter: defaultFilter,
            properties,
        };
        try {
            const res = await window.h3Daf.Api.BizData.queryCommonBizData(params);
            if (!res.successful) {
                throw new Error();
            }
            return res.returnData.returnData;
        } catch {
            return [];
        }
    }

    /**
     * 判断给的数据是否在给定范围内
     * @param schemaCode 表单编码
     * @param objectId 数据id
     * @param expressionFilter 数据限定范围
     * @param filterItems 额外筛选条件
     * @param defaultFilter 是否使用默认过滤条件，默认为 false
     * @param effective 是否仅查询已生效数据，默认为 true
     * @return 在给定范围内的数据
     */
    static async existInScope(
        schemaCode: string,
        objectId: string | string[],
        expressionFilter?: H3.BizData.Filter.IMatcherFilter['expressionFilter'],
        filterItems?: Record<string, unknown>,
        defaultFilter = false,
        effective = true,
    ) {
        const objectIds = Array.isArray(objectId) ? objectId : [objectId];
        if (!objectIds.length) {
            return [];
        }

        const matchers: H3.BizData.Filter.IMatcherItem[] = [];

        // 查询给定objectId
        matchers.push({
            type: H3.BizData.Filter.MatchType.ITEM,
            name: 'ObjectId',
            operator: H3.BizData.Filter.ComparisonOperatorType.In,
            value: objectIds,
            matchers: [],
        });

        // 查询状态为已生效状态的表单
        if (effective) {
            matchers.push({
                type: H3.BizData.Filter.MatchType.ITEM,
                name: 'Status',
                value: [H3.Workflow.BizObjectStatus.Effective],
                operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                matchers: [],
            });
        }

        // 混合额外筛选条件
        if (filterItems) {
            Util.Object.forEachOwnKey(filterItems, (key) => {
                if (!filterItems[key]) {
                    return;
                }
                matchers.push({
                    type: H3.BizData.Filter.MatchType.ITEM,
                    name: key,
                    value: filterItems[key],
                    operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                    matchers: [],
                });
            });
        }

        return Api.BizData.queryCommonBizData({
            schemaCode: schemaCode,
            pageIndex: 0,
            pageSize: objectIds.length,
            filter: {
                expressionFilter,
                matcher: {
                    type: H3.BizData.Filter.MatchType.AND,
                    operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                    matchers,
                },
            },
            requireCount: true,
            scope: H3.Auth.DataScopeType.All,
            includeDefaultFilter: defaultFilter,
        })
            .then((res) => {
                if (!res?.successful) {
                    return [];
                }
                return res.returnData.returnData;
            })
            .catch(() => {
                return [];
            });
    }

    /**
     * 获取关联表单下拉数据
     * @description 指获取指定表单已生效数据的id和数据标题，当传入的是子表编码时，返回的是子表数据的id和数据标题
     * @param schemaCode 表单编码
     * @param searchKey 搜索关键字
     * @param pageIndex 页码
     * @param pageSize 页大小
     * @param searchItems 额外搜索字段
     * @param expressionFilter 数据限定范围数据
     * @param filterItems 额外筛选条件，是筛选键到筛选值的映射
     * @param defaultFilter 是否使用默认过滤条件，默认为 false
     * @param properties 需要查询的字段信息
     */
    static async getFormQueryDropdownData(
        schemaCode: string,
        searchKey: string,
        page: { pageIndex: number; pageSize: number },
        properties: H3.BizData.IProperty[],
        searchItems?: string[],
        expressionFilter?: H3.BizData.Filter.IMatcherFilter['expressionFilter'],
        filterItems?: Record<string, unknown>,
        defaultFilter = false,
        options?: H3.Http.Request.RequestOptions,
    ) {
        const { pageIndex, pageSize } = page;
        const matchers: H3.BizData.Filter.IMatcherItem[] = [];

        // 查询状态为已生效状态的表单
        matchers.push({
            type: H3.BizData.Filter.MatchType.ITEM,
            name: 'Status',
            value: [H3.Workflow.BizObjectStatus.Effective],
            operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
            matchers: [],
        });

        // 混合额外筛选条件
        if (filterItems) {
            Util.Object.forEachOwnKey(filterItems, (key) => {
                if (!filterItems[key]) {
                    return;
                }
                matchers.push({
                    type: H3.BizData.Filter.MatchType.ITEM,
                    name: key,
                    value: filterItems[key],
                    operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                    matchers: [],
                });
            });
        }

        // 根据传入的搜索关键字对数据标题进行模糊查询
        if (searchKey) {
            const searchFields = ['Name', ...(searchItems || [])];
            const searchMatchers = searchFields.map((field) => ({
                type: H3.BizData.Filter.MatchType.ITEM,
                name: field,
                value: searchKey,
                operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                matchers: [],
            }));
            matchers.push({
                type: H3.BizData.Filter.MatchType.OR,
                operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                matchers: searchMatchers,
            });
        }

        const res = await Api.BizData.queryCommonBizData(
            {
                schemaCode: schemaCode,
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: {
                    expressionFilter,
                    matcher: {
                        type: H3.BizData.Filter.MatchType.AND,
                        operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                        matchers,
                    },
                },
                properties: [
                    {
                        propertyName: 'Name',
                        function: H3.BizData.Filter.SQLFunction.None,
                    },
                    ...properties,
                ],
                requireCount: true,
                scope: H3.Auth.DataScopeType.All,
                includeDefaultFilter: defaultFilter,
            },
            options,
        );

        if (!res?.successful) {
            return {
                dataCount: 0,
                returnData: [],
            };
        }

        return {
            dataCount: res.returnData.dataCount,
            returnData: res.returnData.returnData,
        };
    }

    /**
     * 查询指定表单指定字段数据并去重
     * @param schemaCode 表单编码
     * @param propertyName 字段名称
     * @param searchKey 搜索关键字
     * @param pageIndex 页码
     * @param pageSize 页大小
     * @param onlyEffective 是否仅查询已生效数据，默认为false
     * @param defaultFilter 是否使用默认过滤条件，默认为false
     * @param expressionFilter 数据限定范围数据
     */
    static async getDistinctPropertyValue(
        schemaCode: string,
        propertyName: string,
        searchKey: string,
        page: { pageIndex: number; pageSize: number },
        onlyEffective = false,
        defaultFilter = false,
        expressionFilter?: H3.BizData.Filter.IMatcherFilter['expressionFilter'],
    ) {
        const { pageIndex, pageSize } = page;
        const matchers: H3.BizData.Filter.IMatcherItem[] = [];
        if (searchKey) {
            matchers.push({
                type: H3.BizData.Filter.MatchType.ITEM,
                name: propertyName,
                value: searchKey,
                operator: H3.BizData.Filter.ComparisonOperatorType.Contains,
                matchers: [],
            });
        }
        if (onlyEffective) {
            matchers.push({
                type: H3.BizData.Filter.MatchType.ITEM,
                name: 'Status',
                value: [H3.Workflow.BizObjectStatus.Effective],
                operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                matchers: [],
            });
        }

        try {
            const res = await Api.BizData.queryDataDistinct({
                schemaCode: schemaCode,
                pageIndex: pageIndex,
                pageSize: pageSize,
                filter: {
                    expressionFilter,
                    matcher: {
                        type: H3.BizData.Filter.MatchType.AND,
                        operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                        matchers,
                    },
                },
                properties: [
                    {
                        propertyName: propertyName,
                        function: H3.BizData.Filter.SQLFunction.Distinct,
                    },
                ],
                scope: H3.Auth.DataScopeType.All,
                sortBy: propertyName,
                sortDirection: 1,
                requireCount: true,
                includeDefaultFilter: defaultFilter,
            });
            if (!res.successful) {
                throw new Error();
            }
            return {
                hasMore: !!res.returnData.hasMore,
                data: res.returnData.data || [],
            };
        } catch {
            return {
                hasMore: false,
                data: [],
            };
        }
    }

    /**
     * 查询指定表单指定字段数据并去重
     * @description 相比于getDistinctPropertyValue方法，该方法始终查询所有状态数据，始终不使用默认过滤条件，且理论上性能稍好
     * @param schemaCode 表单编码
     * @param propertyName 字段名称
     * @param searchKey 搜索关键字
     * @param pageIndex 页码
     * @param pageSize 页大小
     */
    static async getDistinctPropertyValueIgnoreStatus(
        schemaCode: string,
        propertyName: string,
        searchKey: string,
        pageIndex: number,
        pageSize: number,
    ) {
        try {
            const res = await Api.BizData.queryBizDataDistinct({
                schemaCode,
                propertyName,
                searchKey,
                pageIndex,
                pageSize,
                scope: H3.Auth.DataScopeType.All,
            });

            if (!res.successful) {
                throw new Error();
            }
            return res.returnData;
        } catch (error) {
            return { data: [], hasMore: false };
        }
    }

    /**
     * 请求指定主表数据id下的所有指定子表数据
     * @param childSchemaCode 子表编码
     * @param parentObjectId 主表数据id
     * @param expressionFilter 子表过滤条件
     * @param defaultFilter 是否使用默认过滤条件，默认为false
     */
    static async getAllChildBizDataByParentId(
        childSchemaCode: string,
        parentObjectId: string,
        expressionFilter?: H3.BizData.Filter.IMatcherFilter['expressionFilter'],
        defaultFilter = false,
    ) {
        const matcher = {
            type: H3.BizData.Filter.MatchType.AND,
            operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
            matchers: [
                {
                    type: H3.BizData.Filter.MatchType.ITEM,
                    operator: H3.BizData.Filter.ComparisonOperatorType.Equal,
                    name: `${childSchemaCode}.ParentObjectId`,
                    value: parentObjectId,
                    matchers: [],
                },
            ],
        };
        try {
            const res = await Api.BizData.queryCommonBizData({
                schemaCode: childSchemaCode,
                pageIndex: 0,
                pageSize: -1,
                filter: {
                    expressionFilter,
                    matcher,
                },
                includeDefaultFilter: defaultFilter,
                scope: H3.Auth.DataScopeType.All,
            });
            if (!res.successful) {
                throw new Error();
            }
            return res.returnData.returnData || [];
        } catch {
            return [];
        }
    }

    /**
     * 格式化业务数据为前端展示数据
     * @description 指从完整的数据中提取出id和数据标题，需要注意的是如果传入的是子表编码，需要返回的是子表数据的id和数据标题
     * @param schemaCode 表单编码
     * @param bizData 业务数据
     */
    static formatBizDataToFront(
        schemaCode: string,
        bizData: Record<string, unknown>,
    ): H3.BizData.FrontControlData.TFormQueryValue {
        const isChildSchema = Reflect.has(bizData, `${schemaCode}.ObjectId`);
        const objectIdKey = isChildSchema ? `${schemaCode}.ObjectId` : 'ObjectId';
        const nameKey = isChildSchema ? `${schemaCode}.Name` : 'Name';
        return {
            ObjectId: bizData[objectIdKey] as string,
            Name: bizData[nameKey] as string,
        };
    }
}
