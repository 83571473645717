/** 发送登录验证码 */
/** 发送注册验证码 */
/** 发送重置密码/外部登录验证码 */
export const SendVerifyCode = '/v1/captcha/verifycode/send';

/** 手机号密码登录 */
export const LoginByPassword = '/v1/login/mobile/password';

/** 手机号密码登录（绑定微信） */
export const LoginByPasswordWechat = '/v1/login/tokens/password';

/** 验证码登录 */
export const LoginByCode = '/v1/login/mobile/verifycode';

/** 个人微信扫码登录 */
export const WechatOaAuth = '/v1/login/wechat/official/scan';

/** 扫码登录-钉钉 */
export const LoginByDingScan = '/v1/login/dingtalk/scan';

/** 扫码登录-企业微信 */
export const LoginByWxworkScan = '/v1/login/wxwork/scan';

/** 外部用户手机号验证码登录 */
export const ExternalLogin = '/v1/login/mobile/verifycode/externaluser';

/** 钉钉后台登录 */
export const DdOaAuth = '/v1/login/dingtalk/oa';

/** 钉钉企业自建应用 */
export const DdCorpLogin = '/v1/login/dingtalk/corp';

/** 钉钉ISV授权(三方应用) */
export const DdAuth = '/v1/login/dingtalk/app';

/** 切换外部组织 */
export const ChangeExternalOrg = '/v1/login/interconnection';

/** 飞书授权登录 */
export const FeiShuCorpLogin = '/v1/login/feishu/corp';

/** 企微自建应用授权登录 */
export const WxworkCorpLogin = '/v1/login/wxwork/corp';

/** 企微ISV登录(三方应用) */
export const WxworkISVLogin = '/v1/login/wxwork/suite/app';

/** 企微管理员登录 */
export const WxworkOaLogin = '/v1/login/wxwork/oa';

/** 企微三方的自建应用登录 */
export const WxworkISVCropLogin = '/v1/login/wxwork/corppublish';

/** 续期token */
export const RefreshToken = '/v1/token/refresh';

/** 选择企业后登录 */
export const LoginByCorp = '/v1/token/exchange';

/** 获取登录接口无痕验证配置 */
/** 获取注册接口无痕验证配置 */
export const GetVerifyConfig = '/v1/captcha/verify/config';

/** 获取钉钉扫码链接 */
export const GetDingScanUrl = '/v1/login/dingtalk/scanurl';

/** 获取企业微信扫码链接 */
export const GetWxworkScanUrl = '/v1/login/wxwork/scanurl';

/** 获取ticket信息(微信) */
export const GetWechatTicketInfo = '/v1/wechat/official/ticket';

/** 获取扫码状态信息(微信) */
export const GetWechatScanStatus = '/v1/wechat/official/qrcode/checkscan';

/** 获取应用信息(企微) */
export const GetWxworkAppInfo = '/v1/login/wxwork/appinfo';

/** 确认互通 */
export const MakeAssociated = '/v1/interconnection/confirm';

/** 校验发起互通的组织状态 */
export const VerifyAssOrg = '/LoginWebAPI/VerifyAssociatedOrganization';

/** 注册相关 */
/** 验证注册验证码 */
export const VerifyRegisterMsg = '/v1/register/validate';

/** 注册企业 */
export const RegisterWithCorp = '/v1/register/engine';

/** 获取企业人员规模 */
export const GetScaleList = '/v1/register/getscalelist';

/** 重置密码 */
export const ResetPassword = '/v1/login/password/reset';

/** 获取应用和解决方案相关信息 */
// export const GetAppInfo = '/v1/app/config';
// TODO 老接口，修改新写接口，支持登录后调整到不同的页面
export const GetAppInfo = '/App/IsLightAndIsBetaForm';

/** 退出登录 */
export const Logout = '/v1/logout';

/** 获取指定企业是否发布新版前端 */
export const GetNextFront = '/v1/login/nextfront/byenginecode';

export const IsSaaSCrop = '/v1/login/saas';

/** 企业微信注册安装链接地址(老接口) */
export const GetWxworkInstallUrl = '/register/wechat/install/authUrl';

/** sso登录地址 */
export const SSOLoginUrl = '/v1/login/sso';

/** 退出登录(旧) */
export const OldLogout = '/Login/Logout';

/** 游客登录 */
export const LoginByH3Visitor = '/v1/login/h3/guest';
