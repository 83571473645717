import { Control } from '../control';

export namespace Log {
    export const LogResponse = {};
    export namespace Request {
        export interface ILogInfo {
            Content?: string;
            Topic?: string;
            SpanId?: number;
            UserTime?: number;
            UsedTimeLevel?: string;
            LogTime?: number;
            UserId?: string;
            Parameters?: string;
            Return?: string;
            Consumer?: string;
            ServiceName?: string;
            TraceId?: string;
            MethodName?: string;
            CustomerException?: string;
            CriticalError?: string;
            SchemaCode?: string;
            Level: string;
            ExceptionContent?: string;
            ExceptionType?: string;
            Route?: string;
            UserAgent?: string;
            BreadCrumb?: string;
        }
    }

    export namespace BizData {
        /**
         * 操作类型
         */
        export enum OperationType {
            Create = 1,
            Update = 2,
            Remove = 3,
            ImportAdd = 5,
            ImportUpdate = 6,
            BusinessRuleUpdate = 201,
            BusinessRuleInsert = 202,
            BusinessRuleDelete = 203,
            BusinessRuleUpdateOrInsert = 204,
            BatchUpdate = 301,
        }

        // 子表数据日志操作类型
        export enum ChildOperatorType {
            Add = 1,
            Update = 2,
            Remove = 3,
        }
        export interface ILogItemDetail {
            dataField: string;
            displayName: string;
            dataType: Control.BizDataType;
            value: unknown;
            controlType: Control.FormControlKeys;
            oldValue: unknown;
            removeVisible?: boolean;
            addVisible?: boolean;
        }
        // 数据变更日志
        export interface ILogItem {
            objectId: string;
            logTime: string;
            operationType: string;
            description: string;
            detail: ILogItemDetail[];
            userId: string;
            entryId: string;
            userName: string;
            avatar: string;
            actionId: string;
        }

        // 子表数据日志
        export interface IChildLogItem {
            objectId: string;
            bizObjectId: string;
            actionType: ChildOperatorType;
            parentIndex: number;
            detail: ILogItemDetail[];
        }

        export type TQueryLogResponse = {
            total: number;
            items: ILogItem[];
        };
    }
}
