import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 发送登录验证码 */
/** 发送注册验证码 */
/** 发送重置密码/外部登录验证码 */
export const sendVerifyCode = (params: H3.Entry.Request.ISendVerifyCodeModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.ISendVerifyCodeModel>>(Api.SendVerifyCode, {
        method: 'post',
        data: params,
    });

/** 手机号密码登录 */
export const loginByPassword = (params: H3.Entry.Request.IPasswordLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByPassword, {
        method: 'post',
        data: params,
    });

/** 手机号密码登录（绑定微信） */
export const loginByPasswordWechat = (params: H3.Entry.Request.IPasswordLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByPasswordWechat, {
        method: 'post',
        data: params,
    });

/** 验证码登录 */
export const loginByCode = (params: H3.Entry.Request.IVerifyCodeLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByCode, {
        method: 'get',
        params,
    });

/** 个人微信扫码登录 */
export const wechatOaAuth = (params: H3.Entry.Request.IWxScanLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.WechatOaAuth, {
        method: 'get',
        params,
    });

/** 扫码登录-钉钉 */
export const loginByDingScan = (params: H3.Entry.Request.IDingScanLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByDingScan, {
        method: 'get',
        params,
    });

/** 扫码登录-企业微信 */
export const loginByWxworkScan = (params: H3.Entry.Request.IWxScanLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByWxworkScan, {
        method: 'get',
        params,
    });

/** 表单外链身份验证（外部联系人手机验证码登录） */
export const externalLogin = (params: H3.Entry.Request.IVerifyCodeLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.ExternalLogin, {
        method: 'get',
        params,
    });

/**
 * 表单外链登录（以匿名身份登录）
 * @param shortLink 外链短链
 */
export const externalAuth = (code: string, engineCode: string) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.TExternalLoginModel>>('/v1/external/login', {
        method: H3.Http.MethodType.POST,
        data: { code, engineCode },
    });

/** 钉钉后台登录 */
export const ddOaAuth = (params: H3.Entry.Request.IDingOaAuthLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.DdOaAuth, {
        method: 'get',
        params,
    });

/** 钉钉授权登录 */
export const ddCorpLogin = (params: H3.Entry.Request.ISelfBuildDingLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.DdCorpLogin, {
        method: 'get',
        params,
    });

/** 钉钉免登授权 */
export const ddAuth = (params: H3.Entry.Request.IDingAuthLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.DdAuth, {
        method: 'get',
        params,
    });

/** 切换外部组织 */
export const changeExternalOrg = (params: H3.Entry.Request.IChangeExternalOrgModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.ChangeExternalOrg, {
        method: 'get',
        params,
    });

/** 飞书授权登录 */
export const feiShuCorpLogin = (params: H3.Entry.Request.ISelfBuildFeiShuLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.FeiShuCorpLogin, {
        method: 'post',
        data: params,
    });

/** 企微自建应用授权登录 */
export const wxworkCorpLogin = (params: H3.Entry.Request.ISelfBuildWxWorkLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.WxworkCorpLogin, {
        method: 'get',
        params,
    });

/** 企微ISV登录 */
export const wxworkISVLogin = (params: H3.Entry.Request.IWxWorkISVLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.WxworkISVLogin, {
        method: 'get',
        params,
    });

/** 企微管理员登录 */
export const wxworkOaLogin = (params: H3.Entry.Request.IWxWorkOaLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.WxworkOaLogin, {
        method: 'get',
        params,
    });

/** 企微三方的自建应用登录 */
export const wxworkISVCorpLogin = (params: H3.Entry.Request.IWxWorkISVCorpLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.WxworkISVCropLogin, {
        method: 'get',
        params,
    });

/** 续期token */
export const refreshToken = (params: H3.Entry.Request.IRefreshTokenModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.RefreshToken, {
        method: 'get',
        params,
    });

/** 选择企业后登录 */
export const loginByCorp = (params: H3.Entry.Request.IFixEngineLoginModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByCorp, {
        method: 'get',
        params,
    });

/** 获取登录接口无痕验证配置 */
/** 获取注册接口无痕验证配置 */
export const getVerifyConfig = (params: H3.Entry.Request.IVerifyConfigModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IVerifyConfigModel>>(Api.GetVerifyConfig, {
        method: 'get',
        params,
    });

/** 获取钉钉扫码链接 */
export const getDingScanUrl = () =>
    request<H3.Http.Response.H3Response<string>>(Api.GetDingScanUrl, {
        method: 'get',
    });

/** 获取企微扫码链接 */
export const getWxworkScanUrl = (params: H3.Entry.Request.IGetWxWorkScanUrlModel) =>
    request<H3.Http.Response.H3Response<string>>(Api.GetWxworkScanUrl, {
        method: 'get',
        params,
    });

/** 获取ticket信息(微信) */
export const getWechatTicketInfo = () =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IWeChatTicketInfoModel>>(Api.GetWechatTicketInfo, {
        method: 'get',
    });

/** 获取扫码状态信息(微信) */
export const getWechatScanStatus = (params: H3.Entry.Request.IGetWeChatScanStatusModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IWeChatScanStatusModel>>(Api.GetWechatScanStatus, {
        method: 'get',
        params,
    });

/** 获取应用信息(企微) */
export const getWxworkAppInfo = (params: H3.Entry.Request.IGetWxWorkAppInfoModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IGetWxWorkAppInfoModel>>(Api.GetWxworkAppInfo, {
        method: 'get',
        params,
    });

/** 确认互通 */
export const makeAssociated = (params: H3.Entry.Request.IAssOrgModel) => {
    return request<H3.Http.Response.H3Response<H3.Entry.Response.IAssOrgModel>>(Api.MakeAssociated, {
        method: 'get',
        params,
    });
};

/** 校验发起互通的组织状态 */
export const verifyAssOrg = (params: H3.Entry.Request.IAssOrgModel) => {
    return request<H3.Http.Response.H3Response<H3.Entry.Response.IAssOrgModel>>(Api.VerifyAssOrg, {
        method: 'post',
        data: params,
    });
};

/** 注册企业 */
/** 验证注册验证码 */
export const verifyRegisterMsg = (params: H3.Entry.Request.IVerifyRegisterMsgModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IVerifyRegisterMsgModel>>(Api.VerifyRegisterMsg, {
        method: 'get',
        params,
    });

/** 注册企业 */
export const registerWithCorp = (params: H3.Entry.IRegisterInfo) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.RegisterWithCorp, {
        method: 'post',
        data: params,
    });

/** 获取企业人员规模 */
export const getScaleList = () =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IGetScaleListModel[]>>(Api.GetScaleList, {
        method: 'get',
    });

/** 重置密码 */
export const resetPassword = (params: H3.Entry.Request.IResetPasswordModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.ResetPasswordModel>>(Api.ResetPassword, {
        method: 'put',
        data: params,
    });

/** 获取应用和解决方案相关信息 */
export const getAppInfo = (params: H3.Entry.Request.IGetAppInfoModel) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IGetAppInfoModel>>(Api.GetAppInfo, {
        method: 'get',
        params,
        camelCase: true,
    });

/** 获取指定企业是否发布新版前端 */
export const getNextFront = (params: { engineCode: string }) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IGetNextFrontModel>>(Api.GetNextFront, {
        method: 'get',
        params,
    });

/** TODO企业微信注册安装链接地址 */
export const getWxworkInstallUrl = (params: H3.Entry.Request.IGetWxWorkInstallUrlModel) =>
    request<{ errcode: number; AuthInstallUrl: string; ErrorMessage: string }>(Api.GetWxworkInstallUrl, {
        method: 'get',
        params,
    });

/** 判断是否是saas企业 */
export const isSaaSCrop = (params: { engineCode: string }) =>
    request<H3.Http.Response.H3Response<H3.Entry.Response.IsSaaSCropModel>>(Api.IsSaaSCrop, {
        method: 'get',
        params,
    });

/** sso login */
export const ssoLogin = (params: { code: string; engineCode: string; state?: string }) =>
    request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.SSOLoginUrl, {
        method: 'get',
        params,
    });

/** 退出登录 */
export const logout = (params: { engineCode: string }) =>
    request<H3.Http.Response.H3Response<null>>(Api.OldLogout, {
        method: 'get',
        params,
    });

/** 前端主动刷新后端用户缓存 */
export function reloadUser() {
    return request<H3.Http.Response.H3Response<null>>('/v1/user/reload', {
        method: H3.Http.MethodType.GET,
    });
}

/** 游客登录 */
export function visitorLogin() {
    return request<H3.Http.Response.H3Response<H3.Entry.ILoginInfo>>(Api.LoginByH3Visitor, {
        method: H3.Http.MethodType.POST,
    });
}
