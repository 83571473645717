import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取当前用户有访问权限的所有应用 */
export function getAllApps() {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/functionnode/app/list', {
        method: 'get',
    });
}

/** 获取当前用户有权限的包含仪表盘子节点的应用 */
export function getAppsHasDashboard() {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/functionnode/app/hasdashboard', {
        method: 'get',
    });
}

/** 获取当前用户管理的所有应用编码 */
export function getAllSubAdminAppList() {
    return request<H3.Http.Response.H3Response<string[]>>('/v1/functionnode/app/subadminapplist', {
        method: 'get',
    });
}

/** 获取指定应用下已发布的快捷方式 */
export function getPublishedShortcut(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<H3.App.PublishedShortcutData[]>>('/v1/shortcut/dingtalk/list', {
        method: 'get',
        params,
    });
}

/** 发布钉钉快捷方式 */
export function publishShortcut(params: H3.App.Request.IPublishShortcutModel) {
    return request<H3.Http.Response.H3Response<string>>('/v1/shortcut/dingtalk', {
        method: 'post',
        data: params,
    });
}

/** 更新已发布的快捷方式 */
export function updateShortcut(params: H3.App.Request.IPublishShortcutModel) {
    return request<H3.Http.Response.H3Response<string>>('/v1/shortcut/dingtalk', {
        method: 'put',
        data: params,
    });
}

/** 删除已发布的快捷方式 */
export function deleteShortcut(params: H3.App.Request.IDeleteShortcutModel) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/shortcut/dingtalk/remove', {
        method: 'post',
        data: params,
    });
}

/** 获取表单/应用依赖关系 */
export function getReferenceNode(params: { code?: string }) {
    return request<H3.Http.Response.H3Response<H3.Exception.RemoveTip.Response.IReferenceFieldModel[]>>(
        '/v1/reference/node',
        {
            method: 'get',
            params,
        },
    );
}

/** 获取应用特性 */
export function getAppFeatures(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<string[]>>('/v1/functionNode/app/feature/list', {
        method: 'get',
        params,
    });
}

/** 获取应用汇总控件使用信息 */
export function getAppRollupSummaries(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<H3.App.Response.AppRollupSummaryResult>>(
        '/v1/functionNode/app/approllupssummaries',
        {
            method: 'get',
            params,
        },
    );
}

/** 发布模版/应用前检查 */
export function checkAppPublish(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<string[]>>('/v1/template/checkPublish', {
        method: 'post',
        data: params,
        disableBizError: true,
    });
}
