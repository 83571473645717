import { request } from '@h3/request';
import { Util } from '@h3/h3common';
import { H3 } from '@h3/types';

// 请求框架封装，请求头部添加token
export default () => {
    request.extendOptions({
        prefix: Util.Env.baseUrl,
    });

    request.interceptors.request.use((url, options) => {
        const headers = {
            Authorization: `Bearer ${Util.Storage.CookieStorage.get(H3.Http.Cache.ACCESS_TOKEN_KEY, null)}`,
            EngineCode: Util.Storage.CookieStorage.get<string>(H3.Http.Cache.H3_ENGINE_CODE, null),
        };
        return {
            url,
            options: { ...options, interceptors: true, headers },
        };
    });
};
