/**
 * 视图数据查询url（需要经过自定义代码处理）
 */
export const QueryViewBizDataUrl = '/v1/bizdata/view/query';

/**
 * 删除数据url
 */
export const DeleteBizDataUrl = '/v1/bizdata/view/delete';

/**
 * 视图列统计
 */
export const ColumnStat = '/v1/bizdata/column/stat';

/**
 * 根据指定id获取业务数据
 */
export const CommonQueryBizDataUrl = '/v1/bizdata/query';

/**
 * 查询业务数据某列去重值
 */
export const QueryBizDataDistinctUrl = '/v1/bizdata/distinctPropertyValue';

/**
 * 请求查看业务数据短链
 */
export const GetBizDataShortLink = '/v1/bizdata/shortlink';

/**
 * 甘特图升降级
 */
export const UpdateGanttTaskLevelUrl = '/v1/gantt/task/changelevel';

/**
 * 更新任务类型
 */
export const UpdateGanttTaskTypeUrl = '/v1/gantt/task/updatetype';

/**
 * 排序
 */
export const UpdateGanttTaskSortUrl = '/v1/gantt/task/sort';

/**
 * 更新任务内容信息
 */
export const UpdateGanttTaskUrl = '/v1/gantt/task/update';

/**
 * 更新所属依赖
 */
export const UpdateGanttTaskDependencyUrl = '/v1/gantt/task/savedependency';
