import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 获取人脸识别插件配置信息 */
export const getFaceRecognitionConfig = () => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.GetFaceRecognitionConfig, {
        method: 'get',
        disableBizError: true,
    });
};

/** 保存人脸识别授权账号配置 */
export const saveFaceRecognitionAuthorize = (params: { redirectUrl: string }) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.SaveFaceRecognitionAuthorize, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取人脸识别人员库列表 */
export const getFaceRecognitionPersonGroupInfo = () => {
    return request<H3.Http.Response.H3Response<H3.Plugin.Response.IFaceRecognitionPersonGroupInfo[]>>(
        Api.GetFaceRecognitionPersonGroupInfo,
        {
            method: 'get',
            disableBizError: true,
        },
    );
};

/** 保存人脸识别人员库 */
export const createFaceRecognitionPersonGroup = (params: { redirectUrl: string }) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.CreateFaceRecognitionPersonGroup, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 取消人脸识别授权 */
export const cancelFaceRecognitionConfigAuthorize = (params: { redirectUrl: string }) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.CancelFaceRecognitionConfigAuthorize, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取人脸识别控件值 */
export const getFaceBizObject = (schemaCode: string, bizObjectId: string, field: string) => {
    return request<H3.Http.Response.H3Response<{ data: H3.BizData.FrontControlData.TFaceRecognitionValue }>>(
        '/v1/plugin/facerecognition/getFaceBizObject',
        {
            method: H3.Http.MethodType.POST,
            data: {
                schemaCode,
                bizObjectId,
                field,
            },
        },
    );
};

/** 删除人脸识别控件值 */
export const deleteFaceBizObject = (schemaCode: string, bizObjectId: string, field: string) => {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/plugin/facerecognition/deleteFaceBizObject', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            bizObjectId,
            field,
        },
    });
};
