import { H3 } from '@h3/types';

export default class SchemaMetaData {
    /** 表单结构 */
    schema: H3.Schema.ISchema | null = null;

    /** 视图偏好 */
    preference: H3.View.IPreferenceItem[] | null = null;

    /**
     * 设置表单结构
     * @param schema 表单结构
     */
    setSchema(schema: H3.Schema.ISchema) {
        this.schema = schema;
    }

    /**
     * 设置视图偏好
     * @param preference 视图偏好
     */
    setPreference(preference: H3.View.IPreferenceItem[]) {
        this.preference = preference;
    }
}
