import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取引擎信息 */
export const getEngineInfo = () =>
    request<H3.Http.Response.H3Response<H3.Engine.EngineInfo>>('/v1/engine/info', {
        method: 'get',
    });

/** 获取互联组织列表 */
export const getConnectEngines = (params: { pageSize: number; pageIndex: number }) =>
    request<H3.Http.Response.H3Response<H3.Engine.Response.IConnectEngineModel>>('/v1/interconnection/engines', {
        method: 'get',
        params,
    });
