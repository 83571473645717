import { H3 } from '@h3/types';
import { request } from '@h3/request';

const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/** 获取用户的信息 */
export const getUserInfo = (params: { engineCode: string }) =>
    request<H3.Http.Response.H3Response<H3.Org.IUserInfo>>('/v1/user/info', {
        method: 'get',
        params,
    });

export const GetCommonUserInfo = () => {
    return request<H3.Http.Response.H3ResponseV2<H3.Org.ICommonUserInfo>>('/Home/OnAction', {
        method: 'post',
        data: {
            PostData: JSON.stringify({
                ActionName: 'GetUserInfo',
            }),
        },
        headers,
        requestType: 'form',
    });
};
