import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 发送robot消息 */
export const sendRobotNotification = (params: H3.Plugin.Request.SendRobotNotificationModel) => {
    return request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.PluginInvokeModel[]>>(Api.SendRobotNotification, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 发送robot消息 */
export const verifyRobotNotification = (params: H3.Plugin.Request.VerifyRobotNotificationModel) => {
    return request<H3.Http.Response.H3Response<H3.Plugin.PluginInvokeModel[]>>(Api.VerifyRobotNotification, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};
