import { request } from '@h3/request';
import { H3 } from '@h3/types';
import * as Api from './constant';

/** 发送邮件 */
export const sendEmails = (params: {
    emails: Array<{
        subject: string;
        body: string;
        to: string[];
    }>;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta<boolean>>(Api.SendEmails, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};
