export namespace Util {
    /** 阻止ts报错，没有实际作用 */
    export enum UnUse {}

    /** 函数类型 */
    export type Func = (...args: unknown[]) => unknown;

    /** 指定字段可选 */
    export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

    /** 指定字段必选 */
    export type PartialRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

    /** 两个对象类型的差集 */
    export type Difference<T extends Record<string, unknown>, U extends Record<string, unknown>> = {
        [P in Exclude<keyof T, keyof U>]: T[P];
    };

    /** JSON序列化函数返回值类型 */
    export type JSONStringify<T> = T extends Record<string, unknown> ? ReturnType<typeof JSON.stringify> : string;

    /**  */
    export enum KeyCodes {
        Backspace = 8,
        Tab = 9,
        Enter = 13,
        Shift = 16,
        Ctrl = 17,
        Alt = 18,
        Pause = 19,
        CapsLock = 20,
        Esc = 27,
        Space = 32,
        PageUp = 33,
        PageDown = 34,
        End = 35,
        Home = 36,
        Left = 37,
        Up = 38,
        Right = 39,
        Down = 40,
        Delete = 46,
        ZERO = 48,
        ONE = 49,
        TWO = 50,
        THREE = 51,
        FOUR = 52,
        FIVE = 53,
        SIX = 54,
        SEVEN = 55,
        EIGHT = 56,
        NINE = 57,
        F1 = 112,
        F2 = 113,
        F3 = 114,
        F4 = 115,
        F5 = 116,
        F6 = 117,
        F7 = 118,
        F8 = 119,
        F9 = 120,
        F10 = 121,
        F11 = 122,
        F12 = 123,
        NumLock = 144,
        ScrollLock = 145,
        Semicolon = 186,
        Plus = 187,
        Comma = 188,
        Minus = 189,
        Period = 190,
        Slash = 191,
        BackQuote = 192,
        BracketLeft = 219,
        Backslash = 220,
        BracketRight = 221,
        Quote = 222,
        A = 65,
        B = 66,
        C = 67,
        D = 68,
        E = 69,
        F = 70,
        G = 71,
        H = 72,
        I = 73,
        J = 74,
        K = 75,
        L = 76,
        M = 77,
        N = 78,
        O = 79,
        P = 80,
        Q = 81,
        R = 82,
        S = 83,
        T = 84,
        U = 85,
        V = 86,
        W = 87,
        X = 88,
        Y = 89,
        Z = 90,
        Meta = 91,
        ContextMenu = 93,
        Num0 = 96,
        Num1 = 97,
        Num2 = 98,
        Num3 = 99,
        Num4 = 100,
        Num5 = 101,
        Num6 = 102,
        Num7 = 103,
        Num8 = 104,
        Num9 = 105,
        NumMultiply = 106,
        NumPlus = 107,
        NumMinus = 109,
        NumPeriod = 110,
        NumDivide = 111,
    }

    /** 时间戳 */
    export const enum TimerStamp {
        /** 0ms */
        None = 0,
        /** 1ms */
        MS = 1,
        /** 10ms */
        MS10 = 10,
        /** 50ms */
        MS50 = 50,
        /** 100ms */
        MS100 = 100,
        /** 200ms */
        MS200 = 200,
        /** 300ms */
        MS300 = 300,
        /** 500ms */
        MS500 = 500,
        /** 800ms */
        MS800 = 800,
        /** 1s */
        S = 1000,
        /** 2s */
        S2 = 2000,
        /** 3s */
        S3 = 3000,
        /** 4s */
        S4 = 4000,
        /** 10s */
        S10 = 10000,
        /** 30s */
        S30 = 30000,
        /** 1m */
        M = 60000,
        /** 1h */
        H = 3600000,
        /** 1d */
        D = 86400000,
    }

    /** 数字进制 */
    export enum NumberScale {
        /** 十进制 */
        Decimal = 10,
        /** 二进制 */
        Binary = 2,
        /** 八进制 */
        Octal = 8,
        /** 十六进制 */
        Hexadecimal = 16,
    }

    /** 列表视图-列-排除的字段 */
    export const TO_EXCLUDE_FIELDS = [
        'ObjectId',
        'Name',
        'ModifiedBy',
        'WorkflowInstanceId',
        'Status',
        'ParentObjectId',
    ];

    /** 秒转为毫秒进制 */
    export const M_TO_MS_SCALE = 1000;

    /** 小时转为分钟进制 */
    export const H_TO_M_SCALE = 60;

    /** 字节大小进制 */
    export const BIT_SCALE = 1024;

    /** MB转KB进制 1024*1024 */
    export const MB_TO_KB_SCALE = BIT_SCALE * BIT_SCALE;

    /** 手机号位数 */
    export const PHONE_LENGTH = 11;

    /** 密码长度 */
    export const PASSWORD_LENGTH = 16;

    /** 验证码长度 */
    export const VERIFY_CODE_LENGTH = 6;

    /** token失效时长 */
    export const TOKEN_EXPIRE_HOUR = 8;

    /** token判断失效时机(一半) */
    export const TOKEN_EXPIRE_HALF = 2;

    /** DataField编码长度 */
    export const DATA_FIELD_LENGTH = 8;

    /** PCT存储最大时长 */
    export const MAX_PCT_LIMIT = 7;

    /** 地图信息存储最大时长 */
    export const MAX_MAP_LIMIT = 7;

    /** 下拉框/复选框/单选框最大长度 */
    export const MAX_SELECT_OPTIONS_LENGTH = 200;

    /** 应用名称最小长度 */
    export const MIN_APP_NAME_LENGTH = 2;

    /** 应用名称最大长度 */
    export const MAX_APP_NAME_LENGTH = 32;

    /** 分组名称最小长度 */
    export const MIN_GROUP_NAME_LENGTH = 2;

    /** 分组名称最大长度 */
    export const MAX_GROUP_NAME_LENGTH = 32;

    /** 表单名称最小长度 */
    export const MIN_SCHEMA_NAME_LENGTH = 2;

    /** 表单名称最大长度 */
    export const MAX_SCHEMA_NAME_LENGTH = 32;

    /** text-box最大长度 */
    export const MAX_TEXT_BOX_LENGTH = 200;

    /** text-box最小长度 */
    export const MIN_TEXT_BOX_LENGTH = 21;

    /** textarea最大长度 */
    export const MAX_TEXTAREA_LENGTH = 2000;

    /** textarea默认行数 */
    export const DEFAULT_TEXTAREA_ROWS = 3;

    /** textarea最大行数 */
    export const MAX_TEXTAREA_ROWS = 999;

    /** number最大长度 */
    export const MAX_NUMBER_LENGTH = 200;

    /** 短信内容 */
    export const MAX_BUTTON_SMS_CONTENT = 200;

    /** 短信接收人 */
    export const MAX_BUTTON_SMS_RECEIVED = 5;

    /** 邮件接收人 */
    export const MAX_BUTTON_EMAIL_RECEIVED = 5;

    /** 最大评论长度 */
    export const MAX_FORM_COMMENT_LENGTH = 2000;

    /** tooltip string max length */
    export const MAX_TOOLTIP_STRING_LENGTH = 200;

    /** import max row length */
    export const MAX_IMPORT_ROW_LENGTH = 200;

    /** import max column length */
    export const MAX_IMPORT_COLUMN_LENGTH = 20;

    /** view calendar max length */
    export const MAX_VIEW_CALENDAR_LENGTH = 1000;

    /** work badge num max */
    export const MAX_WORK_BADGE_NUM = 99;

    /** view gantt swimlane max length */
    export const MAX_VIEW_GANTT_SWIMLANE_LENGTH = 3;

    /** view action select max length */
    export const MAX_VIEW_ACTION_SELECT_LENGTH = 100;

    /** 批量扫码最大数量 */
    export const MAX_FORM_BATCH_SCAN_LENGTH = 50;

    /** 空白画布打印 */
    export const MAX_PRINT_SPACE_TEMPLATE_LENGTH = 100;

    /** 查看业务数据二维码 */
    export const MAX_PRINT_QR_CODE_LENGTH = 50;

    /** Excel打印 */
    export const MAX_PRINT_EXCEL_TEMPLATE_LENGTH = 20;

    /** 引擎编码 */
    export const MAX_ENGIN_CODE_LENGTH = 2000;

    /** 文件名长度限制 */
    export const MAX_FILE_NAME_LENGTH = 200;

    /** 图片控件允许上传的默认最大大小(单位MB) */
    export const DEFAULT_IMAGE_SIZE = 20;

    /** 附件控件允许上传的默认最大大小(单位MB) */
    export const DEFAULT_ATTACHMENT_SIZE = 10;

    /** 附件控件允许上传的最大大小(单位MB) */
    export const MAX_UPLOAD_SIZE = 500;

    /** 视图下子表最大行数 */
    export const MAX_VIEW_GRID_ROWS = 7;

    /** 外部组织根节点ID */
    export const EXTERNAL_ROOT_ID = '18f923a7-5a5e-426d-94ae-a55ad1a4b241';

    /** 编辑器标记 */
    export const EDITOR_MARK = 'field-wEsbLBvP';

    /** 控件默认上限 */
    export const MAX_CONTROL_UPPER_LIMIT = 62250;

    /** 用户名最大长度 */
    export const MAX_USERNAME_LENGTH = 2;

    /** 树形结构最大层级 */
    export const MAX_TREE_LEVEL = 10;

    /** 树形结构最大数据量 */
    export const MAX_TREE_DATA_LENGTH = 10000;

    /** 层级视图最大层级 */
    export const MAX_HIERARCHY_VIEW_LEVEL = 10;

    /** 层级视图最大数据量 */
    export const MAX_HIERARCHY_VIEW_DATA_LENGTH = 100000;

    /** 视图查询空值(关联表单) */
    export const VIEW_QUERY_EMPTY_VALUE = '__Empty__';

    /** 表单不满足条件时提示 */
    export const H3_MAX_TREE_MAP = {
        [MAX_TREE_LEVEL]:
            '1、当前表单已开启【树形展示】，最多支持10层数据 <br/> 2、如需新增数据，请联系管理员前往表单属性中关闭【树形展示】',
        [MAX_TREE_DATA_LENGTH]:
            '1、当前表单已开启【树形展示】，最多支持一万条数据 <br/> 2、如需新增数据，请联系管理员前往表单属性中关闭【树形展示】',
    };

    /** 层级视图不满足条件时提示 */
    export const H3_MAX_HIERARCHY_VIEW_MAP = {
        [MAX_HIERARCHY_VIEW_LEVEL]:
            '1、当前表单已开启【层级视图】，最多支持10层数据 <br/> 2、如需新增数据，请联系管理员前往列表设计中关闭【层级视图】',
        [MAX_HIERARCHY_VIEW_DATA_LENGTH]:
            '1、当前表单已开启【层级视图】，最多支持十万条数据 <br/> 2、如需新增数据，请联系管理员前往列表设计中关闭【层级视图】',
    };

    /** 表单不满足条件时提示 */
    export const H3_DESIGNER_TREE_MAP = {
        circularReference: '数据将存在循环引用',
        overReferenceLevel: '数据的引用层级将超过10层',
        overLimitCount: '表单数据量超过一万条',
    };

    /** 开启树形展示后，表单需满足以下条件 */
    export const H3_DESIGNER_LIMIT_TREE_MAP = {
        circularReference: '使用父级字段构建的表单数据，不存在循环引用',
        overReferenceLevel: '使用父级字段构建的表单数据，引用层级不超过10层',
        overLimitCount: '表单数据量不超过一万条',
    };

    /** 百分比 */
    export enum PercentEnum {
        /** 0% */
        Zero = 0,
        /** 5% */
        Five = 5,
        /** 10% */
        Ten = 10,
        /** 20% */
        Twenty = 20,
        /** 80% */
        Eighty = 80,
        /** 95% */
        NinetyFive = 95,
        /** 99% */
        NinetyNine = 99,
        /** 100% */
        Hundred = 100,
    }

    export enum UploadSizeEnum {
        /** 3M */
        Three = 3,
        /** 4M */
        Four = 4,
        /** 5M */
        Five = 5,
        /** 8M */
        Eight = 8,
        /** 10M */
        Ten = 10,
        /** 20M */
        Twenty = 20,
        /** 50M */
        Fifty = 50,
        /** 100 */
        HundredM = 100,
        /** 500 */
        FiveHundred = 500,
    }

    /** 上传文件大小 */
    export const MaxUploadSizeScene: Record<string, number> = {
        /** 表单设计器描述控件上传图片 */
        FormDescriptionImage: UploadSizeEnum.Three,
        /** 子表导入文件 */
        GridViewImportFile: UploadSizeEnum.Twenty,
        /** sns文件 */
        Sns: UploadSizeEnum.Fifty,
        /** 表单微盘附件 */
        FormAttachmentWeDrive: UploadSizeEnum.Fifty,
        /** 表单审批流程手写签名 */
        FormApprovalHandSign: UploadSizeEnum.Ten,
        /** 表单审批流程附件 */
        FormApprovalAttachment: UploadSizeEnum.Ten,
        /** 表单手写签名 */
        FormHandSign: UploadSizeEnum.Ten,
        /** ocr附件 */
        FormOcrAttachment: UploadSizeEnum.Eight,
        /** ocr图片 */
        FormOcrImage: UploadSizeEnum.Twenty,
        /** 附件 */
        FormAttachment: UploadSizeEnum.Ten,
        /** 图片 */
        FormImage: UploadSizeEnum.Twenty,
    };

    export enum UploadCountEnum {
        /** 1 */
        One = 1,
        /** 8*/
        Eight = 8,
        /** 10 */
        Ten = 10,
        /** 50 */
        Fifty = 50,
        /** 100 */
        Hundred = 100,
    }

    /** 上传文件数量 */
    export const MaxUploadCountScene = {
        Sns: UploadCountEnum.Eight,
    };

    /** 分页大小 */
    export enum PageSizeEnum {
        /** 0 */
        Zero = 0,
        /** 1 */
        One = 1,
        /** 5 */
        Five = 5,
        /** 10 */
        Ten = 10,
        /** 20 */
        Twenty = 20,
        /** 50 */
        Fifty = 50,
        /** 100 */
        Hundred = 100,
        /** 200 */
        TwoHundred = 200,
        /** 500 */
        FiveHundred = 500,
    }

    /** 限制 */
    export enum LimitEnum {
        /** 0 */
        Zero = 0,
        /** 3 */
        Three = 3,
        /** 4 */
        Four = 4,
        /** 5 */
        Five = 5,
        /** 7 */
        Seven = 7,
        /** 10 */
        Ten = 10,
        /** 15 */
        Fifteen = 15,
        /** 20 */
        Twenty = 20,
        /** 21 */
        TwentyOne = 21,
        /** 50 */
        Fifty = 50,
        /** 99 */
        NinetyNine = 99,
        /** 100 */
        Hundred = 100,
        /** 200 */
        TwoHundred = 200,
        /** 500 */
        FiveHundred = 500,
        /** 1000 */
        Thousand = 1000,
        /** 2000 */
        TwoThousand = 2000,
        /** 5000 */
        FiveThousand = 5000,
        /** 10000 */
        TenThousand = 10000,
    }

    /** 月天数 */
    export enum MonthDays {
        /** 30天 */
        Thirty = 30,
        /** 31天 */
        ThirtyOne = 31,
        /** 28天 */
        TwentyEight = 28,
    }

    /** 月份 */
    export enum Month {
        /** 一月 */
        January = 1,
        /** 二月 */
        February = 2,
        /** 三月 */
        March = 3,
        /** 四月 */
        April = 4,
        /** 五月 */
        May = 5,
        /** 六月 */
        June = 6,
        /** 七月 */
        July = 7,
        /** 八月 */
        August = 8,
        /** 九月*/
        September = 9,
        /** 十月 */
        October = 10,
        /** 十一月 */
        November = 11,
        /** 十二月 */
        December = 12,
    }

    /** 域名枚举 */
    export enum DomainType {
        /** 本地 */
        LOCAL = 'local',
        /** 开发环境 */
        DEV = 'dev',
        /** 测试环境 */
        MASTER = 'master',
        /** 预发布环境 */
        PRE = 'pre',
        /** 正式环境 */
        PRO = 'pro',
    }

    /** 官网、应用中心、模板中心 */
    export const H3_DOMAIN_MAP = {
        [DomainType.LOCAL]: ['localhost'],
        [DomainType.DEV]: ['infrastructure', 'devcenter', 'store-dev'],
        [DomainType.MASTER]: ['master', 'masterstore', 'testcenter'],
        [DomainType.PRE]: [],
        [DomainType.PRO]: ['www', 'center', 'store'],
    };
}
