import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import 'dayjs/locale/zh-cn';

// config dayjs
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.extend(WeekOfYear);
dayjs.extend(QuarterOfYear);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

export default dayjs;

/**
 * 配置dayjs国际化
 * 这个为什么要写成方法而不是直接调
 * 因为这个还有一个作用是配置ant-design-vue中的日期国际化
 * 所以必须在每个独立打包的模块入库中调用一次
 */
export function setupDayjs() {
    dayjs.locale('zh-cn');
}
