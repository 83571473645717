export namespace ThirdParty {
    /** 跳转或打开页面目标类型 */
    export const enum PageTarget {
        /** 当前页面 */
        Current = 'current',

        /** 新页面 */
        Blank = 'blank',

        /** 浏览器 */
        Browser = 'browser',
    }

    /** 页面跳转选项 */
    export type PageJumpOptions = {
        /** 是否使用路由跳转，默认为true */
        useRouter?: boolean;

        /** 是否替换当前路由，默认为false */
        replace?: boolean;

        /** 跳转目标，默认为Current，当跳转目标不为Current时，前两个选项无效 */
        target?: PageTarget;

        /** 从第三方工作台打开浏览器时是否自动登录, 默认为true */
        autoLogin?: boolean;

        /** url query（始终拼接到query中，注意routerQuery参数区分） */
        query?: Record<string, string>;

        /** 路由参数（history路由拼接到query中，hash路由拼接到hash中） */
        routerQuery?: Record<string, string>;

        /** 是否附加时间戳，默认为false（添加到query） */
        timestamp?: boolean;
    };

    /** 文件上传或下载平台类型 */
    export const enum FileOperationPlatform {
        /** 普通上传/下载 */
        Common = 'Common',

        /** 上传/下载到微盘 */
        WeDrive = 'WeDrive',
    }

    export type IDraftFilesOptions = {
        fileName: string;
        objectKey: string;
    };

    export namespace Request {
        /** 下载文件到微盘接口参数 */
        export interface IDownloadParams {
            /** 文件地址：下载错误报告等文件使用 */
            fileUrl?: string;
            /** 附件id：下载表单附件使用 */
            attachmentIds?: string | string[];
            /** 表单评论附件：下载表单评论中的附件使用 */
            snsAttachment?: {
                /** 评论id */
                postId: string;
                /** 附件id */
                attachmentIds: string[];
            };
            /** 选择的微盘目录ticket */
            selectedTicket: string | number;

            draftFiles?: IDraftFilesOptions[];
        }
        /** 上传微盘文件到服务器接口参数 */
        export interface IUploadParams {
            /** 上传场景（0：表单上传，1：表单评论上传） */
            uploadType: 0 | 1;
            /** 表单编码 */
            schemaCode: string;
            /** 业务数据id */
            bizObjectId?: string;
            /** 文件最大大小（单位MB） */
            maxSize: number;
            /** 文件排序key */
            sortKey: number;
            /** 选择的微盘文件tickets */
            selectedTickets: Array<string>;
            /** 是否只上传图片 */
            isOnlyImage?: boolean;
        }
    }

    export namespace Response {
        /** 上传微盘文件到服务器接口响应 */
        export interface IWedriveUploadRes {
            /** 上传文件信息 */
            fileInfos: Array<{
                /** 文件名称 */
                fileName: string;
                /** 文件类型 */
                contentType: string;
                /** 文件大小 */
                fileSize: number;
                /** 文件ticket */
                selectedTicket: string;
                /** 文件id */
                attachmentId: string;
                /** 文件状态（0：成功，1：失败） */
                state: 0 | 1;
                /** 文件上传失败时的错误信息 */
                resultStr: string;
                /** 文件缩略图地址 */
                thumbnailUrl: string;
                /** 文件是否超出大小限制 */
                fileExceed: boolean;
            }>;
        }
    }
}
