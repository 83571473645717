import { H3 } from '@h3/types';
import * as Api from '../../api';
import DB from '../../db/database';
import ContextMetaData from '../meta/context-meta-data';
import SchemaMetaData from '../meta/schema-meta-data';

export default class SchemaContext {
    /** 表单编码 */
    private readonly _schemaCode: string;

    /** 主表编码 */
    private _mainSchemaCode?: string;

    /** 通用元数据 */
    private readonly _contextMetaData: ContextMetaData;

    /** 表单元数据 */
    private readonly _schemaMetaData: SchemaMetaData;

    constructor(schemaCode: string, contextMetaData: ContextMetaData) {
        this._schemaCode = schemaCode;
        this._contextMetaData = contextMetaData;
        this._schemaMetaData = new SchemaMetaData();
    }

    /** 主表编码 */
    get mainSchemaCode() {
        return this._mainSchemaCode ?? this._schemaCode;
    }

    /** 表单编码 */
    get schemaCode() {
        return this._schemaCode;
    }

    /** 构建时传入的是子表编码 */
    get isChildSchema() {
        return this._mainSchemaCode !== this._schemaCode;
    }

    /** 主表表单节点信息 */
    get nodeInfo() {
        return this._contextMetaData.getFunctionNode(this.mainSchemaCode);
    }

    /** 是否流程表单 */
    get isWorkflow() {
        return this.nodeInfo?.nodeType === H3.FunctionNode.FunctionNodeType.WorkflowModule;
    }

    /** 表单结构 */
    get schema() {
        return this._schemaMetaData.schema!;
    }

    /** 视图偏好 */
    get preferences() {
        return this._schemaMetaData.preference || [];
    }

    /**
     * 加载表单（包括加载表单functionNode信息和加载schema结构）
     * params: functionNode 默认也加载functionNode信息
     * */
    async load(functionNode = true) {
        // 加载schema结构：先从缓存中取，再从接口取
        const cachedSchema: H3.Schema.ISchema = await DB.getSchema(this._schemaCode);
        let latestSchema: H3.Schema.ISchema | undefined;
        const params = {
            schemaCode: this._schemaCode,
            timestamp: cachedSchema?.timestamp ?? -1,
        };
        const resp = await Api.Schema.onceGetSchema?.refresh(params);
        // 清理缓存数据(接口)
        Api.Schema.onceGetSchema?.clear(params);

        // 接口请求失败，清除缓存数据
        if (!resp?.successful) {
            await DB.deleteSchema(this._schemaCode);
            return;
        }

        if (typeof resp?.returnData === 'object') {
            // 说明缓存失效，存在最新的schema数据
            latestSchema = resp.returnData;
        }

        if (!latestSchema && !cachedSchema) {
            return;
        }

        // 写入主表编码
        this._mainSchemaCode = latestSchema?.schemaCode || cachedSchema?.schemaCode;

        // 有了主表编码，加载主表functionNode信息
        if (!this.nodeInfo && functionNode) {
            const res = await Api.FunctionNode.getFunctionNodeByCodes([this.mainSchemaCode]);
            if (res.successful) {
                this._contextMetaData.setFunctionNodes(res.returnData);
            }
        }

        // 兼容schema数据并写入
        const compatibleSchema = this._getCompatibleSchema(latestSchema ?? cachedSchema);
        await DB.setSchema(compatibleSchema);
        this._schemaMetaData.setSchema(compatibleSchema);
    }

    /** 获取视图偏好配置 */
    async getViewPreference(force = false) {
        // 取缓存
        if (!force && this._schemaMetaData.preference) {
            return this.preferences;
        }

        // 请求接口
        const ret = await Api.View.getViewPreference({ schemaCode: this._schemaCode });
        if (ret.successful) {
            this._schemaMetaData.setPreference(ret.returnData || []);
        }
        return this.preferences;
    }

    /** 添加视图偏好配置 */
    async addViewPreference(params: H3.View.Request.IAddViewPreferenceModel) {
        const ret = await Api.View.addViewPreference(params);
        if (ret.successful) {
            await this.getViewPreference(true);
        }
        return ret;
    }

    /** 更新视图偏好配置 */
    async updateViewPreference(preference: H3.View.IPreferenceItem) {
        const params: H3.View.Request.IUpdateViewPreferenceModel = {
            id: preference.Id,
            title: preference.Title,
            scopeType: preference.ScopeType,
            settingType: preference.SettingType,
            config: preference.Config,
            schemaCode: this._schemaCode,
            visible: preference.Visible,
            fields: preference.Fields,
        };
        const ret = await Api.View.updateViewPreference(params);
        if (ret.successful) {
            const { id } = params;
            const idx = this.preferences.findIndex((pre) => pre.Id === id);
            if (idx > -1) {
                this.preferences.splice(idx, 1, preference);
            }
        }
        return ret;
    }

    /** 删除视图偏好配置 */
    async removeViewPreference(params: H3.View.Request.IRemoveViewPreferenceModel) {
        const ret = await Api.View.removeViewPreference(params);
        if (ret.successful) {
            const { id } = params;
            const idx = this.preferences.findIndex((preference) => preference.Id === id);
            if (idx > -1) {
                this.preferences.splice(idx, 1);
            }
        }
        return ret;
    }

    /** 对视图偏好排序 */
    async sortViewPreference(params: H3.View.Request.ISortViewPreferenceModel) {
        const ret = await Api.View.sortViewPreference(params);
        if (ret.successful) {
            await this.getViewPreference(true);
        }
        return ret;
    }

    /**
     * 对schema数据进行兼容处理
     * @param schema 接口请求到的schema数据
     * @returns 兼容过的schema数据
     */
    private _getCompatibleSchema(schema: H3.Schema.ISchema) {
        const { properties, childSchemas } = schema;
        for (const property of properties) {
            PropertyCompatibleTool.compatible(property as H3.Schema.IProperty<Record<string, unknown>>, this);
        }

        if (childSchemas && childSchemas.length) {
            childSchemas.forEach((childSchema, index) => {
                childSchemas[index] = this._getCompatibleSchema(childSchema);
            });
        }

        return schema;
    }
}

/** 字段属性兼容工具 */
class PropertyCompatibleTool {
    /**
     * 兼容字段属性（改变原属性）
     * @param property 字段属性
     * @param schemaContext schema上下文
     */
    static compatible(property: H3.Schema.IProperty<Record<string, unknown>>, schemaContext: SchemaContext) {
        // 部分系统字段并不是控件，没有对应的controlType
        // 另有部分字系统字段在历史数据中的controlType不准确
        // 上述两种场景都需要按照字段编码进行兼容

        switch (property.name) {
            case 'Status': {
                this._compatibleFormStatus(property, schemaContext);
                break;
            }
            case 'ModifiedBy': {
                this._compatibleFormModifiedBy(property);
                break;
            }
            case 'SeqNo': {
                this._compatibleFormSeqNo(property);
                break;
            }
            case 'CreatedBy': {
                this._compatibleFormCreater(property);
                break;
            }
            case 'OwnerId': {
                this._compatibleFormOwner(property);
                break;
            }
            case 'OwnerDeptId': {
                this._compatibleFormOwnerDept(property);
                break;
            }
            case 'CreatedTime': {
                this._compatibleFormCreatedTime(property);
                break;
            }
            case 'ModifiedTime': {
                this._compatibleFormModifiedTime(property);
                break;
            }
            default: {
                break;
            }
        }

        switch (property.controlType) {
            case H3.Control.FormControlKeys.FormDateTime: {
                this._compatibleFormDateTime(property);
                break;
            }
            case H3.Control.FormControlKeys.FormNumber: {
                this._compatibleFormNumber(property);
                break;
            }
            case H3.Control.FormControlKeys.FormFormula: {
                this._compatibleFormFormula(property);
                break;
            }
            case H3.Control.FormControlKeys.FormRollup: {
                this._compatibleFormRollup(property);
                break;
            }
            case H3.Control.FormControlKeys.FormAssociateProperty: {
                this._compatibleFormAssociateProperty(property);
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * 兼容日期字段属性
     * @param property 日期字段属性
     * @example
     * 1. 兼容日期字段显示格式
     */
    private static _compatibleFormDateTime(property: H3.Schema.IProperty<Record<string, unknown>>) {
        const sourceFormat = property.extension.displayFormat;
        let compatibleFormat: H3.Control.DateTimeMode;
        switch (sourceFormat) {
            case 'YYYY-MM-DD HH:mm':
            case 'yyyy-mm-dd hh:ii':
            case 'yyyy-mm-dd hh:mm':
                compatibleFormat = H3.Control.DateTimeMode.Y_M_D_H_M;
                break;
            case 'YYYY-MM-DD':
            case 'yyyy-mm-dd':
                compatibleFormat = H3.Control.DateTimeMode.Y_M_D;
                break;
            case 'yyyy-mm':
                compatibleFormat = H3.Control.DateTimeMode.Y_M;
                break;
            case 'hh:ii':
            case 'hh:mm':
                compatibleFormat = H3.Control.DateTimeMode.H_M;
                break;
            default:
                compatibleFormat = H3.Control.DateTimeMode.Y_M_D_H_M;
                break;
        }
        property.extension.displayFormat = compatibleFormat;
    }

    /**
     * 兼容数字字段属性
     * @param property 数字字段属性
     * @example
     * 1. 兼容小数位数
     */
    private static _compatibleFormNumber(property: H3.Schema.IProperty<Record<string, unknown>>) {
        const { decimalPlaces, showMode } = property.extension;
        if (!decimalPlaces || typeof decimalPlaces !== 'number') {
            const isPercent = [H3.Control.NumberShowMode.Percentage, H3.Control.NumberShowMode.BarChart].includes(
                showMode as H3.Control.NumberShowMode,
            );
            // 位数
            const p = 2;
            const defaultDecimalPlaces = isPercent ? p : 0;
            property.extension.decimalPlaces = Math.max(decimalPlaces as number, defaultDecimalPlaces);
        }
    }

    /**
     * 兼容公式型字段属性
     * @param property 公式型字段属性
     * @example
     * 1. 兼容bindType，历史数据里部分bindType为文本时值是Text
     */
    private static _compatibleFormFormula(property: H3.Schema.IProperty<Record<string, unknown>>) {
        property.extension.bindType = ((property.extension.bindType || '') as string).toLowerCase();
    }

    /**
     * 兼容汇总字段属性
     * @param property 汇总字段属性
     * @example
     * 1. 兼容bindType
     */
    private static _compatibleFormRollup(property: H3.Schema.IProperty<Record<string, unknown>>) {
        property.extension.bindType = ((property.extension.bindType || '') as string).toLowerCase();
    }

    /**
     * 兼容关联属性字段属性
     * @param property 关联属性字段属性
     * @example
     * 1. 兼容关联的目标字段配置
     */
    private static _compatibleFormAssociateProperty(property: H3.Schema.IProperty<Record<string, unknown>>) {
        // 有几种情况会导致sourcePropertySchema不存在
        // 1. 历史数据，后端获取不到sourcePropertySchema
        // 2. 关联的目标字段被删除
        // 此时前端兼容为一个单行文本的配置，这可能是有问题的，但是目前没有更好的解决方案
        if (!property.extension.sourcePropertySchema) {
            property.extension.sourcePropertySchema = {
                controlType: H3.Control.FormControlKeys.FormTextBox,
                dataType: H3.Control.BizDataType.ShortString,
                displayName: '',
                name: '',
                extension: {
                    description: '',
                    placeHolder: '',
                    hideRule: '',
                    formula: '',
                    defaultValueType: -1,
                    dataLink: null,
                    mode: H3.Control.TextMode.Normal,
                    inputByScan: false,
                    scanUpdateEnable: false,
                    noRepeat: false,
                },
            };
        }
    }

    /**
     * 兼容流程或数据状态字段属性
     * @param property 流程或状态字段属性
     * @param schemaContext schema上下文
     * @example
     * 1. 兼容控件类型
     * 2. 兼容字段显示名称
     */
    private static _compatibleFormStatus(property: H3.Schema.IProperty, schemaContext: SchemaContext) {
        property.controlType = H3.Control.FormControlKeys.FormStatus;
        property.displayName = schemaContext.isWorkflow ? '流程状态' : '数据状态';
    }

    /**
     * 兼容修改人字段属性
     * @description 注意，修改人并非控件，没有独立的controlType，不要依赖于其controlType写逻辑
     * @param property 修改人字段属性
     * @example
     * 1. 兼容字段显示名称
     */
    private static _compatibleFormModifiedBy(property: H3.Schema.IProperty) {
        property.displayName = '修改人';
    }

    /**
     * 兼容流水号字段属性
     * @param property 流水号字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormSeqNo(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormSeqNo;
    }

    /**
     * 兼容创建人字段属性
     * @param property 创建人字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormCreater(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormCreater;
    }

    /**
     * 兼容拥有者字段属性
     * @param property 拥有者字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormOwner(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormOwner;
    }

    /**
     * 兼容所属部门字段属性
     * @param property 所属部门字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormOwnerDept(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormOwnerDepartment;
    }

    /**
     * 兼容创建时间字段属性
     * @param property 创建时间字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormCreatedTime(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormCreatedTime;
    }

    /**
     * 兼容修改时间字段属性
     * @param property 修改时间字段属性
     * @example
     * 1. 兼容控件类型
     */
    private static _compatibleFormModifiedTime(property: H3.Schema.IProperty) {
        property.controlType = H3.Control.FormControlKeys.FormModifiedTime;
    }
}
