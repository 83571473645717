/** 获取视图设计时配置 */
export const GetDesignerViewSetting = '/v1/view/designer/setting';

/** 获取视图运行时配置 */
export const GetViewSetting = '/v1/view/setting';

/** 更新视图设计配置 */
export const UpdateViewSetting = '/v1/view/designer/setting';

/** 获取视图偏好配置 */
export const GetViewPreference = '/v1/preference/view';

/** 新增视图偏好配置 */
export const AddViewPreference = '/v1/preference/addView';

/** 更新视图偏好配置 */
export const UpdateViewPreference = '/v1/preference/updateView';

/** 删除视图偏好配置 */
export const RemoveViewPreference = '/v1/preference/deleteView';

/** 排序视图偏好配置 */
export const SortViewPreference = '/v1/preference/view/sort';

/** 获取层级视图是否超出阈值(层级请求还是全部请求) */
export const GetHierarchyLimit = '/v1/view/isoverhierarchylimit';
