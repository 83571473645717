import { H3 } from '@h3/types';
import * as Api from '../../api';
import AppMetaData from '../meta/app-meta-data';
import ContextMetaData from '../meta/context-meta-data';
import SchemaContext from './schema';

export default class AppContext {
    /** 应用编码 */
    private readonly _appCode: string;

    /** 通用元数据 */
    private readonly _contextMetaData: ContextMetaData;

    /** 应用元数据 */
    private readonly _appMetaData: AppMetaData;

    /** 是否是最终缓存(列表加载functionNode追求速度,拿到的数据hasChild不准确,而在选择应用tree时,需要拿到正确的hasChild,所以不能读缓存) */
    private _isFinalCache = false;

    private _features: string[] = []; // 应用支持的特性清单

    /** 当前活跃的表单 */
    activeSchema?: SchemaContext;

    constructor(appCode: string, contextMetaData: ContextMetaData) {
        this._appCode = appCode;
        this._contextMetaData = contextMetaData;
        this._appMetaData = new AppMetaData();
    }

    /** 应用编码 */
    get appCode() {
        return this._appCode;
    }

    /** 应用信息 */
    get appInfo() {
        return this._contextMetaData.getFunctionNode(this._appCode);
    }

    /** 应用子节点信息 */
    get children() {
        const codes = this._appMetaData.children || [];
        return this._contextMetaData.getFunctionNodes(codes);
    }

    /**
     * 加载应用
     * 加载应用子菜单信息
     */
    async load() {
        await this.loadChildren();
    }

    /**
     * 加载应用子菜单
     * @param force 是否不走缓存强制加载
     * @param hasChild 是否有子集(默认是false,对后端性能影响很大)
     */
    async loadChildren(force = false, hasChild = false) {
        if (!force && this._appMetaData.children) {
            // 查询子集时 如果不是最终缓存,则需要重新加载
            if (!hasChild || (hasChild && this._isFinalCache)) {
                return;
            }
        }
        const ret = await Api.FunctionNode.getChildrenByParentCode(this._appCode, hasChild);
        if (ret.successful) {
            if (hasChild) {
                this._isFinalCache = true;
            }
            const children = ret.returnData || [];
            this._contextMetaData.setFunctionNodes(children);
            this._appMetaData.initChildren(children.map((child) => child.code));
        }
    }

    /**
     * 切换活动的表单
     * @param schemaCode 切换到的表单编码
     */
    async toggleActiveSchema(schemaCode: string) {
        if (!this.children.some((child) => child.code === schemaCode)) {
            this.activeSchema = void 0;
            return false;
        }
        const schemaContext = this.getSchemaContext(schemaCode);
        this.activeSchema = schemaContext;
        return true;
    }

    /** 新增子节点 */
    async addChild(params: H3.FunctionNode.Request.ICreateFunctionNodeModel) {
        const ret = await Api.FunctionNode.createFunctionNode(params);
        if (ret.successful) {
            const { id, code } = ret.returnData;
            const node: H3.FunctionNode.IFunctionNode = {
                ...params,
                code,
                objectId: id,
            };
            this._contextMetaData.setFunctionNode(node);
            this._appMetaData.addChildren(node.code);
        }
        return ret;
    }

    /** 更新子节点 */
    async updateChild(node: H3.FunctionNode.IFunctionNode) {
        const ret = await Api.FunctionNode.updateFunctionNode(node);
        if (ret.successful) {
            this._contextMetaData.setFunctionNode(node);
        }
        return ret;
    }

    /** 删除子节点 */
    async removeChild(code: string, nodeType: H3.FunctionNode.FunctionNodeType) {
        const ret = await Api.FunctionNode.deleteFunctionNode(code);
        if (ret.successful) {
            if (nodeType === H3.FunctionNode.FunctionNodeType.GroupModule) {
                // 删除分组节点后，涉及到将表单放到应用下的排序问题，所以重新加载一下
                await this.loadChildren(true);
                this._contextMetaData.deleteFunctionNode(code);
            } else {
                // 否则直接从缓存中删除
                this._appMetaData.removeChild(code);
                this._contextMetaData.deleteSchemaContext(code);
                // 如果删除的是当前表单，需要清空activeSchemaContext
                if (!this.activeSchema?.nodeInfo) {
                    this.toggleActiveSchema('');
                }
            }
        }
        return ret;
    }

    /** 移动子节点 */
    async moveChild(params: H3.FunctionNode.Request.IMoveFunctionNodeModel) {
        const ret = await Api.FunctionNode.moveFunctionNode(params);
        if (ret.successful) {
            // 因为不太好做移动后排序，所以直接清空子节点重新加载
            await this.loadChildren(true);
            // 如果移走的节点包含当前表单，需要清空activeSchemaContext
            if (!this.activeSchema?.nodeInfo) {
                await this.toggleActiveSchema('');
            }
            // 如果目标应用不是当前应用，则清除应用缓存（因为移过去后排序前端不确定）
            if (params.targetAppCode !== this._appCode) {
                this._contextMetaData.deleteAppContext(params.targetAppCode);
            }
        }
        return ret;
    }

    /** 更新子节点顺序 */
    async sortChild(
        sortedNodes: Array<{
            code: string;
            parentCode: string;
        }>,
        params: {
            code: string;
            parentCode: string;
            preCode?: string;
        },
    ) {
        // 更新缓存
        this._appMetaData.sortNodes(sortedNodes);
        sortedNodes.forEach((node) => {
            this._contextMetaData.getFunctionNode(node.code)!.parentCode = node.parentCode;
        });

        // 请求接口更新顺序
        return Api.FunctionNode.sortFunctionNode(params);
    }

    /** 复制指定表单, 调整为异步 */
    async copySchema(schemaCode: string) {
        return await Api.FunctionNode.copyFunctionNode(schemaCode);
    }

    /** 获取应用支持的特性集合 */
    async getAppFeatures() {
        if (!this._features.length) {
            const ret = await Api.App.getAppFeatures(this._appCode);
            if (ret.successful) {
                this._features = ret.returnData || [];
            }
        }
        return this._features;
    }

    /**
     * 异步任务id
     * @param actionId 任务id
     */
    async getCopySchemaProgress(actionId: string) {
        const ret = await Api.FunctionNode.copyFunctionNodeProgress(actionId);
        if (ret.successful && ret.returnData.isComplete) {
            const copySchema = ret.returnData.node;
            this._contextMetaData.setFunctionNode(copySchema);
            this._appMetaData.addChildren(copySchema.code);
        }
        return ret;
    }

    /** 获取表单上下文 */
    getSchemaContext(schemaCode: string) {
        let context = this._contextMetaData.getSchemaContext(schemaCode);
        if (!context) {
            context = new SchemaContext(schemaCode, this._contextMetaData);
            this._contextMetaData.setSchemaContext(context);
        }
        return this._contextMetaData.getSchemaContext(schemaCode)!;
    }
}
