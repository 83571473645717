import { View } from '../view';

export namespace DB {
    export enum CacheName {
        SCHEMA = 'Schema',
        FUNCTION_NODE = 'FunctionNode',
        VIEW_SETTING = 'ViewSetting',
    }

    export enum DBTable {
        SCHEMA = 'schema',
        FUNCTION_NODE = 'functionNode',
        VIEW_SETTING = 'viewSetting',
        FORM_GRID_SETTING = 'formGridSetting',
        VIEW_LIST_SETTING = 'viewListSetting',
        VIEW_PERSONALIZATION = 'viewPersonalization',
    }

    export enum DBField {
        ID = 'id',
        TIMESTAMP = 'timestamp',
        VALUE = 'value',
    }

    export enum DBFormGridSettingKeys {
        /** 翻页信息 currentPage/pageSize */
        PAGE = 'page',
        /** 排序信息 order/field */
        SORT = 'sort',
        /** 列宽 */
        COLUMN_WIDTH = 'columnWidth',
    }

    export enum DBViewListSettingKeys {
        /** 是否显示 */
        Visible = 'visible',
        /** 排序信息 order/field */
        SORT = 'sort',
        /** 列宽 */
        COLUMN_WIDTH = 'columnWidth',
        /** 固定列 */
        FIXED_COLUMN = 'fixedColumn',
        /** 翻页信息 */
        PAGE = 'page',
    }

    export enum DBViewPersonalizationKeys {
        /** flag */
        IS_NEW = 'isNew',
        /** 子表编码 */
        GRID_SCHEMA_CODE = 'gridSchemaCode',
        /** 配置列编码 */
        CONFIGURABLE_COLUMN_CODES = 'configurableColumnCodes',
        /** 显示列编码 */
        DISPLAY_COLUMN_CODES = 'displayColumnCodes',
        /** 泳道显示列编码 */
        LINE_DISPLAY_COLUMN_CODES = 'lineDisplayColumnCodes',
        /** 列表固定列 */
        FIXED_COLUMNS = 'fixedColumns',
        /** 列表列宽 */
        COLUMNS_WIDTH = 'columnsWidth',
        /** 列表排序信息 order/field */
        SORT = 'sort',
        /** 翻页信息 */
        PAGE = 'page',
        /** 筛选显示状态 */
        FILTER_VISIBLE = 'filterVisible',
        /** 筛选固定状态 */
        FILTER_FIXED = 'filterFixed',
        /** 个性化(筛选条件组) */
        VIEW_PREFERENCE = 'viewPreference',
        /** 分组筛选展开状态 */
        GROUP_COLLAPSED = 'groupCollapsed',
        /** 分组筛选包含下级状态 */
        GROUP_IS_CONTAIN = 'groupIsContain',
    }

    export type EntityParams = {
        [DBField.ID]: string;
    };

    export type DBFormGridSetting = {
        [DBFormGridSettingKeys.PAGE]?: {
            currentPage?: number;
            pageSize?: number;
        };
        [DBFormGridSettingKeys.SORT]?: {
            order: 'asc' | 'desc';
            field: string;
        };
        [DBFormGridSettingKeys.COLUMN_WIDTH]?: {
            [key: string]: number;
        };
    };

    export type DBViewListSetting = {
        [DBViewListSettingKeys.FIXED_COLUMN]?: string[];
        [DBViewListSettingKeys.SORT]?: {
            order: 'asc' | 'desc';
            field: string;
        };
        [DBViewListSettingKeys.COLUMN_WIDTH]?: {
            [key: string]: number;
        };
        [DBViewListSettingKeys.Visible]?: boolean;
        [DBViewListSettingKeys.PAGE]?: {
            currentPage?: number;
            pageSize: number;
        };
    };

    /** 视图个性化配置 */
    export type DBViewPersonalization = {
        /** 显示列编码 */
        [DBViewPersonalizationKeys.DISPLAY_COLUMN_CODES]?: Partial<Record<View.ViewMode, string[]>>;
        /** 泳道显示列编码 */
        [DBViewPersonalizationKeys.LINE_DISPLAY_COLUMN_CODES]?: string[];
        /** 配置列编码 */
        [DBViewPersonalizationKeys.CONFIGURABLE_COLUMN_CODES]?: Partial<
            Record<View.ViewMode, { code: string; children?: { code: string }[] }[]>
        >;
        /** 子表编码 */
        [DBViewPersonalizationKeys.GRID_SCHEMA_CODE]?: Partial<Record<View.ViewMode, string>>;
        /** 列表固定列 */
        [DBViewPersonalizationKeys.FIXED_COLUMNS]?: Partial<Record<View.ViewMode, string[]>>;
        /** 列表列宽 */
        [DBViewPersonalizationKeys.COLUMNS_WIDTH]?: Partial<Record<View.ViewMode, { [key: string]: number }>>;
        /** 列表排序信息 */
        [DBViewPersonalizationKeys.SORT]?: Partial<
            Record<View.ViewMode, { sortBy: string; sortDirection: View.SortDirection }>
        >;
        /** 翻页信息 */
        [DBViewPersonalizationKeys.PAGE]?: Partial<Record<View.ViewMode, { currentPage?: number; pageSize: number }>>;
        /** 筛选显示状态 */
        [DBViewPersonalizationKeys.FILTER_VISIBLE]?: boolean;
        /** 筛选固定状态 */
        [DBViewPersonalizationKeys.FILTER_FIXED]?: boolean;
        /** 个性化(筛选条件组) */
        [DBViewPersonalizationKeys.VIEW_PREFERENCE]?: string;
        /** 分组筛选展开状态 */
        [DBViewPersonalizationKeys.GROUP_COLLAPSED]?: boolean;
        /** 分组筛选包含下级状态 */
        [DBViewPersonalizationKeys.GROUP_IS_CONTAIN]?: boolean;
    };
}
