import { Control } from '../control';
import { CustomCode } from '../custom-code';
import { FunctionNode } from '../node';
import { Print } from '../print';
import { Workflow } from '../workflow';
import { Filter } from '../filter';
export namespace Form {
    /** 表单设计类型 */
    export enum LinkType {
        Sheet = 0,
        Workflow,
        View,
        Setting,
    }

    /** 表单默认模态窗配置 */
    export enum FormModalDefaultConfig {
        /** 模态窗默认宽度 */
        Width = 820,
        /** 模态窗默认高度 */
        Height = 500,
    }

    /** 打开表单场景 */
    export enum FormScene {
        /** 通用场景 */
        Common = 'Common',

        /** 单表模式 */
        SingletonModule = 'SingletonModule',

        /** 列表视图数据标题 */
        ListDataTitle = 'ListDataTitle',

        /** 甘特图新增节点 */
        GanttCreateNode = 'GanttCreateNode',

        /** 外链填单 */
        ExternalForm = 'ExternalForm',

        /** 外链分享 */
        ExternalShare = 'ExternalShare',

        /** 公开查询 */
        OpenQuery = 'OpenQuery',

        /** 自定义代码 */
        CustomCode = 'CustomCode',

        /** 全局Api */
        GlobalApi = 'GlobalApi',
    }

    /** 表单模式 */
    export enum FormMode {
        /** 编辑模式 */
        Edit = 'Edit',

        /** 查看模式 */
        View = 'View',

        /** 新增模式 */
        Create = 'Create',

        /** 只读模式 */
        Readonly = 'Readonly',

        // 这个模式应该是没有用到
        /** 打印模式 */
        Print = 'Print',
    }

    /** 表单模式 */
    export enum FormModeValue {
        /** 未指定 */
        Unspecified = -1,
        /** 编辑 */
        Edit = 0,
        /** 只读 */
        Readonly = 1,
        /** 新增 */
        Create = 2,
        /** 打印 */
        Print = 3,
        /** 查看 */
        View = 4,
    }

    /** 表单数据类型(取决于当前流程是否开启而非数据创建时流程是否开启) */
    export enum FormDataType {
        /** 流程表单数据 */
        WorkflowModule = 1,
        /** 非流程表单数据 */
        FormModule = 2,
    }

    /** 表单选项 */
    export type FormOption = {
        /** 场景 */
        scene: FormScene;

        /** 表单模式 */
        mode?: FormMode;

        /** 表单编码 */
        schemaCode: string;

        /** 业务数据id */
        bizObjectId?: string;

        /** 当前流程项id */
        workItemId?: string;

        /** 甘特图新增数据时的父节点业务数据id */
        parentObjectId?: string;

        /** 表单内切换数据 */
        objectIds?: string[];

        /** 外链填单开启查看 */
        externalFormEnableView?: boolean;

        /** 自定义代码选项 */
        customOption?: {
            /** 自定义代码参数 */
            customParam?: Record<string, unknown>;

            /** 模态窗配置 */
            modalConfig?: {
                /** 自定义title */
                title?: string;

                /** 宽 */
                width: number;

                /** 高 */
                height: number;

                /** 模态窗打开回调 */
                onShowCallback?: () => void;

                /** 模态窗关闭回调 */
                onHiddenCallback?: (postVal: Record<string, unknown>) => void;

                /** 是否全屏 */
                fullscreen: boolean;
            };
        };

        /** 复制表单选项 */
        copyOption?: {
            data: Record<string, unknown>;
        };

        /** 提交并新增 */
        submitAndAdd?: boolean;

        /** 携带初始值（目前场景是关联列表新增时要携带关联表单控件值），需要注意的是这里的控件值应该与表单加载数据接口返回的一致 */
        initialValues?: Record<string, unknown>;

        /** 表单关闭回调 */
        closeCallback?: (closeOptions: FormCloseCallbackParams) => void;

        /** 新增生效数据回调（作用是关联表单新增数据回填） */
        onCreateBizObject?: (bizObjectId: string) => void;

        /** 刷新视图的方法 */
        refreshView?: () => void;

        // 表单初始化时的需要初始化值的关联关系（逻辑是： 新建表中的关联表单字段关联到该项中的schemaCode，就给该关联表单控件初始值设置为对应的bizObjectId）
        initRelationInfo?: {
            bizObjectId: string;
            displayName: string;
            schemaCode: string;
        };
        /** 表单扩展选项 */
        extensionOption?: {
            defaultExtensionType: ExtensionTypes; // 默认展开的key
        };
    };

    /** 表单关闭回调参数类型 */
    export type FormCloseCallbackParams = {
        /** 触发关闭的操作编码 */
        actionCode: FormActionCode;

        /** 是否曾新增并添加 */
        submitAndAdd: boolean;
    };

    /** 表单系统操作编码 */
    export enum FormSystemActionCode {
        /** 复制 */
        Copy = 'Copy',

        /** 打印 */
        Print = 'Print',

        /** 查看二维码 */
        ViewQrCode = 'ViewQrCode',

        /** 数据分享 */
        ExternalShare = 'ExternalShare',

        /** 提交并新增下一个 */
        SubmitAndAdd = 'SubmitAndAdd',

        /** 编辑 */
        Edit = 'Edit',

        /** 暂存 */
        Save = 'Save',

        /** 提交和审批同意 */
        Submit = 'Submit',

        /** 审批不同意 */
        Reject = 'Reject',

        /** 转交 */
        Forward = 'Forward',

        /** 作废流程 */
        CancelInstance = 'CancelInstance',

        /** 撤回流程 */
        RetrieveInstance = 'RetrieveInstance',

        /** 退回 */
        ForBack = 'ForBack',

        /** 删除 */
        Remove = 'Remove',

        /** 加签 */
        Revise = 'Revise',

        /** 已阅 */
        Read = 'Read',

        /** TODO 未知 */
        ViewInstance = 'ViewInstance',

        /** 关闭表单 */
        Close = 'Close',
    }

    /** 表单加签操作编码 */
    export enum FormReviseActionCode {
        /** 前加签 */
        ForComment = 'ForComment',

        /** 后加签 */
        TempAddition = 'TempAddition',
    }

    /** 表单自定义操作编码 */
    export type FormCustomActionCode = string;

    /** 表单操作编码类型 */
    export type FormActionCode = FormSystemActionCode | FormCustomActionCode;

    /** 表单操作按钮 */
    export type FormAction = {
        /** 按钮编码 */
        action: FormActionCode;

        /** 按钮名称 */
        text: string;

        /** 按钮图标 */
        icon: string;

        /** 按钮排序key */
        sortKey: number;

        /** 按钮类型（不要用这个字段） */
        actionType: ActionType;

        /** 流程按钮配置 */
        commentConfig?: {
            items: CommentConfig[];
        };

        /** 自定义按钮执行配置 */
        executeConfig?: CustomActionExecuteConfig;

        /** 样式类型 */
        styleType?: ActionStyleType;
    };

    /** 按钮类型 */
    export enum ActionType {
        /** 系统按钮 */
        System = 0,

        /** 自定义按钮 */
        Custom = 1,
    }

    /** 按钮样式类型 */
    export enum ActionStyleType {
        Default = 'default',

        Primary = 'primary',

        Danger = 'danger',
    }

    /** 自定义按钮执行配置 */
    export type CustomActionExecuteConfig = {
        /** 执行类型 */
        executeContent: CustomActionExecuteType;

        /** 自动化id */
        automationTemplateId: string;

        /** 自动化二次确认信息 */
        confirmInfo?: TConfirmInfo;
        submitRule?: {
            state: boolean;
            errorMessage: string;
            ruleString: string;
            rule: Filter.IFilterItem[][];
        }; // 数据校验
        associationSchemaCode?: string; // 新增关联表单编码
    };

    /** 审批配置 */
    export type CommentConfig = {
        /** 编码 */
        code: Workflow.ApproveCode;
        /** 名称 */
        name: string;
        /** 是否激活 */
        active: string;
        /** 是否必填 */
        require: boolean;
    };

    /** 自定义按钮执行类型 */
    export enum CustomActionExecuteType {
        /** 执行自动化 */
        Automation = 1,

        /** 仅自定义代码 */
        CustomCode = 2,
        /** 新增数据 */
        NewData = 4,
    }

    /** 自定义按钮自动化二次确认信息 */
    export type TConfirmInfo = {
        title: string;

        detail: string;
    };

    /** 表单容器模式 */
    export enum FormContainerMode {
        /** 指定容器 */
        Specified = 'Specified',

        /** 模态窗 */
        Modal = 'Modal',

        /** 抽屉弹窗 */
        Drawer = 'Drawer',
    }

    /** 表单扩展类型枚举 */
    export enum ExtensionTypes {
        /** 流程日志 */
        WorkflowLog = 'WorkflowLog',

        /** 评论 */
        Comment = 'Comment',

        /** 任务提醒 */
        Task = 'Task',

        /** 数据日志 */
        OperationLog = 'OperationLog',

        /** AI分析 */
        AIAnalysis = 'AIAnalysis',
    }

    /** 表单扩展信息数据结构 */
    export type ExtensionInfo = {
        /** 扩展编码 */
        type: ExtensionTypes;

        /** 扩展名称 */
        title: string;

        /** 扩展图标 */
        icon: string;
    };

    /** 表单样式布局枚举 */
    export enum FormStyleLayout {
        /** 未指定 */
        Unspecified = 0,

        /** 上下布局 */
        Vertical = 1,

        /** 左右布局 */
        Horizontal = 2,
    }

    export enum FormStyleTitleFontWeight {
        /** 400 */
        Normal = 400,

        /** 600 */
        Bold = 600,
    }

    /** 表单样式类型 */
    export type FormStyle = {
        /** 布局类型 */
        layoutType: FormStyleLayout;

        /** 标题字重 */
        titleFontWeight: FormStyleTitleFontWeight;

        /** 标题对齐方式 */
        titleAlign: 'left' | 'right';

        /** 标题宽度 */
        titleWidth: number;

        /** 输入框是否显示边框 */
        borderShow: boolean;
    };

    /** 公式编辑器入参值 */
    export type IFormulaEditorValue = {
        formula: string;
        editorText: string;
        editorMark: string;
    };

    /** 关联列表基本信息 */
    export type TAssociationListSummary = {
        /** 关联列表表单编码 */
        schemaCode: string;

        /** 关联列表表单名称 */
        displayName: string;

        /** 关联列表关联字段（指对应表单中关联当前表单的关联表单控件字段编码） */
        mappingProperty: string;

        /** 是否允许新增关联列表 */
        canCreate: boolean;

        /** 关联列表数据量 */
        count: number;
    };

    /** 子表行操作 */
    export enum GridViewRowEvt {
        Copy = 'copy',
        Clear = 'clear',
        AddPre = 'addPre',
        AddAfter = 'addAfter',
        Move = 'move',
        Remove = 'remove',
        Click = 'click',
    }

    export namespace FormDesigner {
        /** 表单设计器公共校验状态 */
        export enum ValidateStatus {
            Success = 'success',
            Warning = 'warning',
            Error = 'error',
        }

        /** 校验结果 */
        export type ValidateResult = {
            /** 校验状态 */
            status: ValidateStatus;

            /** 提示信息 */
            msg: string;
        };

        /** 设计器模式 */
        export enum Mode {
            /** 编辑模式 */
            Edit = 'Edit',
            /** 新增模式 */
            Create = 'Create',
        }

        /** 代码类型 */
        export enum CodeType {
            Js,
            Behind,
        }

        /** 表单样式设置终端类型 */
        export enum LayoutUserAgent {
            PC = 'pc',
            Mobile = 'mobile',
        }

        /** 控件操作错误状态(增删改) */
        export enum ControlVerifyStatus {
            /** 成功 */
            Success = 'success',
            /** 唯一 */
            OnlyOne = 'onlyOne',
            /** 超限 */
            UpperLimit = 'upperLimit',
            /** 子表不支持 */
            GridViewNoSupport = 'gridViewNoSupport',
            /** 标签页不支持 */
            TabNoSupport = 'tabNoSupport',
            /** 一行多列 */
            LayoutNoSupport = 'layoutNoSupport',
            /** 其他 */
            Other = 'other',
        }

        export enum FormVisible {
            /** 未指定 */
            Unspecified = 0,
            /** 全部可见 */
            All = 1,
            /** PC端可见 */
            PC = 2,
            /** 移动端可见 */
            Mobile = 3,
            /** 全部不可见 */
            None = 4,
        }

        /** 表单样式类型 */
        export interface ILayout {
            /** 布局类型(1:上下 2:左右) */
            Type: FormStyleLayout;
            /** 标题字体粗细 */
            TitleFontWeight: FormStyleTitleFontWeight;
            /** 标题宽度 */
            TitleWidth: number;
            /** 标题居中 */
            TitleAlign: 'left' | 'right';
            /** 是否显示边框 */
            BorderShow: boolean;
        }

        /** 表单类型 */
        export interface IFormAttribute {
            /** 表单相关属性 */
            functionNode: {
                /** PC端显示设置(新增) */
                pcVisible: boolean;
                /** 移动端显示设置(新增) */
                mobileVisible: boolean;
                /** 表单名称 */
                displayName: string;
                /** 表单图标 */
                icon: string;
                /** 表单类型 */
                nodeType: number;
            };
            /** 布局 */
            layout: {
                pc: FormStyle;
                mobile: FormStyle;
            };
            parentCode: string;
            /** 表单控件最大限度 */
            controlSizeLimit: number;
            /** 是否开启评论 */
            enableFormSNS: boolean;
            /** 是否开启提醒 */
            enableTask: boolean;
            /** 是否开启日志 */
            enableDataLog: boolean;
            /** 是否启用数据权限 */
            enableDataAcl: boolean;
            /** 数据标题 */
            nameSchema?: string;
            /** 表单编码 */
            schemaCode?: string;
            /** 树形展示 */
            isTreeStructure?: boolean;
            /** 树形编码 */
            treeStructureControlCode?: string;
        }

        /** 表单设计器配置项类型 */
        export interface IDesignConfigOption {
            /** 名称 */
            Name: IControlAttributeKey | IFormAttributeKey;
            /** 值 */
            Text?: string;
            /** 描述 */
            Instruct?: string;
            /** 取值范围 */
            ValueRange?: ITextVal[];
            /** 是否隐藏 */
            Hide?: boolean;
            /** 是否编辑 */
            Disabled?: boolean;
            /** 是否有外层包裹 */
            Layout?: boolean;
            /** 不存在时取Text(位置不同) */
            Label?: string;
            /** 提示 */
            Tip?: string;
            /** 组件提示 */
            HelpTip?: string;
            /** 底部提示 */
            BottomTip?: string;
            /** 右边提示 */
            ToolTip?: string;
            /** 解释 */
            Explain?: string;
            /** 是否必填 */
            Required?: boolean;
            /** 默认值(用于更改config的值而不涉及元数据,目前Layout是否是PC/Mobile) */
            DefaultValue?: unknown;
        }

        /** 表单属性key */
        export enum IFormAttributeKey {
            /** 表单编码 */
            FormDataField = 'FormDataField',
            /** 表单名称 */
            FormDisplayName = 'FormDisplayName',
            /** 显示设置 */
            Visible = 'Visible',
            /** 应用编码 */
            AppCode = 'appCode',
            /** 表单编码 */
            SchemaCode = 'schemaCode',
            /** 表单名称 */
            DisplayName = 'displayName',
            /** 应用信息 */
            FunctionNode = 'node',
            /** 布局 */
            Layout = 'layout',
            /** 单表模式 */
            NodeType = 'nodeType',
            /** 所属分组 */
            ParentCode = 'parentCode',
            /** 表单控件最大限度 */
            FormCapacity = 'controlSizeLimit',
            /** 是否开启评论 */
            EnableFormSNS = 'enableFormSNS',
            /** 是否开启提醒 */
            EnableTask = 'enableTask',
            /** 是否开启日志 */
            EnableLog = 'enableDataLog',
            /** 是否启用数据权限 */
            EnableDataAcl = 'enableDataAcl',
            /** 数据标题 */
            NameSchema = 'nameSchema',
            /** 表单图标 */
            Icon = 'icon',
            /** PC端显示隐藏 */
            PCVisible = 'pcVisible',
            /** 移动端显示隐藏 */
            MobileVisible = 'mobileVisible',
            /** 终端类型 */
            LayoutUserAgent = 'LayoutUserAgent',
            /** 标题样式 */
            LayoutTitleStyle = 'LayoutTitleStyle',
            /** 字段布局 */
            FormFieldLayout = 'layoutType',
            /** 标题字体粗细 */
            TitleFontWeight = 'titleFontWeight',
            /** 标题居中 */
            TitleAlign = 'titleAlign',
            /** 标题宽度 */
            TitleWidth = 'titleWidth',
            /** 是否显示边框 */
            BorderShow = 'borderShow',
            /** 控件容量 */
            ControlCapacity = 'capacity',
            /** 是否开启树形展示 */
            IsTreeStructure = 'isTreeStructure',
            /** 关联表单中用于构建树型结构的关联表单控件编码，要求这个关联表单控件必须是自关联的 */
            TreeStructureControlCode = 'treeStructureControlCode',
        }

        /** 控件属性key */
        export enum IControlAttributeKey {
            /** 控件字段编码 */
            DataField = 'DataField',
            /** 显示名称 */
            DisplayName = 'DisplayName',
            /** 描述 */
            Description = 'Description',
            /** 控件类型关键字 */
            ControlKey = 'ControlKey',
            /** 隐藏规则 */
            DisplayRule = 'DisplayRule',
            /** 提示语 */
            PlaceHolder = 'PlaceHolder',
            /** 计算规则 */
            ComputationRule = 'ComputationRule',
            /** 默认值设置方式 1: 数据联动 2: 计算公式 */
            DefaultValue = 'DefaultValue',
            /** 数据联动 */
            DataLink = 'DataLink',
            DataLinkSchemaCode = 'DataLinkSchemaCode',
            DataLinkSchema = 'DataLinkSchema',
            DataLinkCondition = 'DataLinkCondition',
            DataLinkResult = 'DataLinkResult',
            /** 控件转换(与ControlKey保持一致 用于实例化新的控件类型) */
            TransferItems = 'TransferItems',
            /** 控件转换(用于人员转化部门) */
            TransferType = 'TransferType',
            /** 文本格式 Normal: 普通文本 Email: 邮箱 Card: 身份证 Telephone: 固话/手机 */
            Mode = 'Mode',
            /** 允许扫码输入 */
            InputByScan = 'InputByScan',
            /** 可修改扫码结果 */
            ScanUpdateEnable = 'ScanUpdateEnable',
            /** 是否允许重复录入 */
            NoRepeat = 'NoRepeat',
            /** 提示信息 */
            NoRepeatTipMessage = 'NoRepeatTipMessage',
            /** 行数 */
            Rows = 'Rows',
            /** 显示格式(日期) */
            DateTimeMode = 'DateTimeMode',
            /** 格式(数字) */
            DataFormatType = 'DataFormatType',
            /** 小数位数 */
            DecimalPlaces = 'DecimalPlaces',
            /** 是否显示千分位 */
            ShowMode = 'ShowMode',
            /** 是否条形图显示 */
            ShowBarChart = 'ShowBarChart',
            /** 显示百分比 */
            Percentage = 'Percentage',
            /** 移动端显示模式 */
            SelectShowMode = 'SelectShowMode',
            /** 输入范围限定 */
            RangeRule = 'RangeRule',
            /** 选项 */
            DefaultItems = 'DefaultItems',
            /** 彩色 */
            ColorSwitch = 'ColorSwitch',
            /** 背景色 */
            ItemColors = 'ItemColors',
            /** 数据来源 */
            DataSource = 'DataSource',
            /** 关联表单编码 */
            BOSchemaCode = 'BOSchemaCode',
            /** 关联表单层级关系 */
            BOSchemaInfo = 'BOSchemaInfo',
            /** 将根据以下表单字段生成选项 */
            SchemaField = 'SchemaField',
            /** 关联携带字段 */
            MappingField = 'MappingField',
            /** 关联表单筛选条件 */
            AssociationFilter = 'AssociationFilter',
            /** 文件限制大小 */
            MaxUploadSize = 'MaxUploadSize',
            /** 图片格式 */
            PhotoMode = 'PhotoMode',
            /** 是否支持上传多张图片 */
            UploadMultiple = 'UploadMultiple',
            /** 是否仅允许拍照 */
            CameraOnly = 'CameraOnly',
            /** 是否有水印 */
            HasWatermark = 'HasWatermark',
            /** 是否自动压缩 */
            Compression = 'Compression',
            /** 默认值设置方式 */
            DefaultValueRuleType = 'DefaultValueRuleType',
            /** 默认值 */
            DefaultValueRule = 'DefaultValueRule',
            /** 格式(地址) */
            AreaMode = 'AreaMode',
            /** 显示详细地址 */
            ShowDetailAddr = 'ShowDetailAddr',
            /** 位置范围 */
            Range = 'Range',
            /** 仅以下人员可被选择 */
            UnitSelectionRange = 'UnitSelectionRange',
            /** 人员信息填充 */
            MappingControls = 'MappingControls',
            /** 允许控件所选人员查看数据 */
            IsRelatedMember = 'IsRelatedMember',
            /** 标题 */
            Title = 'Title',
            /** 对齐 */
            Alignment = 'Alignment',
            /** 布局 */
            Layout = 'Layout',
            /** 占比 */
            PercentageBlock = 'PercentageBlock',
            /** 描述 */
            Content = 'Content',
            /** 数据标题 */
            NameItems = 'NameItems',
            /** 子表字段 */
            GridViewFields = 'GridViewFields',
            /** 冻结列 */
            ShowFixedCol = 'ShowFixedCol',
            /** 冻结列数 */
            FixedColNum = 'FixedColNum',
            /** 批量扫码 */
            BatchScan = 'BatchScan',
            /** 移动端显示模式 */
            MobileGridViewMode = 'MobileGridViewMode',
            /** 行高 */
            RowHeight = 'RowHeight',
            /** 标签页 */
            Tab = 'Tab',
            /** 选中标签页 */
            SelectedTabKey = 'SelectedTabKey',
            /** 流水号规则 */
            SeqNoStructure = 'SeqNoStructure',
            /** 最大自增位数 */
            IncrementNumber = 'IncrementNumber',
            /** 生成流水号 */
            GenerateHistorySeqNo = 'GenerateHistorySeqNo',
            /** 关联表单字段 */
            BOSchemaField = 'BOSchemaField',
            /** 作为关联表单搜索项 */
            AsFilter = 'AsFilter',
            /** 类型(公式型控件) */
            BindType = 'BindType',
            /** 扩展配置 */
            AdvancedSetting = 'AdvancedSetting',
            /** 允许复用签名 */
            AllowReuse = 'AllowReuse',
            /** 允许上传签名 */
            AllowUpload = 'AllowUpload',
            /** 识别类型 */
            IdentifyType = 'IdentifyType',
            /** 类型 */
            ServiceValue = 'serviceValue',
            /** 服务信息 */
            ServiceInfo = 'serviceInfo',
            /** 人员库 */
            GroupValue = 'groupValue',
            /** 人员库 */
            GroupInfo = 'groupInfo',
            /** 人员库 */
            FacePhoto = 'facePhoto',
            /** 动态检测 */
            DynamicDetection = 'dynamicDetection',
            /** 录入人员库 */
            FaceEnterLib = 'FaceEnterLib',
            /** 搜索人员库 */
            FaceSearchLib = 'FaceSearchLib',
            /** 人脸照片 */
            FacePhoneLib = 'FacePhoneLib',
            /** 显示字段 */
            AssociationFields = 'AssociationFields',
            /** 移动端显示模式(兼容数据) */
            GridViewMode = 'GridViewMode',
            /** 是否验真(文字识别) */
            IsVerify = 'IsVerify',
            /** 发起签署配置 */
            StartSetting = 'StartSetting',
            /** 签署状态同步配置 */
            SyncSetting = 'SyncSetting',
            /** 汇总计算规则 */
            RollupSettings = 'RollupSettings',
            /** 汇总计算汇总类型 */
            RollupBindType = 'RollupBindType',
            /** 关联表单子表关联条件过滤 */
            MappingControlsGridFilter = 'MappingControlsGridFilter',
            /** 关联表单关联属性 */
            MappingProperties = 'MappingProperties',
            /** 公式型控件类型 */
            FormulaBindType = 'FormulaBindType',
            /** 关联属性中对应的关联表单字段 */
            AssociationTargetField = 'AssociationTargetField',
            AssociateControlKey = 'AssociateControlKey',
            SourceType = 'SourceType',
            ParentSchemaCode = 'ParentSchemaCode',
            /** 仅展示(rollup控件) */
            Referenceable = 'Referenceable',
            /** 选择组件 */
            Component = 'Component',
            /** 人员visible */
            UserVisible = 'UserVisible',
            /** 部门visible */
            OrgUnitVisible = 'OrgUnitVisible',
            /** 条件样式 */
            StyleRule = 'StyleRule',
            /** 字段默认宽度(子表属性) */
            DefaultWidthFields = 'DefaultWidthFields',
        }

        /** 表单属性key对象 */
        export type IFormAttributeInKey = {
            [key in IFormAttributeKey]?: unknown;
        };

        /** 控件属性key对象 */
        export type IControlAttributeInKey = {
            [key in IControlAttributeKey]?: unknown;
        };

        export type IAttributeInKey = IFormAttributeInKey | IControlAttributeInKey;

        interface ITextVal {
            Val: string | number | boolean;
            Text: unknown;
        }

        export type IControlSetting = {
            key: string;
            dataField: string;
            options: Record<string, unknown>;
        };

        export namespace Request {
            export interface ILoadFormModel {
                schemaCode: string;
            }

            export interface ISaveFormModel {
                /** 引擎编码 */
                engineCode: string;
                /** 应用编码 */
                appCode: string;
                /** 父级编码 */
                parentCode?: string;
                /** 表单编码 */
                schemaCode?: string;
                /** 表单名称 */
                displayName: string;
                /** 表单图标 */
                icon: string;
                /** 表单类型 */
                nodeType: number;
                /** 是否开启评论 */
                enableFormSNS: boolean;
                /** 是否开启提醒 */
                enableTask: boolean;
                /** 是否开启日志 */
                enableDataLog: boolean;
                /** 是否启用数据权限 */
                enableDataAcl: boolean;
                /** 数据标题 */
                nameSchema: string;
                /** PC端布局样式 */
                pcLayout: FormStyle;
                /** 移动端布局样式 */
                mobileLayout: FormStyle;
                /** 前端自定义代码 */
                jsCode?: string;
                /** 后端自定义代码 */
                behindCode?: string;
                /** 表单是否可见 */
                nodeVisible: FormVisible;
                /** 关联填充 */
                associationMappings: Record<string, unknown>;
                /** 控件配置 */
                controlSettings: IControlSetting[];
                /** 表单控件 */
                formControls: Control.IControlLayout[];
            }

            export interface IAssociationFieldModel {
                /** 表单编码 */
                schemaCode: string;
                /** 关联表单控件编码 */
                dataField: string;
                /** 关联表单编码 */
                associationSchemaCode: string;
            }

            export type TFormulaExpressionValidateParams = {
                /** 类型 */
                type: string;
                /** 表单编码 */
                schemaCode?: string;
                /** 字段编码 */
                dataField: string;
                /** 表达式 */
                expression: string;
                /** 关联表单筛选场景关联表单编码 */
                associationSchemaCode?: string;
                /** 公式型控件是否开启了数据引用 */
                referenceable?: boolean;
            };

            export interface IRollupValidateParams {
                SchemaCode: string;
                PropertyName: string;
                PropertyDisplayName: string;
                MainSchemaCode: string;
                RollupSettings: unknown[];
            }

            export interface IValidateReferenceableParams {
                SchemaCode: string;
                PropertyName: string;
                Formula: string;
                Referenceable: boolean;
            }

            export interface IGenerateHistorySeqNoParams {
                SchemaCode: string;
                ToUpdateCount: number;
            }
        }

        export namespace Response {
            export interface ILoadFormModel extends IFormAttribute {
                /** 表单设计器控件属性数据(FormLayout) */
                designContent: string | null;
                /** 表单设计器控件配置数据 */
                controlSettings: IControlSetting[];
            }

            export interface ISaveFormModel {
                schemaCode: string;
                hasAsyncTask: boolean;
            }

            export enum BusinessRulePropertyNameType {
                /** 公式控件 */
                Formula = 1,
                /** 汇总控件 */
                Rollup = 2,
            }

            export type TFormulaExpressionValidateModel = {
                validatePass: boolean;
                /** 错误信息 */
                errorMessage: string;
                businessRuleErrors: {
                    businessRulePropertyName: string;
                    formulaPropertyName: string;
                    /** 1.公式控件 2.汇总控件 */
                    businessRulePropertyNameType: BusinessRulePropertyNameType;
                }[];
            };
        }
    }

    /** 表单外链相关 */
    export namespace External {
        /** 表单外链类型 */
        export enum ExternalType {
            /** 外链填单 */
            ExternalForm = 'ExternalForm',

            /** 外链分享 */
            ExternalShare = 'ExternalShare',

            /** 公开查询 */
            OpenQuery = 'OpenQuery',
        }

        /** 外链填单主题枚举 */
        export enum ExternalFormTheme {
            /** 默认主题 */
            Default = '0',

            /** 主题一 */
            One = '1',

            /** 主题二 */
            Two = '2',

            /** 主题三 */
            Three = '3',

            /** 主题四 */
            Four = '4',

            /** 主题五 */
            Five = '5',
        }

        /** 身份验证类型 */
        export enum IdentityMode {
            /** 手机号验证 */
            phoneNumber = 0,
        }

        /** 公开查询公开级别 */
        export enum OpenQueryAccessLevel {
            /** 完全公开 */
            Public = 0,

            /** 凭密码查询 */
            Password = 1,

            /** 凭身份识别 */
            Identity = 2,
        }

        /** 外链相关请求响应 */
        export namespace Response {
            /** 外链配置 */
            export type TExternalSetting<T extends ExternalType> = {
                /** 外链类型 */
                type: ExternalType;

                /** 是否开启 */
                enable: boolean;

                /** 短链 */
                shortUrl: string;

                /** 字段权限 */
                controls: Array<{
                    /** 字段完整编码 */
                    code: string;

                    /** 字段数据类型 */
                    dataType: Control.BizDataType;

                    /** 字段完整名称 */
                    displayName: string;

                    /** 是否支持设置 */
                    disableSetting: boolean;

                    /** 是否可见 */
                    visible: boolean;

                    /** 是否可编辑 */
                    editable: boolean;

                    /** 是否必填 */
                    required: boolean;
                }>;

                /** 额外配置，取决于不同的外链类型 */
                setting: TExternalSettings[T];
            };

            /** 外链填单配置 */
            export type TExternalFormSetting = {
                /** 主题 */
                theme: ExternalFormTheme;

                /** 主题自定义标题 */
                title: string;

                /** 主题自定义描述 */
                description: string;

                /** 公司名称 */
                companyName: string;

                /** 是否开启外链查看（填单后查看） */
                enableView: boolean;

                /** 是否启用身份验证 */
                enableIdentityRecognize: boolean;

                /** 身份验证类型 */
                identityMode: IdentityMode;

                /** 短信余量 */
                smsAllowance: number;

                /** 外链地址域名 */
                hostAddress: string;
            };

            /** 公开查询配置 */
            export type TOpenQuerySetting = {
                /** 标题 */
                title: string;

                /** 筛选字段（字段编码列表） */
                queryColumns: string[];

                /** 公开级别 */
                accessLevel: OpenQueryAccessLevel;

                /** 凭密码查询时设置的密码 */
                passWord: string;

                /** 凭身份识别时的身份验证类型 */
                identityMode: IdentityMode;
            };

            /** 外链类型到外链配置映射 */
            export type TExternalSettings = {
                [ExternalType.ExternalForm]: TExternalFormSetting;
                [ExternalType.ExternalShare]: undefined;
                [ExternalType.OpenQuery]: TOpenQuerySetting;
            };
        }
    }

    export namespace Request {
        /** 加载表单数据接口参数模型 */
        export type TLoadFormDataParams = {
            /** 表单编码 */
            schemaCode: string;

            /** 业务数据id（新增时不传） */
            bizObjectId?: string;

            /** 流程工作项id（不传则由后端判断应该返回哪个工作项） */
            workItemId?: string;

            /** 是否外链填单 */
            isExternalForm: boolean;

            /** 是否外链分享 */
            isExternalShare: boolean;

            /** 是否公开查询 */
            isOpenQuery: boolean;

            /** 是否打开编辑态表单（仅对已生效数据有效） */
            isEditable: boolean;
        };

        /** 表单执行按钮操作接口参数模型 */
        export type TDoActionParams = {
            /** 操作类型 */
            actionName: FormActionCode;

            /** 表单编码 */
            schemaCode: string;

            /** 业务数据id */
            bizObjectId: string;

            /** 流程版本 */
            workflowVersion?: number;

            /** 流程实例id */
            workflowInstanceId?: string;

            /** 当前流程工作项id */
            workItemId?: string;

            /** 表单当前模式 */
            mode: FormMode;

            /** 是否外链填单 */
            isExternalForm: boolean;

            /** 是否外链分享 */
            isExternalShare: boolean;

            /** 流程退回意见 */
            backwardComment?: string;

            /** 提交数据 */
            postValue?: {
                /** 操作类型 */
                command: FormActionCode;

                /** 所有都传空字符串 */
                destActivityCode?: string;

                /** 含义由具体操作决定 */
                data: Record<string, unknown>;

                /** 含义由具体操作决定 */
                comment?: Record<string, unknown>;

                /** 没有操作传了 */
                activityParticipants?: Record<string, unknown>;

                /** 转交目标人员 */
                forwardTo?: string;
            };

            /** 自定义参数，场景是自定义代码在自定义按钮中添加Datas字段 */
            param?: unknown;

            /** 自定义参数，场景是自定义代码提交数据 */
            customData?: Record<string, unknown>;

            /** 请求id，场景时提交较慢时轮询当前操作进度，优化提示 */
            requestId?: string;
        };

        export type THandSignLinkParams = {
            /** 表单编码 */
            schemaCode: string;
            /** 字段编码 */
            propertyName: string;
            /** 业务数据Id */
            bizObjectId: string;
            /** 活动节点编码 */
            activityCode: string;
        };

        export type TSaveHandSignParams = {
            /** 表单编码 */
            schemaCode: string;
            /** 字段编码 */
            propertyName: string;
            /** 保存手写签名的文件id */
            fileId: string;
            /** 业务数据Id */
            bizObjectId: string;
        };

        export type TBindHandSignParams = {
            /** 用户id */
            userId: string;
            /** 文件id */
            fileId: string;
        };
    }

    export namespace Response {
        /** 加载表单配置接口响应模型 */
        export type TLoadFormSettingModal = {
            /** 前端自定义代码 */
            javascript: string;

            /** 控件布局信息（实际是序列化后的IControlLayout数组） */
            formContent: string;

            /** 1.0表单控件布局信息（1.0是一段html，需要前端解析） */
            runtimeContent: string;

            /** 最后一次修改的时间戳，用于前端缓存 */
            timestamp: number;

            /** 是否开启表单评论 */
            enableFormSNS: boolean;

            /** 是否开启任务提醒 */
            enableTask: boolean;

            /** 是否开启操作日志 */
            enableDataLog: boolean;

            /** 树形编码 */
            treeStructureControlCode: string;

            /** PC端布局样式 */
            pcLayout: FormStyle;

            /** 移动端布局样式 */
            mobileLayout: FormStyle;
        };

        /** 加载表单数据接口响应模型 */
        export type TLoadFormDataModal = {
            /** 数据标题 */
            name: string;

            /** 是否显示复制按钮 */
            enableCopy: boolean;

            /**
             * 表单模式
             * 这里有一个很扯的逻辑，理论上mode取值只有create、edit、view，表示当前表单是创建、编辑还是查看模式
             * 然而不知道为了什么需求，当没有字段可写时，返回的是readonly模式，使得这个字段语义变得很奇怪
             * 实际逻辑应该由后端来梳理
             */
            mode: FormMode;

            /** 是否创建模式 */
            isCreateMode: boolean;

            /** 响应上下文 */
            responseContext: {
                /** 流程版本号 */
                workflowVersion: number;

                /** 流程状态 */
                workflowState: Workflow.WorkflowStatus;

                /** 最终审批状态 */
                approvalState: Workflow.ApprovalStatus;

                /** 当前流程节点编码 */
                activityCode: string;

                /** 当前流程实例id */
                instanceId: string;

                /** 发起人id */
                originator: string;

                /** 发起人code（这是什么） */
                originatorCode: string;

                /** 发起人部门id */
                originatorParentId: string;

                /** 表单按钮 */
                actions: FormAction[];

                /** 字段值与权限 */
                returnData: Record<
                    string,
                    {
                        /** 字段值 */
                        value: unknown;

                        /** 是否可见 */
                        visible: boolean;

                        /** 是否可编辑 */
                        editable: boolean;

                        /** 是否必填 */
                        required: boolean;

                        /** 是否可打印 */
                        printable: boolean;
                    }
                >;

                /** 流程所有工作项 */
                workItems: Workflow.WorkItemInfo[];

                /** 当前流程工作项id */
                workItemId: string;

                /** 流程审批记录 */
                comments: Workflow.CommentInfo[];

                /** 撤回类型 */
                retrieveType: Workflow.RetrieveType;

                /** 是否是未阅节点 */
                isUnfinishedCirculate: boolean;

                /** 调试数据 */
                debugTrack: CustomCode.IDebugTrack | null;

                /** 是否有关联列表 */
                hasAssociationList: boolean;

                /** 默认打印配置 */
                printConfig: Print.TDefaultPrintConfig;

                /** 是否开启外链分享 */
                enableFormExternalShare: boolean;

                /** 是否成功 */
                successful: boolean;

                /** 自定义提示信息 */
                message: string;

                /** 自定义提示信息 */
                infos: string[];

                /** 自定义错误信息 */
                errors: string[];

                /**
                 * 表单模式
                 * 这个字段存在的背景是，在2.0中后端也返回了两个mode，且在特定场景下，这两个mode值并不一样
                 * 目前这个字段的作用是用于表单操作时请求接口
                 */
                requestMode: FormMode;
            };
        };

        /** 关联列表视图配置 */
        export type TAssociationListView = {
            StartFormResponse: unknown;
        };

        /** 关联列表列配置 */
        export type TAssociationListColumn = {
            /** 字段编码 */
            propertyName: string;

            /** 字段名称 */
            displayName: string;

            /** 字段是否可见 */
            visible: boolean;

            /** 是否子表字段 */
            isChild: boolean;

            /** 字段业务数据类型 */
            bizDataType: Control.BizDataType;
        };

        /** 关联表单列配置 */
        export type TAssociationColumn = {
            /** 字段名称 */
            propertyName: string;

            /** 是否可见 */
            visible: boolean;

            /** 是否子表 */
            isChild: boolean;

            /** 子表内列配置 */
            childColumns: TAssociationColumn[];
        };

        /** 表单执行按钮操作接口响应模型 */
        export type TDoActionModal = {
            StartFormResponse: {
                /** 流程实例id */
                instanceId: string;

                /** 流程实例名称（实际应该是数据标题） */
                instanceName: string;

                /** 当前流程工作项id */
                workItemId: string;

                /** 业务数据id */
                bizObjectId: string;

                /** 表单functionNode类型 */
                nodeType: FunctionNode.FunctionNodeType;

                /** 是否关闭当前表单 */
                closePage: boolean;

                /** 是否刷新当前表单 */
                refresh: boolean;

                /** 返回数据 */
                returnData: Record<string, unknown>;

                /** 请求是否成功 */
                successful: boolean;

                /** 自定义提示信息 */
                message: string;

                /** 自定义提示信息 */
                infos: string[];

                /** 自定义错误信息 */
                errors: string[];

                /** 自定义错误信息详情 */
                details: string[];

                /** 自定义错误信息 */
                debugTrack: CustomCode.IDebugTrack | null;

                /** 操作类型 */
                actionName: unknown;

                /** 不知道是什么url */
                url: string;

                /** 不知道是什么地址 */
                externalFormUrl: string;
            };
        };

        /** 加载表单数据接口响应中的文件类型控件字段值数据结构 */
        export type TLoadDataFile = {
            /** 文件编码 */
            code: string;

            /** 文件描述 */
            description?: string;

            /** 文件名称 */
            name: string;

            /** 文件大小 */
            size: number;

            /** 文件缩略图地址 */
            thumbnailUrl: string;

            /** 文件地址 */
            url: string;

            /** 文件排序key */
            sortKey: number;

            /** 复制出来的文件对应的源文件的uid */
            sourceId?: string;

            /** 文件类型 */
            contentType?: string;
        };

        /** 加载表单数据接口响应中的关联表单和关联多选字段值数据结构 */
        export type TLoadDataBo = {
            /** 业务数据id */
            id: string;

            /** 数据标题 */
            name: string;
        };
    }

    export namespace TaskTip {
        // 新增任务提醒
        export type TTaskTipAddParams = {
            schemaCode: string;
            bizObjectId: string;
            title: string;
            content: string;
            assignUserId: string;
            startTime: string;
            alertTime: string;
            tipType: number;
        };

        // 任务提醒
        export interface ITaskTip {
            objectId: string;
            sender: string;
            userId: string;
            userName: string;
            entryId?: string;
            name: string;
            summary: string;
            alertTime: string;
            taskState: number;
        }
    }

    export namespace SNS {
        export interface ISNSFileItem {
            objectId: string;
            postId: string;
            contentType: string;
            fileName: string;
            thumbnailUrl: string;
            contentLength: number;
            downloadUrl?: string;
        }
        export interface ISNSReplyItem {
            objectId: string;
            formatCreatedTime: string;
            profilePhotoUrl: string;
            name: string;
            text: string;
            userId: string;
            replyToUser: string;
            isMyComment: boolean;
            entryType: string;
            entryId: string;
        }
        export interface ISNSItem {
            objectId: string;
            userId: string;
            userName: string;
            createdTime: string;
            entryId: string;
            avatar: string;
            text: string;
            notificationContent: string;
            attachments: ISNSFileItem[];
            replies: ISNSReplyItem[];
        }
        export interface ISNSCount {
            snsCount: number;
        }

        // 新增评论请求参数
        export type IAddSNSItemParams = {
            schemaCode: string;
            bizObjectId: string;
            content: string;
            text: string;
            userId: string;
            attachmentIds: string[];
            atUserIds: string[];
        };

        export type IGetAtUserParams = {
            schemaCode: string;
            bizObjectId: string;
            searchKey: string;
            pageIndex: number;
            pageSize: number;
        };

        export interface ISNSAtInfo {
            userId: string;
            displayName: string;
            icon: string;
            entryId: string;
        }
        export namespace Response {
            export interface ISNSAddItem {
                postId: string;
            }
            export interface ISNSAddComment {
                commentId: string;
            }
        }
    }

    export namespace FormPrint {
        export namespace Response {
            export interface IDiyPrint {
                actionId: string;

                queueCount: number;
            }
            export interface IPrintSetting {
                pageType: number;
                pageDirection: number;
                height: number;
                width: number;
                marginLeft: number;
                marginRight: number;
                marginTop: number;
                marginBottom: number;
                backGround: string;
                isPrintBackGround: boolean;
                isDownload: boolean;
                pageHeader: {
                    marginTop: number;
                    left: string;
                    center: string;
                    right: string;
                };
                pageFooter: {
                    marginBottom: number;
                    left: string;
                    center: string;
                    right: string;
                };
                printDocType: number;
                printHeadRepeatedly: boolean;
                activityType: string[];
                rowSpace: number;
                isNewTemplate: boolean;
            }
            export interface IFormPrintProgress {
                printProcess: number;
                printState: number;
                printErrorMsg: string | null;
                isDownload: boolean;
                ossUrl: string;
                printSetting: IPrintSetting;
            }
        }
    }
}
