import { H3 } from '@h3/types';
import { request } from '@h3/request';
import { Util } from '@h3/h3common';

const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/**
 * 加载消息提醒页面列表数据
 * @param taskState 消息类型
 * @param pageIndex 查询页码
 * @param pageSize 一个页面多少条
 * @return 消息列表数据
 */
export function getTaskListItems(taskState: number, pageIndex: number, pageSize: number) {
    return request<H3.Http.Response.H3ResponseV2<H3.Message.Response.ITaskListData>>('/FormTaskTips/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetTaskListItems',
                TaskState: taskState,
                PageIndex: pageIndex,
                PageSize: pageSize,
            }),
        },
        headers,
        requestType: 'form',
    });
}

/**
 * 标记所有的未读消息为已读
 */
export function readAllTask() {
    return request<H3.Http.Response.H3ResponseV2>('/FormTaskTips/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'ReadAllTask',
            }),
        },
        headers,
        requestType: 'form',
    });
}

/**
 * 标记一条消息为已读
 * @param taskId 消息/任务Id
 * @param userId 用户Id
 */
export function markAsRead(taskId: string, userId: string) {
    return request<H3.Http.Response.H3ResponseV2>('/FormTaskTips/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'ReadTask',
                TaskIds: taskId,
                UserId: userId,
            }),
        },
        headers,
        requestType: 'form',
    });
}
