import { View } from '../view';

export namespace Plugin {
    export enum ConnectionInvokeType {
        /** 普通 */
        Normal = 1,
        /** 代码调用 */
        CodeEditor = 2,
    }

    export enum PluginStatus {
        /** 未安装 */
        UnInstall = 0,
        /** 已安装 */
        Install = 1,
    }

    export enum ConnectionConfigStatus {
        /** 禁用 */
        Disable = 0,
        /** 正常 */
        Normal = 1,
        /** 异常 */
        Exception = 2,
    }

    export enum SmsTemplateStatus {
        /** 正常 */
        Normal = 1,
        /** 禁用 */
        Disable = 2,
    }

    export enum PluginsCodeEnum {
        /** 第三方连接 */
        Connection = 'connection',
        /** 钉钉OCR */
        DingTalkOcr = 'ocr',
        /** 邮件 */
        Email = 'email',
        /** 钉钉云盘 */
        DingtalkDrive = 'dingtalkdrive',
        /** 批量扫码 */
        BatchScan = 'batchscan',
        /** 钉钉通知 */
        DingTalkNotice = 'dingtalknotice',
        /** 消息发送至群聊 */
        RobotNotification = 'robotnotification',
        /** 人脸识别 */
        FaceRecognition = 'facerecognition',
        /** 电子签章 */
        Esign = 'esign',
        /** 电子签章通用版 */
        EsignCommon = 'commonesign',
        /** 钉钉连接器 */
        DingTalkConnector = 'dingtalkconnector',
        /** 短信 */
        Sms = 'sms',
        /** 在线支付 */
        Payment = 'payment',
        /** 钉钉考勤 */
        DingTalkAttendance = 'dingtalkattendance',
        /** 企微家校通讯录 */
        WxworkSchool = 'wxworkschool',
    }

    export enum EsignType {
        /** 通用版 */
        CommonEsign = 'commonesign',
        /** 钉签 */
        DingtalkEsign = 'esign',
    }

    export enum EsignFlowStatus {
        /** 未指定 */
        Unspecified = -9,
        /** 找不到E签宝任务 */
        NoFound = -2,
        /** 预提交(接口调用后尚未在e签宝发起) */
        PreCommit = -1,
        /** 草稿 */
        Draft = 0,
        /** 签署中 */
        Running = 1,
        /** 完成 */
        Finish = 2,
        /** 撤销 */
        Revoke = 3,
        /** 终止 */
        Abort = 4,
        /** 过期 */
        Expired = 5,
        /** 删除 */
        Delete = 6,
        /** 拒签 */
        Refuse = 7,
    }

    export const EsignFlowStatusText = {
        [EsignFlowStatus.Unspecified]: '未指定',
        [EsignFlowStatus.NoFound]: '未找到',
        [EsignFlowStatus.PreCommit]: '待发起',
        [EsignFlowStatus.Draft]: '草稿',
        [EsignFlowStatus.Running]: '签署中',
        [EsignFlowStatus.Finish]: '完成',
        [EsignFlowStatus.Revoke]: '撤销',
        [EsignFlowStatus.Abort]: '终止',
        [EsignFlowStatus.Expired]: '过期',
        [EsignFlowStatus.Delete]: '删除',
        [EsignFlowStatus.Refuse]: '拒签',
    };

    export enum EsignSignerStatus {
        Waiting = 0,
        Signing = 1,
        Complete = 2,
        WaitForApproval = 3,
        Refuse = 4,
    }

    /** 机器人类型 */
    export enum RobotType {
        DingTalk = 1,
        WeChat = 2,
        FeiShu = 3,
    }

    export const EsignSignerStatusText = {
        [EsignSignerStatus.Waiting]: '待签',
        [EsignSignerStatus.Signing]: '未签',
        [EsignSignerStatus.Complete]: '已签',
        [EsignSignerStatus.WaitForApproval]: '待审批',
        [EsignSignerStatus.Refuse]: '拒签',
    };

    export enum ConnectionPosition {
        /** query */
        Query = 1,
        /** header */
        Header = 2,
        /** body */
        Body = 3,
        /** response */
        Response = 4,
    }

    /** 插件 */
    export interface PluginsModel {
        objectId: string;
        name: string;
        code: PluginsCodeEnum;
        state: PluginStatus;
    }

    /** 连接 */
    export interface ConnectionConfigModel {
        objectId: string;
        title: string;
        engineCode: string;
        invokeType: ConnectionInvokeType;
        status: ConnectionConfigStatus;
        intro: string;
    }

    export interface ConnectionDetailField {
        objectId: string;
        bizSource: number;
        position: ConnectionPosition;
        title: string;
        dataType: number;
        fieldValue: string;
        sort: number;
        isToken: boolean;
    }

    /** 连接详情 */
    export interface ConnectionDetailModel {
        authenticationId: string;
        /** 同title */
        code: string;
        contenttype: string;
        /** 企业编码 */
        engineCode: string;
        /** 字段 */
        fields: ConnectionDetailField[];
        /** 调用类型 */
        invokeType: ConnectionInvokeType;
        /** id */
        objectId?: string;
        /** 请求方法 */
        requestMethod: string;
        /** 相应方式 */
        responseType: string;
        /** 状态 */
        status: ConnectionConfigStatus;
        /** 名称 */
        title: string;
        /** 地址 */
        url: string;
    }

    /** 短信模板 */
    export interface SmsTemplateModel {
        /** id */
        objectId: string;
        subject: string;
        sign: string;
        /** 创建时间 */
        createdTime: string;
        /** 企业编码 */
        engineCode: string;
        /** 状态 */
        status: SmsTemplateStatus;
    }

    export interface PluginInvokeModel {
        key: string;
        value: string | View.Response.TLoadDataFile[];
    }

    export interface EsignUserInfo {
        accountType: 'DING_USER' | 'OUTER_USER';
        account: string;
        accountName: string;
        orgName: string;
        userId: string;
        signRequirements?: string;
        ccRequirements?: string;
    }

    /** 发送机器人消息类型 */
    export enum RobotNotificationMessageType {
        /** 文本 */
        Text = 1,
        Link = 3,
    }

    export namespace Request {
        export interface EsignProcessStartUrlModel {
            files: Array<{ fileId: string; fileName: string; uploadUrl: string }>;
            participants: Array<EsignUserInfo>;
            ccs: Array<EsignUserInfo>;
            initiatorUserId: string;
            bizObjectId: string;
            schemaCode: string;
            dataField: string;
            redirectUrl: string;
            autoStart?: boolean;
            sourceInfo?: Record<string, unknown>;
            taskName?: string;
        }

        export interface EsignAuthInfoModel {
            engineCode: string;
            /** 用户Id */
            userId?: string;
            /** 是否是组织认证，为true不用传unitId */
            isOrgAuth: boolean;
        }

        /** 获取个人/组织认证地址 */
        export interface EsignAuthUrlModel {
            engineCode: string;
            /** 用户Id */
            userId?: string;
            companyName?: string;
            isOrgAuth: boolean;
        }

        /** 获取印章管理地址 */
        export interface EsignSealUrlModel {
            engineCode: string;
            userId: string;
        }

        /** 获取签署流程详情 */
        export interface EsignSignFlowDetailModel {
            engineCode: string;
            userId: string;
            /** 签署任务Id */
            taskId: string;
        }

        /** 获取个人签署地址 */
        export interface EsignSignUrlModel {
            engineCode: string;
            userId: string;
            /** 签署任务Id */
            taskId: string;
        }

        export interface EsignDocsModel {
            taskId: string;
            bizObjectId: string;
            schemaCode: string;
            dataField: string;
        }

        export interface SendRobotNotificationModel {
            robotType: RobotType;
            Webhook: string;
            EncryKey?: string;
            isAtAll: boolean;
            mobiles?: string[];
            /** 消息类型 1:文本 3:链接 */
            messageType?: RobotNotificationMessageType;
            textContent?: string;
            link?: {
                title: string;
                text: string;
                messageUrl: string;
            };
        }

        export interface VerifyRobotNotificationModel {
            webhook: string;
            secretKey: string;
            botType: RobotType;
        }
    }

    export namespace Response {
        export interface IFaceRecognitionPersonGroupInfo {
            groupId: string;
            groupName: string;
            /** 扩展字段 */
            groupExDescriptions: string[];
            description: string[];
        }

        export interface EsignAuthInfoModel {
            /** 实名状态 */
            realNameStatus: boolean;
            /** 授权状态 */
            authorizeStatus: boolean;
            /** 用户psnId */
            eSignId: string;
            companyName: string;
        }

        export interface EsignSignFlowDetailModel {
            status: string;
            participants: Array<{
                name: string;
                status: string;
            }>;
        }
    }
}
