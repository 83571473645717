/** 加载自定义代码 */
export const LoadCode = '/v1/codeeditor/loadcode';
/* 更新代码 */
export const UpdateCode = '/v1/codeeditor/sourcecode';
/* 更新行代码 */
export const UpdateLineCode = '/v1/codeeditor/line';
/* 获取智能提示 */
export const GetSense = '/v1/codeeditor/sense';
/* 添加按钮任务 */
export const AddTask = '/v1/bizlog/action/add';

/* 更新按钮状态 */
export const UpdateTaskStatus = '/v1/bizlog/action/state/update';

/* 更新按钮中多条数据状态 */
export const UpdateObjectsStatus = '/v1/bizlog/action/bizdata/state/update';

/* 查询按钮操作执行日志记录 */
export const QueryTaskLog = '/v1/toolkit/bizlog/action';
/* 查询按钮操作对应的业务数据执行日志记录 */
export const QueryObjectsLog = '/v1/toolkit/bizlog/action/bizobject';
