export default class AppMetaData {
    children: string[] | null = null;

    initChildren(children: string[]) {
        this.children = children;
    }

    /**
     * 添加子节点
     * @param children 要添加的子节点
     */
    addChildren(children: string) {
        this.children!.push(children);
    }

    /**
     * 删除节点
     * @param childCode 要删除的节点
     */
    removeChild(childCode: string) {
        const index = this.children!.findIndex((code) => code === childCode);
        if (index > -1) {
            this.children!.splice(index, 1);
        }
    }

    /**
     * 节点排序
     * @param sortedNodes 节点顺序
     */
    sortNodes(
        sortedNodes: Array<{
            code: string;
            parentCode: string;
        }>,
    ) {
        const result = sortedNodes.map((item) => item.code);
        this.initChildren(result);
    }
}
