import { H3 } from '@h3/types';
import { request } from '@h3/request';
import { Util } from '@h3/h3common';

/**
 * 获取当前路由信息
 * @returns string
 */
function getRootName() {
    const href = window.location.href;
    let path = href.split('?')[0];
    path = path.replace(`${window.location.origin}/`, '');
    return `/${path.split('/')[0]}`;
}

/**
 * 格式化异常上报数据
 * @param reportData
 * @param appName
 * @returns LogParams
 */
function formatData(params: H3.Log.Request.ILogInfo) {
    return {
        ...params,
        Route: getRootName(),
        UserAgent: window.navigator.userAgent,
    };
}

/** 上报日志 */
export function sendLog(params: H3.Log.Request.ILogInfo) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/log', {
        method: 'post',
        data: formatData(params),
    });
}

// 获取表单单条数据主表数据日志
export function queryDataLog(offset: number, limit: number, schemaCode: string, bizObjectId: string) {
    const data = {
        offset,
        limit,
        schemaCode,
        bizObjectId,
    };
    return request<H3.Http.Response.H3Response<H3.Log.BizData.TQueryLogResponse>>(
        '/v1/toolkit/bizlog/datalogs/detail',
        {
            method: 'post',
            data,
        },
    );
}

// 获取子表数据日志
export function queryChildDataLogs(params: {
    offset: number;
    limit: number;
    schemaCode: string;
    bizObjectId: string;
    childSchemaCode: string;
    actionId: string;
}) {
    return request<H3.Http.Response.H3Response<H3.Log.BizData.TQueryLogResponse>>(
        '/v1/toolkit/bizlog/datalogs/detail/child',
        {
            method: 'post',
            data: params,
        },
    );
}

// 获取数据日志的操作类型
export const getDataLogOperatorTypes = () => {
    return request<H3.Http.Response.H3Response<{ code: string; name: string }[]>>(
        '/v1/toolkit/bizlog/data/operationTypes',
        {
            method: 'get',
            data: {},
        },
    );
};

export const eventRecordLog = (appCode = '', schemaCode = '', eventCode: string, solutionCode = '') => {
    return request<H3.Http.Response.H3Response>('/h3/v1/eventLog/createEventLog', {
        method: 'post',
        data: {
            PostData: Util.JSON.stringify({
                AppCode: appCode,
                SchemaCode: schemaCode,
                EventCode: eventCode,
                SolutionCode: solutionCode,
                IsMobile: false,
            }),
        },
    });
};
