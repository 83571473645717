import request from '@h3/request';
import { H3 } from '@h3/types';

/**
 * 获取指定外链类型的配置
 * @param schemaCode 表单编码
 * @param type 外链类型
 * @returns 外链配置
 */
export function getExternalSetting<T extends H3.Form.External.ExternalType>(schemaCode: string, type: T) {
    return request<H3.Http.Response.H3ResponseV2<H3.Form.External.Response.TExternalSetting<T>>>(
        '/h3/v1/external/setting',
        {
            method: H3.Http.MethodType.GET,
            params: {
                schemaCode,
                type,
            },
        },
    );
}

/** 外链填单是否需要验证身份（不需要验证的场景包括没有配置需要验证或已经验证通过） */
export function externalFormIdentity(schemaCode: string) {
    return request<H3.Http.Response.H3ResponseV2<boolean>>('/h3/v1/external/identity', {
        method: H3.Http.MethodType.GET,
        params: {
            schemaCode,
        },
    });
}
