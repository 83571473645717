import { createPinia, defineStore } from 'pinia';
import { App } from 'vue';

/**
 * 创建一个状态机实例
 */
const store = createPinia();

export function setupStore(app: App<Element>) {
    app.use(store);
}

export { store, defineStore };
