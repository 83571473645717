import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 获取第三方连接 */
export const getConnectConfigList = (params: { engineCode: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectConfigList, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 新增第三方连接 */
export const addConnectConfig = (params: H3.Plugin.ConnectionDetailModel) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectConfigAdd, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 编辑更新第三方连接 */
export const updateConnectConfig = (params: H3.Plugin.ConnectionDetailModel) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectConfigUpdate, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 新增第三方连接(编码模式) */
export const addConnectCodingConfig = (params: { code: string; title: string; intro: string; url: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectCodingConfigAdd, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 编辑第三方连接(编码模式) */
export const updateConnectCodingConfig = (params: {
    objectId: string;
    code: string;
    title: string;
    intro: string;
    url: string;
}) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectCodingConfigUpdate, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 删除第三方连接 */
export const deleteConnectConfig = (params: { objectId: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectConfigDelete, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 获取第三方连接详情 */
export const getConnectConfigDetail = (params: { objectId: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionDetailModel>>(Api.ConnectConfigDetail, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 禁用启用第三方连接 */
export const setConnectConfigStatus = (params: { engineCode: string; objectId: string; status: 0 | 1 }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectConfigStatus, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 执行或者调试第三方连接 */
export const invokeConnectionConfig = (params: {
    objectId: string;
    fields: Array<{
        objectId: string;
        fieldValue: string;
    }>;
}) =>
    request<
        H3.Http.Response.H3ResponseV2Beta<
            H3.Util.JSONStringify<{ msg: string; success: boolean; code: number; data: Record<string, unknown> }>
        >
    >(Api.ConnectionConfigInvoke, {
        method: 'post',
        data: params,
        disableBizError: true,
    });

/** 查看当前连接的表单使用情况 */
export const getConnectionUsageList = (params: { objectId: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.ConnectionConfigModel[]>>(Api.ConnectionUsageList, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
