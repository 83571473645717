/** 根据id获取当前组织或下级（支持部门和角色） */
export const OrgChild = '/v1/org/currentandchild';

/** 加载部门下人员 */
export const OrgUserList = '/v1/org/user/list';

/** 加载部门或公司下子部门 */
export const OrgDepartmentList = '/v1/org/department/list';

/** 加载角色列表 */
export const OrgRoleList = '/v1/org/role/list';

/** 根据外部组织id取分组列表 */
export const ExternalOrgDepartmentList = '/v1/org/external/department/list';

/** 取人员详情 */
export const OrgUserInfo = '/v1/org/user';

/** 取部门详情 */
export const OrgDepartmentInfo = '/v1/org/department';

/** 搜索人员/部门 */
export const OrgSearch = '/v1/org/search';

/** 批量取组织机构单元，包括人员/部门/角色 */

export const OrgList = '/v1/org/list';

/** 人员/部门信息(视图点击人员部门-展示特殊信息) */
export const OrgInfo = '/v1/org/unit/info';

/** 外部组织列表 */
export const OrgAssociationList = '/v1/org/association/list';

export const CheckRange = '/v1/org/unit/checkRange';

/** 根据组织机构名称获取组织机构信息 */
export const GetOrgByNames = '/v1/org/search/byname';
