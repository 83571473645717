import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';
import * as Gantt from './gantt';

/**
 * 视图统一数据加载接口
 * @param params
 * @returns
 */
export const queryData = (params: H3.BizData.Request.IQueryBizDataModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataQueryResponse>>(Api.QueryViewBizDataUrl, {
        method: 'post',
        data: params,
    });
};

/**
 * 查询业务数据某列去重值
 * @param params
 * @returns
 */
export const queryBizDataDistinct = (params: H3.BizData.Request.IQueryBizDataDistinctUrlParams) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataDistinctQueryResponse>>(
        Api.QueryBizDataDistinctUrl,
        {
            method: 'post',
            data: params,
        },
    );
};

/**
 * 删除数据
 * @param params
 * @returns
 */
export const deleteBizData = (params: H3.BizData.Request.IDeleteFormatBizObjectModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.DeleteBizDataUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/**
 * 请求给定数据的短链信息
 * @param schemaCode 表单编码
 * @param bizObjectIds 要请求的数据objectId
 * @param isCreate 是否请求创建数据的链接
 * @param isExternal 是否请求外部链接
 * @param sortKey 排序字段
 * @param direction 排序方向
 * @returns 短链数据
 */
export const getBizDataShortLink = (
    schemaCode: string,
    bizObjectIds: string[],
    isCreate: boolean,
    isExternal: boolean,
    sortKey: string,
    direction: H3.View.SortDirection,
) => {
    const data: Record<string, unknown> = {
        schemaCode,
        isExternal,
        sortKey,
        direction,
    };

    if (isCreate) {
        data.isCreate = isCreate;
    } else {
        data.bizObjectIds = bizObjectIds;
    }

    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataShortLink[]>>(Api.GetBizDataShortLink, {
        method: H3.Http.MethodType.POST,
        data,
    });
};

/**
 * 通用的数据加载接口，排除视图数据加载
 */
export const queryCommonBizData = (
    params: H3.BizData.Request.ICommonQueryBizDataModel,
    options?: H3.Http.Request.RequestOptions,
) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataQueryResponse>>(Api.CommonQueryBizDataUrl, {
        method: 'post',
        data: params,
        ...options,
    });
};

/**
 * 支持去重加载数据
 * @param params 参数
 */
export const queryDataDistinct = (params: H3.BizData.Request.ICommonQueryBizDataModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IDataDistinctQueryResponse>>(
        '/v1/bizdata/property/distinct',
        {
            method: 'post',
            data: params,
        },
    );
};

/**
 * 表格视图统计列
 * @param params
 * @returns
 */
export const columnState = (params: H3.BizData.Request.IStatColumnModel) => {
    return request<H3.Http.Response.H3Response<H3.BizData.Response.IColumnStatResponse>>(Api.ColumnStat, {
        method: 'post',
        data: params,
    });
};

/**
 * 批量获取数据联动结果
 * @param schemaCode 目标表单编码
 * @param filters 筛选条件
 * @returns 数据联动结果
 */
export const batchGetDataLinkage = (schemaCode: string, filters: Record<string, Record<string, string>>) => {
    return request<H3.Http.Response.H3Response<Record<string, Record<string, unknown>>>>(
        '/v1/form/loaddatalink/batch',
        {
            method: H3.Http.MethodType.POST,
            data: {
                schemaCode,
                filter: JSON.stringify(filters),
            },
        },
    );
};

/**
 * 获取关联表单级联数据
 * @param params 参数
 * @returns 关联表单数据
 */
export const batchGetFormQueryBo = (params: H3.BizData.Request.IBatchGetFormQueryBoModel[]) => {
    return request<H3.Http.Response.H3Response<Record<string, Record<string, unknown>[]>>>(
        '/v1/form/associationlist/batch',
        {
            method: H3.Http.MethodType.POST,
            data: params,
        },
    );
};

export { Gantt };

/**
 * 根据属性名和内容匹配数据，子表导入关联数据根据文本内容返回匹配的关联表单数据
 * @param schemaCode 表单编码
 * @param propertyName 属性名
 * @param values 属性值
 * @returns 关联关系值
 */
export const getDataByProperty = (params: { schemaCode: string; propertyName: string; values: string[] }) => {
    return request<H3.Http.Response.H3Response<Record<string, string>>>('/v1/bizdata/property/value', {
        method: H3.Http.MethodType.POST,
        data: params,
    });
};
