import { H3 } from '@h3/types';
import { request } from '@h3/request';
import { Util } from '@h3/h3common';

const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/**
 * 获取流程手写签名配置
 * @param workItemId 当前流程工作项ID
 */
export function getWorkflowHandSignConfig(workItemId: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.Response.THandSignConfig>>('/v1/workflow/handsign/config', {
        method: H3.Http.MethodType.GET,
        params: {
            workItemId,
        },
    });
}

/** 获取指定流程节点之前的节点 */
export function getPreActivities(instanceId: string, activityCode: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.Response.TActivityInfo[]>>('/v1/workflow/activity/pre', {
        method: H3.Http.MethodType.GET,
        params: {
            instanceId,
            activityCode,
        },
    });
}

/** 根据instanceId获取流程实例相关信息 */
export function getInstanceInfo(instanceId: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.Response.TInstanceInfo>>('/v1/workflow/instance/info', {
        method: H3.Http.MethodType.GET,
        params: {
            instanceId,
        },
    });
}

/** 根据instanceId获取流程实例详情 */
export function getInstanceDetail(instanceId: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.Response.TInstanceDetail>>('/v1/workflow/instance/detail', {
        method: H3.Http.MethodType.GET,
        params: {
            instanceId,
        },
    });
}

/** 根据instanceId获取流程实例详情 */
export function getInstanceActivities(instanceId: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.IWorkflowActivity[]>>(
        '/v1/workflow/instance/activity/list',
        {
            method: H3.Http.MethodType.GET,
            params: {
                instanceId,
            },
        },
    );
}

/** 获取表单的流程实例详情 */
export function getActivityListBySchema(schemaCode: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.IWorkflowActivity[]>>(
        '/v1/workflow/template/activity/list',
        {
            method: H3.Http.MethodType.GET,
            params: {
                schemaCode,
            },
        },
    );
}

/** 重新激活流程实例 */
export function reactivate(instanceId: string, destActivityCode: string, userIds: string[]) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/workflow/instance/reactivate', {
        method: H3.Http.MethodType.POST,
        data: {
            instanceId,
            destActivityCode,
            userIds,
        },
    });
}

/** 取消流程 */
export function cancelInstance(instanceId: string) {
    return request<H3.Http.Response.H3Response<boolean>>(`/v1/workflow/instance/cancel?instanceId=${instanceId}`, {
        method: H3.Http.MethodType.POST,
        data: {
            instanceId,
        },
    });
}

/** 取消流程 */
export function finishInstance(instanceId: string) {
    return request<H3.Http.Response.H3Response<boolean>>(`/v1/workflow/instance/finish?instanceId=${instanceId}`, {
        method: H3.Http.MethodType.POST,
        data: {},
    });
}

/** 获取指定流程节点参与人 */
export function getActivityParticipants(instanceId: string, activityCode: string) {
    return request<H3.Http.Response.H3Response<H3.Workflow.IWorkflowActivityParticipant[]>>(
        '/v1/workflow/instance/activity/participant/list',
        {
            method: H3.Http.MethodType.GET,
            params: {
                instanceId,
                activityCode,
            },
            disableBizError: true,
        },
    );
}

/** 获取指定流程节点参与人 */
export function urge(instanceId: string, content: string) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/workflow/instance/urge', {
        method: H3.Http.MethodType.POST,
        data: {
            instanceId,
            content,
        },
    });
}

// 获取流程状态图实例信息
export function getInitInstanceState(bizObjectId: string, workflowCode: string, workflowVersion: number) {
    return request<H3.Http.Response.H3ResponseV2>('/WorkFlowCenter/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: JSON.stringify({
                ActionName: 'Init',
                BizObjectId: bizObjectId,
                WorkflowCode: workflowCode,
                WorkflowVersion: workflowVersion,
                WorkItemID: null,
                InstanceId: null,
            }),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
}

// 加载待办数据列表
export function getWorkflowList(
    HomeDataType: string | number,
    PageIndex: number,
    Offset: number,
    PageSize: number,
    Originator: string,
    From: string,
    To: string,
    FinishFrom: string,
    FinishTo: string,
    SchemaCodes: string,
    Status: string | number,
    FilterItem = '',
    KeyWord = '', //关键字搜索
) {
    const postData = {
        ActionName: 'GetHomeListItem',
        HomeDataType,
        PageIndex,
        Offset,
        PageSize,
        Originator,
        From,
        To,
        FinishFrom,
        FinishTo,
        SchemaCodes,
        Status: Status || '0',
        FilterItem,
        KeyWord,
    };
    return request<H3.Http.Response.H3ResponseV2<H3.Workflow.WorkflowListReturnData>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify(postData),
        },
        headers,
        requestType: 'form',
    });
}

// 获取单条待办数据
export function getListDetail(WorkItemId: string) {
    return request<H3.Http.Response.H3ResponseV2<any>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetWorkItem',
                WorkItemId,
            }),
        },
        headers,
        requestType: 'form',
    });
}

// 验证是否可以进行快速审核
export function validQuickApprove(SchemaCode: string) {
    return request<H3.Http.Response.H3ResponseV2<any>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'CheckCanRapidApproval',
                SchemaCode,
            }),
        },
        headers,
        requestType: 'form',
    });
}

// 进行快速审核
export function quickApprove(
    approval: boolean,
    WorkItemId: string,
    WorkItemType: number,
    SchemaCode: string,
    WorkflowVersion: number,
    InstanceId: string,
    BizObjectId: string,
) {
    return request<H3.Http.Response.H3ResponseV2<any>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'RapidApproval',
                approval,
                WorkItemId,
                WorkItemType,
                SchemaCode,
                WorkflowVersion,
                InstanceId,
                BizObjectId,
            }),
        },
        headers,
        requestType: 'form',
    });
}

// 一键全部已阅
export function markAsAllRead() {
    return request<H3.Http.Response.H3ResponseV2<any>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'FinishAllCirculate',
            }),
        },
        headers,
        requestType: 'form',
    });
}

// 获取GetHeaderMenusInfo
export function getHeaderMenusInfo() {
    return request<H3.Http.Response.H3ResponseV2>('/Navigation/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetHeaderMenusInfo',
                NodeCode: '',
            }),
        },
        headers,
        requestType: 'form',
    });
}

export function getMyWorkflow() {
    return request<H3.Http.Response.H3ResponseV2<H3.Workflow.Response.IMyWorkflow>>('/WorkFlowCenter/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetMyWorkflow',
                Command: 'DoAction',
            }),
        },
        headers,
        requestType: 'form',
    });
}

// 首页待办流程卡片数据
export function getHomeTodoCount() {
    return request<H3.Http.Response.H3ResponseV2<H3.Workflow.Response.IHomeTodoCount>>('/Home/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: Util.JSON.stringify({
                ActionName: 'GetHomeTodoCount',
            }),
        },
        headers,
        requestType: 'form',
    });
}
