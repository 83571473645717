import { request } from '@h3/request';
import { H3 } from '@h3/types';
import { Util } from '@h3/h3common';

/** 下载示例模板 */
export const getImportTemplate = () => {
    const params = {
        ActionName: 'GetImportTemplate',
    };
    return request<H3.Http.Response.H3ResponseV2<H3.Import.Response.IGetImportTemplate>>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
};

/** 检查excel属性字段 */
export const checkPropertyFields = (params: H3.Import.Request.ICheckPropertyFields) => {
    return request<H3.Http.Response.H3ResponseV2<H3.Import.Response.ICheckPropertyFields>>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
};

/** 根据sheetName获取excel表单数据 */
export function getSheetData(params: H3.Import.Request.IGetSheetData) {
    return request<H3.Http.Response.H3ResponseV2<H3.Import.Response.IGetSheetData>>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
}

/** excel导入创建表单schema */
export function generateSchema(params: H3.Import.Request.IGenerateSchema) {
    return request<H3.Http.Response.H3ResponseV2<H3.Import.Response.IGenerateSchema>>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
}

/** excel导入获取导入进度 */
export function getImportProgress(params: H3.Import.Request.IGetGenerateSchemaProgress) {
    return request<H3.Http.Response.H3ResponseV2<H3.Import.Response.IGetGenerateSchemaProgress>>(
        '/ExcelImport/OnAction',
        {
            method: 'post',
            requestType: 'form',
            data: {
                PostData: Util.JSON.stringify(params),
            },
        },
    );
}

/** 中断excel导入 */
export function abortImport(params: H3.Import.Request.IAbortGenerateSchemaImport) {
    return request<H3.Http.Response.H3ResponseV2>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
}

/** 清除缓存 */
export function clearImportCache(params: H3.Import.Request.IClearCache) {
    return request<H3.Http.Response.H3ResponseV2>('/ExcelImport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: Util.JSON.stringify(params),
        },
    });
}

export function getLogByTaskId(taskId: string) {
    return request<H3.Http.Response.H3Response<H3.Import.Response.RecordDetailModel>>(
        '/v1/bizlog/action/import/bytaskId',
        {
            method: H3.Http.MethodType.GET,
            params: { taskId },
        },
    );
}
