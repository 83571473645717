import { H3 } from '@h3/types';
import * as Api from '../../api';
import EngineContext from './engine';

export default class OrgContext {
    /** 人员部门数据 */
    private readonly _orgMetaData = new Map<string, H3.Org.IOrgInfo>();

    /** 引擎上下文 */
    private readonly _engineContext: EngineContext;

    constructor(engineContext: EngineContext) {
        this._engineContext = engineContext;
    }

    /**
     * 获取部门下人员(请求接口,不走缓存)
     * @param params 入参
     * @returns H3.Org.IOrgUserInfo[]
     */
    async getOrgUserList(params: H3.Org.Request.IGetUserListModel): Promise<H3.Org.IOrgUserInfo[]> {
        if (params.parentId) {
            params.range = [];
        }
        const res = await Api.Org.getOrgUserList(params);

        if (!res.successful) {
            return [];
        }

        return res.returnData.map((item) => ({
            ...item,
            type: H3.Org.WeChartOrgType.User,
            nodeType: H3.Org.OrgNodeType.Unit,
            unitType: H3.Org.UnitType.User,
        }));
    }

    /**
     * 获取部门或公司下子部门(请求接口,不走缓存)
     * @param params 入参
     * departmentId: 部门ID
     * showUnActive: 是否显示未激活
     * orgScope: 组织范围
     * range: 限定范围
     * showExternalRoot: 是否包装外部组织根节点
     * showInnerRoot: 是否包装内部内部根节点
     * onlyIncludeCurrentUser: 是否只包含当前用户
     * @returns H3.Org.IOrgDepartmentInfo[]
     */
    async getOrgDepartmentList(params: {
        departmentId?: string | number;
        showUnActive: boolean;
        orgScope: H3.Org.OrgScopeType;
        range?: string[];
        showExternalRoot?: boolean;
        showInnerRoot?: boolean;
        onlyIncludeCurrentUser?: boolean;
    }): Promise<H3.Org.IOrgDepartmentInfo[]> {
        if (params.departmentId) {
            params.range = [];
        }

        if (!params.departmentId) {
            // 内部部门
            if (params.orgScope === H3.Org.OrgScopeType.INNER) {
                return this._getRootInnerDepartmentList({ showRoot: params.showInnerRoot });
            }

            // 外部部门
            if (params.orgScope === H3.Org.OrgScopeType.EXTERNAL) {
                return this._getRootExternalDepartmentList({
                    showRoot: params.showExternalRoot,
                    onlyIncludeCurrentUser: params.onlyIncludeCurrentUser,
                });
            }

            // 所有部门
            if (params.orgScope === H3.Org.OrgScopeType.ALL) {
                const rootDepartment = await Promise.all([
                    this._getRootInnerDepartmentList({ showRoot: params.showInnerRoot }),
                    this._getRootExternalDepartmentList({
                        showRoot: params.showExternalRoot,
                        onlyIncludeCurrentUser: params.onlyIncludeCurrentUser,
                    }),
                ]);
                return [...rootDepartment[0], ...rootDepartment[1]];
            }
        }

        const res = await Api.Org.getOrgDepartmentList({
            departmentId: params.departmentId,
            showUnActive: params.showUnActive,
            range: params.range,
            showExternal: params.orgScope === H3.Org.OrgScopeType.INNER ? false : true,
        });

        if (!res.successful) {
            return [];
        }

        return res.returnData.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.OrganizationUnit,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.Department,
        }));
    }

    /**
     * 加载角色列表(请求接口,不走缓存)
     * @returns H3.Org.IOrgRoleInfo[]
     */
    async getOrgRoleList(): Promise<H3.Org.IOrgRoleInfo[]> {
        const res = await Api.Org.getOrgRoleList();

        if (!res.successful) {
            return [];
        }

        return res.returnData.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.Role,
            nodeType: H3.Org.OrgNodeType.Unit,
        }));
    }

    /** 获取部门详情(请求接口,一个人可能多个部门,不走缓存) */
    async getOrgDepartmentInfo(): Promise<H3.Org.IOrgDepartmentInfo[]> {
        const userInfo = this._engineContext.userInfo;
        const departmentId = userInfo?.departmentId || '';
        const companyId = userInfo?.currentEngine?.companyId || '';

        // 若部门ID和公司ID相同自己组装数据 否则请求后台获取
        if (departmentId === companyId) {
            return [
                {
                    id: departmentId,
                    parentId: '',
                    name: userInfo?.currentEngine?.engineName || '',
                    hasChild: true,
                    state: -1,
                    isExternal: false,
                    entryId: userInfo?.currentEngine?.engineName || '',
                    unitType: H3.Org.UnitType.OrganizationUnit,
                    nodeType: H3.Org.OrgNodeType.Unit,
                    type: H3.Org.WeChartOrgType.Department,
                },
            ];
        } else {
            const res = await Api.Org.getOrgDepartmentInfo({ id: departmentId });

            if (!res.successful || !res.returnData) {
                return [];
            }

            return [
                {
                    ...res.returnData,
                    unitType: H3.Org.UnitType.OrganizationUnit,
                    nodeType: H3.Org.OrgNodeType.Unit,
                    type: H3.Org.WeChartOrgType.Department,
                },
            ];
        }
    }

    /** 获取人员详情(请求接口,走缓存) */
    async getOrgUserInfo(params: H3.Org.Request.IGetOrgInfoModel) {
        const cacheOrgInfo = this.getOrgInfoById(params.id);
        if (cacheOrgInfo) {
            return Promise.resolve(cacheOrgInfo);
        }
        const res = await Api.Org.getOrgUserInfo(params);

        if (!res.successful) {
            return null;
        }
        return {
            ...res.returnData,
            unitType: H3.Org.UnitType.User,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.User,
        };
    }

    /** 搜索人员/部门/角色(请求接口,不走缓存) */
    async searchOrgList(params: H3.Org.Request.ISearchOrgListParams) {
        const res = await Api.Org.searchOrgList(params);

        if (!res.successful) {
            return { data: [], hasMore: false };
        }

        const data = this._mergeOrgData(res.returnData);
        let users: H3.Org.IOrgUserInfo[] = [];
        let departments: H3.Org.IOrgDepartmentInfo[] = [];
        let roles: H3.Org.IOrgRoleInfo[] = [];

        if (params.orgTypes.includes(H3.Org.UnitType.User)) {
            users = data.filter((item) => item.unitType === H3.Org.UnitType.User) as H3.Org.IOrgUserInfo[];
        }
        if (params.orgTypes.includes(H3.Org.UnitType.OrganizationUnit)) {
            departments = data.filter(
                (item) => item.unitType === H3.Org.UnitType.OrganizationUnit,
            ) as H3.Org.IOrgDepartmentInfo[];
        }
        if (params.orgTypes.includes(H3.Org.UnitType.Role)) {
            roles = data.filter((item) => item.unitType === H3.Org.UnitType.Role) as H3.Org.IOrgRoleInfo[];
        }
        return {
            data: [...users, ...departments, ...roles],
            hasMore: res.returnData.hasMore,
        };
    }

    /**
     * 根据传入ID加载数据(请求接口)
     * @param params 入参
     * ids: ID数组
     * cache: 是否走缓存
     * range: 限定范围
     * @returns H3.Org.IOrgInfo[]
     */
    async getOrgList(params: { ids: string[]; cache?: boolean; range?: string[] }) {
        const cache = params.cache ?? true;
        if (cache) {
            // 缓存中不存在的id
            const complementIds = this.getOrgInfoComplement(params.ids);
            if (complementIds?.length) {
                const res = await Api.Org.getOrgList({ ids: complementIds, range: params.range });
                if (res.successful) {
                    const orgData = this._mergeOrgData(res.returnData);
                    // 保存
                    this.setOrgInfoByIds(orgData);
                }
            }
            const data = this.getOrgInfoByIds(params.ids);
            return { data, count: data.length };
        } else {
            const res = await Api.Org.getOrgList(params);
            if (!res.successful) {
                return { data: [], count: 0 };
            }
            const data = this._mergeOrgData(res.returnData);
            return { data, count: data.length };
        }
    }

    /**
     * 根据id获取当前组织或下级(支持部门和角色)
     * 不能走缓存 数据不一致
     * @param params 入参 ids: ID数组
     * @returns H3.Org.IOrgInfo[]
     */
    async getOrgChildren(params: { ids: string[] }) {
        const res = await Api.Org.getOrgChild(params);
        if (!res.successful) {
            return { data: [], count: 0 };
        }
        const data = this._mergeOrgData(res.returnData);
        return { data, count: data.length };
    }

    /**
     * 人员/部门信息(视图点击人员部门-展示特殊信息,分接口请求快速响应)
     * @param params 入参 unitId: ID
     * @returns H3.Org.IOrgInfo | null
     */
    async getOrgInfo(params: { unitId: string }) {
        const res = await Api.Org.getOrgInfo(params);
        if (!res.successful) {
            return null;
        }
        if (res.returnData.unitType === H3.Org.UnitType.User) {
            return {
                type: H3.Org.WeChartOrgType.User,
                data: res.returnData.user,
            };
        } else {
            return {
                type: H3.Org.WeChartOrgType.Department,
                data: res.returnData.department,
            };
        }
    }

    /**
     * 获取顶级内部部门数据
     * @param showRoot 是否包装根节点
     * @returns H3.Org.IOrgDepartmentInfo[]
     */
    private async _getRootInnerDepartmentList(params: { showRoot?: boolean }) {
        const showRoot = params.showRoot ?? true;
        const userInfo = this._engineContext.userInfo;
        const companyId = userInfo?.currentEngine?.companyId || '';

        const res = await Api.Org.getOrgDepartmentList({
            departmentId: companyId,
        });

        if (!res.successful) {
            return [];
        }

        const children =
            res.returnData
                .map((item) => ({
                    ...item,
                    unitType: H3.Org.UnitType.OrganizationUnit,
                    nodeType: H3.Org.OrgNodeType.Unit,
                    type: H3.Org.WeChartOrgType.Department,
                }))
                .filter((item) => item.parentId !== '') ?? [];

        const root = {
            id: companyId,
            parentId: '',
            name: userInfo?.currentEngine?.engineName || '',
            hasChild: true,
            state: -1,
            isExternal: false,
            entryId: '',
            children,
            unitType: H3.Org.UnitType.OrganizationUnit,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.Department,
        };

        return showRoot ? [root] : children;
    }

    /**
     * 获取顶级外部部门数据
     * @param showRoot 是否包装根节点
     * @param onlyIncludeCurrentUser 是否只包含当前用户
     * @returns H3.Org.IOrgDepartmentInfo[]
     */
    private async _getRootExternalDepartmentList(params: { showRoot?: boolean; onlyIncludeCurrentUser?: boolean }) {
        const showRoot = params.showRoot ?? true;
        const res = await Api.Org.getOrgAssociationList({
            onlyIncludeCurrentUser: params.onlyIncludeCurrentUser ?? true,
        });

        if (!res.successful || res.returnData.length === 0) {
            return [];
        }

        const children = res.returnData.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.OrganizationUnit,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.Department,
        }));

        const root = {
            id: H3.Util.EXTERNAL_ROOT_ID,
            parentId: '',
            name: '外部组织',
            hasChild: true,
            state: 0,
            isExternal: true,
            entryId: '',
            children,
            selectable: false,
            unitType: H3.Org.UnitType.OrganizationUnit,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.Department,
        };

        return showRoot ? [root] : children;
    }

    /** 合并组织机构数据 */
    private _mergeOrgData(data: H3.Org.ISearchOrgListModel): H3.Org.IOrgInfo[] {
        // 合并
        const users = data.users.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.User,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.User,
        }));
        const departments = data.departments.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.OrganizationUnit,
            nodeType: H3.Org.OrgNodeType.Unit,
            type: H3.Org.WeChartOrgType.Department,
        }));
        const roles = data.roles.map((item) => ({
            ...item,
            unitType: H3.Org.UnitType.Role,
            nodeType: H3.Org.OrgNodeType.Unit,
        }));
        return [...users, ...departments, ...roles];
    }

    /**
     * 获取人员部门数据
     */
    private getOrgInfoById(id: string) {
        return this._orgMetaData.get(id)!;
    }

    /**
     * 获取人员部门数据
     */
    private getOrgInfoByIds(ids: string[]) {
        return ids.map((id) => this.getOrgInfoById(id));
    }

    /**
     * 判断是否有人员部门数据
     */
    private hasOrgInfoById(id: string) {
        return this._orgMetaData.has(id);
    }

    /**
     * 获取人员部门的补集
     */
    private getOrgInfoComplement(ids: string[]) {
        return ids.filter((id) => !this.hasOrgInfoById(id));
    }

    /**
     * 设置人员部门数据
     */
    private setOrgInfoById(data: H3.Org.IOrgInfo) {
        this._orgMetaData.set(data.id, data);
    }

    /**
     * 设置人员部门数据
     */
    private setOrgInfoByIds(data: Array<H3.Org.IOrgInfo>) {
        data.forEach((item) => this.setOrgInfoById(item));
    }
}
