import { H3 } from '@h3/types';

export namespace Workflow {
    /** 令牌状态 */
    export enum TokenState {
        /** 运行中 */
        Running = 0,
        /** 完成 */
        Finished = 1,
        /** 废弃 */
        Dropped = 2,
        /** 撤回 */
        Revoke = 3,
        /** 退回 */
        Back = 201,
        /** 已审批  */
        Approved = 200,
    }

    /** 数据状态 */
    export enum BizObjectStatus {
        /** 草稿 */
        Draft = 0,

        /** 审批通过(已生效)，或者表单提交后 */
        Effective = 1,

        /** 流程运行中(审批中) */
        Running = 2,

        /** 被取消 */
        Canceled = 3,
    }

    /** 流程状态 */
    export enum WorkflowStatus {
        /** 初始化 */
        Initiated = 0,

        /** 正在启动 */
        Starting = 1,

        /** 正在运行 */
        Running = 2,

        /** 正在结束 */
        Finishing = 3,

        /** 已完成 */
        Finished = 4,

        /** 已取消 */
        Canceled = 5,

        // 看起来后面这三个并不是真实的状态，而是语义上的东西
        /** 未完成 */
        Unfinished = 6,

        /** 取消以外的状态 */
        NotCanceled = 7,

        /** 未指定 */
        Unspecified = 8,
    }

    /** 工作项的类型 */
    export enum WorkItemType {
        /** 未指定 */
        UNSPECIFIED = -1,
        /** 普通工作项 */
        FILL = 0,
        /** 审批类型 */
        APPROVE = 2,
        /** 传阅 */
        CIRCULATE = 3,
        /** 子流程 */
        ChildInstance = 9,
    }

    /** 工作项的状态 */
    export enum WorkItemState {
        /** 等待 */
        Waiting = 0,
        /** 进行中 */
        Working = 1,
        /** 完成 */
        Finished = 2,
        /** 取消 */
        Canceled = 3,
        /** 这个并不属于WorkItem本身的状态，只是为了方便进行查询，他指Waiting或者Working状态 */
        Unfinished = 4,
        /** 这个并不属于WorkItem本身的状态，只是为了方便进行查询，未被取消的状态，指Waiting、Working或者Finished状态 */
        NotCanceled = 5,
        /** 已转交状态 */
        Forwarded = 6,
        /** 撤回 */
        Revoked = 7,
        /** 未指定的状态，这个主要是为了方便查询 */
        Unspecified = -1,
        /** 已经结束和转交的状态，这个主要是为了方便查询 */
        FinishedAndForwarded = 100,
        /** 加签，表示操作过前或后加签 */
        Revise = 200,
        /** 退回 */
        ForBack = 201,
        /** 前加签的取消，表示由于被改变了审批人的取消 */
        ChangedCanceled = 203,
        /** 待生效，产生了一条待办但是没有生效 */
        NotEffective = 300,
    }

    /** 审批意见类型 */
    export enum CommentType {
        /** 默认 */
        Normal = 0,

        /** 征询意见，设置前加签发起的消息 */
        ForComment = 1,

        /** 临时添加审批人，设置后加签发起的消息 */
        TempAddition = 2,
    }

    /** 流程工作项活动类型 */
    export enum ActionEventType {
        /** 用于WorkItem.PreActionEventType，表示前一个步骤既不是提交页不是打回，可能是直接激活了某个活动，或者是征询意见、协办之类的请求创建的任务 */
        None = 0,
        /** 流程向下流转，通常是普通的提交事件 */
        Forward = 2,
        /** 流程回退流转，通常是打回的时候激活 */
        Backward = 3,
        /** 调整活动激活 */
        Adjust = 5,
        /** 结束并取消流程，用于流程节点设置DisapproveExitType.Cancel时候给发起者发送消息 */
        Cancel = 6,
        /** 自动审批通过 */
        Automatic = 7,
        /** 修改节点审批人，使用加签的原正常审批/经办节点发起的消息 */
        Revise = 8,
        /** 征询意见，设置前加签发起的消息 */
        ForComment = 9,
        /** 临时添加审批人，设置后加签发起的消息 */
        TempAddition = 10,
        /** 退回 */
        ForBack = 11,
    }

    /** 流程审批状态 */
    export enum ApprovalStatus {
        /** 流程还在进行中 */
        Pending = -1,

        /** 不同意 */
        Disagree = 0,

        /** 同意 */
        Agree = 1,
    }

    /** 流程撤回类型 */
    export enum RetrieveType {
        /** 发起人撤回 */
        originatorRetrieve = 0,

        /** 其他人撤回 */
        otherRetrieve = 1,
    }

    /** 审批编码 */
    export enum ApproveCode {
        Text = 'Text',
        Picture = 'Picture',
        Attachment = 'Attachment',
        Signature = 'Signature',
    }

    /** 审批字段编码 */
    export enum CommentField {
        Text = 'text',
        Attachments = 'attachments',
        Signature = 'signature',
        SignatureId = 'signatureId',
    }

    export enum WorkFlowReturnStatus {
        Unspecified = 0, // 未定义
        MyInstance, // 我的
        Unfinished, // 待办
        Finished, // 已办
        Unread, // 待阅
        Read, // 已阅
        Fill, // 手工
    }

    /** 流程工作项 */
    export interface WorkItemInfo {
        /** 活动类型 */
        actionEventType: ActionEventType;

        /** 流程节点编码 */
        activityCode: string;

        /** 流程节点名称 */
        activityName: string;

        /** 审批结果 */
        approval: ApprovalStatus;

        /** 用户头像 */
        avatar: string;

        /** 委托人（作用未知，不要使用） */
        delegant: string; // TODO

        /** 用户第三方平台ID */
        entryId: string;

        /** 工作项完成时间 */
        finishTime: string;

        /** 工作项完成人信息 */
        finisher: {
            /** 第三方id */
            entryId: string;

            /** 名称 */
            name: string;

            /** 头像 */
            avatar: string;
        };

        /** 工作项类型 */
        itemType: WorkItemType;

        /** 工作项是否发送通知 */
        notify: boolean;

        /** 上一个步骤的活动类型 */
        preActionEventType: ActionEventType;

        /** 工作项接收时间 */
        receiveTime: string;

        /** 工作项的状态 */
        state: WorkItemState;

        /** 流程步骤ID */
        tokenId: number;

        /** 流程步骤状态 */
        tokenState: TokenState;

        /** 用户编码 */
        userId: string;

        /** 用户名称 */
        userName: string;

        /** 任务停留耗时 */
        waitTime: string;

        /** 工作项ID */
        workItemId: string;
    }

    export interface IRuntimeWorkItemInfo {
        /** 活动类型 */
        actionEventType: ActionEventType;

        /** 流程节点编码 */
        activityCode: string;

        /** 流程节点名称 */
        activityName: string;

        /** 审批结果 */
        approval: ApprovalStatus;

        /** 用户头像 */
        avatar: string;

        /** 委托人（作用未知，不要使用） */
        delegant: string; // TODO

        /** 用户第三方平台ID */
        entryId: string;

        /** 工作项完成时间 */
        finishTime: string;

        /** 工作项完成人信息 */
        finisher: {
            /** 第三方id */
            entryId: string;

            /** 名称 */
            name: string;

            /** 头像 */
            avatar: string;
        };

        /** 工作项类型 */
        itemType: WorkItemType;

        /** 工作项是否发送通知 */
        notify: boolean;

        /** 上一个步骤的活动类型 */
        preActionEventType: ActionEventType;

        /** 工作项接收时间 */
        receiveTime: string;

        /** 工作项的状态 */
        state: WorkItemState;

        /** 流程步骤ID */
        tokenId: number;

        /** 流程步骤状态 */
        tokenState: TokenState;

        /** 用户编码 */
        userId: string;

        /** 用户名称 */
        userName: string;

        /** 任务停留耗时 */
        waitTime: string;

        /** 工作项ID */
        workItemId: string;
        comment?: CommentInfo;
    }

    export interface IParticipantInfo {
        id: string;
        name: string;
        url: string;
        dingtalkAccount?: string;
        entryUserId?: string;
        entryType?: string;
        entryId?: string;
    }

    type TWorkflowLogItemInfo = IRuntimeWorkItemInfo | IChildInstanceInfo;

    export interface IRuntimeGroupWorkItem {
        key: number;
        tokenId: number;
        name?: string;
        itemType?: WorkItemType | null;
        status: WorkItemState;
        statusText?: string;
        finished?: IRuntimeWorkItemInfo[];
        canceled?: IRuntimeWorkItemInfo[];
        source: IRuntimeWorkItemInfo[];
        todoUsers?: IParticipantInfo[];
        endorsers?: IParticipantInfo[];
        collapse?: boolean;
        notify?: unknown;
        urgePopupShow?: boolean;
    }

    /** 审批记录 */
    export interface CommentInfo {
        /** 审批是否同意 */
        approval: boolean;

        /** 审批附件 */
        attachments: Array<{
            code: string;
            name: string;
            description: string;
            size: number;
            sortKey: number;
            thumbnailUrl: string;
            url: string;
        }>;

        /** 审批记录的Id */
        commentId: string;

        /** 审批记录类型 */
        commentType: CommentType;

        /** 委托人名称 */
        delegantName: string;

        /** 审批状态 */
        integerApproval: ApprovalStatus;

        /** 是否是我的审批意见 */
        isMyComment: boolean;

        /** 审批意见最后修改时间（YYYY-MM-DD HH:MM:SS） */
        modifiedTime: string;

        /** 审批手写签名 */
        signature: string;

        /** 审批手写签名的fileId */
        signatureId: string;

        /** 审批的文本内容 */
        text: string;

        /** 流程步骤ID */
        tokenId: number;

        /** 用户的头像Url */
        userAvatar: string;

        /** 用户的父组织名称 */
        userDepartmentName: string;

        /** 用户第三方集成id */
        userEntryId: string;

        /** 用户id */
        userId: string;

        /** 用户名称 */
        userName: string;

        /** 所属工作项id */
        workItemId: string;

        /** 所属工作项状态 */
        workItemState: WorkItemState;
        additionUsers?: IParticipantInfo[];
    }

    // 流程活动节点Token信息
    export interface ITokenInfo {
        objectId: string;
        tokenId: number;
        approval: ApprovalStatus;
        state: TokenState;
        activity: string;
        instanceId: string;
    }

    // 子流程信息
    export interface IChildInstanceInfo {
        objectId: string;
        originator: string;
        state: WorkflowStatus;
        tokenId: number;
        displayName: string;
        bizObjectId: string;
        schemaCode: string;
        userName: string;
        dingTalkAccount: string;
        profilePhotoUrl: string;
        finishTime: string;
        parentInstanceId: string;
        parentActivityCode: string;
        parentActivityDisplayName: string;
        entryId: string;
        itemType?: WorkItemType; // 动态添加的类型，接口不返回该字段
        notify?: boolean;
    }

    // 流程状态日志
    export interface IWorkflowStateLog {
        activityName: string;
        createdTime: string;
        finishedTime: string;
        usedTime: number;
        participant: string;
        state: string;
    }

    // 流程节点接收人信息
    export interface IWorkflowActivityReceiver {
        Activity: string;
        Participants: IParticipantInfo[];
        WorkItemType: WorkItemType;
    }

    // 流程详情信息
    export interface IWorkflowInstanceDetail {
        validate: boolean;
        workflowStateLogs: IWorkflowStateLog[];
        receiverTable: IWorkflowActivityReceiver[];
    }

    // 流程节点信息
    export interface IWorkflowActivity {
        displayName: string;
        activityCode: string;
    }

    // 流程节点参与人信息
    export interface IWorkflowActivityParticipant {
        parentName: string;
        birtyday: string;
        gender: string;
        email: string;
        mobile: string;
        avatar: string;
        employeeNumber: string;
        position: string;
        entryDate: string;
        parentId: string;
        entryDepartmentId: string;
        id: string;
        unitID: string;
        entryId: string;
        name: string;
        state: WorkItemState;
        authorizedState: number;
        roleName: string;
        isExternal: boolean;
    }

    /** 流程颜色 */
    export enum WorkflowColor {
        /** 草稿 */
        Draft = '#FF7527',
        /** 审批通过(已生效) */
        Effective = '#FFFFFF',
        /** 流程运行中(审批中) */
        Running = '#107FFF',
        /** 被取消 */
        Canceled = '#8893A7',
        /** None */
        None = 'none',
    }

    /** 流程样式 */
    export const WorkflowColorStyle = {
        /** 草稿 */
        [BizObjectStatus.Draft]: {
            color: '#FF7527',
            background: '#FFF8F0',
            border: 'none',
        },
        /** 已生效 */
        [BizObjectStatus.Effective]: {
            color: '#8893a7',
            background: '#FFFFFF',
            border: 'thin solid #E8EAF0',
        },
        /** 进行中 */
        [BizObjectStatus.Running]: {
            color: '#107FFF',
            background: '#E6F6FF',
            border: 'none',
        },
        /** 已取消 */
        [BizObjectStatus.Canceled]: {
            color: '#8893A7',
            background: '#F3F5F8',
            border: 'thin solid #E8EAF0',
        },
    };

    /** 流程配置 */
    export const WorkflowStatusOptions = [
        {
            label: '草稿',
            value: BizObjectStatus.Draft,
            color: WorkflowColor.Draft,
            status: 'Draft',
        },
        {
            label: '已生效',
            value: BizObjectStatus.Effective,
            color: WorkflowColor.Effective,
            status: 'Effective',
        },
        {
            label: '进行中',
            value: BizObjectStatus.Running,
            color: WorkflowColor.Running,
            status: 'Running',
        },
        {
            label: '已取消',
            value: BizObjectStatus.Canceled,
            color: WorkflowColor.Canceled,
            status: 'Canceled',
        },
    ];

    export namespace Response {
        /** 流程手写签名配置 */
        export type THandSignConfig = {
            /** 是否可重用签名 */
            reusable: boolean;

            /** 是否可上传签名 */
            uploadable: boolean;
        };

        /** 加载指定节点前流程节点响应模型 */
        export type TActivityInfo = {
            /** 节点名称 */
            displayName: string;

            /** 节点编码 */
            activityCode: string;
        };

        /** 流程信息响应模型 */
        export type TInstanceInfo = {
            tokens: ITokenInfo[];
            children: IChildInstanceInfo[];
        };

        // 流程实例详情信息
        export type TInstanceDetail = {
            showAdjust: boolean;
            showActivate: boolean;
            showCancel: boolean;
            showUrge: boolean;
            instanceDetail: IWorkflowInstanceDetail;
        };

        export interface IHomeTodoCount {
            UnFinishedCount: number;
            UnReadCount: number;
            MyInstanceCount: number;
        }

        export interface IMyWorkflow {
            AppNameTable: Record<string, H3.Task.IWorkflowFormData>;
            WorkflowCategorys: Record<string, H3.Task.IWorkflowCategorys[]>;
        }
    }

    export interface WorkflowListReturnData {
        Items: WorkflowListItem[];
        ColorControl: string | null;
        PageIndex?: number;
        Offset?: number;
    }

    export interface WorkflowListItem {
        ActivityName: string;
        AlertTime?: string;
        Approval: ApprovalStatus;
        ApproveText: string;
        BizObjectId: string;
        CanQuicklyApprove: boolean;
        DisApproveText: string;
        DisplayName?: string;
        FinishedTime?: string;
        FinishTime: string;
        HasRequired: boolean;
        InstanceId: string;
        InstanceState?: WorkflowStatus;
        ItemSummary?: string;
        Name: string;
        Originator: string;
        OriginatorName: string;
        OriginatorProfileUrl?: string;
        ReceiveTime: string;
        ReminderType: number;
        ReturnStatus: WorkFlowReturnStatus;
        SchemaCode: string;
        ShowApprove: boolean;
        ShowDisApprove: boolean;
        State: WorkItemState;
        Summary: string;
        SummaryDic?: SummaryData;
        TaskId?: string;
        TaskRecieveId?: string;
        TaskState: number;
        WorkItemId: string;
        WorkItemType: WorkItemType;
        WorkflowVersion: number;
        [Props: string]: any;
    }

    export interface SummaryData {
        controlType?: string;
        entryIds?: string[];
        [key: string]: any;
    }
    export interface SummaryDic {
        [controlKey: string]: SummaryData;
    }

    export interface Columns {
        key: string;
        title?: string;
        width?: number | string;
        render?: FunRender;
        slot?: string;
        sort: boolean;
        selections?: SelectItems[];
    }
    interface FunRender {
        // 返回类型还需修改
        (params: { row: Record<string, any>; column: Columns; index: number }): string;
    }
    interface SelectItems {
        label: string;
        value: string | number;
    }
}
