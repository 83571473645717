import { H3 } from '@h3/types';
import { request } from '@h3/request';

export const getExportColumns = (params: H3.Export.Request.IExportColumnModel) =>
    request<H3.Http.Response.H3ResponseV2<H3.Export.Response.GetExportColumnsResponse>>('/ExcelExport/OnAction', {
        method: 'post',
        params,
    });

export const exportExcel = (params: H3.Export.Request.IExportExcelModel) =>
    request<H3.Http.Response.H3ResponseV2<H3.Export.Response.ExportExcelResponse>>('/ExcelExport/OnAction', {
        method: 'post',
        requestType: 'form',
        data: {
            PostData: JSON.stringify(params),
        },
    });

export const getExportProgress = (params: H3.Export.Request.IExportProgressModel) =>
    request<H3.Http.Response.H3ResponseV2<H3.Export.Response.GetExportProgressResponse>>('/ExcelExport/OnAction', {
        method: 'post',
        params,
    });

export const getTaskRecord = (params: H3.Export.Request.ITaskRecordModel) =>
    request<H3.Http.Response.H3ResponseV2<H3.Export.Response.TaskRecordResponse[]>>(
        '/h3/v1/importOrExport/getTaskRecord',
        {
            method: 'post',
            data: params,
        },
    );

/** 根据异步任务id获取导出日志数据 */
export const getLogByTaskId = (taskId: string) =>
    request<H3.Http.Response.H3Response<H3.Export.Response.RecordDetailModel>>('/v1/bizlog/action/export/bytaskId', {
        method: H3.Http.MethodType.GET,
        params: { taskId },
    });
