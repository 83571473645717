import { H3 } from '@h3/types';
import * as Api from '../../api';

/** 全局功能开关 */
export default class SwitchContext {
    /** 开关缓存 */
    private readonly _switchMap: Map<string, Map<H3.Operate.FunctionSwitchNameEnum, boolean>> = new Map();

    /** 当前企业引擎编码 */
    private get _engineCode() {
        return window?.h3Daf?.EngineContext?.engineCode;
    }

    /**
     * 根据关键字获取开关状态
     * @param key 查询关键字
     * @returns
     */
    async getSwitchStatus(key: H3.Operate.FunctionSwitchNameEnum) {
        if (!this._switchMap.has(this._engineCode)) {
            this._switchMap.set(this._engineCode, new Map());
        }
        const curEngineMap = this._switchMap.get(this._engineCode)!;
        if (!curEngineMap.has(key)) {
            const ret = await Api.Operate.getSwitchListByName([key]);
            if (ret.successful && ret.returnData.switches) {
                this.standardize(ret.returnData.switches[0]);
            }
        }
        return curEngineMap!.get(key)!;
    }

    /**
     * 批量获取开关状态
     * @param keys
     */
    async batchGetSwitchStatus(keys: H3.Operate.FunctionSwitchNameEnum[]) {
        if (!this._switchMap.has(this._engineCode)) {
            this._switchMap.set(this._engineCode, new Map());
        }
        const curEngineMap = this._switchMap.get(this._engineCode)!;
        const noCacheKeys = keys.filter((key) => !curEngineMap.has(key));

        // 有未缓存的开关则请求
        if (noCacheKeys.length > 0) {
            const ret = await Api.Operate.getSwitchListByName(noCacheKeys);
            if (ret.successful) {
                ret.returnData.switches.forEach((item) => {
                    this.standardize(item);
                });
            }
        }

        return keys.map((key) => curEngineMap.get(key)!);
    }

    /**
     * 标准化开关值并存储
     * @param item 一个开关数据
     */
    private standardize(item: { name: H3.Operate.FunctionSwitchNameEnum; value: string }) {
        const standardizedValue = item.value === '1';
        this._switchMap.get(this._engineCode)!.set(item.name, standardizedValue);
    }

    /**
     * 初始化预加载部分开关
     */
    async init() {
        this._switchMap.clear();
        // 初始化需要加载的开关状态
        const keys = [
            H3.Operate.FunctionSwitchNameEnum.OpenAIEnabled,
            H3.Operate.FunctionSwitchNameEnum.AnalysisAIEnabled,
            // H3.Operate.FunctionSwitchNameEnum.EnableFormulaControl,
            H3.Operate.FunctionSwitchNameEnum.AdvancedDataSourceEnabled,
            H3.Operate.FunctionSwitchNameEnum.SwitchFormToV2,
            H3.Operate.FunctionSwitchNameEnum.IsFormV3,
            H3.Operate.FunctionSwitchNameEnum.IsNextFormDesigner,
            // H3.Operate.FunctionSwitchNameEnum.FormButtonDefinitionEnabled,
            H3.Operate.FunctionSwitchNameEnum.AssociatedOrganizationEnabled,
            H3.Operate.FunctionSwitchNameEnum.EnableUpgradeReport,
            H3.Operate.FunctionSwitchNameEnum.SSOEnabled,
            // H3.Operate.FunctionSwitchNameEnum.FormRollupEnable,
            // H3.Operate.FunctionSwitchNameEnum.EnableAutomation,
            H3.Operate.FunctionSwitchNameEnum.FormDynamicEnable,
            H3.Operate.FunctionSwitchNameEnum.NewSubTableEnabled,
            H3.Operate.FunctionSwitchNameEnum.BusinessRuleEnabled,
            H3.Operate.FunctionSwitchNameEnum.HierarchyEnabled,
        ];
        await this.batchGetSwitchStatus(keys);
    }
}
