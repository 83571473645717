import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 发送短信 */
export const sendSms = (params: {
    List: Array<{
        Subject: string;
        EngineCode: string;
        Address: string;
    }>;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta<boolean>>(Api.SendSms, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取短信模板列表 */
export const getSmsTemplateList = () =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.SmsTemplateModel[]>>(Api.SmsTemplateList, {
        method: 'post',
        disableBizError: true,
    });

/** 删除短信模板列表 */
export const removeSmsTemplate = (params: { tempId: string }) =>
    request<H3.Http.Response.H3ResponseV2<{ Result: boolean }>>(Api.SmsTemplateRemove, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
