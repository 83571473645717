export namespace GlobalSearch {
    export enum SEARCH_TYPE {
        /** 全部 */
        ALL = 'all',
        /** 数据 */
        DATA = 'data',
        /** 功能 */
        FUNCTION = 'function',
    }
    export interface IModelItem {
        title: string;
        content: string;
        createdTime: string;
        hitUrl: string;
        ownerName: string | null;
        objectId: string;
        displayName: string;
        schemaCode: string;
    }
    export interface INodeItem {
        appCode: string;
        code: string;
        creatable: boolean;
        iconCss: string;
        name: string;
    }
    export namespace Response {
        export interface ISearchResult {
            Successful: boolean;
            ErrorMessage: string;
            Total: number;
            SearchModels: IModelItem[];
            SearchNodes: INodeItem[];
        }
        export interface IModalReturnData {
            items: IModelItem[];
            totalCount: number;
        }

        export interface INodeReturnData {
            items: INodeItem[];
            totalCount: number;
        }
    }
}
