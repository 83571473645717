import { FunctionNode } from '../node';

export namespace App {
    /** 发布到钉钉工作台的类型 */
    export enum PublishedShortcutType {
        /** 应用 */
        App = 1,

        /** 报表 */
        Report = 3,

        /** 表单 */
        Form = 4,

        /** 列表 */
        List = 5,

        /** 仪表盘 */
        Dashboard = 9,
    }

    /**
     * 应用来源
     */
    export const enum AppSourceType {
        Own = 0, // 自建
        Template = 1, // 模版中心
        DirectInstall = 2, // 定向安装
    }

    /** 发布到钉钉工作台的快捷方式的数据结构 */
    export interface PublishedShortcutData {
        /** 快捷方式类型 */
        linkType: PublishedShortcutType;

        /** 快捷方式名称 */
        displayName: string;

        /** 快捷放弃图表地址 */
        iconUrl: string;

        /** 快捷方式对应的FunctionNode编码 */
        code: string;
    }

    // 应用汇总控件汇总信息
    export interface AppRollupSummary {
        sourcePropertyName: string; // 汇总源字段名
        sourcePropertyDisplayName: string; // 汇总源字段显示名
        sourceSchemaCode: string; // 汇总源模式编码（主表或子表编码）
        sourceSchemaDisplayName: string; // 汇总源模式显示名称
        sourceMainSchemaCode: string; // 汇总源主表编码
        sourceMainSchemaDisplayName: string; // 汇总源主表显示名称
        targetPropertyName: string; // 目标字段名
        targetPropertyDisplayName: string; // 目标字段显示名称
        targetSchemaCode: string; // 汇总目标模式编码
        targetSchemaDisplayName: string; // 汇总目标模式显示名称
        targetMainSchemaCode: string; // 汇总目标主表编码
        targetMainSchemaDisplayName: string; // 汇总目标主表显示名称
    }

    export namespace Request {
        export interface IAddAppModel {
            /** 名称 */
            displayName: string;

            /** 编码 */
            code: string;

            /** 图标颜色，由于图标版本的问题，实际上值不止在这个映射中 */
            iconColor: FunctionNode.FunctionNodeIconColorType | undefined;

            /** 图标 */
            icon: string;
        }
        /** 发布、更新钉钉工作台快捷方式参数类型 */
        export interface IPublishShortcutModel {
            /** 快捷方式类型 */
            linkType: PublishedShortcutType;

            /** 快捷放弃图表地址 */
            icon: string;

            /** 快捷方式对应的FunctionNode编码 */
            code: string;
        }

        /** 删除钉钉工作台快捷方式参数类型 */
        export interface IDeleteShortcutModel {
            /** 快捷方式对应的FunctionNode编码 */
            code: string;

            /** 快捷方式类型 */
            linkType: PublishedShortcutType;
        }
    }

    export namespace Response {
        // 应用汇总结果集
        export interface AppRollupSummaryResult {
            appRollupSummaries: AppRollupSummary[];
            totalCount: number;
            limit: number;
        }
    }
}
