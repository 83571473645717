import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** 获取设置时视图配置 */
export const getViewDesignerSetting = (params: H3.View.Request.IGetDesignerViewSettingModel) => {
    return request<H3.Http.Response.H3Response<H3.View.IViewSettingModel>>(Api.GetDesignerViewSetting, {
        method: 'get',
        params,
    });
};

/** 获取运行时视图配置 */
export const getViewSetting = (params: H3.View.Request.IGetViewSettingModel) => {
    return request<H3.Http.Response.H3Response<H3.View.IViewSettingModel>>(Api.GetViewSetting, {
        method: 'get',
        params,
    });
};

/** 更新视图配置 */
export const updateViewSetting = (params: H3.View.IViewSettingUpdateModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateViewSetting, {
        method: 'put',
        data: params,
    });
};

/**
 * 获取视图偏好配置
 * @param params
 * @returns
 */
export const getViewPreference = (params: H3.View.Request.IGetViewPreferenceModel) => {
    return request<H3.Http.Response.H3Response<H3.View.IPreferenceItem[]>>(Api.GetViewPreference, {
        method: 'get',
        params,
    });
};

/**
 * 新增视图偏好配置
 * @param params
 * @returns
 */
export const addViewPreference = (params: H3.View.Request.IAddViewPreferenceModel) => {
    return request<H3.Http.Response.H3Response<string>>(Api.AddViewPreference, {
        method: 'post',
        data: params,
    });
};

/**
 * 获取视图偏好配置
 * @param params
 * @returns
 */
export const updateViewPreference = (params: H3.View.Request.IUpdateViewPreferenceModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.UpdateViewPreference, {
        method: 'post',
        data: params,
    });
};

/**
 * 获取视图偏好配置
 * @param params
 * @returns
 */
export const removeViewPreference = (params: H3.View.Request.IRemoveViewPreferenceModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.RemoveViewPreference, {
        method: 'post',
        data: params,
    });
};

/**
 * 对视图偏好排序
 * @param params
 * @returns
 */
export const sortViewPreference = (params: H3.View.Request.ISortViewPreferenceModel) => {
    return request<H3.Http.Response.H3Response<boolean>>(Api.SortViewPreference, {
        method: 'post',
        data: params,
    });
};

/** 获取层级视图是否超出阈值 */
export const getHierarchyLimit = (params: { schemaCode: string }) => {
    return request<
        H3.Http.Response.H3Response<{
            /** 超出1k条 */
            isOverHierarchyLimit1: boolean;
            /** 超出10w条 */
            isOverHierarchyLimit2: boolean;
        }>
    >(Api.GetHierarchyLimit, {
        method: 'get',
        params,
    });
};
