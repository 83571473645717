/**
 * 请求相关
 */
export namespace Http {
    /**
     * http 状态
     */
    export enum HttpStatusEnum {
        OK = 200,
        CREATED = 201,
        ACCEPTED = 202,
        NO_CONTENT = 204,
        BAD_REQUEST = 400,
        UNAUTHORIZED = 401,
        FORBIDDEN = 403,
        NOT_FOUND = 404,
        METHOD_NOT_ALLOWED = 405,
        INTERNAL_SERVER_ERROR = 500,
        NOT_IMPLEMENTED = 501,
        BAD_GATEWAY = 502,
        SERVICE_UNAVAILABLE = 503,
        GATEWAY_TIMEOUT = 504,
        HTTP_VERSION_NOT_SUPPORTED = 505,
    }

    export enum HttpReadyStateEnum {
        /** 未发送请求，未调用 open() */
        UNSENT = 0,
        /** 已调用 open()，但未调用 send() */
        OPENED = 1,
        /** 已接收到响应头，但还没有接收到响应体 */
        HEADERS_RECEIVED = 2,
        /** 正在接收响应体，但还有数据可用 */
        LOADING = 3,
        /** 请求已完成 */
        DONE = 4,
    }

    /**
     * @description: 请求方法
     */
    export enum MethodType {
        GET = 'GET',
        POST = 'POST',
        PATCH = 'PATCH',
        PUT = 'PUT',
        DELETE = 'DELETE',
    }

    /**
     * @description:  常用的contentTyp类型
     */
    export enum ContentType {
        // json
        JSON = 'application/json;charset=UTF-8',
        // json
        TEXT = 'text/plain;charset=UTF-8',
        // form-data 一般配合qs
        FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
        // form-data  上传
        FORM_DATA = 'multipart/form-data;charset=UTF-8',
    }

    /**
     * @description 请求方法类型
     */
    export namespace Request {
        // TODO 这个和@h3/request里的RequestOptionsInit必须保持一致
        // TODO 不知道怎么解耦
        /**
         * @description 请求选项
         */
        export interface RequestOptions extends RequestInit {
            /** 禁用业务Error，主要是禁用默认错误提示 */
            disableBizError?: boolean;

            /** 禁用系统错误，主要是禁用默认错误提示 */
            disableSysError?: boolean;

            /** response的key首字母转小写 */
            camelCase?: boolean;

            remoteService?: string;
        }
    }

    export namespace Response {
        /** 3.0接口响应 */
        export interface H3Response<T = unknown> {
            /** 请求是否成功 */
            successful: boolean;

            /** 错误信息 */
            errorMessage: string;

            /** 错误码 */
            errorCode: number | string;

            /** 响应数据 */
            returnData: T;
        }

        /** 2.0接口响应 */
        export interface H3ResponseV2<T = unknown> {
            /** 请求是否成功 */
            Successful: boolean;

            /** 错误信息 */
            ErrorMessage: string;

            /** 数据类型（待定） */
            DataType: number;

            /** 是否登录 */
            Logined: boolean;

            /** 响应数据 */
            ReturnData: T;
        }

        /** beta版2.0接口 */
        export interface H3ResponseV2Beta<T = unknown> {
            /** 响应数据 */
            data: T;

            /** 错误码 */
            errorCode: number | string;

            /** 错误信息 */
            errorMessage: string;
        }
        /** 流程待办切换组织接口相应 */
        export interface H3ResponseChangeOrg<T = unknown> {
            errcode: number | string;
            errmsg: string;
            nextAccessIsNeedNc: boolean;
            result: T;
        }
    }

    export namespace Cache {
        export const APP_LOCAL_CACHE_KEY = 'COMMON_LOCAL_KEY';

        export const APP_SESSION_CACHE_KEY = 'COMMON_SESSION_KEY';

        /** 用户token */
        export const ACCESS_TOKEN_KEY = 'h3_token';

        /** 官网token */
        export const SITE_TOKEN_KEY = 'token';

        /** engine */
        export const H3_ENGINE_CODE = 'h3_EngineCode';

        export const H3_AGENT_ID = 'h3_AgentId';

        export const SHARD_KEY = 'shardkey2';

        export const TOKEN_CORP_KEY = 'token_corp';

        export const H3_LOGIN_INFO = 'h3_loginInfo';

        export const H3_SUITE_KEY = 'h3_SuiteKey';

        export const H3_SOLUTION_CODE = 'h3_SolutionCode';

        /** corpId，token失效时用到 */
        export const CORP_ID_KEY = 'corpIdKey';

        /** 内部组织信息(用于切换外部组织) */
        export const INNER_ORG_INFO = 'inner_org_info';

        /** 服务器信息 */
        export const UPSTREAM_IP = 'upstreamip3';

        /** engines(供应用中心官网使用) */
        export const H3_ENGINES = 'h3_engines';

        /** 用户信息 */
        export const ACCESS_USERINFO_KEY = 'ACCESS_TOKENINFO';

        /** 国际化 */
        export const LOCALE_KEY = 'LOCALE__';

        /** 调试模式 */
        export const IS_DEBUG = 'isDebug';

        /** 是否是退出登录 */
        export const IS_LOGOUT = 'isLogout';
    }
}
