import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 根据指定报表编码获取报表下所有图表 */
export function getWidgetByDashboardId(dashboardId: string) {
    return request<H3.Http.Response.H3Response<H3.DashBoard.IReportWidget[]>>('/v1/chartnode/bydashboard', {
        method: 'get',
        params: {
            dashboardId,
        },
    });
}
