import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取地址库数据 */
export const getAddressInfo = () =>
    request<H3.Http.Response.H3Response<H3.PCT.IAddressInfoModel>>('/v1/address/info', {
        method: 'get',
        camelCase: true,
    });

/**
 * 请求json地址文件
 * @param fileUrl 地址库json文件地址
 * @returns
 */
export const getAddressByFileUrl = (fileUrl: string) =>
    request<unknown>(fileUrl, {
        method: 'get',
    });

/**
 * 根据地址编码获取地址信息
 * @param adcode 地址编码
 */
export const getAddressInfoByAdcode = (adcode: string) =>
    request<H3.Http.Response.H3Response<H3.PCT.Response.IGetAddressByCodeModal>>(`/h3/v1/address/getNode/${adcode}`, {
        method: 'get',
        camelCase: true,
    });
