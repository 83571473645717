import { H3 } from '@h3/types';
import { request } from '@h3/request';

// 请求头
const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/** 加载表单设计器数据 */
export const getFormDesignData = (params: H3.Form.FormDesigner.Request.ILoadFormModel) =>
    request<H3.Http.Response.H3Response<H3.Form.FormDesigner.Response.ILoadFormModel>>('/v1/formdesign/load', {
        method: H3.Http.MethodType.GET,
        params,
    });

/** 保存表单设计器 */
export const saveFormDesignData = (params: H3.Form.FormDesigner.Request.ISaveFormModel) =>
    request<H3.Http.Response.H3Response<H3.Form.FormDesigner.Response.ISaveFormModel>>('/v1/formdesign/save', {
        method: H3.Http.MethodType.POST,
        data: params,
        disableBizError: true,
    });

/** 获取关联表单可见字段 */
export const getFormDesignAssociationField = (params: H3.Form.FormDesigner.Request.IAssociationFieldModel) =>
    request<H3.Http.Response.H3Response<string[]>>('/v1/formdesign/associationform/field', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 校验id是否重复 */
export const checkDataFieldRepeat = (params: { schemaCode: string; datafield?: string }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/formdesign/checkcodeavailable', {
        method: H3.Http.MethodType.POST,
        data: params,
        disableBizError: true,
    });

/** 控件删除依赖检查 */
export const checkReference = (params: { schemaCode: string; dataFields: string[] }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/formdesign/checkreference', {
        method: H3.Http.MethodType.POST,
        data: params,
        disableBizError: true,
    });

/**
 * 获取表单/应用被依赖的关系
 * @param params fieldCodes: 被删除的控件 formControls: 最新的formControls
 */
export const checkReferenceField = (params: {
    schemaCode: string;
    fieldCodes: string[];
    formControls?: H3.Control.IControlLayout[];
}) =>
    request<H3.Http.Response.H3Response<Array<H3.Exception.RemoveTip.Response.IReferenceFieldModel>>>(
        '/v1/reference/field',
        {
            method: H3.Http.MethodType.POST,
            data: params,
        },
    );

/** 描述控件上传文件 */
export const uploadDescriptionFile = (params: { schemaCode: string; file: File }) =>
    request<H3.Http.Response.H3Response<string>>('/v1/file/description/upload', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 获取扫码签名二维码地址 */
export const getHandSignQrLink = (params: H3.Form.Request.THandSignLinkParams) =>
    request<H3.Http.Response.H3Response<{ fileId: string; url: string }>>('/v1/handsign/qrlink', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 获取移动端扫码签名后文件链接 */
export const getHandSignFile = (params: { fileId: string }) =>
    request<H3.Http.Response.H3Response<{ fileUrl: string; Thumbnail: string }>>('/v1/form/sign/file', {
        method: H3.Http.MethodType.GET,
        params,
        disableBizError: true,
    });

/** 获取扫码签名文件链接 */
export const getFormHandSignLink = (params: H3.Form.Request.THandSignLinkParams) =>
    request<H3.Http.Response.H3Response<{ fileId: string; url: string }>>('/v1/form/sign/qrlink', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 获取当前用户最近手写签名 */
export const getLatestFormHandSign = () =>
    request<H3.Http.Response.H3Response<string>>('/v1/form/sign/latest', {
        method: H3.Http.MethodType.GET,
    });

/** 保存手写签名 */
export const saveFormHandSign = (params: H3.Form.Request.TSaveHandSignParams) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/form/sign/save', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 绑定用户的手写签名（用于签名复用） */
export const bindFormHandSign = (params: H3.Form.Request.TBindHandSignParams) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/form/sign/bind', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 删除手写签名 */
export const removeFormHandSign = (params: { fileId: string }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/form/sign/delete', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 获取表达式文本 */
export const getFormulaExpressionParse = (params: { schemaCode?: string; expression: string }) =>
    request<H3.Http.Response.H3Response<string>>('/v1/formula/expression/parse', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 表达式校验 */
export const validateFormulaExpression = (params: H3.Form.FormDesigner.Request.TFormulaExpressionValidateParams) =>
    request<H3.Http.Response.H3Response<H3.Form.FormDesigner.Response.TFormulaExpressionValidateModel>>(
        '/v1/formula/expression/validate',
        {
            method: H3.Http.MethodType.POST,
            data: params,
            disableBizError: true,
        },
    );

/** 自增位数的值重置 */
export const resetSeqNo = (params: { schemaCode: string; nextNumber: number }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/formdesign/seqno/reset', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/** 获取流水号信息 */
export const getSeqNoInfo = (params: { schemaCode: string }) =>
    request<H3.Http.Response.H3Response<{ increment: number; nullCount: number }>>('/v1/formdesign/seqno/info', {
        method: H3.Http.MethodType.GET,
        params,
    });

const SheetDesignerAction = '/Console/SheetDesigner/OnAction';
/** 校验rollup */
export const getRollupValidate = (params: H3.Form.FormDesigner.Request.IRollupValidateParams) =>
    request<H3.Http.Response.H3ResponseV2>(SheetDesignerAction, {
        method: 'post',
        data: {
            PostData: JSON.stringify({
                ...params,
                ActionName: 'ValidateFormRollup',
            }),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });

/** 校验公式型控件是否可以开启数据引用 */
export const validateReferenceable = (params: H3.Form.FormDesigner.Request.IValidateReferenceableParams) =>
    request<H3.Http.Response.H3ResponseV2>(SheetDesignerAction, {
        method: 'post',
        data: {
            PostData: JSON.stringify({
                ...params,
                ActionName: 'ValidateFormulaReferenceable',
            }),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });

/** 异步更新生成历史记录流水号 */
export const generateHistorySeqNo = (params: H3.Form.FormDesigner.Request.IGenerateHistorySeqNoParams) =>
    request<H3.Http.Response.H3ResponseV2>(SheetDesignerAction, {
        method: 'post',
        data: {
            PostData: JSON.stringify({
                ...params,
                ActionName: 'GenerateSeqNoForHistory',
            }),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });

export const checkFormDesignerIntegrity = (params: { schemaCode: string }) =>
    request<H3.Http.Response.H3Response<H3.Exception.RemoveTip.Response.ICheckFormDesignerIntegrityModel[]>>(
        '/v1/formdesign/incomplete',
        {
            method: H3.Http.MethodType.GET,
            params,
        },
    );

/** 校验树形展示 */
export const checkTreeStructure = (params: { schemaCode: string }) =>
    request<H3.Http.Response.H3Response<H3.Exception.RemoveTip.Response.ICheckTreeStructureModel>>(
        '/v1/formdesign/checktreestructure',
        {
            method: H3.Http.MethodType.GET,
            params,
        },
    );
