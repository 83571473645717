export namespace Auth {
    /**
     * 用户权限的枚举
     * 主管理员、子管理员、个人（是否管理个人应用还是普通使用者）、匿名、外部用户
     */
    export enum UserAuthType {
        Administrator = 1, // 管理员，拥有所有权限
        SubAdministrator = 2, // 子管理员，拥有对应应用的管理权限，但无人员分配权限(应用管理员)
        Personal = 3, // 普通用户，无管理权限，只有数据权限
        Anonymous = 4, // 匿名用户
        External = 5, // 外部用户： 外链的手机号、信任通道的用户
        AppCreator = 6, // 应用的创建者
    }

    /**
     * 数据权限范围
     */
    export enum DataScopeType {
        Own = 0, // 我的
        Relevant = 2, // 相关的
        OwnAndRelevant = 3, // 我的|部门的|相关的
        All = 4, // 所有
        OwnAndOwnDept = 5, // 我部门的
    }

    /**
     * 数据权限元数据
     */
    export const DataScopeTypes = [
        {
            label: '我的',
            value: 0,
        },
        {
            label: '我拥有部门权限的',
            value: 1,
        },
        {
            label: '相关的',
            value: 2,
        },
        {
            label: '我的|部门的|相关的',
            value: 3,
        },
        {
            label: '全部的',
            value: 4,
        },
        {
            label: '部门的',
            value: 5,
        },
    ];
}
