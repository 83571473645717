import { H3 } from '@h3/types';
import { AppContext, SchemaContext } from '../state';

/** 通用缓存数据 */
export default class ContextMetaData {
    private readonly _appContextMap = new Map<string, AppContext>();

    private readonly _schemaContextMap = new Map<string, SchemaContext>();

    private readonly _functionNodeMap = new Map<string, H3.FunctionNode.IFunctionNode>();

    clear() {
        this._appContextMap.clear();
        this._schemaContextMap.clear();
        this._functionNodeMap.clear();
    }

    setAppContext(appContext: AppContext) {
        this._appContextMap.set(appContext.appCode, appContext);
    }

    getAppContext(appCode: string) {
        return this._appContextMap.get(appCode);
    }

    deleteAppContext(appCode: string) {
        const appContext = this.getAppContext(appCode);
        this._appContextMap.delete(appCode);
        if (!appContext) {
            return;
        }
        const children = appContext.children;
        children.forEach((child) => {
            this.deleteSchemaContext(child.code);
        });
    }

    deleteSchemaContext(schemaCode: string) {
        this.deleteFunctionNode(schemaCode);
        this._schemaContextMap.delete(schemaCode);
    }

    setSchemaContext(schemaContext: SchemaContext) {
        this._schemaContextMap.set(schemaContext.schemaCode, schemaContext);
    }

    getSchemaContext(schemaCode: string) {
        return this._schemaContextMap.get(schemaCode);
    }

    getFunctionNode(code: string) {
        return this._functionNodeMap.get(code);
    }

    getFunctionNodes(codes: string[]) {
        return codes.map((code) => this.getFunctionNode(code)!).filter(Boolean);
    }

    setFunctionNode(functionNode: H3.FunctionNode.IFunctionNode) {
        this._functionNodeMap.set(functionNode.code, functionNode);
    }

    setFunctionNodes(functionNodes: H3.FunctionNode.IFunctionNode[]) {
        functionNodes.forEach((functionNode) => {
            this.setFunctionNode(functionNode);
        });
    }

    deleteFunctionNode(code: string) {
        this._functionNodeMap.delete(code);
    }

    deleteFunctionNodes(codes: string[]) {
        codes.forEach((code) => this.deleteFunctionNode(code));
    }
}
