import { Control } from '../control';

export namespace Button {
    /**
     * 动作场景类型
     */
    export const enum ButtonSceneType {
        Form = 1,
        List = 2,
    }
    // 自定义参数字段配置属性
    export interface IDynamicControlProperty {
        defaultItems?: string[];
        defaultValue?: string;
        displayFormat?: string;
        decimalPlaces?: number;
        showMode?: Control.NumberShowMode;
        mode: Control.TextMode;
        unitSelectionRange?: string;
        associationSchemaCode?: string;
    }

    // 按钮自定义参数字段
    export interface IDynamicControl {
        key: string;
        dataType: Control.BizDataType;
        controlType: Control.FormControlType;
        displayName: string;
        visible: boolean;
        required: boolean;
        extension: IDynamicControlProperty;
    }
    export namespace Request {
        // 批量编辑请求参数
        export interface IBatchUpdateRequestParams {
            schemaCode: string;
            actionCode: string;
            actionName: string;
            bizObjectIds: string[];
            bizData: Record<string, unknown>;
            appendOpFields: string[];
        }
    }

    export namespace Response {
        // 批量编辑返回类型
        export interface IBatchUpdateResponse {
            taskId: string;
            logTime: string;
        }

        export type IControlPermission = {
            visible: boolean;
            editable: boolean;
            required: boolean;
            printable: boolean;
        };
    }
}
