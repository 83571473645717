import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';
import * as Attendance from './attendance';
import * as Connect from './connect';
import * as Email from './email';
import * as Esign from './esign';
import * as Ocr from './ocr';
import * as Payment from './payment';
import * as Sms from './sms';
import * as FaceRecognition from './face-recognition';
import * as RobotNotification from './robot-notification';
import * as SyncRule from './sync';

/** 获取插件列表 */
export const getPluginList = () =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.PluginsModel[]>>(Api.PluginList, {
        method: 'post',
    });

/** 插件调用 */
export const invokePlugin = () =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.PluginsModel[]>>(Api.PluginInvoke, {
        method: 'post',
    });

/** 添加插件 */
export const addPlugin = (params: {
    objectId: string;
    code: string;
    name: string;
    pluginType: number;
    content: string;
    state: number;
    createdTime: string;
    modifiedTime: string;
}) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.PluginsModel[]>>(Api.PluginAdd, {
        method: 'post',
        data: params,
    });

/** 删除插件 */
export const removePlugin = (params: { objectId: string }) =>
    request<H3.Http.Response.H3ResponseV2Beta<H3.Plugin.PluginsModel[]>>(Api.PluginRemove, {
        method: 'post',
        data: params,
    });

export { Attendance, Connect, Email, Esign, Ocr, Payment, Sms, FaceRecognition, RobotNotification, SyncRule };
