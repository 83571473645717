import { Control } from '../control';

export namespace AI {
    /**
     * 字段类型
     */
    export const enum FieldType {
        Date = 'date',
        Number = 'number',
        String = 'text',
        Select = 'select',
        Address = 'address',
        SeqNo = 'seqNo',
        User = 'user',
        SubTable = 'subSchema',
        Formula = 'formula',
    }

    /**
     * 控件类型映射
     */
    export const ControlTypeMap = {
        [FieldType.Date]: Control.FormControlType.FormDateTime,
        [FieldType.String]: Control.FormControlType.FormTextBox,
        [FieldType.Number]: Control.FormControlType.FormNumber,
        [FieldType.Select]: Control.FormControlType.FormDropDownList,
        [FieldType.Address]: Control.FormControlType.FormAreaSelect,
        [FieldType.SeqNo]: Control.FormControlType.FormSeqNo,
        [FieldType.User]: Control.FormControlType.FormUser,
        [FieldType.SubTable]: Control.FormControlType.FormGridView,
        [FieldType.Formula]: Control.FormControlType.FormFormula,
    };

    export interface Field {
        fieldName: string;
        type: FieldType;
        // properties?: {};
        fields?: Field[];
        newFieldName?: string;
        options?: string[];
        newOptions?: string[];
        schemaName?: string;
    }

    export enum MessageType {
        User = 'userMessage',
        AI = 'aiMessage',
    }

    /**
     * 消息状态
     */
    export enum MessageStatus {
        Loading = 0, // 正在处理
        Finished = 1, // 已经处理结束
        Canceled = 2, // 手动取消
    }

    export interface MessageData {
        schemaName?: string;
        newSchemaName?: string;
        appName?: string;
        newAppName?: string;
        fields?: Field[] | string[];
        fieldName?: string;
        newFieldName?: string;
        type?: string;
        options?: string[];
    }

    export enum CommentType {
        Like = 0,
        DisLike = 1,
    }

    /**
     * 会话消息体
     */
    export interface Message {
        eventType?: EventType;
        messageType: MessageType;
        dateTime: string;
        aiText?: string; // chatGPT返回内容
        text: string;
        data?: MessageData;
        status: MessageStatus;
        id?: string;
        commentType?: CommentType;
    }

    export interface Chat {
        id: number;
        messages?: Message[];
    }

    export class UserInfo {
        /** 登录唯一标识 */
        token: string | undefined;
        /** 系统唯一标识 */
        userId: string | undefined;
        /** 用户名 */
        name: string | undefined;
        /** 头像 */
        avatar: string | undefined;
    }

    export type d = 0;

    /**
     * 指令类型
     */
    export enum EventType {
        CreateApp = 'createApp',
        RenameApp = 'renameApp',
        CreateSchemaWithApp = 'createSchemaWithApp',
        CreateSchemaWithoutApp = 'createSchemaWithoutApp',
        SelectApp = 'selectApp',
        NotSupport = 'notSupported',
        InitSchema = 'initSchema', // 初始化表单
        AddFields = 'addFields',
        AboutMe = 'aboutMe',
        RemoveField = 'removeField',
        RemoveFields = 'removeFields',
        UpdateField = 'updateField',
        UpdateSchemaName = 'updateSchemaName',
        UpdateFieldName = 'updateFieldName',
        Publish = 'publish',
        Cancel = 'cancel',
    }

    /**
     * 对话框状态缓存对象
     */
    export interface StateCache {
        isSimpleMode: boolean;
        isOpen: boolean;
        position: {
            x: number;
            y: number;
        };
        size: {
            width: number;
            height: number;
        };
    }

    /**
     * 指令执行的上下文
     */
    export interface AIAppContext {
        appName?: string;
        appCode?: string;
        schemaName?: string;
        schemaCode?: string; // 修改表的场景
        fields?: Field[];
        deferCommand?: string; // 延迟执行的指令，如创建表单，先返回文本，后执行页面跳转，再执行表单初始化
    }

    /**
     * 错误指令与提示信息的对应
     */
    export const ErrorMap = {
        1000: '很抱歉，控件名称长度最多允许输入32个字符。建议您缩短控件名称长度后再进行添加。',
        1001: '很抱歉，{typeName}最多允许添加一个，且当前已存在，无法完成添加。如果仍然需要，您可以先删除当前已存在的{typeName}。',
        1002: '最多只允许添加n个，目前已达上限，无法完成添加。如果仍然需要，您可以先删除部分。',
        1003: '很抱歉，由于{typeName}不是子表支持的类型，无法完成添加。建议您选择其他类型的控件进行添加。',
        1004: '很抱歉，由于{typeName}不是Tab控件支持的类型，无法完成添加。建议您选择其他类型的控件进行添加。',
        1005: '很抱歉，由于{typeName}不是布局控件支持的类型，无法完成添加。建议您选择其他类型的控件进行添加。',
        2000: '很抱歉，该名称的控件不存在，无法为您完成删除。您可以选择已存在的控件进行删除。',
        3000: '很抱歉，控件名称长度最多允许输入32个字符。建议您缩短表单名称长度后再进行更新。',
        3001: '很抱歉，该指定控件不存在，无法完成修改。建议您先找到要修改的控件再进行修改。',
        3002: '很抱歉，{typeName}最多允许添加一个，且当前已存在，无法完成更新。如果仍然需要，您可以先删除当前已存在的{typeName}。',
        3003: '很抱歉，目前子表暂不支持{typeName}控件类型，建议您更换其他类型的控件。',
        4000: '很抱歉，表单名称长度最多允许输入32个字符。建议您缩短表单名称长度后再进行更新。',
    };
}
