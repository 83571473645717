import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 新增 */
export function createFunctionNode(params: H3.FunctionNode.Request.ICreateFunctionNodeModel) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.Response.ICreateFunctionNodeModel>>('/v1/functionnode', {
        method: 'post',
        data: params,
    });
}

/** 更新 */
export function updateFunctionNode(params: H3.FunctionNode.Request.IUpdateFunctionNodeModel) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode>>('/v1/functionnode/update', {
        method: 'post',
        data: params,
    });
}

/** 删除 */
export function deleteFunctionNode(code: string) {
    const params = {
        code,
    };
    return request<H3.Http.Response.H3Response<boolean>>('/v1/functionnode/remove', {
        method: 'post',
        data: params,
    });
}

/** 排序 */
export function sortFunctionNode(params: H3.FunctionNode.Request.ISortFunctionNodeModel) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/functionnode/sort', {
        method: 'put',
        data: params,
    });
}

/** 复制 */
export function copyFunctionNode(code: string) {
    const params = {
        code,
    };
    return request<H3.Http.Response.H3Response<string>>('/v1/functionnode/copy', {
        method: 'post',
        data: params,
    });
}

/** 获取复制进度 */
export function copyFunctionNodeProgress(actionId: string) {
    const params = {
        actionId,
    };
    return request<H3.Http.Response.H3Response<H3.FunctionNode.Response.GetCopyFunctionProgressModal>>(
        '/v1/functionnode/copy/progress',
        {
            method: 'get',
            params,
        },
    );
}

/** 移动 */
export function moveFunctionNode(params: H3.FunctionNode.Request.IMoveFunctionNodeModel) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/functionnode/move', {
        method: 'post',
        data: params,
    });
}

/** 根据父节点编码查询指定类型的子节点编码，未指定子节点类型时返回所有子节点 */
export function getChildrenByParentCode(
    parentCode: string,
    checkHasChild = false,
    nodeTypes?: H3.FunctionNode.FunctionNodeType[],
) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/functionnode/children', {
        method: 'post',
        data: {
            parentCode,
            nodeTypes,
            checkHasChild,
        },
    });
}

/** 根据关键字查询指定类型的节点，未指定节点类型时默认查询所有节点（该接口会同时返回查询节点的所有祖先节点） */
export function getFunctionNodeByKeyword(
    keyword: string,
    nodeTypes?: H3.FunctionNode.FunctionNodeType[],
    options?: H3.Http.Request.RequestOptions,
) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/functionnode/list/bykeyword', {
        method: 'post',
        data: {
            keyword,
            nodeTypes,
        },
        ...options,
    });
}

/** 根据节点编码查询节点信息 */
export function getFunctionNodeByCodes(codes: string[]) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNode[]>>('/v1/functionnode/list/bycodes', {
        method: 'post',
        data: {
            codes,
        },
    });
}

/** 根据节点编码查询节点权限 */
export function getFunctionNodeAuthorizedByCodes(codes: string[]) {
    return request<H3.Http.Response.H3Response<H3.FunctionNode.IFunctionNodeAuthorized[]>>(
        '/v1/functionnode/auth/bycodes',
        {
            method: 'post',
            data: {
                codes,
            },
        },
    );
}

/**
 * 获取自定义链接信息
 * @param schemaCode 自定义链接编码
 */
export function getCustomLinkInfo(schemaCode: string) {
    return request<
        H3.Http.Response.H3Response<{
            pc?: {
                fileId: string;
                fileName: string;
            };
            mobile?: {
                fileId: string;
                fileName: string;
            };
        }>
    >('/v1/customlink', {
        method: 'get',
        params: {
            schemacode: schemaCode,
        },
    });
}

/** 报表升级到仪表盘 */
export function upgradeReport(
    params: H3.FunctionNode.Request.IUpgradeReportModel,
    requestOption: H3.Http.Request.RequestOptions,
) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/functionnode/report/upgrade', {
        method: 'post',
        data: params,
        disableBizError: requestOption.disableBizError,
        disableSysError: requestOption.disableSysError,
    });
}

/** 批量升级旧报表 */
export function batchUpdateReports(params: H3.FunctionNode.Request.IBatchUpgradeReport) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/reporting/migrate', {
        method: 'post',
        params,
    });
}

/** 获取旧报表数量 */
export function getReportCount(appCode: string) {
    return request<H3.Http.Response.H3Response>(`/v1/reporting/list/migratable/${appCode}`, {
        method: 'get',
    });
}

/** 通过appCode获取应用安装状态 */
export function getAppStatus(appCode: string) {
    const params = {
        appCode,
    };
    return request<
        H3.Http.Response.H3Response<{
            appCode: '';
            schemaCode: '';
            progress: number;
            installErrorFlag: boolean;
        }>
    >('/v1/functionnode/app/status', {
        method: 'get',
        params,
    });
}

/** 通过AppCode获取应用下汇总控件数量 */
export function getAppRollupCount(appCode: string) {
    const params = {
        appCode,
    };
    return request<H3.Http.Response.H3Response<number>>('/v1/functionnode/app/rollupscount', {
        method: H3.Http.MethodType.GET,
        params,
    });
}

/** 通过AppCode获取应用下汇总控件数量 */
export function getAssociationTree(schemaCode: string) {
    const url = `/v1/form/loadAssociationstree?schemaCode=${schemaCode}`;
    return request<H3.Http.Response.H3Response<{ nodes: H3.FunctionNode.ITreeNode[] }>>(url, {
        method: H3.Http.MethodType.GET,
        params: {},
        disableBizError: true,
    });
}

/**
 *
 * @param code 要移动的节点编码
 * @param targetAppCode 目标应用编码
 * @param targetParentCode 目标分组编码
 */
export function checkMove(code: string, targetAppCode: string, targetParentCode: string) {
    return request<H3.Http.Response.H3Response<null | string[]>>('/v1/functionNode/checkmove', {
        method: 'post',
        data: {
            code,
            targetAppCode,
            targetParentCode,
        },
        disableBizError: true,
    });
}
