import { H3 } from '@h3/types';
import { request } from '@h3/request';

/**
 * @param schemaCode 模型编码
 * @param actionGuid 操作编码
 * @param templateCode 模版编码
 * @param ids 数据id集合
 */
export function diyPrint(schemaCode: string, actionGuid: string, templateCode: string, ids: string[]) {
    return request<H3.Http.Response.H3Response<H3.Form.FormPrint.Response.IDiyPrint>>('/v1/toolkit/print/paper', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            actionGuid,
            templateCode,
            bizObjectIds: ids,
        },
    });
}

/**
 * 清除正在执行的打印
 * @param schemaCode 模型编码
 * @param actionGuid 操作编码
 * @param templateCode 模版编码
 * @returns
 */
export function clearDiyPrint(schemaCode: string, actionGuid: string, templateCode: string) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/toolkit/print/clearaction', {
        method: H3.Http.MethodType.DELETE,
        data: {
            schemaCode,
            actionGuid,
            templateCode,
        },
    });
}

/**
 * 获取打印进度
 * @param schemaCode 模型编码
 * @param actionGuid 操作编码
 * @param templateCode 模版编码
 * @returns
 */
export const getPrintProgress = (schemaCode: string, actionGuid: string, templateCode: string) => {
    return request<H3.Http.Response.H3Response<H3.Form.FormPrint.Response.IFormPrintProgress>>(
        '/v1/toolkit/print/paper/form/progress',
        {
            method: H3.Http.MethodType.GET,
            params: {
                schemaCode,
                actionGuid,
                templateCode,
            },
        },
    );
};
/**
 * 加载表单配置（布局配置）
 * @param schemaCode 表单编码
 * @param timestamp 缓存时间戳，默认为-1表示没有缓存
 */
export const getFormSetting = (schemaCode: string, timestamp = -1) =>
    request<H3.Http.Response.H3Response<H3.Form.Response.TLoadFormSettingModal>>('/v1/form/loadsetting', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            timestamp,
        },
    });

/**
 * 加载表单数据
 * @param params 表单信息
 */
export const getFormData = (params: H3.Form.Request.TLoadFormDataParams) =>
    request<H3.Http.Response.H3Response<H3.Form.Response.TLoadFormDataModal>>('/v1/form/loaddata', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

/**
 * 获取表单关联列表基本信息
 * @deprecated 该接口已废弃，拆分为getFormAssociationListHeader和getFormAssociationListCount两个接口
 */
export const getFormAssociationListInfo = (schemaCode: string, bizObjectId: string) =>
    request<H3.Http.Response.H3Response<H3.Form.TAssociationListSummary[]>>('/v1/form/associationlist/info', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            bizObjectId,
        },
    });

/** 获取指定数据关联列表基本信息 */
export const getFormAssociationListHeader = (schemaCode: string, bizObjectId: string) =>
    request<H3.Http.Response.H3Response<Omit<H3.Form.TAssociationListSummary, 'count'>[]>>(
        '/v1/form/associationlist/header',
        {
            method: H3.Http.MethodType.POST,
            data: {
                schemaCode,
                bizObjectId,
            },
        },
    );

/** 获取执行数据关联列表数据量 */
export const getFormAssociationListCount = (
    schemaCode: string,
    bizObjectId: string,
    associationSchemaCodes?: string[],
) => {
    return request<H3.Http.Response.H3Response<Record<string, number>>>('/v1/form/associationlist/count', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            bizObjectId,
            associationSchemaCodes,
        },
    });
};

/** 获取表单关联列表列配置 */
export const getFormAssociationListColumns = (schemaCode: string, associationSchemaCode: string) =>
    request<H3.Http.Response.H3Response<H3.Form.Response.TAssociationListColumn[]>>('/v1/form/associationlist/column', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            associationSchemaCode,
        },
    });

/** 获取关联表单控件列配置 */
export const getFormAssociationColumns = (schemaCode: string, propertyName: string, associationSchemaCode: string) =>
    request<H3.Http.Response.H3Response<H3.Form.Response.TAssociationColumn[]>>('/v1/form/association/column', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            propertyName,
            associationSchemaCode,
        },
    });

/** 表单按钮操作 */
export const formDoAction = (params: H3.Form.Request.TDoActionParams) =>
    request<H3.Http.Response.H3Response<H3.Form.Response.TDoActionModal>>('/v1/form/action/execute', {
        method: H3.Http.MethodType.POST,
        data: params,
    });

export function getFormActionStatus(requestId: string) {
    return request<H3.Http.Response.H3Response<string>>('/v1/toolkit/slowrequest/state', {
        method: H3.Http.MethodType.GET,
        params: {
            requestId,
        },
    });
}

/**
 * 复制表单文件
 * @param fileIds 文件id列表
 * @param times 复制次数
 * @returns 文件原始id到复制后文件列表的映射
 */
export const copyFormFile = async (fileIds: string[], times: number) =>
    request<H3.Http.Response.H3Response<Record<string, Array<H3.Form.Response.TLoadDataFile>>>>('/v1/form/copyfile', {
        method: H3.Http.MethodType.POST,
        data: {
            fileIds,
            times,
        },
    });

/**
 * oss批量下载文件
 * @param fileIds 文件信息
 * @returns 任务Id
 */
export const batchDownloadFile = async (schemaCode: string, fileIds: string[]) =>
    request<H3.Http.Response.H3Response<Array<string>>>('/v1/file/batchdownload', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            fileIds,
        },
    });

// 新增任务提醒
export const addTaskTip = (params: H3.Form.TaskTip.TTaskTipAddParams) => {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/tasktip/add', {
        method: H3.Http.MethodType.POST,
        data: params,
    });
};

// 获取任务提醒列表
export const getTaskTips = (schemaCode: string, bizObjectId: string) => {
    const url = `/v1/tasktip/list?schemaCode=${schemaCode}&bizObjectId=${bizObjectId}`;
    return request<H3.Http.Response.H3Response<H3.Form.TaskTip.ITaskTip[]>>(url, {
        method: H3.Http.MethodType.GET,
        data: {},
    });
};

// 删除任务提醒
export const removeTaskTip = (taskId: string) => {
    const url = `/v1/tasktip/remove?taskId=${taskId}`;
    return request<H3.Http.Response.H3Response<boolean>>(url, {
        method: H3.Http.MethodType.POST,
        data: {},
    });
};

// 终止任务提醒
export const cancelTaskTip = (taskId: string) => {
    const url = `/v1/tasktip/cancel?taskId=${taskId}`;
    return request<H3.Http.Response.H3Response<boolean>>(url, {
        method: H3.Http.MethodType.POST,
        data: {},
    });
};

// 获取评论数量
export const countSNS = (schemaCode: string, bizObjectId: string) => {
    const url = `/v1/toolkit/sns/count?schemaCode=${schemaCode}&bizObjectId=${bizObjectId}`;
    return request<H3.Http.Response.H3Response<H3.Form.SNS.ISNSCount>>(url, {
        method: H3.Http.MethodType.GET,
        data: {},
    });
};

// 获取评论数据
export const getSNSList = (params: {
    schemaCode: string;
    bizObjectId: string;
    pageIndex: number;
    pageSize: number;
}) => {
    const url = '/v1/toolkit/sns/list';
    return request<H3.Http.Response.H3Response<H3.Form.SNS.ISNSItem[]>>(url, {
        method: H3.Http.MethodType.POST,
        data: params,
    });
};

// 新增评论
export const addSNS = (params: H3.Form.SNS.IAddSNSItemParams) => {
    return request<H3.Http.Response.H3Response<H3.Form.SNS.Response.ISNSAddItem>>('/v1/toolkit/sns/add', {
        method: H3.Http.MethodType.POST,
        data: params,
    });
};

// 获取可以@的人员列表信息
export const getAtList = (params: H3.Form.SNS.IGetAtUserParams) => {
    return request<H3.Http.Response.H3Response<H3.Form.SNS.ISNSAtInfo[]>>('/v1/form/sns/quotable/user', {
        method: H3.Http.MethodType.POST,
        data: params,
    });
};

// 删除评论
export const removeSNS = (snsId: string) => {
    const url = `/v1/toolkit/sns/remove?snsId=${snsId}`;
    return request<H3.Http.Response.H3Response<boolean>>(url, {
        method: H3.Http.MethodType.DELETE,
    });
};

// 回复评论
export const addComment = (snsId: string, text: string) => {
    const data = {
        snsId,
        text,
    };
    return request<H3.Http.Response.H3Response<H3.Form.SNS.Response.ISNSAddComment>>('/v1/toolkit/sns/comment/add', {
        method: H3.Http.MethodType.POST,
        data,
    });
};
