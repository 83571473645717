export namespace Import {
    /** 导入任务状态 */
    export const enum ExportState {
        /** 等待中 */
        Waiting = 0,

        /** 进行中 */
        Running = 1,

        /** 成功 */
        Success = 2,

        /** 失败 */
        Fail = 3,

        /** 取消 */
        Cancel = 4,
    }
    export namespace Request {
        export interface IGetSheetData {
            Rows: number;
            SheetName: string;
            ImportGuid: string | undefined;
            ActionName: string;
        }
        export interface ICheckPropertyFields {
            appCode: string;
            sheetName: string;
            importGuid: string | undefined;
            ActionName: string;
            selectRow: number;
            fields: object;
        }
        export interface IGenerateSchema {
            appCode: string;
            sheetName: string | undefined;
            displayName: string;
            groupCode?: string;
            iconCss: string;
            titleRowNum: number | undefined;
            importGuid: string | undefined;
            fields: object[];
        }
        export interface IGetGenerateSchemaProgress {
            ImportGuid: string | undefined;
            ActionName: string;
        }
        export interface IAbortGenerateSchemaImport {
            ImportGuid: string;
            ActionName: string;
        }
        export interface IClearCache {
            ImportGuid: string | undefined;
            ActionName: string;
        }
    }
    export namespace Response {
        export interface IGetImportTemplate {
            OSSUrl: string;
        }
        export interface IGetSheetData {
            Rows: object;
        }
        export interface ICheckPropertyFields {
            FunctionGroup: object;
            PropertyFields: object;
        }
        export interface IGenerateSchema {
            SchemaCode: string;
        }
        export interface IGetGenerateSchemaProgress {
            SchemaCode: string;
            ImportProgress: number;
            ImportState: number;
            ImportSuccessCount: number;
            ImportErrorMsg?: string;
        }
        /** 日志记录模型 */
        export type RecordDetailModel = {
            /** 操作id */
            actionGuid: string;

            /** 表单编码 */
            schemaCode: string;

            /** 表单名称 */
            displayName: string;

            /** 文件名称 */
            fileName: string;

            /** 进度百分比 */
            percent: number;

            /** 错误报告地址 */
            errorRecordPath: string;

            /** 记录类型（这个指示导入、导出类型） */
            recordType: number;

            /** 导出文件地址 */
            fileUrl: string;

            /** 记录类型名称 */
            recordTypeName: string;

            /** 任务状态 */
            taskState: ExportState;

            /** 创建时间 */
            createdTime: number;

            /** unknown */
            remark: string;

            /** 是否存在失败的记录 */
            hasFailRecords: boolean;

            /** 跳过的数量 */
            skipQuantity: number;

            /** 前面还在等待的任务数 */
            wattingTasks: number;
        };
    }
}
