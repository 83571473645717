import { request, streamRequest } from '@h3/request';
import { H3 } from '@h3/types';

/** 向ai发送指令 */
export function generateText(message: string, user: string) {
    return request<
        H3.Http.Response.H3Response<{
            choices: Array<{ message: { content: string } }>;
            id: string;
            usage: { promptTokens: number };
        }>
    >('/v1/openai/chat/completions', {
        method: 'post',
        data: {
            message,
            user,
        },
    });
}

/**
 *
 * @param id 消息id
 * @param usefulFlag 是否有用标识
 */
export function comment(id: string, usefulFlag: boolean) {
    return request<H3.Http.Response.H3Response>('v1/openai/choice/useful', {
        method: 'post',
        data: {
            id,
            usefulFlag,
        },
    });
}

/**
 * 流式AI对话
 * @param engineCode 引擎编码
 * @param userId 用户id
 * @param sceneId 场景id（用于识别一个场景下所有的会话）
 * @param message prompt
 */
export function chatStream(engineCode: string, userId: string, sceneId: string, message: string, init?: RequestInit) {
    return streamRequest('/v1/ai-service/stream-chat', {
        method: H3.Http.MethodType.POST,
        body: JSON.stringify({
            engineCode,
            userId,
            sceneId,
            message,
        }),
        ...init,
    });
}
