import { Control } from '../control';
import { Org } from '../org';

export namespace Print {
    export enum PageDirection {
        Horizontal = 0,

        Vertical = 1,
    }

    export enum PrintState {
        Error = -1,

        Wait = 0,

        Print = 1,

        LoadData = 2,

        AssembleData = 3,

        CalcPage = 4,

        GenerateFile = 5,

        Complete = 6,
    }

    /** 打印模板基础数据 */
    export type TPrintTemplate = {
        /** 模板编码 */
        templateCode: string;

        /** 模板名称 */
        displayName: string;

        /** 是否Excel打印 */
        isExcel: boolean;
        sortKey?: number; // 排序码
    };

    /** 表单默认打印配置 */
    export type TDefaultPrintConfig = {
        /** 是否打印公司名称 */
        PrintCompanyName: boolean;

        /** 是否打印评论 */
        PrintComment: boolean;

        /** 是否打印打印人 */
        Printer: boolean;

        /** 是否打印时间 */
        PrintTime: boolean;

        /** 是否打印二维码 */
        PrintQrCode: boolean;
    };

    /** 默认打印相关数据结构 */
    export namespace DefaultPrint {
        /** 打印数据类型枚举 */
        export enum PrintDataType {
            /** 普通控件打印数据 */
            Common = 'common',

            /** 一行多列控件打印数据 */
            Layout = 'layout',

            /** 分组标题控件打印数据 */
            GroupTitle = 'groupTitle',

            /** 描述型控件打印数据 */
            Description = 'description',

            /** 子表控件打印数据 */
            GridView = 'gridView',

            /** 标签页控件打印数据 */
            Tab = 'tab',
        }

        /** 控件打印数据 */
        export type TPrintData =
            | TCommonPrintData
            | TLayoutPrintData
            | TGroupTitlePrintData
            | TDescriptionPrintData
            | TGridViewPrintData
            | TTabPrintData;

        /** 控件打印值 */
        export type TPrintValue =
            | string
            | {
                  type: 'files';
                  files: Array<{
                      name: string;
                      url: string;
                      isHandSign: boolean;
                  }>;
              }
            | {
                  type: 'orgs';
                  orgs: Array<{
                      name: string;
                      entryId: string;
                      type: Org.WeChartOrgType;
                  }>;
              };

        /** 普通控件打印数据 */
        export type TCommonPrintData = {
            /** 打印数据类型 */
            type: PrintDataType.Common;

            /** 标题 */
            label: string;

            /** 值 */
            value: TPrintValue;
        };

        /** 一行多列控件打印数据 */
        export type TLayoutPrintData = {
            /** 打印数据类型 */
            type: PrintDataType.Layout;

            /** 总布局列数 */
            totalFlex: number;

            /** 子数据 */
            children: Array<{
                /** 布局列占比 */
                flex: number;

                /** 打印数据 */
                item: TCommonPrintData | null;
            }>;
        };

        /** 分组标题控件打印数据 */
        export type TGroupTitlePrintData = {
            /** 打印数据类型 */
            type: PrintDataType.GroupTitle;

            /** 标题 */
            label: string;

            /** 标题对齐方式 */
            align: Control.GroupTitleAlignment;
        };

        /** 描述型控件打印数据 */
        export type TDescriptionPrintData = {
            /** 打印数据类型 */
            type: PrintDataType.Description;

            /** 描述 */
            value: string;
        };

        /** 子表控件打印数据 */
        export type TGridViewPrintData = {
            /** 打印数据类型 */
            type: PrintDataType.GridView;

            /** 子表名称 */
            label: string;

            /** 子表列数据 */
            columns: Array<{
                /** 列字段编码 */
                field: string;

                /** 列标题 */
                title: string;
            }>;

            /** 子表内控件打印数据 */
            rows: Array<Record<string, TCommonPrintData | null>>;
        };

        /** 标签页控件打印数据 */
        export type TTabPrintData = {
            /** 打印数据类型 */
            type: PrintDataType.Tab;

            /** 标签页数据 */
            data: Array<{
                /** 页签名称 */
                label: string;

                /** 页签数据 */
                data: Array<TPrintData>;
            }>;
        };
    }

    export namespace Response {
        export interface IGetProgressModel {
            download: boolean;

            process: number;

            state: PrintState;

            fileUrl: string;

            direction: PageDirection;
        }

        export interface IStartSpacePrint {
            actionId: string;

            queueCount: number;
        }
    }
}
