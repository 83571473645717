import { Filter } from '../filter';
import { Org } from '../org';
import { Util } from '../util';

export namespace Control {
    /** 业务数据类型 */
    export const enum BizDataType {
        /// 空值
        Unspecified = -1,
        /// 逻辑数组型
        BoolArray = 0,
        /// 逻辑型
        Bool = 1,
        /// 时间数组型
        DateTimeArray = 4,
        /// 日期型
        DateTime = 5,
        /// 双精度数组型
        DoubleArray = 6,
        /// 双精度数值型
        Double = 7,
        /// 整数数组型
        IntArray = 8,
        /// 整数
        Int = 9,
        /// 长整型数组型
        LongArray = 10,
        /// 长整数
        Long = 11,
        /// 字符串数组型
        StringArray = 12,
        /// 长文本
        String = 13,
        /// 短文本
        ShortString = 14,
        /// 签名
        // Sign  = 15,
        /// 链接
        HyperLink = 16,
        /// 审批
        Comment = 17,
        /// 二进制流
        ByteArray = 20,
        /// 图片类型
        Image = 23,
        /// 未指定类型的附件
        Attachment = 24,
        /// 时间段型
        TimeSpan = 25,
        /// 参与者（单人）
        SingleParticipant = 26,
        /// 参与者（多人）
        MultiParticipant = 27,
        // /// 参与部门 (单个部门)
        // SingleDepartment = 28,
        // /// 参与部门 (单个部门)
        // MultiDepartment = 29,
        /// Html
        Html = 30,
        /// 对象类型
        Object = 31,
        /// Xml
        Xml = 32,
        /// Guid
        Guid = 33,
        /// Guid数组
        GuidArray = 34,
        /// Decimal
        Decimal = 35,
        /// Decimal数组
        DecimalArray = 36,
        /// 业务对象
        BizObject = 40,
        /// 业务对象数组
        BizObjectArray = 41,
        /// 业务结构
        BizStructure = 42,
        /// 业务结构数组
        BizStructureArray = 43,
        /// 关联查询
        Association = 50,
        // 关联查询多表单
        AssociationArray = 51,
        /// 地图
        Map = 55,
        // 地址
        Address = 56,
        // 公式型控件
        Formula = 57,
        // 手写签名
        Signature = 58,
        // 扩展控件 文字识别
        Plugin = 70,
    }

    /** 控件名称枚举 */
    export enum FormControlKeys {
        /**
         * 单文本
         */
        FormTextBox = 'FormTextBox',
        /**
         * 多行文本
         */
        FormTextArea = 'FormTextArea',
        /**
         * 数字
         */
        FormNumber = 'FormNumber',
        /**
         * 日期
         */
        FormDateTime = 'FormDateTime',
        /**
         * 子表
         */
        FormGridView = 'FormGridView',
        /**
         * 下拉框
         */
        FormDropDownList = 'FormDropDownList',
        /**
         * 单选框
         */
        FormRadioButtonList = 'FormRadioButtonList',
        /**
         * 复选框
         */
        FormCheckboxList = 'FormCheckboxList',
        /**
         * 图片
         */
        FormPhoto = 'FormPhoto',
        /**
         * 附件
         */
        FormAttachment = 'FormAttachment',
        /**
         * 单选人
         */
        FormUser = 'FormUser',
        /**
         * 多选人
         */
        FormMultiUser = 'FormMultiUser',
        /**
         * 位置
         */
        FormMap = 'FormMap',
        /**
         * 公式型组件
         */
        FormFormula = 'FormFormula',
        /**
         * 流水号
         */
        FormSeqNo = 'FormSeqNo',
        /**
         * label控件
         */
        FormLabel = 'FormLabel',
        /**
         * 地址控件
         */
        FormAreaSelect = 'FormAreaSelect',
        /**
         * 关联表单
         */
        FormQuery = 'FormQuery',
        /**
         * 是否控件
         */
        FormCheckbox = 'FormCheckbox',
        /**
         * 关联表单多选
         */
        FormMultiQuery = 'FormMultiQuery',
        /**
         * 关联属性
         */
        FormAssociateProperty = 'FormAssociateProperty',
        /**
         * 按钮
         */
        FormButton = 'FormButton',
        /**
         * 扩展控件 文字识别
         */
        FormOcr = 'FormOcr',
        /**
         * 手写签名
         */
        FormHandSign = 'FormHandSign',
        /**
         * 电子签章
         */
        FormEsign = 'FormEsign',
        /**
         * 部门单选
         */
        FormDepartment = 'FormDepartment',
        /**
         * 部门多选
         */
        FormMultiDepartment = 'FormMultiDepartment',
        /**
         * 人脸识别
         */
        FormFaceRecognition = 'FormFaceRecognition',
        /**
         * 创建人
         */
        FormCreater = 'FormCreater',
        /**
         * 拥有者
         */
        FormOwner = 'FormOwner',
        /**
         * 所属部门
         */
        FormOwnerDepartment = 'FormOwnerDepartment',
        /**
         * 创建时间
         */
        FormCreatedTime = 'FormCreatedTime',
        /**
         * 修改时间
         */
        FormModifiedTime = 'FormModifiedTime',
        /**
         * 描述控件
         */
        FormDescription = 'FormDescription',
        /**
         * 标签页
         */
        FormTab = 'FormTab',
        /**
         * 分组标题
         */
        FormGroupTitle = 'FormGroupTitle',
        /**
         * 一行多列
         */
        FormLayout = 'FormLayout',
        /**
         * 流程状态
         */
        FormStatus = 'FormStatus',
        /**
         * 批量扫码
         */
        FormBatchScan = 'FormBatchScan',
        /**
         * 扩展按钮
         */
        FormAdvancedButton = 'FormAdvancedButton',
        /**
         * 汇总计算
         */
        FormRollup = 'FormRollup',

        /**
         * 动态组件
         */
        FormDynamic = 'FormDynamic',
        /**
         * 当前节点
         */
        ActivityName = 'ActivityName',
        /**
         * 当前处理人
         */
        Participant = 'Participant',

        /** 系统字段 */
        // ObjectId = 'ObjectId',
        // ParentObjectId = 'ParentObjectId',
        // Name = 'Name',
        // ModifiedBy = 'ModifiedBy',
        // Status = 'Status',
    }

    /** 表单控件类型 */
    export enum FormControlType {
        // 基础控件
        /**
         * 单文本
         */
        FormTextBox = 1,

        /**
         * 长文本
         */
        FormTextArea = 2,

        /**
         * 日期
         */
        FormDateTime = 3,

        /**
         * 数值
         */
        FormNumber = 4,

        /**
         * 单选
         */
        FormRadioButtonList = 5,

        /**
         * 复选
         */
        FormCheckboxList = 6,

        /**
         * 下拉
         */
        FormDropDownList = 7,

        /**
         * 是否控件
         */
        FormCheckbox = 8,

        /**
         * 地址
         */
        FormAreaSelect = 9,

        /**
         * 位置
         */
        FormMap = 10,

        /**
         * 人员多选
         */
        FormMultiUser = 11,

        /**
         * 人员单选
         */
        FormUser = 12,

        /**
         * 部门单选
         */
        FormDepartment = 13,

        /**
         * 部门多选
         */
        FormMultiDepartment = 14,

        /**
         * 附件
         */
        FormAttachment = 15,

        /**
         * 图片
         */
        FormPhoto = 16,

        // 布局控件
        /**
         * 分组标题
         */
        FormGroupTitle = 101,

        /**
         * 布局控件（一行两列）
         */
        FormLayout = 102,

        /**
         * 描述说明控件
         */
        FormDescription = 103,

        /**
         * 明细表
         */
        FormGridView = 104,

        /**
         * 标签页
         */
        FormTab = 105,

        // 系统控件
        /**
         * 系统-流水号
         */
        FormSeqNo = 201,

        /**
         * 创建人
         */
        FormCreater = 202,

        /**
         * 拥有者
         */
        FormOwner = 203,

        /**
         * 所属部门
         */
        FormOwnerDepartment = 204,

        /**
         * 创建时间
         */
        FormCreatedTime = 205,

        /**
         * 修改时间
         */
        FormModifiedTime = 206,

        /**
         * 系统字段-创建人、创建时间、修改时间
         */
        FormLabel = 207,

        /**
         * 流程控件
         */
        WorkFlow = 208,

        /**
         * 当前节点
         */
        ActivityName = 209,

        /**
         * 当前处理人
         */
        Participant = 210,

        // 高级控件
        /**
         * 关联表单
         */
        FormQuery = 301,

        /**
         * 关联多选
         */
        FormMultiQuery = 302,

        /**
         * 关联属性
         */
        FormAssociateProperty = 303,

        /**
         * 公式型控件
         */
        FormFormula = 304,

        /**
         * 按钮
         */
        FormButton = 305,

        /**
         * 手写签名
         */
        FormHandSign = 306,

        /**
         * 文字识别
         */
        FormOcr = 401,

        /**
         * 扩展按钮(未用到)
         */
        FormAdvancedButton = 402,

        /**
         * 电子签章
         */
        FormEsign = 403,

        /**
         * 批量扫码(未用到)
         */
        FormBatchScan = 404,

        /**
         * 人脸识别
         */
        FormFaceRecognition = 405,

        /**
         * 数据标题
         */
        FormDataTitle = 1000,

        /**
         * 流程状态
         */
        FormStatus = 10001,
        /**
         * 汇总计算
         */
        FormRollup = 501,

        /**
         * 动态自定义组件 没有DataField 不存schema
         */
        FormDynamic = 1001,
    }

    /** 控件类型映射 */
    export const ControlTypeToKeys = {
        [FormControlType.FormTextBox]: FormControlKeys.FormTextBox,
        [FormControlType.FormTextArea]: FormControlKeys.FormTextArea,
        [FormControlType.FormDateTime]: FormControlKeys.FormDateTime,
        [FormControlType.FormNumber]: FormControlKeys.FormNumber,
        [FormControlType.FormRadioButtonList]: FormControlKeys.FormRadioButtonList,
        [FormControlType.FormCheckboxList]: FormControlKeys.FormCheckboxList,
        [FormControlType.FormDropDownList]: FormControlKeys.FormDropDownList,
        [FormControlType.FormCheckbox]: FormControlKeys.FormCheckbox,
        [FormControlType.FormAttachment]: FormControlKeys.FormAttachment,
        [FormControlType.FormPhoto]: FormControlKeys.FormPhoto,
        [FormControlType.FormAreaSelect]: FormControlKeys.FormAreaSelect,
        [FormControlType.FormMap]: FormControlKeys.FormMap,
        [FormControlType.FormUser]: FormControlKeys.FormUser,
        [FormControlType.FormMultiUser]: FormControlKeys.FormMultiUser,
        [FormControlType.FormDepartment]: FormControlKeys.FormDepartment,
        [FormControlType.FormMultiDepartment]: FormControlKeys.FormMultiDepartment,
        [FormControlType.FormGroupTitle]: FormControlKeys.FormGroupTitle,
        [FormControlType.FormLayout]: FormControlKeys.FormLayout,
        [FormControlType.FormDescription]: FormControlKeys.FormDescription,
        [FormControlType.FormTab]: FormControlKeys.FormTab,
        [FormControlType.FormGridView]: FormControlKeys.FormGridView,
        [FormControlType.FormSeqNo]: FormControlKeys.FormSeqNo,
        [FormControlType.FormCreater]: FormControlKeys.FormCreater,
        [FormControlType.FormOwner]: FormControlKeys.FormOwner,
        [FormControlType.FormOwnerDepartment]: FormControlKeys.FormOwnerDepartment,
        [FormControlType.FormCreatedTime]: FormControlKeys.FormCreatedTime,
        [FormControlType.FormModifiedTime]: FormControlKeys.FormModifiedTime,
        [FormControlType.FormQuery]: FormControlKeys.FormQuery,
        [FormControlType.FormAssociateProperty]: FormControlKeys.FormAssociateProperty,
        [FormControlType.FormMultiQuery]: FormControlKeys.FormMultiQuery,
        [FormControlType.FormFormula]: FormControlKeys.FormFormula,
        [FormControlType.FormButton]: FormControlKeys.FormButton,
        [FormControlType.FormHandSign]: FormControlKeys.FormHandSign,
        [FormControlType.FormOcr]: FormControlKeys.FormOcr,
        [FormControlType.FormEsign]: FormControlKeys.FormEsign,
        [FormControlType.FormFaceRecognition]: FormControlKeys.FormFaceRecognition,
        [FormControlType.FormStatus]: FormControlKeys.FormStatus,
        [FormControlType.FormLabel]: FormControlKeys.FormLabel,
        [FormControlType.FormRollup]: FormControlKeys.FormRollup,
        [FormControlType.ActivityName]: FormControlKeys.ActivityName,
        [FormControlType.Participant]: FormControlKeys.Participant,
    };

    /** 控件类型到数据类型的映射 */
    export const ControlTypeToDateType = {
        [FormControlKeys.FormTextBox]: BizDataType.ShortString,
        [FormControlKeys.FormTextArea]: BizDataType.String,
        [FormControlKeys.FormNumber]: BizDataType.Double,
        [FormControlKeys.FormDateTime]: BizDataType.DateTime,
        [FormControlKeys.FormDropDownList]: BizDataType.ShortString,
        [FormControlKeys.FormRadioButtonList]: BizDataType.ShortString,
        [FormControlKeys.FormCheckbox]: BizDataType.Bool,
        [FormControlKeys.FormCheckboxList]: BizDataType.ShortString,
        [FormControlKeys.FormUser]: BizDataType.SingleParticipant,
        [FormControlKeys.FormMultiUser]: BizDataType.MultiParticipant,
        [FormControlKeys.FormDepartment]: BizDataType.SingleParticipant,
        [FormControlKeys.FormMultiDepartment]: BizDataType.MultiParticipant,
        [FormControlKeys.FormAttachment]: BizDataType.Attachment,
        [FormControlKeys.FormPhoto]: BizDataType.Image,
        [FormControlKeys.FormMap]: BizDataType.Map,
        [FormControlKeys.FormSeqNo]: BizDataType.ShortString,
        [FormControlKeys.FormAreaSelect]: BizDataType.Address,
        [FormControlKeys.FormCreatedTime]: BizDataType.DateTime,
        [FormControlKeys.FormCreater]: BizDataType.SingleParticipant,
        [FormControlKeys.FormModifiedTime]: BizDataType.DateTime,
        [FormControlKeys.FormMultiQuery]: BizDataType.AssociationArray,
        [FormControlKeys.FormQuery]: BizDataType.Association,
        [FormControlKeys.FormOwner]: BizDataType.SingleParticipant,
        [FormControlKeys.FormGridView]: BizDataType.BizObjectArray,
        [FormControlKeys.FormStatus]: BizDataType.Decimal,
    };

    /** 数据类型到控件类型的映射 */
    export const DateTypeToControlTypes = {
        [BizDataType.ShortString]: [
            FormControlKeys.FormTextBox,
            FormControlKeys.FormDropDownList,
            FormControlKeys.FormCheckboxList,
            FormControlKeys.FormRadioButtonList,
            FormControlKeys.FormSeqNo,
        ],
        [BizDataType.Decimal]: [FormControlKeys.FormNumber, FormControlKeys.FormStatus],
        [BizDataType.DateTime]: [
            FormControlKeys.FormDateTime,
            FormControlKeys.FormCreatedTime,
            FormControlKeys.FormModifiedTime,
        ],
        [BizDataType.SingleParticipant]: [
            FormControlKeys.FormUser,
            FormControlKeys.FormOwner,
            FormControlKeys.FormCreater,
            FormControlKeys.FormDepartment,
            FormControlKeys.FormOwnerDepartment,
        ],
        [BizDataType.MultiParticipant]: [FormControlKeys.FormMultiUser, FormControlKeys.FormMultiDepartment],
        [BizDataType.Address]: [FormControlKeys.FormAreaSelect],
        [BizDataType.Map]: [FormControlKeys.FormMap],
        [BizDataType.Attachment]: [FormControlKeys.FormAttachment],
        [BizDataType.Image]: [FormControlKeys.FormPhoto],
        [BizDataType.Association]: [FormControlKeys.FormQuery],
        [BizDataType.AssociationArray]: [FormControlKeys.FormMultiQuery],
        [BizDataType.Bool]: [FormControlKeys.FormCheckbox],
        [BizDataType.BizObjectArray]: [FormControlKeys.FormGridView],
    };

    /** 不支持作为数据标题的控件类型 */
    export const ControlTypeNotSupportDataTitle = [
        FormControlType.FormPhoto,
        FormControlType.FormAttachment,
        FormControlType.FormGroupTitle,
        FormControlType.FormDescription,
        FormControlType.FormGridView,
        FormControlType.FormAssociateProperty,
        FormControlType.FormButton,
        FormControlType.FormLayout,
        FormControlType.FormOcr,
        FormControlType.FormHandSign,
        FormControlType.FormEsign,
        FormControlType.FormTab,
        FormControlType.FormFaceRecognition,
        FormControlType.FormFormula,
        FormControlType.FormRollup,
        FormControlType.FormBatchScan,
    ];

    /** 人员部门类控件类型 */
    export const OrgControlTypes = [
        FormControlType.FormMultiUser,
        FormControlType.FormUser,
        FormControlType.FormDepartment,
        FormControlType.FormMultiDepartment,
        FormControlType.FormCreater,
        FormControlType.FormOwner,
        FormControlType.FormOwnerDepartment,
    ];

    /** 删除不需要提醒的控件 */
    export const NoConfirmRemoveControlType = [
        FormControlType.FormCreater,
        FormControlType.FormOwner,
        FormControlType.FormOwnerDepartment,
        FormControlType.FormCreatedTime,
        FormControlType.FormModifiedTime,
    ];

    /** 控件类型权重值 */
    export const ControlTypeWeight = {
        // 基础控件
        [FormControlType.FormTextBox]: 800,
        [FormControlType.FormTextArea]: 0,
        [FormControlType.FormDateTime]: 8,
        [FormControlType.FormNumber]: 8,
        [FormControlType.FormRadioButtonList]: 800,
        [FormControlType.FormCheckboxList]: 800,
        [FormControlType.FormDropDownList]: 800,
        [FormControlType.FormCheckbox]: 4,
        [FormControlType.FormAreaSelect]: 800,
        [FormControlType.FormMap]: 800,
        [FormControlType.FormMultiUser]: 0,
        [FormControlType.FormUser]: 150,
        [FormControlType.FormDepartment]: 150,
        [FormControlType.FormMultiDepartment]: 0,
        [FormControlType.FormAttachment]: 0,
        [FormControlType.FormPhoto]: 0,
        // 布局控件
        [FormControlType.FormGroupTitle]: 0,
        [FormControlType.FormLayout]: 0,
        [FormControlType.FormDescription]: 0,
        [FormControlType.FormGridView]: 0,
        [FormControlType.FormTab]: 0,
        // 系统控件
        [FormControlType.FormSeqNo]: 800,
        [FormControlType.FormCreater]: 150,
        [FormControlType.FormOwner]: 150,
        [FormControlType.FormOwnerDepartment]: 150,
        [FormControlType.FormCreatedTime]: 0,
        [FormControlType.FormModifiedTime]: 0,
        [FormControlType.FormLabel]: 0,
        // 高级控件
        [FormControlType.FormQuery]: 800,
        [FormControlType.FormMultiQuery]: 0,
        [FormControlType.FormAssociateProperty]: 800,
        [FormControlType.FormFormula]: 800,
        [FormControlType.FormRollup]: 800,
        [FormControlType.FormButton]: 0,
        [FormControlType.FormHandSign]: 0,
        [FormControlType.FormOcr]: 800,
        [FormControlType.FormAdvancedButton]: 0,
        [FormControlType.FormEsign]: 800,
        [FormControlType.FormBatchScan]: 0,
        [FormControlType.FormFaceRecognition]: 800,
        [FormControlType.FormStatus]: 0,
        [FormControlType.FormDynamic]: 0,
    };

    /** 系统支持的运算符 */
    export const enum ArithmeticOperatorTypes {
        Max = 'max',
        Min = 'min',
        Avg = 'avg',
        Sum = 'sum',
        Filled = 'filled', // 非空
        Empty = 'empty', // 为空
        None = 'none', // 不统计
    }

    /** 系统支持的统计函数 */
    export const enum StatFunctionType {
        SUM = 0,
        AVG = 1,
        MAX = 2,
        MIN = 3,
        NULL = 4,
        NOTNULL = 5,
        NONE = -1,
    }

    /** 默认值类型 */
    export enum DefaultValueType {
        /** 数据联动 */
        DataLink = 1,
        /** 计算公式 */
        Formula = 2,
        /** 默认值 */
        FixedValue = 3,
        /** 条件计算 */
        Condition = 4,
    }

    /** 控件文本模式 */
    export enum TextMode {
        /** 普通文本 */
        Normal = 'Normal',

        /** 电子邮箱 */
        Email = 'Email',

        /** 身份证 */
        Card = 'Card',

        /** 电话号 */
        Tel = 'Telephone',
    }
    export const TextModeMap = {
        /** 普通文本 */
        [TextMode.Normal]: '普通文本',

        /** 电子邮箱 */
        [TextMode.Email]: '电子邮箱',

        /** 身份证 */
        [TextMode.Card]: '身份证',

        /** 电话号 */
        [TextMode.Tel]: '电话号码',
    };

    /** 控件规则类型（目前计算规则和隐藏规则后端返回的是解析后的字符串，所以这个类型目前用于数据限定范围） */
    export type Rule = {
        rule: string;
    };

    export type DataLinkSchema = {
        /** 应用编码 */
        appPackage: string;

        /** unknown */
        appGroup: string;

        /** unknown */
        appMenu: string;

        /** 表单编码 */
        boSchemaCode: string;

        /** 是否子表 */
        isChildSchema: boolean;
    } | null;

    export type DataLinkCondition = {
        /** 当前表单字段 */
        curField: string;

        /** 联动的表单字段 */
        relationField: string;
    };

    /** 汇总控件汇总类型 */
    export enum RollupType {
        /** 求和 */
        Sum = 1,

        /** 计数 */
        Count = 5,

        /** 最大值 */
        Max = 6,

        /** 最小值 */
        Min = 7,

        /** 为空 */
        IsNull = 11,

        /** 不为空 */
        IsNotNull = 12,
    }

    /** 汇总控件汇总配置 */
    export type RollupSetting = {
        /** 源表单编码 */
        sourceSchemaCode: string;

        /** 汇总类型 */
        rollupType: RollupType;

        /** JSON化的汇总条件 */
        expressionJson: Util.JSONStringify<Filter.IFilterItem>;

        /** 字段映射规则 */
        propertyMapping: {
            /** 源表单编码 */
            sourceSchemaCode: string;

            /** 源表单主表编码 */
            sourceMainSchemaCode: string;

            /** 源字段编码 */
            sourcePropertyName: string;

            /** 当前表单编码 */
            targetSchemaCode: string;

            /** 当前字段编码 */
            targetPropertyName: string;
        };
    };

    /** 控件数据联动配置 */
    export type DataLink = {
        /** 联动的表单编码 */
        dataLinkSchemaCode: string;

        /** 联动的表单结构 */
        dataLinkSchema: DataLinkSchema;

        /** 联动条件 */
        dataLinkCondition: DataLinkCondition[];

        /** 联动的结果字段（被赋值给当前控件的字段） */
        dataLinkResult: string;
    };

    /** 控件地址模式 */
    export enum AreaSelectMode {
        /** 省市区 */
        PCT = 'P-C-T',

        /** 省市 */
        PC = 'P-C',

        /** 省 */
        P = 'P',
    }

    /** 数字格式类型 */
    export enum NumberFormatType {
        /** 数值 */
        Number = '0',

        /** 百分比 */
        Percentage = '1',
    }

    /** 数字格式类型 */
    export enum NumberShowMode {
        /** 数字 */
        Number = 0,
        /** 千分位 */
        Thousands = 1,
        /** 百分比 */
        Percentage = 2,
        /** 条形图 */
        BarChart = 4,
    }

    /** 日期格式枚举 */
    export enum DateTimeMode {
        /** 年月日 */
        Y_M_D = 'yyyy-mm-dd',

        /** 年月 */
        Y_M = 'yyyy-mm',

        /** 年月日时分 */
        Y_M_D_H_M = 'yyyy-mm-dd hh:mm',

        /** 时分 */
        H_M = 'hh:mm',
    }

    export enum DateTimeModeFormat {
        /** 年月日时分 */
        Y_M_D_H_M = 'YYYY-MM-DD HH:mm',

        /** 年月日 */
        Y_M_D = 'YYYY-MM-DD',

        /** 年月 */
        Y_M = 'YYYY-MM',

        /** 时分 */
        H_M = 'HH:mm',
    }

    /** 日期格式文本枚举 */
    export const DataTimeModeText = {
        [DateTimeMode.Y_M_D]: '年-月-日',
        [DateTimeMode.Y_M]: '年-月',
        [DateTimeMode.Y_M_D_H_M]: '年-月-日 时:分',
        [DateTimeMode.H_M]: '时:分',
    };

    /** 选项移动端显示模式 */
    export enum SelectShowMode {
        /** 弹出 */
        Modal = '0',

        /** 平铺 */
        Tile = '1',
    }

    /** 下拉框数据源枚举 */
    export enum DropdownDataSource {
        /** 自定义 */
        Custom = 'Custom',

        /** 关联表单 */
        Association = 'Association',
    }

    /** 电子签章发起类型枚举 */
    export enum ESignStartMode {
        /** 前往第三方发起 */
        ThirdParty = 1,
    }

    export enum ESignRequirements {
        /** 企业章 */
        Enterprise = 1,
        /** 个人章 */
        Personal = 2,
    }

    /** 电子签章签署人信息 */
    export type ESignParticipantInfo = {
        /** 签署人类型: 内部人员 | 外部人员 */
        accountType: 'DING_USER' | 'OUTER_USER';

        /** 签署人类型选择外部人员时，指定控件来作为发起人名称 */
        accountName: string;

        /** 签署人类型选择外部人员时，指定控件来作为发起人账户 */
        account: string;

        /** 签署人类型选择外部人员时，指定控件来作为发起人部门名称 */
        orgName: string;

        /** 签章类型: 企业章 | 个人章 */
        signRequirements: ESignRequirements[];

        /** 签署人类型选择内部人员时，指定控件来作为发起人 */
        userId: string;

        /** unknown */
        corpId: string;
    };

    /** 电子签章抄送人信息 */
    export type ESignCcsInfo = {
        /** 抄送人类型: 内部人员 | 外部人员 */
        accountType: 'DING_USER' | 'OUTER_USER';

        /** 抄送人类型选择外部人员时，指定控件来作为发起人名称 */
        accountName: string;

        /** 抄送人类型选择外部人员时，指定控件来作为发起人账户 */
        account: string;

        /** 抄送人类型选择外部人员时，指定控件来作为发起人部门名称 */
        orgName: string;

        /** 抄送主体: 企业 | 个人 */
        ccSubject: 'PERSONAL' | 'COMPANY';

        /** 抄送人类型选择内部人员时，指定控件来作为发起人 */
        userId: string;

        /** unknown */
        corpId: string;
    };

    /** 发起签署配置 */
    export type EsignStartSetting = {
        /** 发起方式 */
        startMode: ESignStartMode;

        /** 签署文件字段FieldId */
        fileId: string;

        /** 签署人信息 */
        participantData: ESignParticipantInfo[];

        /** 抄送人信息 */
        ccsData: ESignCcsInfo[];
    };

    /** 签署状态同步配置 */
    export type ESignSyncSetting = {
        /** 状态同步到指定控件 */
        signStatus: string;

        /** 签署成功文件回写到指定控件 */
        successFileId: string;
    };

    /** 子表行高 */
    export enum GridViewRowHeight {
        /** 紧凑 */
        Compact = 'compact',
        /** 普通 */
        Common = 'common',
        /** 中等 */
        Medium = 'medium',
        /** 较高 */
        Large = 'large',
        /** 自适应 */
        Auto = 'auto',
    }

    /** 子表行高值 */
    export const GridViewRowHeightValue = {
        [GridViewRowHeight.Compact]: 36,
        [GridViewRowHeight.Common]: 46,
        [GridViewRowHeight.Medium]: 68,
        [GridViewRowHeight.Large]: 90,
    };

    /** 移动端子表模式枚举 */
    export enum MobileGridViewMode {
        /** 纵表 */
        Vertical = '0',

        /** 横向表格 */
        Horizontal = '1',
    }

    /** 流水号格式类型枚举 */
    export enum SeqNoStructureType {
        /** 提交日期 */
        CreatedTime = 1,

        /** 自增位 */
        Increment = 2,

        /** 固定字符 */
        FixedValue = 3,

        /** 表单控件 */
        FormControl = 4,
    }

    /** 流水号提交日期枚举 */
    export enum SeqNoSubmitDateType {
        None = 'None',
        YYYY = 'YYYY',
        YYYYMM = 'YYYYMM',
        YYYYMMDD = 'YYYYMMDD',
    }

    /** 流水号重置类型枚举 */
    export enum SeqNoResetType {
        /** 不重置 */
        NoReset = '1',

        /** 按年重置 */
        ResetByYear = '2',

        /** 按月重置 */
        ResetByMonth = '3',

        /** 按日重置 */
        ResetByDay = '4',
    }

    /** 带颜色选项的颜色 */
    export type ColorItemOption = {
        /** 背景色 */
        backgroundColor: StyleRuleColor;

        /** 边框色 */
        borderColor: string;

        /** hover时边框色 */
        hoverBorderColor: string;

        /** 颜色 */
        color: string;
    };

    /** 带颜色的选项 */
    export type ColorSelectItem = {
        /** 选项文本 */
        text: string;

        /** 选项颜色 */
        color: ColorItemOption;

        id?: string;
    };

    /** 普通选项类型 */
    export type SelectOption = {
        /** 选项文本 */
        text: string;

        /** 选项值 */
        value: string;
    };

    /** 人员部门控件固定默认值 */
    export type OrgDefaultValue = {
        /** id */
        value: string;

        /** name */
        label: string;

        /** 其数据结构在选择不同的人员部门类型时有所区别 */
        nodes: Array<
            Record<string, unknown> & {
                ObjectId: string | number;
                Name: string;
                NodeType?: Org.OrgNodeType;
                UnitType?: Org.UnitType;
            }
        >;
    };

    /** 子表默认值 */
    export type GridViewDefaultValue = Array<{
        [key: string]: unknown;
    }>;

    /** 地图范围限定枚举 */
    export enum MapRange {
        /** 限定附近位置 */
        Vicinity = 0,

        /** 允许任意位置 */
        NoLimit = 1,
    }

    /** 打开定位组件的方式 */
    export enum OpenLocationCompType {
        Input = 'Input',
        Icon = 'Icon',
    }

    /** 分组标题对齐枚举 */
    export enum GroupTitleAlignment {
        /** 左对齐 */
        Left = 'left',

        /** 居中 */
        Center = 'center',

        /** 右对齐 */
        Right = 'right',
    }

    /** 子表批量扫码设置 */
    export type GridViewBatchScanSetting = {
        /** 是否允许批量扫码 */
        allowBatchScan: boolean;

        /** 扫码填充的字段 */
        selectedTextField?: string;

        /** 扫码计数填充的字段 */
        selectedNumberField?: string;
    };

    /** 公式计算类型枚举 */
    export enum FormulaCalcType {
        /** 数值 */
        Number = 'number',

        /** 文本 */
        Text = 'text',

        /** 日期 */
        DateTime = 'datetime',

        /** 是否 */
        Bool = 'boolean',
    }

    /** 绑定类型到控件类型的映射 */
    export const BindTypeToControlType = {
        [FormulaCalcType.Number]: FormControlKeys.FormNumber,
        [FormulaCalcType.Text]: FormControlKeys.FormTextBox,
        [FormulaCalcType.DateTime]: FormControlKeys.FormDateTime,
        [FormulaCalcType.Bool]: FormControlKeys.FormCheckbox,
    };

    export const FormulaCalcTypeText = {
        [FormulaCalcType.Number]: '数字',
        [FormulaCalcType.Text]: '文本',
        [FormulaCalcType.DateTime]: '日期',
        [FormulaCalcType.Bool]: '是否',
    };

    /** 按钮扩展配置 */
    export type ButtonAdvanceSetting = {
        /** 插件类型 */
        pluginType: ButtonPluginType;

        /** 插件服务id */
        itemId: string;

        // 请求和响应映射针对每个插件都不一样，但作用都是用表单的一些字段值作为相应参数请求插件，将请求结果复制给指定字段
        /** 插件请求映射 */
        requestData: unknown;

        /** 插件响应映射 */
        responseData: unknown;

        /** 短信 */
        template?: string;
    };

    /** 按钮支持的插件类型 */
    export enum ButtonPluginType {
        /** 第三方连接 */
        ApiConnection = 'ApiConnection',
        /** 钉钉连接器 */
        DingTalkConnecter = 'DingTalkConnecter',
        /** 在线支付 */
        Payment = 'payment',
        /** 钉钉 */
        DingTalkAttendance = 'dingtalkattendance',
        /** 短信 */
        Sms = 'sms',
        /** 邮件 */
        Email = 'email',
        /** 发送群消息 */
        RobotNotification = 'robotnotification',
    }

    /** 按钮插件类型文本 */
    export const ButtonPluginTypeText = {
        [ButtonPluginType.ApiConnection]: '第三方连接',
        [ButtonPluginType.DingTalkConnecter]: '钉钉连接器',
        [ButtonPluginType.Payment]: '在线支付',
        [ButtonPluginType.DingTalkAttendance]: '考勤打卡',
        [ButtonPluginType.Sms]: '发送短信',
        [ButtonPluginType.Email]: '发送邮件',
        [ButtonPluginType.RobotNotification]: '发送群消息',
    };

    /** 按钮插件二级id类型 */
    export enum ButtonPluginItemId {
        /** 第三方连接 */
        ApiConnection = 'ApiConnection',
        /** 支付-退款 */
        Refund = 'refund',
        /** 支付-查询 */
        Query = 'query',
        /** 邮件-发送邮件 */
        SendEmail = 'sendEmail',
        /** 短信-发送短信 */
        SendSms = 'sendSms',
        /** 钉钉-考勤 */
        Attendance = 'attendance',
        /** 钉钉-打卡记录 */
        Clock = 'clock',
        /** 钉钉-请假记录 */
        Vacate = 'vacate',
        /** 钉钉-机器人 */
        Robot = 'robot',
        /** 钉钉-会话 */
        Sessions = 'sessions',
    }

    /** 按钮插件二级id类型文本 */
    export const ButtonAdvanceSettingItemIdText = {
        [ButtonPluginItemId.ApiConnection]: '第三方连接',
        [ButtonPluginItemId.Refund]: '订单退款',
        [ButtonPluginItemId.Query]: '订单查询',
        [ButtonPluginItemId.SendEmail]: '发送邮件',
        [ButtonPluginItemId.SendSms]: '发送短信',
        [ButtonPluginItemId.Attendance]: '考勤打卡-统计人员考勤信息',
        [ButtonPluginItemId.Clock]: '考勤打卡-查询人员打卡记录',
        [ButtonPluginItemId.Vacate]: '考勤打卡-查询人员请假记录',
        [ButtonPluginItemId.Robot]: '发送消息至群机器人',
        [ButtonPluginItemId.Sessions]: '发送消息至会话',
    };

    /** 文字识别服务类型枚举 */
    export enum OCRService {
        /** 身份证 */
        IdCard = 'idcard',
        /** 银行卡 */
        BankCard = 'bank_card',
        /** 驾驶证 */
        DrivingLicense = 'driving_license',
        /** 行驶证 */
        VehicleLicense = 'vehicle_license',
        /** 定额发票 */
        QuotaInvoice = 'quota_invoice',
        /** 营业执照 */
        BusinessLicense = 'blicense',
        /** 增值税发票 */
        Invoice = 'invoice',
        /** 增值税发票(验真) */
        InvoiceVerification = 'invoice_verification',
        /** 机票行程单 */
        AirItinerary = 'air_itinerary',
        /** 出租车发票 */
        TaxiTicket = 'taxi_ticket',
        /** 火车票 */
        TrainTicket = 'train_ticket',
        /** 车牌 */
        CarNo = 'car_no',
    }

    /** 人脸识别服务类型 */
    export enum FaceRecognitionService {
        /** 人脸录入 */
        FaceInput = 'face_input',

        /** 人脸搜索 */
        FaceSearch = 'face_search',

        /** 人脸拍照 */
        FacePhoto = 'face_photo',
    }

    /** 人脸识别服务文本 */
    export const FaceRecognitionServiceText = {
        [FaceRecognitionService.FaceInput]: '录入人脸',
        [FaceRecognitionService.FaceSearch]: '搜索人脸',
        [FaceRecognitionService.FacePhoto]: '拍照',
    };

    /** 人脸识别 */
    export type FaceRecognitionMappingRule = {
        /** 当前控件编码 */
        currentFormControl?: string;
        /** 识别字段 */
        recognitionTextfield: string;
        /** 类型: 输入 | 输出 */
        type: 'in' | 'out';
    };

    /** 关联表单控件显示字段配置 */
    export type FormQueryDisplaySetting = {
        /** 是否默认显示字段 */
        isDefault: boolean;

        /** 字段设置 */
        fieldSetting: Array<{
            /** 字段编码 */
            PropertyName: string;

            /** 字段是否可见 */
            Visible: boolean;

            /** 是否子表字段 */
            IsChild: boolean;

            /** 子表内字段设置 */
            ChildColumns: Array<{
                /** 字段编码（非完整编码） */
                PropertyName: string;

                /** 字段是否可见 */
                Visible: boolean;

                /*** 名称 */
                DisplayName: string;
            }>;

            /*** 名称 */
            DisplayName: string;
        }>;
    };

    /** 系统支持的统计函数名称映射 */
    export const STAT_FUNCTION_MAP = {
        [StatFunctionType.SUM]: '求和',
        [StatFunctionType.AVG]: '平均值',
        [StatFunctionType.MAX]: ' 最大值',
        [StatFunctionType.MIN]: '最小值',
        [StatFunctionType.NULL]: '空值',
        [StatFunctionType.NOTNULL]: '非空值',
        [StatFunctionType.NONE]: '不统计',
    };

    /**
     * 控件基础结构
     */
    export interface IControl {
        code: string;
        displayName: string;
        controlType: FormControlKeys;
        options: Record<string, unknown>;
        children?: IControl[]; // 子控件
        parentCode?: string;
        // 公式编辑器用到
        realName?: string;
    }

    // TODO 理论上后续所有的IControl结构都要迁移到当前结构上来
    /**
     * 控件布局结构
     * 该数据结构用于前端向后端保存表单结构信息以及控件配置
     * 同时用于前端读取表单布局和表单布局控件配置
     * 其他控件配置应该从H3.Schema.IProperty类型的数据中获取
     */
    export interface IControlLayout<T = Record<string, any>> {
        /** 控件唯一标识，字段编码或随机字符串（非字段控件） */
        Key: string;

        /** 控件类型 */
        Type: FormControlType;

        /** 父控件唯一标识 */
        ParentKey: string;

        /** 所属标签页唯一标识 */
        TabGroupKey: string;

        /** 控件配置选项（实际类型应为IControlOptions，该类型定义在控件体系中） */
        Options: T;

        /** 子控件 */
        ChildControls?: Array<IControlLayout | null>;
    }

    /** 控件名称为空时默认显示的名称 */
    export const EmptyControlDefaultName = '--';

    export enum HandSignActionType {
        /** 手写 */
        Write = 'write',
        /** 复用 */
        Reuse = 'reuse',
        /** 上传 */
        Upload = 'upload',
        /** 扫码 */
        Scan = 'scan',
    }

    /** 条件样式类型 */
    export enum StyleRuleType {
        /** 文本样式 */
        Text = 'text',
        /** 标签样式 */
        Tag = 'tag',
    }

    export enum StyleRuleColor {
        /** 浅蓝色 */
        LIGHT_BLUE = '#D6E4FF',
        /** 浅绿色 */
        // LIGHT_GREEN = '#E7F9F0',
        /** 浅黄色 */
        LIGHT_YELLOW = '#FFE7BA',
        /** 淡红色 */
        PALE_RED = '#FFCCC7',
        /** 浅褐色 */
        LIGHT_TAN = '#FFFB8F',
        /** 褐绿色 */
        TAN_GREEN = '#D9F7BE',
        /** 浅青色 */
        LIGHT_CYAN = '#B5F5EC',
        /** 青蓝色 */
        CYAN_BLUE = '#BAE7FF',
        /** 略带粉色 */
        PINK_ISH = '#FFD6E7',
        /** 略带灰色 */
        GRAY_ISH = '#EFDBFF',
        /** 略带紫色 */
        PURPLE_ISH = '#E8E8E8',
        /** 深蓝色 */
        DEEP_BLUE = '#597EF7',
        /** 深绿色 */
        DEEP_GREEN = '#73D13D',
        /** 橙色 */
        ORANGE = '#FFA940',
        /** 鲜红色 */
        BRIGHT_RED = '#FF4D4F',
        /** 淡黄色 */
        YELLOWISH = '#FBCE00',
        /** 略带绿色 */
        // GREENISH = '#A0D911',
        /** 水蓝色 */
        AQUA_BLUE = '#36CFC9',
        /** 浅蓝绿色 */
        LIGHT_BLUEISH = '#40A9FF',
        /** 亮粉色 */
        HOT_PINK = '#F759AB',
        /** 深紫色 */
        DARK_PURPLE = '#9254DE',
        /** 深灰色 */
        DARK_GRAY = '#8C8C8C',
    }

    /** UI升级数据库中存储的是旧颜色，这里映射成序号，在样式里再映射到指定样式 */
    export const StyleColorMap = {
        [StyleRuleColor.PALE_RED]: 4,
        [StyleRuleColor.LIGHT_YELLOW]: 3,
        [StyleRuleColor.LIGHT_TAN]: 5,
        [StyleRuleColor.TAN_GREEN]: 6,
        [StyleRuleColor.LIGHT_CYAN]: 7,
        [StyleRuleColor.CYAN_BLUE]: 8,
        [StyleRuleColor.LIGHT_BLUE]: 1,
        [StyleRuleColor.GRAY_ISH]: 10,
        [StyleRuleColor.PINK_ISH]: 9,
        [StyleRuleColor.PURPLE_ISH]: 11,
        [StyleRuleColor.BRIGHT_RED]: 15,
        [StyleRuleColor.ORANGE]: 14,
        [StyleRuleColor.YELLOWISH]: 16,
        [StyleRuleColor.DEEP_GREEN]: 13,
        [StyleRuleColor.AQUA_BLUE]: 18,
        [StyleRuleColor.LIGHT_BLUEISH]: 19,
        [StyleRuleColor.DEEP_BLUE]: 12,
        [StyleRuleColor.DARK_PURPLE]: 21,
        [StyleRuleColor.HOT_PINK]: 20,
        [StyleRuleColor.DARK_GRAY]: 22,
    };

    /** 文本颜色 */
    export const StyleTextColorMap = {
        [StyleRuleColor.BRIGHT_RED]: 15,
        [StyleRuleColor.ORANGE]: 14,
        [StyleRuleColor.YELLOWISH]: 16,
        [StyleRuleColor.DEEP_GREEN]: 13,
        [StyleRuleColor.AQUA_BLUE]: 18,
        [StyleRuleColor.LIGHT_BLUEISH]: 19,
        [StyleRuleColor.DEEP_BLUE]: 12,
        [StyleRuleColor.DARK_PURPLE]: 21,
        [StyleRuleColor.HOT_PINK]: 20,
        [StyleRuleColor.DARK_GRAY]: 22,
    };

    export namespace ControlDesigner {
        export type Rule = {
            Rule: string;
        };

        export type DataLinkCondition = {
            /** 当前表单字段 */
            CurField: string;

            /** 联动的表单字段 */
            RelationField: string;
        };

        export type DataLinkSchema = {
            /** 应用编码 */
            AppPackage: string;

            /** unknown */
            AppGroup: string;

            /** unknown */
            AppMenu: string;

            /** 表单编码 */
            BOSchemaCode: string;

            /** 是否子表 */
            IsChildSchema: boolean;
        } | null;

        /** 电子签章签署人信息 */
        export type ESignParticipantInfo = {
            /** 签署人类型: 内部人员 | 外部人员 */
            AccountType: 'DING_USER' | 'OUTER_USER';

            /** 签署人类型选择外部人员时，指定控件来作为发起人名称 */
            AccountName?: string;

            /** 签署人类型选择外部人员时，指定控件来作为发起人账户 */
            Account?: string;

            /** 签署人类型选择外部人员时，指定控件来作为发起人部门名称 */
            OrgName?: string;

            /** 签章类型: 企业章 | 个人章 */
            SignRequirements: ESignRequirements[];

            /** 签署人类型选择内部人员时，指定控件来作为发起人 */
            UserId?: string;

            /** unknown */
            CorpId: string;

            Id?: string;
        };

        /** 电子签章抄送人信息 */
        export type ESignCcsInfo = {
            /** 抄送人类型: 内部人员 | 外部人员 */
            AccountType: 'DING_USER' | 'OUTER_USER';

            /** 抄送人类型选择外部人员时，指定控件来作为发起人名称 */
            AccountName?: string;

            /** 抄送人类型选择外部人员时，指定控件来作为发起人账户 */
            Account?: string;

            /** 抄送人类型选择外部人员时，指定控件来作为发起人部门名称 */
            OrgName?: string;

            /** 抄送主体: 企业 | 个人 */
            CCSubject?: 'PERSONAL' | 'COMPANY';

            /** 抄送人类型选择内部人员时，指定控件来作为发起人 */
            UserId?: string;

            /** unknown */
            CorpId: string;

            Id?: string;
        };

        /** 发起签署配置 */
        export type EsignStartSetting = {
            /** 发起方式 */
            StartMode: ESignStartMode;

            /** 签署文件字段FieldId */
            FileId?: string;

            /** 签署人信息 */
            ParticipantData: ControlDesigner.ESignParticipantInfo[];

            /** 抄送人信息 */
            CCSData: ControlDesigner.ESignCcsInfo[];

            /** 签署文件来源 */
            Source?: EsignFileSource;

            /** 模板ID */
            TemplateId?: string;

            /** 签署文件内容 */
            Content?: Array<EsignContentMappingRule>;
        };

        /** 电子签章签署文件来源 */
        export enum EsignFileSource {
            /** 表单控件 */
            FormControl = 'FormControl',

            /** 模板 */
            Template = 'Template',
        }

        /** 签署状态同步配置 */
        export type ESignSyncSetting = {
            /** 状态同步到指定控件 */
            SignStatus?: string;

            /** 签署成功文件回写到指定控件 */
            SuccessFileId?: string;
        };

        /** 签署内容MappingRule */
        export type EsignContentMappingRule = {
            /** 当前控件编码 */
            currentField: string;
            /** 氚云表单字段 */
            controlField?: string;
            /** 类型 */
            type: EsignContentType;
        };

        /** 签署内容类型 */
        export enum EsignContentType {
            /** 字段 */
            Field = 'field',
            /** 自定义 */
            Custom = 'custom',
        }

        /** 汇总控件汇总计算规则 */
        export type RollupSetting = {
            SourceSchemaCode?: string;
            RollupType?: number | string;
            Expression: Filter.IFilterItem[][];
            PropertyMapping: {
                SourcePropertyName?: string;
                SourceSchemaCode?: string;
            };
        };

        /** 按钮扩展配置 */
        export type ButtonAdvanceSetting = {
            /** 插件类型 */
            PluginType: ButtonPluginType;
            /** 插件服务id */
            ItemId: string;
            /** 插件请求映射 */
            RequestData: unknown;
            /** 插件响应映射 */
            ResponseData: unknown;
            /** 短信 */
            Template?: string;
        };

        /** 按钮短信用户手机号码类型 */
        export enum ButtonSmsUserPhoneType {
            /** 手动输入 */
            Input = 1,
            /** 表单字段 */
            Field = 2,
        }

        /** 按钮短信内容类型 */
        export enum ButtonSmsTempParamType {
            /** 动态值 */
            Dynamic = '1',
            /** 固定值 */
            Fixed = '2',
        }

        /** 按钮第三方连接填充类型 */
        export enum ButtonApiConnectionMappingType {
            /** 动态 */
            Dynamic = 1,
            /** 固定值 */
            Fixed = 0,
        }

        /** 按钮机器人消息类型 */
        export enum ButtonRobotChatRequestMsgType {
            /** 文本 */
            Text = 'text',
            /** 链接 */
            Link = 'link',
        }

        /** 按钮机器人链接类型 */
        export enum ButtonRobotChatRequestLinkType {
            /** 固定值 */
            Fixed = 1,
            /** 动态值 */
            Dynamic = 2,
        }

        /** 按钮机器人@类型 */
        export enum ButtonRobotChatRequestSetType {
            /** 指定群成员 */
            Assign = 1,
            /** 所有群成员 */
            All = 2,
        }

        /** 按钮机器人发送用户类型 */
        export enum ButtonRobotChatRequestMobilesType {
            /** 手动输入 */
            Manual = 1,
            /** 表单字段 */
            FormField = 2,
        }

        /** 按钮发送邮件接收人类型 */
        export enum ButtonEmailRequestReceivedType {
            /** 手动输入 */
            Manual = 1,
            /** 表单字段 */
            FormField = 2,
        }

        /** 条件样式 */
        export type StyleRule = {
            Type: StyleRuleType;
            Rule: Array<{
                Filter: Filter.IFilterItem;
                Color: StyleRuleColor;
            }>;
        };
    }

    export namespace ControlValueType {
        // 地址控件
        export type Address = {
            adname: string;
            detail: string;
            Detail: string;
        };

        // 人员部门
        export type Org = {
            id: string;
            name: string;
            type?: Org.OrgNodeType;
        };

        // 关联查询
        export type Query = {
            Id: string;
            Name: string;
        };
    }
}
