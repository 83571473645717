import { App } from '../app';
export namespace FunctionNode {
    /** 节点图标颜色类型 */
    export const enum FunctionNodeIconColorType {
        Blue = 0,
        Turquoise = 1,
        Green = 2,
        Orange = 3,
        Pink = 4,
        Purple = 5,
    }

    /** 节点类型枚举 */
    export enum FunctionNodeType {
        /** 应用 */
        AppPackage = 190,

        /** 非流程表单 */
        FormModule = 200,

        /** 流程表单 */
        WorkflowModule = 210,

        /** 报表 */
        ReportModule = 220,

        /** 分组 */
        GroupModule = 230,

        /** 单表模式 */
        SingletonModule = 240,

        /** 钉钉流程列表模块（发起的流程是在钉钉端） */
        // Obsolete
        DingTalkWorkflowModule = 250,

        /** 仪表盘 */
        DashBoardModule = 270,

        /** 自定义链接 */
        CustomLinkModule = 280,

        /** 高级数据源 */
        DataSource = 290,
    }

    /** 节点授权类型枚举 */
    export const enum FunctionNodeAuthorizeType {
        /** 无权限 */
        NONE = 1,

        /** 访问权限 */
        ACCESS = 2,

        /** 新建权限 */
        CREATE = 3,

        /** 管理权限 */
        ADMINISTRATOR = 4,
    }

    /** 应用节点状态枚举 */
    export const enum FunctionNodeStateType {
        /** 正常 */
        Normal = 0,

        /** 禁用 */
        Disabled = 1,
    }

    /** FunctionNode类型到名称的映射 */
    export const FUNCTION_NODE_TYPE_NAME_MAP: {
        [key in FunctionNodeType]: string;
    } = {
        [FunctionNodeType.AppPackage]: '应用',
        [FunctionNodeType.CustomLinkModule]: '自定义链接',
        [FunctionNodeType.DashBoardModule]: '仪表盘',
        [FunctionNodeType.DataSource]: '数据源',
        [FunctionNodeType.FormModule]: '表单',
        [FunctionNodeType.GroupModule]: '分组',
        [FunctionNodeType.ReportModule]: '报表',
        [FunctionNodeType.DingTalkWorkflowModule]: '表单',
        [FunctionNodeType.SingletonModule]: '表单',
        [FunctionNodeType.WorkflowModule]: '表单',
    };

    export namespace Request {
        export interface ICreateFunctionNodeModel {
            /** 编码 */
            code?: string;

            /** 父节点编码 */
            parentCode: string;

            /** 所属应用编码 */
            appCode: string;

            /** 名称 */
            displayName: string;

            /** 图标 */
            icon: string;

            /** 图标颜色 */
            iconColor?: FunctionNodeIconColorType;

            /** 节点类型 */
            nodeType: FunctionNodeType;
        }
        /** 更新functionNode接口参数类型 */
        export interface IUpdateFunctionNodeModel {
            /** 更新的节点编码 */
            code: string;

            /** 名称 */
            displayName: string;

            /** 图标 */
            icon?: string;

            /** 图标颜色 */
            iconColor?: FunctionNodeIconColorType;
        }

        /** 排序functionNode接口参数类型 */
        export interface ISortFunctionNodeModel {
            /** 编码 */
            code: string;

            /** 排序后的父节点编码，没有则不传（应用） */
            parentCode?: string;

            /** 排序后的前一个同级节点编码，在第一个时不传 */
            preCode?: string;
        }

        export interface IMoveFunctionNodeModel {
            /** 移动端节点编码 */
            code: string;

            /** 目标应用编码 */
            targetAppCode: string;

            /** 目标父节点 */
            targetParentCode: string;
        }

        /** 旧报表升级仪表盘接口请求参数模型 */
        export interface IUpgradeReportModel {
            /** 旧报表编码 */
            reportNodeCode: string;

            /** 升级的仪表盘编码 */
            dashboardNodeCode: string;
        }

        /** 批量升级旧报表接口请求参数模型 */
        export interface IBatchUpgradeReport {
            /** 应用编码 */
            appCode: string;
        }
    }

    export namespace Response {
        /** 创建functionNode接口响应类型 */
        export interface ICreateFunctionNodeModel {
            /** 唯一id */
            id: string;

            /** 编码 */
            code: string;
        }
        /** 获取应用复制进度接口响应类型 */
        export type GetCopyFunctionProgressModal = ICopyProgress | ICopySuccess;
    }

    /** 节点数据结构 */
    export interface IFunctionNode {
        /** 唯一标识 */
        objectId: string;

        /** 菜单编码(唯一) */
        code: string;

        /** 菜单名称 */
        displayName: string;

        // /** 应用编码 */
        appCode: string;

        // /** 应用状态，目前只有获取应用摘要一个接口会返回这个字段 */
        appState?: FunctionNodeStateType;

        // /** 图标颜色，由于图标版本的问题，实际上值不止在这个映射中 */
        iconColor?: FunctionNodeIconColorType;

        /** 图标 */
        icon: string;

        /** 父级编码 */
        parentCode: string;

        /** 菜单类型 */
        nodeType: FunctionNodeType;

        /** 是否有子集 */
        hasChild?: boolean;

        source?: App.AppSourceType;
        isClosed?: boolean; // 是否是封闭应用
    }

    /** 节点权限信息数据结构 */
    export interface IFunctionNodeAuthorized {
        /** 节点编码 */
        code: string;

        /** 节点权限 */
        auth: FunctionNodeAuthorizeType;
    }
    interface ICopyProgress {
        isComplete: false; // 未完成
        rate: number; // 进度
    }
    interface ICopySuccess {
        isComplete: true; // 已完成
        node: IFunctionNode; // 复制完成的节点
    }

    // 表单节点树枚举类型
    export enum ITreeNodeType {
        App = 1, // 应用节点
        Group = 2, // 分组节点
        Form = 3, // 表单节点
    }

    // 表单节点树节点结构
    export interface ITreeNode {
        code: string;
        nodeType: ITreeNodeType;
        displayName: string;
        children?: ITreeNode[];
    }
}
