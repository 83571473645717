import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取oss存储凭证 */
export const getOssToken = () =>
    request<H3.Http.Response.H3Response<H3.Oss.Response.IOssTokenRes>>('/v1/file/policy/upload?scence=form', {
        method: H3.Http.MethodType.GET,
    });

/**
 * 获取文件oss上的下载地址
 * @param fileIds 文件id列表
 * @returns 文件对应oss上的下载地址
 */
export const getFileOssUrlByIds = async (fileIds: string[], scence = H3.Oss.OssUseType.FORM) =>
    request<H3.Http.Response.H3Response<H3.Oss.Response.IOssUrlRes[]>>('/v1/file/urls/byfileids', {
        method: H3.Http.MethodType.POST,
        data: {
            fileIds,
            scence,
        },
    });

/**
 * 存储草稿文件头信息
 * @param files 文件头信息
 * @param requireThumbnail 是否需要生成缩略图，默认false
 * @returns 文件fileId
 */
export const saveFileHeaders = async (
    files: H3.Oss.FileHeaders.TFileHeadersParams[],
    requireThumbnail = false,
    scence = H3.Oss.OssUseType.FORM,
) =>
    request<H3.Http.Response.H3Response<H3.Oss.Response.ISaveFileHeaderRes[]>>('/v1/file/headers', {
        method: H3.Http.MethodType.POST,
        data: {
            items: files,
            requireThumbnail,
            scence,
        },
    });
