import request from '@h3/request';
import { H3 } from '@h3/types';

/** 启动空白画布打印 */
export function startSpacePrint(schemaCode: string, templateCode: string, bizObjectIds: string[]) {
    return request<H3.Http.Response.H3Response<H3.Print.Response.IStartSpacePrint>>('/v1/toolkit/print/paper', {
        method: H3.Http.MethodType.POST,
        data: {
            schemaCode,
            templateCode,
            bizObjectIds,
        },
    });
}

/** 查询空白画布打印进度 */
export function getSpacePrintProgress(schemaCode: string, templateCode: string, actionId: string) {
    return request<H3.Http.Response.H3Response<H3.Print.Response.IGetProgressModel>>(
        '/v1/toolkit/print/paper/progress',
        {
            method: H3.Http.MethodType.POST,
            data: {
                schemaCode,
                templateCode,
                actionId,
            },
        },
    );
}

/**
 * 获取表单打印模板信息
 * @deprecated 打印模板添加了权限，请使用 getPrintTemplateInfos
 */
export function getPrintTemplateInfo(schemaCode: string) {
    return request<H3.Http.Response.H3Response<H3.Print.TPrintTemplate[]>>('/v1/print/header', {
        method: H3.Http.MethodType.GET,
        params: {
            schemaCode,
        },
    });
}

/** 获取用户有权限的打印模版 */
export function getPrintTemplateInfos(schemaCode: string) {
    return request<H3.Http.Response.H3Response<H3.Print.TPrintTemplate[]>>('/v1/print/actions', {
        method: H3.Http.MethodType.GET,
        params: {
            schemaCode,
        },
    });
}
