export namespace Export {
    /**
     * 导出任务状态
     */
    export const enum ExportState {
        /** 等待中 */
        Waiting = 0,

        /** 进行中 */
        Exporting = 1,

        /** 成功 */
        Success = 2,

        /** 失败 */
        Fail = 3,

        /** 取消 */
        Cancel = 4,
    }
    /**
     * 导出目标
     */
    export enum ExportTarget {
        Local = 0,
        WXWorkSpace = 1,
    }
    export namespace Request {
        export interface IExportColumnModel {
            PostData: {
                ActionName: string;
                SchemaCode: string;
            };
        }
        export interface IExportExcelModel {
            ActionName: string;
            SchemaCode: string;
            SearchParamsJson: string;
            PropertyNames: string;
            SortOrder: string;
            SortName: string;
            ScopeType: number;
            ActionGuid: string;
            ExportDataType: string;
            ObjectIds: string[];
            SelectedTicket?: string; // 导出到第三方目录ticket（微盘）
        }
        export interface IExportProgressModel {
            PostData: {
                ActionName: string;
                SchemaCode: string;
                ActionGuid: string;
            };
        }
        export interface ITaskRecordModel {
            OperationType: number;
            ActionGuids?: string[];
        }
    }
    export namespace Response {
        /** 导出列数据结构 */
        export interface IExportColumnItem {
            FieldName: string;
            DisPlayName: string;
            IsMustBeChecked: boolean;
            IsSpecialField: boolean;
            BizDataType: number;
        }
        /** 获取导出列返回信息 */
        export interface GetExportColumnsResponse {
            Columns: Record<string, IExportColumnItem[]>;
            SchemaNames: Record<string, string>;
        }
        /** 导出excel数据接口返回 */
        export interface ExportExcelResponse {
            QueueCount: number; // 排队数量
        }
        /**
         * 获取导出进度返回信息
         */
        export interface GetExportProgressResponse {
            ExportProgress: number;
            ExportState: ExportState;
            ExportErrorMsg: string;
            OSSUrl?: string;
            TotalNum?: number;
            ChildTableDetails?: object;
        }
        /** 导出历史记录明细 */
        export interface TaskRecordResponse {
            ActionGuid: string;
            SchemaCode: string;
            DisplayName: string;
            FileName: string;
            Percent: number;
            ErrorRecordPath: string;
            RecordType: number;
            FileUrl: string;
            RecordTypeName: string;
            TaskState: ExportState;
            CreatedTime: string;
            Remark: string;
            HasFailRecords: boolean;
            SkipQuantity: number;
            WattingTasks: number;
        }
        /** 导出记录明细模型（v1接口版） */
        export interface RecordDetailModel {
            /** 操作id */
            actionGuid: string;

            /** 表单编码 */
            schemaCode: string;

            /** 表单名称 */
            displayName: string;

            /** 文件名称 */
            fileName: string;

            /** 进度百分比 */
            percent: number;

            /** 错误报告地址 */
            errorRecordPath: string;

            /** 记录类型（这个指示导入、导出类型） */
            recordType: number;

            /** 导出文件地址 */
            fileUrl: string;

            /** 记录类型名称 */
            recordTypeName: string;

            /** 任务状态 */
            taskState: ExportState;

            /** 创建时间 */
            createdTime: number;

            /** unknown */
            remark: string;

            /** 是否存在失败的记录 */
            hasFailRecords: boolean;

            /** 跳过的数量 */
            skipQuantity: number;

            /** 前面还在等待的任务数 */
            wattingTasks: number;
        }
    }
}
