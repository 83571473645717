import { H3 } from '@h3/types';

export class TableViewListSetting {
    [H3.DB.DBField.ID]!: string;
    [H3.DB.DBField.TIMESTAMP]?: number;
    [H3.DB.DBField.VALUE]: H3.DB.DBViewListSetting | null = null;

    constructor({ id, value }: { [H3.DB.DBField.ID]: string; [H3.DB.DBField.VALUE]: H3.DB.DBViewListSetting }) {
        this.id = id;
        this.timestamp = new Date().getTime();
        this.value = value;
    }
}
