import { request } from '@h3/request';
import { H3 } from '@h3/types';

// 添加批量编辑任务
export const batchUpdate = (params: H3.Button.Request.IBatchUpdateRequestParams) => {
    return request<H3.Http.Response.H3Response<H3.Button.Response.IBatchUpdateResponse>>('/v1/bizdata/batchupdate', {
        method: 'post',
        data: params,
    });
};

// 批量编辑之前获得可编辑的字段
export const getEditableControl = (schemaCode: string) => {
    return request<H3.Http.Response.H3Response<Record<string, H3.Button.Response.IControlPermission>>>(
        '/v1/schema/field/permission/editing',
        {
            method: 'get',
            params: {
                schemaCode,
            },
        },
    );
};

// 计算指定数据、指定按钮数据范围验证是否满足条件
/**
 *
 * @param ids 选择的数据的ObjectId，列表自定义按钮为多条，表单按钮为单条
 * @param buttonCode 自定义按钮编码
 * @param buttonType 自定义按钮类型， 1 表示表单， 2 表示列表
 * @param schemaCode 表单编码
 * @returns Record<string, { ret: boolean; errorMsg: string }>,key 为bizObjectId，value 为 { ret: boolean; errorMsg: string }
 */
export const buttonRangeValidate = (ids: string[], buttonCode: string, buttonType: number, schemaCode: string) => {
    return request<H3.Http.Response.H3Response<Record<string, { ret: boolean; errorMsg: string }>>>(
        '/v1/backendcalculator/calculatebuttonsubmitRule',
        {
            method: 'post',
            data: {
                bizObjectIds: ids,
                buttonCode,
                buttonType,
                schemaCode,
            },
            disableBizError: true,
        },
    );
};
