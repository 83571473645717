export namespace Entry {
    export namespace Request {
        /** 发送登录验证码 */
        /** 发送注册验证码 */
        /** 发送重置验证码 */
        export interface ISendVerifyCodeModel {
            /** 手机号 */
            mobile: string;
            /** 类型 REGISTER/LOGIN */
            type?: string;
            /** 人机信息串 */
            captcha?: string;
            /** 引擎编码 */
            engineCode?: string;
        }

        /** 手机号密码登录（包括绑定微信） */
        export interface IPasswordLoginModel {
            /** 手机号 */
            mobile: string;
            /** 密码 */
            password: string;
            /** 引擎编码 */
            engineCode?: string;
            /** 人机信息串 */
            captcha: string;
            /** 个人微信code码，用于绑定微信 */
            wechatCode?: string;
            /** 来自 */
            from?: string;
        }

        /** 验证码登录 */
        export interface IVerifyCodeLoginModel {
            /** 手机号 */
            mobile: string;
            /** 验证码码 */
            verifyCode: string;
            /** 引擎编码 */
            engineCode?: string;
        }

        /** 扫码登录-钉钉 */
        export interface IDingScanLoginModel {
            /** 扫码的临时授权码 */
            code: string;
            /** 扫码的appId */
            scanAppId: string;
            /** 引擎编码 */
            engineCode?: string | '';
        }

        /** 微信扫码登录(个人微信和企微) */
        export interface IWxScanLoginModel {
            /** 扫码的临时授权码 */
            code: string;
        }

        /** 钉钉后台登录 */
        export interface IDingOaAuthLoginModel {
            /** 钉钉后台管理，临时授权码 */
            code: string;
            /** 钉钉微应用编码 */
            suiteKey: string;
        }

        /** 钉钉企业自建应用接入，钉钉客户端登录 */
        export interface ISelfBuildDingLoginModel {
            /** 临时授权码 */
            code: string;
            /** 钉钉企业编码 */
            corpId: string;
            /** 钉钉应用agentId */
            agentId?: string;
            /** 钉钉微应用编码 */
            suiteKey?: string;
            /** 来源 */
            from?: string;
        }

        /** 钉钉免登授权 */
        export interface IDingAuthLoginModel {
            /** 钉钉免等授权码 */
            code: string;
            /** 钉钉企业编码 */
            corpId: string;
            /** 钉钉微应用编码 */
            suiteKey?: string;
            /** 来源 */
            from?: string;
        }

        /** 飞书授权登录 */
        export interface ISelfBuildFeiShuLoginModel {
            /** 飞书corpId */
            corpId: string;
            /** 飞书agentId */
            agentId: string;
            /** 临时授权码 */
            code: string;
        }

        /** 企微授权登录 */
        export interface ISelfBuildWxWorkLoginModel {
            /** 临时授权码 */
            code: string;
            /** 企业微信id */
            corpId: string;
            /** 企业微信自建应用id */
            agentId: string;
        }

        /** 企微ISV登录 */
        export interface IWxWorkISVLoginModel {
            /** 临时授权码(必填) */
            code: string;
            /** 企微isvCorpId */
            isvCorpId: string;
            /** 企微suiteId */
            suiteId: string;
        }

        /** 企微管理员登录 */
        export interface IWxWorkOaLoginModel {
            /** 临时授权码 */
            code: string;
            /** appId */
            appId: string;
            /** 来自 */
            from?: string;
            /** 引擎编码 */
            engineCode?: string;
            /** 客户端: browser */
            loginClient?: string;
        }

        /** 企微三方的自建应用登录 */
        export interface IWxWorkISVCorpLoginModel {
            /** 临时授权码(必填) */
            code: string;
            /** 企微isvCorpId */
            corpId: string;
        }

        /** 续期token */
        export interface IRefreshTokenModel {
            /** 即将过期的token */
            token: string;
        }

        /** 选择企业后登录(公共) */
        export interface IFixEngineLoginModel {
            /** 引擎编码 */
            engineCode: string;
        }

        /** 切换外部组织 */
        export interface IChangeExternalOrgModel {
            /** 引擎编码 */
            engineCode: string;
        }

        /** 获取登录接口无痕验证配置 */
        /** 获取注册接口无痕验证配置 */
        export interface IVerifyConfigModel {
            type: string;
        }

        /** 获取钉钉/企微扫码链接 */
        export interface IGetWxWorkScanUrlModel {
            hostUrl: string;
        }

        export type ScanUrlModel = string;

        /** 获取扫码状态信息(微信) */
        export interface IGetWeChatScanStatusModel {
            /** sceneId,来自获取ticket接口 */
            sceneId: string | number;
            /** 签名,来自获取ticket接口 */
            sign: string;
        }

        /** 获取应用信息(企微) */
        export interface IGetWxWorkAppInfoModel {
            /** 企微agentId */
            agentId: string;
            /** 企微CorpId */
            wechatCorpId: string;
        }

        /** 校验发起互通的组织状态/确认互通 */
        export interface IAssOrgModel {
            /** 引擎编码 */
            engineCode: string;
            /** guid */
            guid: string;
        }

        export interface IVerifyAssOrgModel {
            /** 引擎编码 */
            engineCode: string;
            /** guid */
            guid: string;
        }

        /** 验证注册验证码 */
        export interface IVerifyRegisterMsgModel {
            mobile: string;
            verifyCode: string;
        }

        /** 获取企业人员规模 */
        export interface IGetScaleListModel {
            typeName: string;
        }

        /** 重置密码 */
        export interface IResetPasswordModel {
            /** 手机号 */
            mobile: string;
            /** 验证码 */
            verifyCode: string;
            /** 密码 */
            password: string;
        }

        /** 获取应用和解决方案相关信息 */
        export interface IGetAppInfoModel {
            /** 临时授权码 */
            code: string;
            /** 解决方案编码 */
            solutionCode?: string;
            /** 应用编码 */
            appCode?: string;
            /** 来源 */
            source: string;
            /** TODO 兼容老接口 */
            [key: string]: unknown;
        }

        /** 企业微信注册安装链接地址 */
        export interface IGetWxWorkInstallUrlModel {
            /** 推广包ID */
            promotionPackageId?: string;
            /** 企业名称 */
            engineName?: string;
            /** 管理员名称 */
            adminName?: string;
            /** 管理员手机号 */
            adminMobile?: string;
        }
    }

    export namespace Response {
        /**
         * 发送验证码返回值
         */
        export interface ISendVerifyCodeModel {
            /** 验证码长度 */
            verifyCodeLength: number;
            expiredTimestamp?: number;
            nextAccessIsNeedNc?: boolean;
        }

        /** 获取ticket信息(微信) */
        export interface IWeChatTicketInfoModel {
            /** 生效时间 */
            expireSecond: number;
            /** sceneId,用于是否关注公众号/扫码 */
            sceneId: number | string;
            /** 签名,用于是否关注公众号/扫码 */
            sign: string;
            /** 票据,用于组装微信扫码链接 */
            ticket: string;
        }

        export interface IVerifyConfigModel {
            appKey: string;
            scene: string;
        }

        export interface IWeChatScanStatusModel {
            /** 是否绑定,若已绑定就调用微信登录接口 否则就跳转登录页 */
            binded: boolean;
            /** 微信临时code码,用于绑定/微信登录 */
            code: string;
            /** 状态,-1: 已失效 0: 未扫码 1: 已扫码 */
            status: number;
        }

        export interface IGetWxWorkAppInfoModel {
            /** 企业名称 */
            name: string;
            /** 企业logo地址 */
            squareLogoUrl: string;
        }

        export interface IAssOrgModel {
            companyName: string;
        }

        export interface IVerifyRegisterMsgModel {
            /** 临时token */
            token: string;
            /** 用户是否存,若不存在需要设置密码 */
            registered: boolean;
            /** 最大限度 */
            engineCountLimit: number;
            /** 企业列表 */
            engines: Array<{ code: string; name: string }>;
        }

        export interface IGetScaleListModel {
            /** 描述 */
            description: string;
            /** 值 */
            value: string;
            /** 名称 */
            name: string;
        }

        export type ResetPasswordModel = null;

        export interface IGetAppInfoModel {
            isBetaForm: boolean;
            schemaCode: string;
            showLandingPage: boolean;
            solutionType: number;
            // 这个是迭代136新增的字段，但在entry全局服务中调用，所以写成可选的
            appInstalled?: boolean;
            appCode?: string;
            solutionState?: number;
            [key: string]: unknown;
        }

        /** 获取指定企业是否发布新版前端 */
        export interface IGetNextFrontModel {
            /** 引擎编码 */
            engineCode: string;
            /** 引擎所在slice */
            slice: string;
            /** 是否已经更新了新前端 */
            nextFrontFlag: string;
        }

        export interface IGetWxWorkInstallUrlModel {
            /** 地址 */
            installUrl?: string;
            authInstallUrl: string;
        }

        export interface IsSaaSCropModel {
            isSaas: boolean;
        }

        export enum FormVersionEnum {
            V1 = 1,
            V2 = 2,
            V3 = 3,
        }

        /** 外链登录响应模型 */
        export type TExternalLoginModel = {
            /** token */
            token: string;

            /** 引擎编码 */
            engineCode: string;

            /** 公司名称 */
            companyName: string;

            /** shardKey */
            shardKey: string;

            /** 表单版本 */
            formVersion: FormVersionEnum;

            /** 表单名称 */
            schemaName: string;

            /** 外链配置 */
            externalConfig?: {
                /** 应用编码 */
                appCode: string;

                /** 表单编码 */
                schemaCode: string;

                /** 业务数据id */
                bizObjectId: string;

                /** 是否外链填单 */
                isExternalForm: boolean;

                /** 外链填单是否开启查看 */
                externalFormEnableView: boolean;

                /** 是否外链填单后查看数据 */
                isReviewExternalForm: boolean;

                /** 是否外链分享 */
                isExternalShare: boolean;

                /** 是否公开查询 */
                isOpenQuery: boolean;
            };

            /** 是否需要身份识别 */
            needLogin: boolean;

            /** 短信余量 */
            smsLeft: number;

            /** 原始链接 */
            originalUrl: string;
        };
    }

    export const WxWorkApiPath = 'https://open.weixin.qq.com/connect/oauth2/authorize';

    /** 平台接入类型 */
    export enum EntryAppType {
        /** 钉钉 */
        DingTalk = 'dingtalk',
        /** 企微 */
        WxWork = 'wxwork',
        /** 飞书 */
        FeiShu = 'feishu',
        /** 微信 */
        WeChat = 'wechat',
        /** 第三方单点登录 */
        SSO = 'sso',
        /** 氚云 */
        H3 = 'h3',
    }

    /** 授权登陆类型 */
    export enum EntryAuthType {
        /** 扫码 */
        Scan = 'scan',
        /** 企业接入 */
        Corp = 'corp',
        /** 三方平台对应的后台管理系统，直接跳转氚云 */
        OA = 'oa',
        /** 单应用(套件) */
        Suite = 'suite',
        /** 代开发(企微) */
        DK = 'dk',
        /** 三方的自建应用(企微) */
        SuiteCorp = 'suiteCorp',
        /** 三方单点登录 */
        SSO = 'sso',
        /** 游客 */
        Visitor = 'visitor',
    }

    /** 进入官网方式 */
    export enum EntryWebsiteType {
        /** 通过 选择组织页面 进入 */
        OrganizationPage = 'organizationPage',
        /** 通过 用户菜单 进入 */
        UserMenu = 'userMenu',
    }

    /** 登录信息 */
    export interface ILoginInfo {
        /** 登录token,存放至cookie */
        token: string;
        /** vessel,存放至cookie */
        shardKey: string | null;
        /** 引擎编码,存放至cookie */
        engineCode: string;
        /** 企业列表 */
        engines: Array<{ code: string; name: string }>;
    }

    /** 注册信息 */
    export interface IRegisterInfo {
        /** 手机号 */
        mobile: string;
        /** 临时token */
        token: string;
        /** 企业名称 */
        engineName: string;
        /** 行业 */
        industry: string;
        /** 人员规模 */
        scaleType: string;
        /** 所属地区 */
        region: string;
        /** 密码 */
        password?: string;
        /** 推荐码 */
        recommendCode?: string;
        /** 来自,目前项目中有EngineConnection;AliyunMarket;register */
        from?: string;
        /** 阿里云市场授权码 */
        aliyunLicenseCode?: string;
    }

    /** 验证注册信息 */
    export interface IVerifyRegisterInfo {
        /** 临时token */
        token: string;
        /** 用户是否存,若不存在需要设置密码 */
        registered: boolean;
        /** 最大限度 */
        engineCountLimit: number;
        /** 企业列表 */
        engines: Array<{ code: string; name: string }>;
    }

    export interface IThirdAppModel {
        agentId: string;
        corpId: string;
        /** 企微用到(区分管理员和普通用户) */
        type?: string;
        url?: string;
        code?: string;
        suiteKey?: string;
        state?: string;
        from?: string;

        [key: string]: string | undefined;
    }

    export namespace ThirdParty {
        /** 企微、钉钉签名 */
        export interface ISignature {
            agentId: string;
            /** 企业微信的corpID */
            corpId: string;
            /** 签名 */
            signature: string;
            /** 生成签名的随机串 */
            nonceStr: string;
            /** 生成签名的时间戳 */
            timeStamp: string;
        }

        export type IThirdAppParams = {
            agentId: string;
            corpId: string;
            /** 企微用到(区分管理员和普通用户) */
            type?: string;
            url?: string;
            code?: string;
            suiteKey?: string;
            state?: string;
            from?: string;
        } & {
            /** 自定义字段 */
            [key in string]: unknown;
        };

        /**
         * 第三方免登参数
         */
        export type ISSOAuthParams = {
            code: string;
            engineCode: string;
            state: string;
        };

        export interface IThirdAppJssdk {
            /** 入参 */
            params: IThirdAppParams;

            /** 请求参数({a:1, b:2}) */
            query?: { [name: string]: string };

            /** 当前路径 */
            localPath: string;

            /** 第三方接口地址 */
            apiPath: string;

            /** jsapi列表 */
            jsApiList: string[];

            /** 获取免登临时码 */
            requestAuthCode: (params: string) => void;

            /** 获取鉴权 */
            getSignature: (params: string) => void;
        }

        // 上传文件到微盘接口返回
        export interface IWedriveDownloadFile {
            fileName: string;
            fileId: string;
            success: boolean;
            errorMessage?: string; // success为true时返回
        }
    }
}
