import { H3 } from '@h3/types';
import { request } from '@h3/request';

/** 获取提醒事项 */
export function getReminding(pageSize: number, pageIndex: number, taskState: H3.Task.TaskRemindingStatus) {
    return request<
        H3.Http.Response.H3Response<{
            remindTasks: Array<H3.Task.IRemindingSummary>;
            total: number;
        }>
    >('/v1/remindtask/list', {
        method: 'post',
        data: {
            pageIndex,
            pageSize,
            taskState,
        },
    });
}

/** 标记提醒事项已读 */
export function markRemindingRead(taskId: string) {
    return request<H3.Http.Response.H3Response<H3.Task.IRemindingSummary>>('/v1/remindtask/state/read', {
        method: 'put',
        data: {
            taskId,
        },
    });
}

/** 获取未读消息数量 */
export function getRemindingUnReadCount() {
    return request<H3.Http.Response.H3Response<number>>('/v1/remindtask/unread/count', {
        method: 'get',
    });
}

/** 获取待办数量 */
export function getTodoCount() {
    return request<H3.Http.Response.H3Response<H3.Task.TodoCount>>('/v1/workflow/todos/count', {
        method: 'get',
    });
}

/** 获取待处理的待办数量 */
export function getUnFinishedCont() {
    return request<H3.Http.Response.H3Response<number>>('/v1/workflow/todos/unfinished/count', {
        method: 'get',
    });
}

// 获得批量编辑任务进度
export const getProcess = (params: H3.Task.Request.IProcess) => {
    return request<H3.Http.Response.H3Response<H3.Task.Response.IProcess>>('/v1/bizdata/batchupdate/progress', {
        method: 'post',
        data: params,
    });
};

// 获得还未执行完成的任务队列;
export const getTaskQueue = (params: H3.Task.Request.IGetTaskQueue) => {
    return request<H3.Http.Response.H3Response<H3.Task.Response.IGetTaskQueue>>('/v1/bizdata/batchupdate/tasks', {
        method: 'post',
        data: params,
    });
};
