export namespace Message {
    export enum MessageState { // 后台.Net代码中用TaskState表示
        UnRead = 0, // 未读，对应后台.Net代码中状态UnFinished
        Read = 1, // 已读，对应后台.Net代码中状态Finished
        All = 2, // 全部，对应后台.Net代码状态UnSpecified
    }

    export enum TaskType {
        IsTask = 1,
    }

    export interface MessageStateOption {
        text: string;
        value: MessageState;
    }

    export interface IMessageItem {
        AlertTime: string;
        AppCode: string;
        BizObjectId: string;
        FinishedTime: string | null;
        IconCss: string;
        Name: string;
        OriginatorName: string;
        OriginatorProfileUrl: string;
        ReceiveTime: string | null;
        RecievedUserName: string | null;
        SchemaCode: string;
        SchemaName: string;
        StartTime: string;
        EndTime: string;
        Summary: string;
        TaskId: string;
        TaskRecieveId: string;
        TaskState: MessageState;
        TaskType: number;
        RecurrenceType: number;
        ReminderType: number;
        RuleId?: string;
    }

    export namespace Response {
        export interface ITaskListData {
            TotalCount: number;
            UnreadCount: number;
            TaskListItems: IMessageItem[];
        }
    }
}
