import { request } from '@h3/request';
import { H3 } from '@h3/types';

// 请求头
const headers = {
    'Content-Type': H3.Http.ContentType.FORM_URLENCODED,
};

/**
 * 触发自动化
 * @param schemaCode
 * @param actionCode
 * @param bizObjectIds
 * @param actionBelong
 * @param customParams
 * @returns
 */
export const triggerAutomation = (
    schemaCode: string,
    actionCode: string,
    bizObjectIds: string[],
    actionBelong: number, // 1 表单 2 列表
    customParams: Record<string, unknown>,
) => {
    const params = {
        schemaCode,
        actionCode,
        bizObjectIds,
        actionBelong,
        customParams,
    };
    return request<H3.Http.Response.H3Response>('/v1/bizdata/action/automation', {
        method: 'post',
        data: params,
    });
};

/**
 * 获取按钮执行自动化的自定义参数
 * @param schemaCode
 * @param actionCode
 * @returns
 */
export const getActionCustomParams = (
    schemaCode: string,
    actionCode: string,
    actionBelong = H3.Button.ButtonSceneType.List,
) => {
    const url = `/v1/automation/customparams?schemaCode=${schemaCode}&actionCode=${actionCode}&actionBelong=${actionBelong}`;
    return request<H3.Http.Response.H3Response<H3.Button.IDynamicControl[]>>(url, {
        method: 'get',
        data: {},
    });
};

// 根据应用编码获取根据表单分组的自动化数据
export const getAutomationByAppCode = (appCode: string) => {
    const params = {
        ActionName: 'LoadTriggersByAppCode',
        AppCode: appCode,
    };
    return request<H3.Http.Response.H3ResponseV2<H3.Automation.IGroupAutomationDataResponse>>('/Automatic/OnAction', {
        method: 'post',
        data: {
            PostData: JSON.stringify(params),
        },
        headers,
        requestType: 'form',
        disableBizError: true,
    });
};
