import { H3 } from '@h3/types';
import { request } from '@h3/request';
import { EsignApi } from './constant';

/** 获取电子签章绑定关系 */
export const getEsignBizObjectTask = (
    type: H3.Plugin.EsignType,
    params: { bizObjectId: string; schemaCode: string; dataField: string },
) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<{
            objectId: string;
            bizObjectId: string;
            taskId: string;
            fileQty?: number;
            finishTime?: string;
            status: H3.Plugin.EsignFlowStatus;
            engineCode?: string;
            schemaCode?: string;
            dataField?: string;
            createdTime?: string;
            modifiedTime?: string;
            modifiedBy?: string;
            description?: string;
        }>
    >(EsignApi[type].EsignBizObjectTask, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取流程签署详细信息 */
export const getEsignFlowSignDetail = (
    type: H3.Plugin.EsignType,
    params: { taskId: string; engineCode: string; userId: string },
) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<{
            businessSense: string;
            flowStatus: H3.Plugin.EsignFlowStatus;
            signers: Array<{ signerName: string; signerStatus: H3.Plugin.EsignSignerStatus }>;
        }>
    >(EsignApi[type].EsignFlowSignDetail, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取签署人签署地址 */
export const getEsignNoticeUrl = (
    type: H3.Plugin.EsignType,
    params: { taskId: string; engineCode: string; userId: string },
) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<{
            pcUrl: string;
            mobileUrl: string;
        }>
    >(EsignApi[type].EsignNoticeUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 通过taskId获取签署完成的文件 */
export const getEsignBizObjectDocs = (
    type: H3.Plugin.EsignType,
    params: {
        taskId: string;
        bizObjectId: string;
        schemaCode: string;
        dataField: string;
    },
) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<
            Array<{
                fileId: string;
                attachmentId: string;
                code: string;
                description: string;
                fileName: string;
                fileUrl: string;
                size: number;
                sortKey: number;
            }>
        >
    >(EsignApi[type].EsignBizObjectDocs, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取文件上传地址 */
export const getEsignUploadUrl = (type: H3.Plugin.EsignType, params: { attachmentIds: string[] }) => {
    return request<H3.Http.Response.H3ResponseV2Beta<Array<{ fileId: string; fileName: string; uploadUrl: string }>>>(
        EsignApi[type].EsignUploadUrl,
        {
            method: 'post',
            data: params,
            disableBizError: true,
        },
    );
};

/** 获取发起签署任务地址 */
export const getEsignProcessStartUrl = (
    type: H3.Plugin.EsignType,
    params: H3.Plugin.Request.EsignProcessStartUrlModel,
) => {
    return request<
        H3.Http.Response.H3ResponseV2Beta<{
            taskId: string;
            pcUrl: string;
            mobileUrl: string;
        }>
    >(EsignApi[type].EsignProcessStartUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取个人/组织认证地址 */
export const getEsignAuthUrl = (type: H3.Plugin.EsignType, params: H3.Plugin.Request.EsignAuthUrlModel) => {
    return request<H3.Http.Response.H3ResponseV2Beta<string>>(EsignApi[type].EsignAuthUrl, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 获取电子签章模板 */
export const getEsignTemplate = (type: H3.Plugin.EsignType, params: { templateId: string }) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(EsignApi[type].EsignTemplate, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};
