import { H3 } from '@h3/types';
import { request } from '@h3/request';
import OnceRequest from '../../utils/once-request';
import * as Api from './constant';
/**
 * 获取schema信息
 * @param params
 * @returns
 */
export const getSchema = (params: H3.Schema.Request.IGetSchemaModel) => {
    return request<H3.Http.Response.H3Response<H3.Schema.ISchema | boolean>>(Api.GET_SCHEMA_URL, {
        method: 'get',
        params,
    });
};

export const onceGetSchema = OnceRequest<
    H3.Http.Response.H3Response<H3.Schema.ISchema | boolean>,
    H3.Schema.Request.IGetSchemaModel
>(getSchema);
