import { H3 } from '@h3/types';
import { Util } from '@h3/h3common';
import * as Api from '../../api';
import EngineMetaData from '../meta/engine-meta-data';
import ContextMetaData from '../meta/context-meta-data';
import AppContext from './app';
import SchemaContext from './schema';
import OrgContext from './org';

export default class EngineContext {
    /** 引擎编码 */
    private _engineCode: string;

    /** 引擎元数据 */
    private readonly _engineMetaData: EngineMetaData;

    /** 上下文元数据 */
    private readonly _contextMetaData: ContextMetaData;

    /** 人员部门数据 */
    org: OrgContext;

    /** 当前活动的应用 */
    activeApp?: AppContext;

    constructor() {
        this._engineCode = Util.Storage.CookieStorage.get(H3.Http.Cache.H3_ENGINE_CODE);
        this._engineMetaData = new EngineMetaData();
        this._contextMetaData = new ContextMetaData();
        this.org = new OrgContext(this);
    }

    /** 引擎编码 */
    get engineCode() {
        return this._engineCode;
    }

    /** 引擎信息 */
    get engineInfo() {
        return this._engineMetaData.userInfo.currentEngine;
    }

    /** 用户信息 */
    get userInfo() {
        return this._engineMetaData.userInfo;
    }

    /** 所有应用 */
    get apps() {
        const appCodes = this._engineMetaData.appCodes || [];
        return this._contextMetaData.getFunctionNodes(appCodes);
    }

    /** 用户有管理权限的应用编码 */
    get adminAppCodes() {
        return this._engineMetaData.adminAppCodes || [];
    }

    /** 信任通道外部组织 */
    get externalEngines() {
        const connectEngines = this._engineMetaData.connectEngines || [];
        return connectEngines.filter((item) => !item.primary);
    }

    /** 关联组织 */
    get relationCorps() {
        return this._engineMetaData.relationCorps || [];
    }

    /**
     * 初始化加载用户信息和引擎信息
     */
    async init() {
        const [userRes, engineRes] = await Promise.all([
            Api.User.getUserInfo({ engineCode: this._engineCode }),
            Api.Engine.getEngineInfo(),
        ]);
        if (userRes.successful) {
            this._engineMetaData.setUserInfo(userRes.returnData);
        }
        if (engineRes.successful) {
            this._engineMetaData.setEngineInfo(engineRes.returnData);
        }
    }

    /** 加载所有应用 */
    async loadApps(forceRefresh = false) {
        if (this._engineMetaData.appCodes && !forceRefresh) {
            return;
        }
        const ret = await Api.App.getAllApps();
        if (ret.successful) {
            const apps = ret.returnData;
            this._contextMetaData.setFunctionNodes(apps);
            this._engineMetaData.initApps(apps.map((app) => app.code));
        }
    }

    /** 加载有权限管理的应用 */
    async loadAdminAppCodes(forceRefresh = false) {
        if (this._engineMetaData.adminAppCodes && !forceRefresh) {
            return;
        }

        const ret = await Api.App.getAllSubAdminAppList();
        if (ret.successful) {
            const codes = ret.returnData;
            this._engineMetaData.setAdminAppCodes(codes);
        }
    }

    /** 加载信任通道组织 */
    async loadConnectEngines() {
        if (this._engineMetaData.connectEngines) {
            return;
        }

        // TODO 目前固定取50条
        const res = await Api.Engine.getConnectEngines({
            pageIndex: 0,
            pageSize: 50,
        });
        if (res.successful) {
            this._engineMetaData.setConnectEngines(res.returnData.engines);
        }
    }

    /** 加载关联组织 */
    async loadRelationCorps() {
        if (this._engineMetaData.relationCorps) {
            return null;
        }

        const res = await Api.Operate.getRelateCorps();
        if (res.successful) {
            this._engineMetaData.setRelationCorps(res.returnData);
        }
        return res;
    }

    /**
     * 切换活动的应用，加载应用子菜单
     * @param appCode 要切换到的应用编码
     * @return 是否有指定应用访问权限
     */
    async toggleActiveApp(appCode: string) {
        // 加载所有应用
        await Promise.all([this.loadApps(), this.loadAdminAppCodes()]);

        if (!this.apps.some((app) => app.code === appCode)) {
            this.activeApp = void 0;
            return false;
        }
        this.activeApp = this.getAppContext(appCode);
        return true;
    }

    /** 创建应用 */
    async createApp(param: {
        code?: string;
        displayName: string;
        icon: string;
        iconColor: H3.FunctionNode.FunctionNodeIconColorType;
    }) {
        const res = await Api.FunctionNode.createFunctionNode({
            code: param.code,
            appCode: param.code || '',
            parentCode: '',
            displayName: param.displayName,
            icon: param.icon,
            iconColor: param.iconColor,
            nodeType: H3.FunctionNode.FunctionNodeType.AppPackage,
        });

        // 更新缓存
        if (res.successful) {
            const newApp: H3.FunctionNode.IFunctionNode = {
                objectId: res.returnData.id,
                code: res.returnData.code,
                icon: param.icon,
                iconColor: param.iconColor,
                displayName: param.displayName,
                nodeType: H3.FunctionNode.FunctionNodeType.AppPackage,
                appCode: res.returnData.code,
                parentCode: '',
                appState: H3.FunctionNode.FunctionNodeStateType.Normal,
                source: H3.App.AppSourceType.Own,
            };
            this._contextMetaData.setFunctionNode(newApp);
            this._engineMetaData.addApp(newApp.code);
            // 更新有权限管理的应用
            await this.loadAdminAppCodes(true);
        }

        return res;
    }

    /** 应用排序 */
    async sortApps(draggingCode: string, preCode?: string) {
        // 更新缓存
        this._engineMetaData.sortApps(draggingCode, preCode);

        const res = await Api.FunctionNode.sortFunctionNode({
            code: draggingCode,
            preCode,
        });
        return res;
    }

    /** 更新指定应用 */
    async updateApp(
        appCode: string,
        displayName: string,
        icon: string,
        iconColor: H3.FunctionNode.FunctionNodeIconColorType | undefined,
    ) {
        const res = await Api.FunctionNode.updateFunctionNode({
            code: appCode,
            displayName,
            icon,
            iconColor,
        });

        // 更新内存缓存
        if (res.successful) {
            const app = this._contextMetaData.getFunctionNode(appCode)!;
            this._contextMetaData.setFunctionNode({
                ...app,
                displayName,
                icon,
                iconColor,
            });
        }

        return res;
    }

    /** 删除应用 */
    async deleteApp(appCode: string) {
        const res = await Api.FunctionNode.deleteFunctionNode(appCode);
        //  更新缓存
        if (res.successful) {
            this._engineMetaData.removeApp(appCode);
            this._contextMetaData.deleteAppContext(appCode);
            // 如果删除的是当前应用，需要清空activeAppContext
            if (appCode === this.activeApp?.appCode) {
                this.toggleActiveApp('');
            }
        }
        return res;
    }

    /** 启动复制应用 */
    async startCopyApp(appCode: string) {
        return Api.FunctionNode.copyFunctionNode(appCode);
    }

    /** 复制应用 */
    async getCopyAppProgress(actionId: string) {
        const ret = await Api.FunctionNode.copyFunctionNodeProgress(actionId);
        if (ret.successful && ret.returnData.isComplete) {
            const copyApp = ret.returnData.node;
            this._contextMetaData.setFunctionNode(copyApp);
            this._engineMetaData.addApp(copyApp.code);
            // 更新有权限管理的应用
            await this.loadAdminAppCodes(true);
        }
        return ret;
    }

    /** 获取应用上下文 */
    getAppContext(appCode: string) {
        let context = this._contextMetaData.getAppContext(appCode);
        if (!context) {
            context = new AppContext(appCode, this._contextMetaData);
            this._contextMetaData.setAppContext(context);
        }
        return this._contextMetaData.getAppContext(appCode)!;
    }

    /** 获取表单上下文 */
    getSchemaContext(schemaCode: string) {
        let context = this._contextMetaData.getSchemaContext(schemaCode);
        if (!context) {
            context = new SchemaContext(schemaCode, this._contextMetaData);
            this._contextMetaData.setSchemaContext(context);
        }
        return this._contextMetaData.getSchemaContext(schemaCode)!;
    }

    /** 切换至指定的引擎上下文 */
    async toggleEngineContext(engineCode: string) {
        this._engineCode = engineCode;
        this._engineMetaData.clear();
        this._contextMetaData.clear();
        await Promise.all([this.init(), this.loadApps(), this.loadAdminAppCodes()]);
    }

    /** 获取指定应用默认表单 */
    async getDefaultSchema(appCode: string) {
        // 跳转到视觉上的第一个节点，与渲染逻辑耦合
        const appContext = this.getAppContext(appCode);
        await appContext.loadChildren();
        const targetNodeType = [
            H3.FunctionNode.FunctionNodeType.FormModule,
            H3.FunctionNode.FunctionNodeType.WorkflowModule,
            H3.FunctionNode.FunctionNodeType.DashBoardModule,
            H3.FunctionNode.FunctionNodeType.ReportModule,
            H3.FunctionNode.FunctionNodeType.SingletonModule,
            H3.FunctionNode.FunctionNodeType.CustomLinkModule,
        ];
        // 如果存在未分组的节点，跳转到未分组的第一个节点
        const unGroupNode = appContext.children.filter(
            (child) => targetNodeType.includes(child.nodeType) && child.parentCode === appCode,
        );
        if (unGroupNode.length > 0) {
            return unGroupNode[0].code;
        }
        // 如果不存在未分组的节点，跳转到第一个非空分组的第一个节点
        const groupNode = appContext.children.filter(
            (child) => child.nodeType === H3.FunctionNode.FunctionNodeType.GroupModule,
        );
        for (const node of groupNode) {
            const children = appContext.children.filter(
                (child) => child.parentCode === node.code && targetNodeType.includes(child.nodeType),
            );
            if (children.length > 0) {
                return children[0].code;
            }
        }
        // 如果不存在非空分组，跳转到空应用
        return '';
    }
}
