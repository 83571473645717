export namespace Oss {
    export enum OssUseType {
        /** 表单 */
        FORM = 'form',
        /** 评论 */
        SNS = 'sns',
        /** 描述控件 */
        DESCRIBEIMAGE = 'describeimage',
        /** 自定义logo上传 */
        LOGO = 'logo',
    }

    export interface IOssInfo {
        /** 由服务器端指定的Policy过期时间 */
        expiration: number;
        /** 访问密钥 */
        credential: string;
        /** 对Policy签名后的字符串 */
        signature: string;
        /** 请求的时间 */
        requestTime: string;
        /** 指定签名的版本和算法 */
        signatureVersion: string;
        /** Bucket域名 */
        host: string;
        /** 限制上传的文件前缀 */
        dir: string;
        /** 安全策略 */
        policy: string;
    }

    export namespace FileHeaders {
        // 草稿文件头信息
        export type TFileHeadersParams = {
            fileId: string;
            sourceFileId?: string;
            storageKey?: string;
            fileName?: string;
            contentType?: string;
            contentLength?: string;
            desc?: string;
            sortKey?: number;
        };
    }
    export namespace Response {
        export type IOssTokenRes = IOssInfo;

        export interface IOssUrlRes {
            url: string;
            fileId: string;
        }

        export interface ISaveFileHeaderRes {
            fileId: string;
            thumbnailUrl?: string;
        }
    }
}
