import * as Api from './api';
import * as Service from './service';
import { SwitchContext, EngineContext as UseEngineContext, SchemaContext } from './context/state';
import CacheService from './cache';
import DB from './db/database';

let EngineContext!: UseEngineContext;
let Switch!: SwitchContext;
let Cache!: CacheService;

function init() {
    EngineContext = new UseEngineContext();
    Switch = new SwitchContext();
    Cache = new CacheService();
}

export { init, Api, Service, EngineContext, Switch, Cache, DB };

export type { SchemaContext };
