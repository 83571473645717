import { H3 } from '@h3/types';

export class TableViewPersonalization {
    [H3.DB.DBField.ID]: string;

    [H3.DB.DBField.VALUE]: H3.DB.DBViewPersonalization | null = null;

    constructor({ id, value }: { [H3.DB.DBField.ID]: string; [H3.DB.DBField.VALUE]: H3.DB.DBViewPersonalization }) {
        this.id = id;
        this.value = value;
    }
}
