import { request } from '@h3/request';
import { H3 } from '@h3/types';
import * as Api from './constant';

/** 查询在线支付插件授权信息 */
export const getPaymentAliPayAuthInfo = () => {
    return request<H3.Http.Response.H3ResponseV2Beta<{ isAuthorized: boolean }>>(Api.PaymentAliPayAuthInfo, {
        method: 'post',
        disableBizError: true,
    });
};

/** 发起支付 */
export const getPaymentAliPayCreate = (params: {
    /** 交易id */
    triggerId: string;
    /** 支付内容 */
    subject: string;
    /** 支付金额 */
    totalAmount: number | string;
    schemaCode: string;
    bizObjectId: string;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.PaymentAliPayCreate, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 查询支付状态 */
export const getPaymentAliPayTradeQuery = (params: {
    /** 商户订单号 */
    outTradeNo: string;
    /** 交易号 */
    tradeNo: string;
    /** 退款金额 */
    refundAmount?: string;
    /** 退款结果 */
    refundReason?: string;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta<{ isError: boolean }>>(Api.PaymentAliPayTradeQuery, {
        method: 'post',
        data: params,
        disableBizError: true,
        camelCase: true,
    });
};

/** 交易关闭 */
export const getPaymentAliPayTradeClose = (params: {
    /** 商户订单号 */
    outTradeNo: string;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta>(Api.PaymentAliPayTradeClose, {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};

/** 交易退款 */
export const getPaymentAliPayTradeRefund = (params: {
    /** 商户订单号 */
    outTradeNo: string;
    /** 交易号 */
    tradeNo: string;
    /** 退款金额 */
    refundAmount?: string;
    /** 退款结果 */
    refundReason?: string;
}) => {
    return request<H3.Http.Response.H3ResponseV2Beta<{ isError: boolean }>>(Api.PaymentAliPayTradeRefund, {
        method: 'post',
        data: params,
        disableBizError: true,
        camelCase: true,
    });
};
