import { ref } from 'vue';
import { Util } from '@h3/h3common';
import { H3 } from '@h3/types';
import { AuthConfig } from '@/interface/auth';

export const useGlobalConfig = defineGlobalConfig();

/** 全局配置 */
function defineGlobalConfig() {
    /** 全局配置 */
    const globalConfig = ref<AuthConfig | null>(null);

    /** 解析全局配置 */
    function parseGlobalConfig(url = window.location.search) {
        // 解析路由参数
        const params = Util.Url.setUrlParamsToObj(url);

        if (!Util.IS.isObject(params)) {
            return;
        }

        // 转小驼峰
        for (const key in params) {
            params[key.toLowerCase()] = params[key];
        }

        params.from = params.from_dingdingda || params.dingdingda ? 'dingdingda' : '';

        globalConfig.value = {
            /** 平台接入类型 */
            appType: params?.appType,
            /** 授权类型 */
            authType: params?.authType as H3.Entry.EntryAuthType,
            /** 应用编码 */
            appId: params?.appid || params?.appId,
            /** 应用编码 */
            appCode: params?.appid || params?.appCode,
            /** 钉钉应用id，自建钉钉应用非套件应用时必须 */
            agentId: params?.agentId || params?.agentid || params?.ai,
            /** 钉钉企业编码/企微corpid/飞书corpid/企微代开发corpid */
            corpId: params?.corpId || params?.corpid,
            /** 企微corpid(兼容旧地址) */
            wechatCorpId: params?.wechatcorpid || '',
            /** 企微代开发corpid(兼容旧地址) */
            isvCorpId: params?.wechatisvcorpid || '',
            /** 企微代开发suiteid(兼容旧地址) */
            wechatSuiteId: params?.wechatsuiteid || '',
            /** 飞书corpid(兼容旧地址) */
            feishuCorpId: params?.feishucorpid || '',
            /** 企微类型(兼容旧地址) */
            type: params?.type,
            /** 菜单编码 */
            menuCode: params?.menucode || params?.menuCode,
            /** 解决方案编码 */
            solutionCode: params?.solutionCode || params?.solutioncode,
            /** 套件key */
            suiteKey: params?.suiteKey || params?.suitekey || params?.sk,
            /** 是否是isv套件 */
            isIsv: params?.type ? true : !!params?.isIsv,
            /** 应用打开的类型 */
            appRankType: params?.appranktype || params?.appRankType,
            /** 表单编码 */
            schemaCode: params?.schemacode || params?.schemaCode || params?.sc,
            /** bizObjectId */
            bizObjectId: params?.bizObjectId || params?.bizobjectid || params?.bo,
            /** 引擎编码 */
            engineCode: params?.engineCode || params?.enginecode,
            /** 流程待办任务id */
            workitemId: params?.workitemid || params?.workItemId,
            /** 钉钉免登授权码 */
            code: params?.code,
            /** 扫码状态码，仅扫码时存在 */
            state: params?.state,
            /** 账户 */
            account: params?.account,
            /** 消息类型 */
            messageType: params?.messagetype || params?.messageType || params?.mt,
            /** 任务打开方式 */
            taskOpenType: params?.taskopentype,
            /** sharingKey */
            sharingKey: params?.sharingkey || params?.sharingKey,
            /** ddIngPid PC端用到 */
            ddIngPid: params?.ddingpid,
            /** ddIngTid PC端用到 */
            ddIngTid: params?.ddingtid,
            /** 重定向方式 */
            redirectType: params?.redirectType,
            /** 重定向链接 */
            redirectUrl: params?.redirectUrl,
            /** token */
            token: params?.token,
            /** 签页标识 */
            ddtab: params?.ddtab,
            /** 流程通知 */
            workNotify: params?.worknotify || params?.workNotify,
            /** 钉钉AppId */
            dingtalkAppId: params?.dingtalkappid,
            /** 标识登录的来源 */
            from: params?.from,
            /** 酷应用群id */
            cid: params?.cid || '',
            /** token失效时同时没法自动授权的场景，需要抛出错误提示 */
            loginInvalid: params?.loginInvalid ? params.loginInvalid : 'false',
            /** 消息通知外部组织引擎编码 */
            associatedCode: params?.associatedCode ? params.associatedCode : '',
            /**  外部组织引擎编码 */
            externalCode: params?.externalCode ? params.externalCode : '',
            /** 进入官网的方式 */
            websiteType: params?.websiteType as H3.Entry.EntryWebsiteType,
            /** 前一个路由path*/
            lastRoutePath: '',
            /** 是否是模板体验 */
            isExperienceMode: params?.isExperienceMode ? params.isExperienceMode : 'false',
        };
    }

    return () => ({
        globalConfig,
        parseGlobalConfig,
    });
}
