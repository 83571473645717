import { init } from '@mitojs/browser';
import { vuePlugin } from '@mitojs/vue';
import type { App } from 'vue';

/**
 * 获取当前路由信息
 * @returns
 */
function getRootName() {
    const href = window.location.href;
    let path = href.split('?')[0];
    path = path.replace(`${window.location.origin}/`, '');
    return `/${path.split('/')[0]}`;
}

/**
 * 格式化异常上报数据
 * @param reportData
 * @param appName
 * @returns
 */
function formatData(reportData, appName) {
    const { message, time, type, stack } = reportData.data;

    return {
        Content: stack,
        Topic: '',
        SpanId: 1,
        UserTime: -1,
        UsedTimeLevel: '',
        LogTime: time,
        UserId: '',
        Parameters: '',
        Return: '',
        Consumer: '',
        ServiceName: appName,
        TraceId: '',
        MethodName: '',
        CustomerException: '',
        CriticalError: '',
        SchemaCode: '',
        Level: 'Error',
        ExceptionContent: message,
        ExceptionType: type,
        Route: getRootName(),
        UserAgent: window.navigator.userAgent,
        BreadCrumb: reportData.breadcrumb.map((item) => JSON.stringify(item)),
    };
}

/** 用类型推断定义类型，而不是使用any */
type BasePluginType = typeof init extends (options: unknown, plugins: infer Plugins) => unknown
    ? Plugins extends Array<infer Plugin>
        ? Plugin
        : never
    : never;

export function setupMonitor(app: App<Element>, appName: string) {
    init(
        {
            vue: app,
            dsn: '/v1/log',
            maxBreadcrumbs: 10,
            silentConsole: true,
            silentXhr: true,
            silentFetch: true,
            silentHistory: true,
            silentHashchange: true,
            beforeDataReport: (reportData) => {
                // 格式化上传数据
                return formatData(reportData, appName);
            },
        },
        [vuePlugin as BasePluginType],
    );
}
