const prefix = process.env.NODE_ENV === 'development' ? '/entry' : '';

/** 路由地址 */
export const PageEnum = {
    /** 登录 */
    LOGIN_HOME: process.env.NODE_ENV === 'development' ? `${prefix}` : '/',
    ENTRY_LOGIN: `${prefix}/login`,
    LOGIN_MOBILE: `${prefix}/login/mobile`,
    LOGIN_CORP: `${prefix}/login/corp`,
    LOGIN_DINGTALK: `${prefix}/login/dingtalk`,
    LOGIN_WECHAT: `${prefix}/login/wechat`,
    LOGIN_WXWORK: `${prefix}/login/wxwork`,
    LOGIN_FEISHU: `${prefix}/login/feishu`,
    RESET_PASSWORD: `${prefix}/login/reset`,
    EXTERNAL_LOGIN_CODE: `${prefix}/login/ext`,
    LOGIN_CODE: `${prefix}/login/verifyCode`,
    LOGIN_MOBILE_CODE: `${prefix}/mobile/code`,
    /** 注册 */
    REGISTER_HOME: `${prefix}/register`,
    REGISTER_HOME_AUTH_CODE: `${prefix}/register/authCode`,
    REGISTER_CORP: `${prefix}/register/corp`,
    REGISTER_DINGTALK: `${prefix}/register/dingtalk`,
    REGISTER_WECHAT: `${prefix}/register/wechat`,
    REGISTER_WXWORK: `${prefix}/register/wxwork`,
    REGISTER_FEISHU: `${prefix}/register/feishu`,
    REGISTER_SUCCESS: `${prefix}/register/success`,
    /** 认证 */
    LOGIN_AUTH: `${prefix}/auth`,
    AUTH_DINGTALK: `${prefix}/auth/dingtalk`,
    AUTH_FEISHU: `${prefix}/auth/feishu`,
    AUTH_WXWORK: `${prefix}/auth/wxwork`,
    AUTH_EXTERNAL: `${prefix}/auth/external`,
    /** 其他 */
    BASE_ERROR: `${prefix}/error`,
    BASE_SUCCESS: `${prefix}/success`,
    ASS_ORG_HOME: `${prefix}/ass-org`,
    ASS_ORG_MAKE: `${prefix}/make-org`,
    WORK_BENCH: '/workbench',
    FORM_DESIGNER: '/form/designer',
    /** 登出 */
    LOGIN_LOGOUT: `${prefix}/logout`,
};

/** 路由名称 */
export const PageNameEnum = {
    /** 登录 */
    LOGIN_HOME: 'EntryLoginHome',
    ENTRY_LOGIN: 'EntryLogin',
    LOGIN_MOBILE: 'LoginMobile',
    LOGIN_CORP: 'LoginCorp',
    LOGIN_DINGTALK: 'LoginDingtalk',
    LOGIN_WECHAT: 'LoginWechat',
    LOGIN_WXWORK: 'LoginWxwork',
    LOGIN_FEISHU: 'LoginFeishu',
    RESET_PASSWORD: 'ResetPassword',
    EXTERNAL_LOGIN_CODE: 'ExternalLoginCode',
    LOGIN_CODE: 'LoginCode',
    LOGIN_MOBILE_CODE: 'LoginMobileCode',
    /** 注册 */
    REGISTER_HOME: 'EntryRegister',
    REGISTER_HOME_AUTH_CODE: 'EntryRegisterAuthCode',
    REGISTER_CORP: 'RegisterCorp',
    REGISTER_DINGTALK: 'RegisterDingtalk',
    REGISTER_WECHAT: 'RegisterWechat',
    REGISTER_WXWORK: 'RegisterWxwork',
    REGISTER_FEISHU: 'RegisterFeishu',
    REGISTER_SUCCESS: 'RegisterSuccess',
    /** 认证 */
    LOGIN_AUTH: 'EntryAuth',
    AUTH_DINGTALK: 'EntryAuthDing',
    AUTH_FEISHU: 'EntryAuthFeishu',
    AUTH_WXWORK: 'EntryAuthWxwork',
    AUTH_EXTERNAL: 'EntryAuthExternal',
    /** 其他 */
    BASE_ERROR: 'EntryError',
    BASE_SUCCESS: 'EntrySuccess',
    ASS_ORG_HOME: 'EntryAssOrg',
    ASS_ORG_MAKE: 'EntryMakeOrg',
    WORK_BENCH: 'WorkBench',
    FORM_DESIGNER: 'FormDesigner',
    /** 登出 */
    LOGIN_LOGOUT: 'EntryLogout',
};

/** 登录相关路由 */
export const PageLoginEnum = {
    LOGIN_HOME: process.env.NODE_ENV === 'development' ? `${prefix}` : '/',
    ENTRY_LOGIN: `${prefix}/login`,
    LOGIN_MOBILE: `${prefix}/login/mobile`,
    LOGIN_CORP: `${prefix}/login/corp`,
    LOGIN_DINGTALK: `${prefix}/login/dingtalk`,
    LOGIN_WECHAT: `${prefix}/login/wechat`,
    LOGIN_WXWORK: `${prefix}/login/wxwork`,
    LOGIN_FEISHU: `${prefix}/login/feishu`,
    RESET_PASSWORD: `${prefix}/login/reset`,
    LOGIN_CODE: `${prefix}/login/verifyCode`,
    EXTERNAL_LOGIN_CODE: `${prefix}/login/ext`,
    LOGIN_MOBILE_CODE: `${prefix}/mobile/code`,
};

/** 注册相关路由 */
export const PageRegisterEnum = {
    REGISTER_HOME: `${prefix}/register`,
    REGISTER_HOME_AUTH_CODE: `${prefix}/register/authCode`,
    REGISTER_CORP: `${prefix}/register/corp`,
    REGISTER_DINGTALK: `${prefix}/register/dingtalk`,
    REGISTER_WECHAT: `${prefix}/register/wechat`,
    REGISTER_WXWORK: `${prefix}/register/wxwork`,
    REGISTER_FEISHU: `${prefix}/register/feishu`,
    REGISTER_SUCCESS: `${prefix}/register/success`,
};
