import { request } from '@h3/request';
import { H3 } from '@h3/types';

/** 发送验证码 */
export const sendMobileVerifyCode = (params: { mobile: string }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/account/sendmobileverifycode', {
        method: 'post',
        data: params,
    });

/** 绑定手机号 */
export const bindMobile = (params: { mobile: string; verifyCode: string }) =>
    request<H3.Http.Response.H3Response<boolean>>('/v1/account/bindmobile', {
        method: 'post',
        data: params,
        disableBizError: true,
    });

// TODO
/** 校验手机号 */
export const validateMobile = (params: { mobile: string }) => {
    return request<H3.Http.Response.H3ResponseV2<{ Result: boolean }>>('/Account/ValidateMobile', {
        method: 'post',
        data: params,
        disableBizError: true,
    });
};
