import { Control } from '../control';
import { CustomCode } from '../custom-code';
import { Auth } from '../auth';
import { Schema } from '../schema';
import { BizData } from '../data';
import { Filter } from '../filter';

export namespace View {
    /**
     * 系统可用的视图类型
     */
    export enum ViewMode {
        List = 0,
        Calendar = 1,
        Timeline = 2,
        // Kanban = 3, // 已废弃
        Gantt = 4,
        Map = 5,
        Hierarchy = 6,
        Card = 7,
    }

    /**
     * 视图类型对应的配置key
     */
    export enum ViewModeKey {
        List = 'list',
        Calendar = 'calendar',
        Timeline = 'timeline',
        Gantt = 'gantt',
        Map = 'map',
        Hierarchy = 'hierarchy',
        Card = 'card',
    }

    /**
     * 视图状态类型
     */
    export enum ViewStateType {
        Unspecified = -1,
        /**
         * 数据预加载
         */
        BeforeLoad = 0,
        /**
         * 数据加载中
         */
        Loading = 1,
        /**
         * 数据加载结束
         */
        Loaded = 2,
        /**
         * 视图开始渲染
         */
        Rendering = 3,
        /**
         * 视图渲染结束
         */
        Rendered = 4,
        /**
         * 空闲状态
         */
        Idle = 5,
        /**
         * 视图销毁前状态
         */
        BeforeDestroy = 6,
        /**
         * 视图销毁结束
         */
        Destroyed = 7,
    }

    /**
     * 系统视图名称
     */
    export enum ViewModeName {
        List = '列表视图',
        Calendar = '日历视图',
        Timeline = '时间轴',
        Gantt = '甘特图',
        Map = '地图视图',
        Hierarchy = '层级视图',
    }

    /**
     * 视图类型集合
     */
    export const ViewList = [
        {
            type: ViewMode.List,
            name: ViewModeName.List,
            key: ViewModeKey.List,
        },
        {
            type: ViewMode.Calendar,
            name: ViewModeName.Calendar,
            key: ViewModeKey.Calendar,
        },
        {
            type: ViewMode.Timeline,
            name: ViewModeName.Timeline,
            key: ViewModeKey.Timeline,
        },
        {
            type: ViewMode.Gantt,
            name: ViewModeName.Gantt,
            key: ViewModeKey.Gantt,
        },
        {
            type: ViewMode.Map,
            name: ViewModeName.Map,
            key: ViewModeKey.Map,
        },
        {
            type: ViewMode.Hierarchy,
            name: ViewModeName.Hierarchy,
            key: ViewModeKey.Hierarchy,
        },
    ];

    /** 基础视图元数据 */
    export type TViewItem = {
        type: ViewMode;
        name: string;
        icon: string;
    };

    /** 氚云支持的视图集 */
    export const VIEW_MAP: Record<string, TViewItem> = {
        [ViewMode.List]: {
            type: ViewMode.List,
            name: '列表视图',
            icon: 'table-view-stroke',
        },
        [ViewMode.Calendar]: {
            type: ViewMode.Calendar,
            name: '日历视图',
            icon: 'calendar-view-stroke',
        },
        [ViewMode.Timeline]: {
            type: ViewMode.Timeline,
            name: '时间轴',
            icon: 'timeline-stroke',
        },
        [ViewMode.Gantt]: {
            type: ViewMode.Gantt,
            name: '甘特图',
            icon: 'gantt-chart-stroke',
        },
        [ViewMode.Map]: {
            type: ViewMode.Map,
            name: '地图视图',
            icon: 'map-view-stroke',
        },
        [ViewMode.Hierarchy]: {
            type: ViewMode.Hierarchy,
            name: '层级视图',
            icon: 'hierarchy-view-stroke',
        },
        [ViewMode.Card]: {
            type: ViewMode.Card,
            name: '卡片视图',
            icon: 'card-view-stroke',
        },
    };

    /**
     * 设备类型
     */
    export enum DeviceType {
        PC = 'pc',
        Mobile = 'mobile',
    }

    /**
     * 可查看的组织数据范围类型
     */
    export enum ViewScopeType {
        ViewOwn = 1,
        ViewOwnDept = 2,
        ViewAll = 3,
    }

    /**
     * 甘特图时间维度
     */
    export enum GanttScaleType {
        Hour = 1,
        Day = 2,
        Week = 3,
        Month = 4,
        Quarter = 5,
        Year = 6,
    }

    /** 层级视图样式 */
    export enum HierarchyCss {
        List = 1,
        Card = 2,
    }

    /**
     * 排序维度
     */
    export enum SortDirection {
        Asc = 0,
        Desc = 1,
    }

    export enum ImagePosition {
        Left = 'left',
        Top = 'top',
    }

    export enum ImageDisplay {
        CenterOriginal = 'centerOriginal', // 原图居中
        FillOriginal = 'fillOriginal', // 原图填充
        StretchFill = 'stretchFill', // 拉升填充
    }

    // 偏好可见类型
    export enum PreferenceVisibleType {
        Unspecified = -1,
        System = 0,
        Personal = 1,
        Public = 2,
    }

    /**
     * 偏好可见类型元数据
     */
    export const PreferenceVisibleTypes = [
        {
            label: '未定义',
            value: -1,
        },
        {
            label: '系统',
            value: 0,
        },
        {
            label: '个人',
            value: 1,
        },
        {
            label: '公共',
            value: 2,
        },
    ];

    export type TFunctionSwitch = {
        action: boolean; // 是否支持功能按钮
        filter: boolean; // 是否支持筛选条件
        defaultFilter: boolean; // 是否支持筛选默认值
        edit: boolean; // 是否支持编辑
        analysis: boolean; // 是否支持统计分析
        viewList: boolean; // 是否支持视图列表切换
        preference: boolean; // 是否可以切换筛选
        subTable: boolean; // 是否显示子表
        summary: boolean; // 是否显示统计
        isRelationMode?: boolean; // 是否关联模式
    };

    /** 视图排序配置 */
    export type TSortConfig = {
        sortBy: string;

        sortDirection: SortDirection;
    };

    /** 视图组件使用场景选项 */
    export type TSceneOptions = {
        functionSwitch: TFunctionSwitch;
    };

    export type TSceneType = 'View' | 'Filter' | 'Form'; // 组件支持的场景

    /** 场景类型 */
    export enum SceneType {
        View = 'View',
        Filter = 'Filter',
        Form = 'Form',
        AssociationList = 'AssociationList',
    }

    export enum ViewPreferenceCacheKey {
        /** 筛选状态 */
        FilterVisible = 'FILTER_VISIBLE',
        /** 筛选固定状态 */
        FilterFixed = 'FILTER_FIXED',
        /** 个性化 */
        ViewPreference = 'VIEW_PREFERENCE',
        /** 分组筛选展开状态 */
        GroupCollapsed = 'GROUP_COLLAPSED',
        /** 分组筛选包含下级状态 */
        GroupIsContain = 'GROUP_IS_CONTAIN',
    }

    /**
     * 不同场景初始值
     */
    export const SCENE_OPTIONS_MAP = {
        [SceneType.View]: {
            functionSwitch: {
                action: true,
                filter: true,
                edit: false,
                analysis: true,
                viewList: true,
                preference: true,
                subTable: true,
                summary: true,
                defaultFilter: true,
            },
        },
        [SceneType.Filter]: {
            functionSwitch: {
                action: false,
                filter: true,
                edit: false,
                analysis: false,
                viewList: false,
                preference: false,
                subTable: false,
                summary: false,
                defaultFilter: true,
                isRelationMode: true,
            },
        },
        [SceneType.Form]: {
            functionSwitch: {
                action: false,
                filter: true,
                edit: false,
                analysis: false,
                viewList: false,
                preference: false,
                subTable: false,
                summary: false,
                defaultFilter: true,
                isRelationMode: true,
            },
        },
        [SceneType.AssociationList]: {
            functionSwitch: {
                action: false,
                filter: false,
                edit: false,
                analysis: false,
                viewList: false,
                preference: false,
                subTable: false,
                summary: false,
                defaultFilter: false,
                isRelationMode: true,
            },
        },
    };

    /**
     * 视图元数据
     */
    export interface IViewProperty extends Schema.IProperty {
        /** 子组件 */
        childProperties?: Schema.IProperty[];

        /** 是否子表控件 */
        isChild?: boolean;
    }

    /**
     * 自定义按钮执行配置
     */
    export interface IExecuteConfig {
        executeContent: ExecuteType;
        automationTemplateId: string;
        confirmInfo: IConfirmInfo | null;
        editableProperties?: string[];
        mergeBizData?: boolean; // 单步执行还是合并执行
        pluginActionParam?: string; // 考虑后期扩展，采用了json 字符串格式
        submitRule?: {
            state: boolean;
            errorMessage: string;
            ruleString: string;
            rule: Filter.IFilterItem[][];
        }; // 数据校验
        associationSchemaCode?: string; // 新增关联表单编码
    }

    /**
     * 自定义按钮二次确认内容结构
     */
    export interface IConfirmInfo {
        title: string;
        detail: string;
    }

    /**
     * 视图按钮类型
     */
    export const enum ActionType {
        System, // 系统按钮
        Custom, // 自定义按钮
        Batch, // 批量编辑
    }

    /**
     * 自定义按钮执行类型
     */
    export const enum ExecuteType {
        Automation = 1, // 执行自动化
        CustomCode = 2, // 执行自定义代码
        PlatformIntegration = 3, // 执行平台集成
        NewData = 4, // 新增数据
    }

    /**
     * 视图按钮结构
     */
    export interface IAction {
        actionCode: string;
        displayName: string;
        icon: string;
        isCustomPrint?: boolean;
        isPrintAction?: boolean;
        // printTemplateCode?: string | null;
        actionType?: ActionType;
        executeConfig?: IExecuteConfig;
    }
    /**
     * 系统内置按钮key
     */
    export enum SystemActionKey {
        Create = 'Create',
        Remove = 'Remove',
        Import = 'Import',
        Export = 'Export',
        ExportToWXWorkspace = 'ExportToWXWorkspace',
        PrintQrCode = 'PrintQrCode',
        Print = 'Print',
        PrintQrCode_Inner = 'PrintQrCode_Inner',
        PrintQrCode_Outer = 'PrintQrCode_Outer',
    }

    /**
     * 系统按钮
     */
    export const SYSTEM_ACTIONS = [
        SystemActionKey.Create,
        SystemActionKey.Import,
        SystemActionKey.Export,
        SystemActionKey.ExportToWXWorkspace,
        SystemActionKey.Remove,
        SystemActionKey.PrintQrCode,
        SystemActionKey.PrintQrCode_Inner,
        SystemActionKey.PrintQrCode_Outer,
        SystemActionKey.Print,
    ];

    /**
     * 视图筛选项
     */
    export interface IQueryItem {
        allowNull: boolean;
        defaultValue: unknown;
        filterConfigs: Array<Record<string, unknown>>;
        filterValue: string | undefined;
        organizationType: ViewScopeType;
        propertyName: string;
        visible: boolean;
        isDefault: boolean;
        associationSchemaCode?: string;
        optionalValues?: unknown[];
        dateTimeMode?: string;
        formControlType?: number;
        formControlTypeKey?: Control.FormControlKeys;
        dataType?: Control.BizDataType;
    }

    export interface IFieldPermission {
        /** 字段名称 */
        code: string;
        /** 是否可见 */
        visible: boolean;
        /** 是否可编辑 */
        editable: boolean;
    }

    export interface IGroupQueryItem {
        propertyName: string;
        isTreeStructure: boolean;
        allowNull?: boolean;
        defaultValue?: unknown;
        filterConfigs?: Array<Record<string, unknown>>;
        filterValue?: string | undefined;
        organizationType?: ViewScopeType;
        visible?: boolean;
        isDefault?: boolean;
    }

    /**
     * 格式化筛选项的筛选公式
     * @description 这个枚举是给统计分析使用的，和氚云请求列表数据筛选参数中的筛选公式不是一回事
     */
    export enum ComparisonFormula {
        /** 等于 */
        Equal = 'Equal',

        /** 不等于 */
        NotEqual = 'NotEqual',

        /** 开头为 */
        StartWith = 'StartWith',

        /** 包含 */
        In = 'In',

        /** 不包含 */
        NotIn = 'NotIn',

        /** 为空 */
        None = 'None',

        /** 不为空 */
        NotNone = 'NotNone',

        /** 模糊匹配 包含 */
        Match = 'Match',

        /** 模糊匹配 不包含 */
        NotMatch = 'NotMatch',

        /** 范围 */
        Range = 'Range',

        /** 大于 */
        Above = 'Above',

        /** 大于等于 */
        NotBelow = 'NotBelow',

        /** 小于 */
        Below = 'Below',

        /** 小于等于 */
        NotAbove = 'NotAbove',

        /** 动态筛选 */
        Dynamic = 'Dynamic',

        /** 自定义 */
        Custom = 'Custom',
    }

    /**
     * 格式化后的视图筛选项
     * @description 这个数据结构是给统计分析使用的
     */
    export type TFormattedQueryItem = {
        /** 筛选值 */
        value?: unknown[];

        /** 表单编码（字段属于某个子表时，该字段表示子表编码） */
        schemaCode?: string;

        /** 字段编码 */
        field?: string;

        /** 筛选公式 */
        formula?: ComparisonFormula;

        ands?: TFormattedQueryItem[];

        ors?: TFormattedQueryItem[];
    };

    /**
     * 列表视图可见列配置
     */
    export interface IListViewColumn {
        /** 字段编码 */
        propertyName: string;

        /** 字段是否可见 */
        visible: boolean;

        /** 字段显示名称 */
        displayName?: string;

        /** 是否子表字段 */
        isChild?: boolean;

        /** 子表字段内字段配置 */
        childColumns?: IListViewColumn[];

        /** 前端写入的控件类型 */
        formControlTypeKey?: Control.FormControlKeys;
    }

    /**
     * 列表配置
     */
    export interface IListViewSetting {
        columns: IListViewColumn[];
        iconField?: string;
        sortBy: string;
        sortDirection: SortDirection;
        defaultDisplayChildField?: string;
    }

    /**
     * 列表视图配置项
     */
    export enum ListViewSettingKey {
        Column = 'columns',
        Icon = 'iconField',
        SortBy = 'sortBy',
        SortDirection = 'sortDirection',
        ChildField = 'defaultDisplayChildField',
    }

    /**
     * 日历视图配置项
     */
    export enum CalendarViewSettingKey {
        Start = 'startField',
        End = 'endField',
    }

    /**
     * 地图视图配置项
     */
    export enum MapViewSettingKey {
        Location = 'locationField',
        SortBy = 'sortBy',
        SortDirection = 'sortDirection',
    }

    /**
     * 时间线视图配置项
     */
    export enum TimelineViewSettingKey {
        Axis = 'timelineAxis',
        SortDirection = 'sortDirection',
    }

    /**
     * 甘特图视图配置项
     */
    export enum GanttViewSettingKey {
        Start = 'startField',
        End = 'endField',
        LevelRelation = 'levelRelation',
        Dependency = 'dependency',
        Scale = 'ganttScale',
        Progress = 'progressField',
        ColorRules = 'colorRules',
        SortBy = 'sortBy',
        SortDirection = 'sortDirection',
    }

    /**
     * 视图全局配置项
     */
    export enum ViewGlobalSettingKey {
        DefaultViewMode = 'defaultViewMode',
        BatchEnabled = 'batchEnabled',
    }

    /**
     * 甘特图视图配置
     */
    export interface ICalendarViewSetting {
        startField?: string;
        endField?: string;
        timeMode?: number;
    }

    export enum CalendarTimeMode {
        Day = 1,
        Week = 2,
        Month = 3,
    }

    export interface IGanttViewSetting {
        startField: string;
        endField?: string;
        levelRelation?: string;
        dependency?: string;
        ganttScale?: GanttScaleType;
        progressField?: string;
        colorRules: IColorRule[];
        sortBy: string;
        sortDirection: SortDirection;
        propertiesAuths: IPropertiesAuth[];
    }

    export interface IColorRule {
        colorTheme: number;
        colors: IColor;
        rule: IRule;
    }

    export interface IColor {
        left: string;
        right: string;
        borderColor: string;
        textColor: string;
    }

    export interface IRule {
        formula: string;
        formulaText: string;
        fields: string[];
    }

    /**
     * 时间线视图配置
     */
    export interface ITimelineViewSetting {
        timelineAxis: string;
        sortDirection: SortDirection;
    }

    /**
     * 地图视图配置
     */
    export interface IMapViewSetting {
        locationField: string;
        sortBy: string;
        sortDirection: SortDirection;
    }

    export interface ICardViewSetting {
        sortKey: string;
        sortDirection: SortDirection;
        imagePropertyName: string;
        imagePosition: ImagePosition;
        imageDisplay: ImageDisplay;
        displayProperties: string[];
        hideDisplayName: boolean;
    }

    export interface IHierarchyViewSetting {
        sortKey: string;
        sortDirection: SortDirection;
        hierarchyField: string;
        css: HierarchyCss;
        displayProperties: string[];
    }

    export interface IQueryValueItem {
        propertyName: string;
        defaultValues: string[];
    }

    export interface IPropertiesAuth {
        propertyName: string;
        visible: boolean;
        editable: boolean;
    }

    // 视图配置模型
    export interface IViewSettingModel {
        displayName?: string; // 设计时
        timestamp?: number; // 运行时
        defaultViewMode: {
            pc: ViewMode;
            mobile: ViewMode;
        };
        batchEnabled: boolean;
        actions: IAction[];
        behindCode?: string;
        backendCode?: string;
        classNames?: string[]; // 后端代码高亮词
        javascript?: string;
        queryItems: IQueryItem[];
        groupQueryItems: IGroupQueryItem[];
        defaultGroupQueryItem?: string;
        calendar?: ICalendarViewSetting;
        list: IListViewSetting;
        gantt?: IGanttViewSetting;
        timeline?: ITimelineViewSetting;
        map?: IMapViewSetting;
        hierarchy?: IHierarchyViewSetting;
        card?: ICardViewSetting;
        queryDefaultValues: IQueryValueItem[]; // 运行时返回的筛选默认值
        scopeTypes: { key: Auth.DataScopeType; value: string }[]; // 当前用户支持的数据查看范围
        debugTrack: CustomCode.IDebugTrack | null;
    }

    // 视图配置更新模型
    export interface IViewSettingUpdateModel {
        schemaCode: string;
        defaultViewMode: {
            pc: ViewMode;
            mobile: ViewMode;
        };
        batchEnabled: boolean;
        actions: IAction[];
        behindCode?: string;
        javascript?: string;
        queryItems: IQueryItem[];
        calendar?: {
            startField?: string;
            endField?: string;
            timeMode?: number;
        };
        list: {
            columns: unknown[];
            iconField?: string;
            sortBy: string;
            sortDirection: SortDirection;
            defaultDisplayChildField?: string;
        };
        gantt?: {
            startField: string;
            endField?: string;
            levelRelation?: string;
            dependency?: string;
            ganttScale?: GanttScaleType;
            progressField?: string;
            colorRules: string[];
            sortBy: string;
            sortDirection: SortDirection;
        };
        timeline?: {
            timelineAxis: string;
            sortDirection: SortDirection;
        };
        map?: {
            locationField: string;
            sortBy: string;
            sortDirection: SortDirection;
        };
    }

    /**
     * 筛选支持的控件类型
     */
    export const FILTER_SUPPORT_TYPES = [
        Control.FormControlKeys.FormTextBox,
        Control.FormControlKeys.FormTextArea,
        Control.FormControlKeys.FormNumber,
        Control.FormControlKeys.FormDateTime,
        Control.FormControlKeys.FormDropDownList,
        Control.FormControlKeys.FormRadioButtonList,
        Control.FormControlKeys.FormCheckbox,
        Control.FormControlKeys.FormCheckboxList,
        Control.FormControlKeys.FormAreaSelect,
        Control.FormControlKeys.FormSeqNo,
        Control.FormControlKeys.FormUser,
        Control.FormControlKeys.FormDepartment,
        Control.FormControlKeys.FormOwner,
        Control.FormControlKeys.FormOwnerDepartment,
        Control.FormControlKeys.FormCreater,
        Control.FormControlKeys.FormCreatedTime,
        Control.FormControlKeys.FormModifiedTime,
        Control.FormControlKeys.FormQuery,
        Control.FormControlKeys.FormMultiQuery,
        Control.FormControlKeys.FormGridView,
        Control.FormControlKeys.FormStatus,
    ];

    /**
     * 筛选支持的系统字段
     */
    export const FILTER_SUPPORT_FIELDS = ['CreatedBy', 'OwnerId', 'OwnerDeptId', 'CreatedTime', 'ModifiedTime'];

    /**
     * 筛选支持的系统字段
     */
    export const FILTER_EXCLUDE_FIELDS = ['ActivityName', 'Participant'];

    /**
     * 排序支持的数据类型
     */
    export const SORT_SUPPORT_DATA_TYPES = [
        Control.BizDataType.ShortString,
        // Control.BizDataType.String,
        Control.BizDataType.DateTime,
        Control.BizDataType.Int,
        Control.BizDataType.Bool,
        Control.BizDataType.Decimal,
        Control.BizDataType.Double,
    ];

    /**
     * 支持排序的系统字段
     */
    export const SORT_SUPPORT_FIELDS = ['CreatedTime', 'ModifiedTime'];

    /**
     * 不支持排序的系统字段
     */
    export const SORT_NOT_SUPPORT_FIELDS = ['ActivityName', 'Participant'];

    /**
     * 列统计支持的数据类型
     */
    export const STAT_SUPPORT_DATA_TYPES = [
        Control.BizDataType.SingleParticipant,
        Control.BizDataType.MultiParticipant,
        Control.BizDataType.String,
        Control.BizDataType.ShortString,
        Control.BizDataType.StringArray,
        Control.BizDataType.DateTime,
        Control.BizDataType.Double,
        Control.BizDataType.Address,
        Control.BizDataType.Association,
        Control.BizDataType.AssociationArray,
    ];

    /**
     * 默认空值
     */
    export const NULL_VALUE = 'none';

    /**
     * 选择框的格式
     */
    export type TOption = {
        label: string;
        value: string | SortDirection | number;
    };

    /**
     * 甘特图视图时间刻度选项
     */
    export const GANTT_SCALE_TYPE_OPTIONS = [
        {
            label: '时',
            value: 1,
        },
        {
            label: '天',
            value: 2,
        },
        {
            label: '周',
            value: 3,
        },
        {
            label: '月',
            value: 4,
        },
        {
            label: '季',
            value: 5,
        },
        {
            label: '年',
            value: 6,
        },
    ];

    /**
     * 排序选项
     */
    export const SORT_DIRECTION_OPTIONS = [
        {
            value: SortDirection.Asc,
            label: '降序',
        },
        {
            value: SortDirection.Desc,
            label: '升序',
        },
    ];

    // /** 偏好类型 */
    export enum PreferenceType {
        System = 0, // 系统预置偏好（全部、我部门的、我的）
        Personal = 1, // 个人
        Public = 2, // 公共
    }

    /** 偏好集合 */
    export interface IPreferenceItem {
        Title: string;
        SystemKey?: string;
        SettingType: number;
        ScopeType: Auth.DataScopeType;
        ItemCode?: string;
        Config?: Record<string, unknown>;
        Id: string;
        Sort: number;
        // key?: string;
        Visible: boolean; // 偏好是否可见
        UserId?: string;
        Fields?: string[];
    }

    /** 快捷日期枚举 */
    export enum ShortcutDay {
        /** 今天 */
        Today = 1,
        /** 昨天 */
        Yesterday = 2,
        /** 本周 */
        CurrWeek = 3,
        /** 上周 */
        LastWeek = 4,
        /** 本月 */
        CurrMonth = 5,
        /** 上月 */
        LastMonth = 6,
        /** 本季度 */
        CurrQuarter = 7,
        /** 本年度 */
        CurrYear = 8,
        /** 过去7天内 */
        PastSevenDays = 9,
        /** 未来7天内 */
        NextSevenDays = 10,
        /** 过去30天内 */
        PastThirtyDays = 11,
        /** 未来30天内 */
        NextThirtyDays = 12,
    }

    /** 快捷年份枚举 */
    export enum ShortcutYear {
        /** 本月 */
        CurrMonth = 5,
        /** 上月 */
        LastMonth = 6,
        /** 本季度 */
        CurrQuarter = 7,
        /** 本年度 */
        CurrYear = 8,
    }

    /**
     * 日期时间筛选项
     */
    export const DATE_FILTER_OPTIONS = [
        {
            label: '当天',
            value: ShortcutDay.Today,
        },
        {
            label: '昨天',
            value: ShortcutDay.Yesterday,
        },
        {
            label: '本周',
            value: ShortcutDay.CurrWeek,
        },
        {
            label: '本月',
            value: ShortcutDay.CurrMonth,
        },
        {
            label: '上月',
            value: ShortcutDay.LastMonth,
        },
        {
            label: '本季度',
            value: ShortcutDay.CurrQuarter,
        },
        {
            label: '本年度',
            value: ShortcutDay.CurrYear,
        },
    ];

    export enum FilterType {
        Text = 'text',
        Time = 'time',
        Number = 'number',
        Unit = 'unit',
        Address = 'address',
        DropDown = 'dropdown',
        Query = 'query',
    }

    /**
     * 控件筛选对应类型配置
     */
    export const CONTROL_FILTER_TYPE_MAP = {
        [Control.FormControlKeys.FormTextBox]: FilterType.Text,
        [Control.FormControlKeys.FormTextArea]: FilterType.Text,
        [Control.FormControlKeys.FormNumber]: FilterType.Number,
        [Control.FormControlKeys.FormDateTime]: FilterType.Time,
        [Control.FormControlKeys.FormDropDownList]: FilterType.DropDown,
        [Control.FormControlKeys.FormRadioButtonList]: FilterType.DropDown,
        [Control.FormControlKeys.FormCheckbox]: FilterType.DropDown,
        [Control.FormControlKeys.FormCheckboxList]: FilterType.DropDown,
        [Control.FormControlKeys.FormAreaSelect]: FilterType.Address,
        [Control.FormControlKeys.FormSeqNo]: FilterType.Text,
        [Control.FormControlKeys.FormUser]: FilterType.Unit,
        [Control.FormControlKeys.FormDepartment]: FilterType.Unit,
        [Control.FormControlKeys.FormOwner]: FilterType.Unit,
        [Control.FormControlKeys.FormOwnerDepartment]: FilterType.Unit,
        [Control.FormControlKeys.FormCreater]: FilterType.Unit,
        [Control.FormControlKeys.FormCreatedTime]: FilterType.Time,
        [Control.FormControlKeys.FormModifiedTime]: FilterType.Time,
        [Control.FormControlKeys.FormQuery]: FilterType.Query,
        [Control.FormControlKeys.FormMultiQuery]: FilterType.Query,
    };

    /** 视图运行时命名空间 */
    export namespace Runtime {
        /** 视图渲染场景 */
        export enum ViewScene {
            /** 普通场景 */
            Normal = 'Normal',

            /** 筛选关联表单控件打开列表 */
            FilterQuery = 'FilterQuery',

            /** 关联列表 */
            AssociationList = 'AssociationList',

            /** 表单关联表单控件打开列表 */
            FormQuery = 'FormQuery',
        }

        /** 普通场景视图参数 */
        export type NormalSceneOptions = {
            /** 应用编码 */
            appCode: string;
            /** 表单编码 */
            schemaCode?: string;
        };

        /** 筛选关联表单控件打开列表场景视图参数 */
        export type FilterQuerySceneOptions = {
            /** 获取级联筛选数据的方法 */
            getCascaderFilterData?: () => Record<string, unknown>;
        };

        /** 关联列表场景视图参数 */
        export type AssociationListSceneOptions = {
            /** 关联列表场景传入源表单编码 */
            sourceSchemaCode: string;

            /** 关联列表场景传入源表单数据id */
            sourceBizObjectId: string;

            /** 关联字段 */
            mappingProperty: string;

            /** 缓存的筛选数据 */
            cachedFilterData?: Record<string, unknown[]>;
        };

        /** 表单关联表单控件打开列表场景视图参数 */
        export type FormQuerySceneOptions = {
            /** 源表单编码 */
            sourceSchemaCode: string;

            /** 源关联表单字段 */
            propertyName: string;

            /** 是否默认列配置 */
            isDefaultColumn: boolean;

            /** 获取数据限定范围筛选参数方法 */
            getAssociationFilterData?: () => BizData.Filter.IMatcherFilter['expressionFilter'];
        };

        /** 不同场景打开表单传递的参数 */
        export type ViewSceneOptions =
            | NormalSceneOptions
            | FilterQuerySceneOptions
            | AssociationListSceneOptions
            | FormQuerySceneOptions;

        /** 运行时视图配置 */
        export type ViewSetting = {
            /** 日历视图配置 */
            calendar?: ICalendarViewSetting | null;

            /** 甘特图视图配置 */
            gantt?: IGanttViewSetting | null;

            /** 列表视图配置 */
            list: IListViewSetting;

            /** 地图视图配置 */
            map?: IMapViewSetting | null;

            /** 时间线视图配置 */
            timeline?: ITimelineViewSetting | null;

            /** 卡片视图配置 */
            card?: ICardViewSetting | null;

            /** 层级视图配置 */
            hierarchy?: IHierarchyViewSetting | null;
        };

        /** 运行时不同场景功能配置 */
        export type TFunctionSwitch = {
            /** 是否显示功能按钮 */
            action: boolean;

            /** 是否允许新增 */
            creatable: boolean;

            /** 新增的数据是否默认选中 */
            defaultSelectCreated: boolean;

            /** 是否显示筛选条件 */
            filter: boolean;

            /** 筛选条件是否支持默认值 */
            defaultFilter: boolean;

            /** 是否支持编辑 */
            edit: boolean;

            /** 是否支持统计分析 */
            analysis: boolean;

            /** 是否支持视图列表切换 */
            viewList: boolean;

            /** 是否支持偏好 */
            preference: boolean;

            /** 是否支持配置列显示 */
            configColumn: boolean;

            /** 配置列时是否允许重新排序列 */
            reorderColumn: boolean;

            /** 是否支持切换子表 */
            switchGrid: boolean;

            /** 是否显示默认子表 */
            showDefaultGrid: boolean;

            /** 是否缓存个性化配置 */
            cachePersonalization: boolean;

            /** 是否支持列统计 */
            summary: boolean;

            /** 是否支持额外列配置 */
            extraColumn: boolean;
        };
    }

    export namespace Request {
        export interface IGetDesignerViewSettingModel {
            /** 表单编码 */
            schemaCode: string;
        }

        export interface IGetViewSettingModel {
            /** 表单编码 */
            schemaCode: string;
            /** 请求时间戳，用于判断是否需要使用缓存 */
            timestamp: number;
        }
        export interface IGetViewPreferenceModel {
            /** 表单编码 */
            schemaCode: string;
        }
        export interface IAddViewPreferenceModel {
            /** 表单编码 */
            schemaCode: string;
            title: string;
            scopeType: unknown;
            settingType: unknown;
            config: unknown;
            visible?: boolean;
            fields?: string[];
        }
        export interface IUpdateViewPreferenceModel {
            /** 表单编码 */
            schemaCode: string;
            title: string;
            scopeType: unknown;
            settingType: unknown;
            config: unknown;
            id: string;
            visible?: boolean;
            fields?: string[];
        }
        export interface IRemoveViewPreferenceModel {
            /** 表单编码 */
            schemaCode: string;
            id: string;
        }
        export interface ISortViewPreferenceModel {
            /** 表单编码 */
            schemaCode: string;
            ids: string[];
        }
    }

    export namespace Response {
        /** 视图加载数据中的文件字段值类型 */
        export type TLoadDataFile = {
            /** 文件id */
            FileId: string;

            /** 文件名 */
            FileName: string;

            /** 文件大小 */
            FileSize: string;

            /** 文件id */
            ObjectId: string;

            /** 文件缩略图 */
            ThumbnailUrl: string;

            /** 文件地址 */
            Url: string;

            /** 文件类型（MIME） */
            ContentType: string;

            /** 文件描述 */
            Description?: string;

            /** 复制出来的文件对应的源文件的FileId */
            SourceFileId?: string;
        };

        /** 运行时加载视图配置 */
        export type TLoadRuntimeViewSetting = {
            /** 视图按钮 */
            actions: IAction[];

            /** 是否支持批量操作 */
            batchEnabled: boolean;

            /** 视图后端自定义代码 */
            behindCode: string | null;

            /** 自定义代码调试栈信息 */
            debugTrack: CustomCode.IDebugTrack | null;

            /** 默认视图模式 */
            defaultViewMode: {
                /** PC端默认视图模式 */
                pc: ViewMode;

                /** 移动端默认视图模式 */
                mobile: ViewMode;
            };

            /** 视图前端自定义代码 */
            javascript: string | null;

            /** 筛选条件默认值 */
            queryDefaultValues: IQueryValueItem[];

            /** 筛选条件 */
            queryItems: IQueryItem[];

            /** 分组筛选 */
            groupQueryItems: IGroupQueryItem[];

            /** 默认钉住的分组 */
            defaultGroupQueryItem?: string | null;

            /** 当前用户支持的数据查看范围 */
            scopeTypes: Array<{ key: Auth.DataScopeType; value: string }>;

            /** 日历视图配置 */
            calendar: ICalendarViewSetting | null;

            /** 甘特图视图配置 */
            gantt: IGanttViewSetting | null;

            /** 列表视图配置 */
            list: IListViewSetting;

            /** 地图视图配置 */
            map: IMapViewSetting | null;

            /** 时间线视图配置 */
            timeline: ITimelineViewSetting | null;

            card: ICardViewSetting | null;

            hierarchy: IHierarchyViewSetting | null;

            /** 缓存时间戳 */
            timestamp: number;

            /** 权限 */
            fieldAcls: IFieldPermission[];
        };
    }
}
