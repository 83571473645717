import request from '@h3/request';
import { H3 } from '@h3/types';

/** 获取钉钉签名 */
export const getDingTalkSignature = (params: unknown) =>
    request<H3.Http.Response.H3Response<{ dingTalkSignature: H3.Entry.ThirdParty.ISignature }>>('/Signature/DingTalk', {
        method: 'get',
        params: params as Record<string, unknown>,
        camelCase: true,
    });

/**
 * 分享表单到钉钉会话
 * @param schemaCode 表单编码
 * @param bizObjectId 数据id
 * @param title 分享标题
 * @param cid 会话id
 */
export function shareFormToConversation(schemaCode: string, bizObjectId: string, title: string, cid: string) {
    return request<H3.Http.Response.H3ResponseV2<void>>('/Mobile/MobileService/OnAction', {
        method: H3.Http.MethodType.POST,
        data: {
            PostData: JSON.stringify({
                ActionName: 'ShareSheet',
                SchemaCode: schemaCode,
                BizObjectId: bizObjectId,
                Title: title,
                Cid: cid,
                Text: null,
                TargetId: null,
            }),
        },
        requestType: 'form',
        disableBizError: true,
    });
}
