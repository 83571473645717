import { H3 } from '@h3/types';

export class TableViewSetting {
    [H3.DB.DBField.ID]!: string;
    [H3.DB.DBField.TIMESTAMP]?: number;
    [H3.DB.DBField.VALUE]: H3.View.IViewSettingModel | null = null;

    constructor({ id, value }: { [H3.DB.DBField.ID]: string; [H3.DB.DBField.VALUE]: H3.View.IViewSettingModel }) {
        this.id = id;
        this.timestamp = value.timestamp;
        this.value = value;
    }
}
