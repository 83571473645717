export namespace Formula {
    export enum FormulaStageType {
        /** 隐藏规则 */
        SchemaDisplay = 'SCHEMADISPLAY',
        /** 计算规则 */
        SchemaComputation = 'SCHEMACOMPUTATION',
        /** 路由跳转规则 */
        Bool = 'BOOL',
        /** 表单提交校验规则 */
        SchemaSubmit = 'SCHEMASUBMIT',
        /** 业务规则 */
        Business = 'BUSINESS',
        /** 报表数据源 */
        DataSource = 'DATASOURCE',
        /** 关联查询过滤 */
        AssociationFilter = 'ASSOCIATIONFILTER',
        /** 推送提醒 */
        ScheduleRule = 'SCHEDULERULE',
        /** 自动化 */
        AutoMation = 'AUTOMATION',
        /** 自动化条件 */
        AutoMationUpdateField = 'AUTOMATIONUPDATEFIELD',
        /** 条件表达式 */
        Expression = 'EXPRESSION',
        /** 颜色规则 */
        ColorRule = 'COLORRULE',
    }

    export type FormulaFunctionInfo = {
        /** 函数名称 */
        name: string;
        /** 函数用法 */
        usage: string | null;
        /** 函数举例 */
        example: string;
        /** 函数描述 */
        description: string;
    };

    export namespace Request {
        export type TFormulaFunctionListModel = {
            type: FormulaStageType;
        };
    }

    export namespace Response {
        export type TFormulaFunctionListModel = Record<string, FormulaFunctionInfo[]>;
    }
}
