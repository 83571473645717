import { App } from './domain/app';
import { BizData } from './domain/data';
import { Entry } from './domain/entry';
import { Http } from './domain/http';
import { Control } from './domain/control';
import { FunctionNode } from './domain/node';
import { Org } from './domain/org';
import { Oss } from './domain/oss';
import { DashBoard } from './domain/dashboard';
import { Schema } from './domain/schema';
import { Task } from './domain/task';
import { WorkBench } from './domain/workbench';
import { View } from './domain/view';
import { Engine } from './domain/engine';
import { PCT } from './domain/pct';
import { Form } from './domain/form';
import { Exception } from './domain/exception';
import { Operate } from './domain/operate';
import { Auth } from './domain/auth';
import { Log } from './domain/log';
import { CustomCode } from './domain/custom-code';
import { AI } from './domain/ai';
import { Print } from './domain/print';
import { Workflow } from './domain/workflow';
import { Util } from './domain/util';
import { Export } from './domain/export';
import { Import } from './domain/import';
import { ThirdParty } from './domain/third-party';
import { Plugin } from './domain/plugin';
import { Automation } from './domain/automation';
import { UI } from './domain/ui';
import { Filter } from './domain/filter';
import { AsyncTask } from './domain/async-task';
import { Button } from './domain/button';
import { DB } from './domain/db';
import { Formula } from './domain/formula';
import { Tree } from './domain/tree';
import { GlobalSearch } from './domain/global-search';
import { Message } from './domain/message';
import { AMap } from './domain/amap';

const H3 = {
    App,
    Auth,
    BizData,
    Entry,
    Http,
    Control,
    FunctionNode,
    Org,
    Oss,
    DashBoard,
    Schema,
    Task,
    WorkBench,
    View,
    Engine,
    PCT,
    Form,
    Exception,
    Operate,
    Log,
    CustomCode,
    AI,
    Print,
    Workflow,
    Util,
    Export,
    Import,
    ThirdParty,
    Plugin,
    Automation,
    UI,
    Filter,
    AsyncTask,
    Button,
    DB,
    Formula,
    Tree,
    GlobalSearch,
    Message,
    AMap,
};
export { H3 };
