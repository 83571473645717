import { request } from '@h3/request';
import { H3 } from '@h3/types';

/**
 * 获取异步任务列表
 * @param sceneCodes 需要的任务场景编码，不传则返回所有任务
 * @returns 异步任务列表
 */
export function getTaskList(sceneCodes?: H3.AsyncTask.TaskScene[]) {
    return request<H3.Http.Response.H3Response<H3.AsyncTask.Response.TGetTaskList[]>>(
        '/v1/toolkit/asynctask/visibletasks',
        {
            method: H3.Http.MethodType.POST,
            data: {
                sceneCodes,
            },
        },
    );
}

/**
 * 设置指定任务不显示
 * @param taskIds 任务id列表
 * @returns 是否隐藏成功
 */
export function disVisibleTask(taskIds: string[]) {
    return request<H3.Http.Response.H3Response<boolean>>('/v1/toolkit/asynctask/tasks/setdisvisible', {
        method: H3.Http.MethodType.POST,
        data: {
            taskIds,
        },
    });
}

/**
 * 查询给定任务的进度
 * @param taskIds 任务id列表
 * @returns 任务id到任务进度数据的映射
 */
export function getProgress(taskIds: string) {
    return request<H3.Http.Response.H3Response<Record<string, H3.AsyncTask.Response.TGetTaskProgress>>>(
        '/v1/asynctask/progress',
        {
            method: H3.Http.MethodType.POST,
            data: {
                taskIds,
            },
        },
    );
}

/**
 * 获取公式型控件执行记录
 * @param taskId 异步任务id
 * @param successful 执行成功与否筛选，null表示不筛选
 * @param failType 执行失败类型筛选，null表示不筛选
 * @param pageIndex 分页索引
 * @param pageSize 分页大小
 * @returns 公式型控件执行记录
 */
export function getFormulaChangedExeRecord(
    taskId: string,
    successful: boolean | null,
    failType: 'fail' | 'abort' | null,
    pageIndex: number,
    pageSize: number,
) {
    return request<H3.Http.Response.H3Response<H3.AsyncTask.Response.TGetFormulaChangedExeRecord>>(
        '/v1/toolkit/bizlog/asynctask/formulachanged',
        {
            method: H3.Http.MethodType.POST,
            data: {
                taskId,
                successful,
                failType,
                pageIndex,
                pageSize,
            },
        },
    );
}
