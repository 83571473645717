import { H3 } from '@h3/types';
import { request } from '@h3/request';
import * as Api from './constant';

/** dingtalk-ocr */
export const invokeDingTalkOcr = (data: { type: H3.Control.OCRService; fileId: string }) => {
    return request<H3.Http.Response.H3Response<H3.Plugin.PluginInvokeModel[]>>(Api.PluginInvoke, {
        method: H3.Http.MethodType.POST,
        data,
    });
};
